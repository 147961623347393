import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "target-select-month" }
const _hoisted_2 = { class: "target-select-month__opt" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElBadge = _resolveComponent("ElBadge")
  const _component_ElOption = _resolveComponent("ElOption")
  const _component_ElSelect = _resolveComponent("ElSelect")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElBadge, {
      class: "target-select-month__badge",
      value: _ctx.total,
      hidden: !_ctx.total || true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElSelect, {
          class: "target-select-month__select",
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.value) = $event)),
          onChange: _ctx.change
        }, {
          prefix: _withCtx(() => [
            _createVNode(_component_ElBadge, {
              value: _ctx.curOpt?.count,
              hidden: !_ctx.curOpt?.count || true
            }, null, 8 /* PROPS */, ["value", "hidden"])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
              return (_openBlock(), _createBlock(_component_ElOption, {
                class: "target-select-month__opt",
                key: item.value,
                label: item.label,
                value: item.value
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ElBadge, {
                    class: "target-select-month__opt-badge",
                    value: item.count,
                    hidden: !item.count || true
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, _toDisplayString(item.label), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "hidden"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "onChange"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["value", "hidden"])
  ]))
}