export default {
	name: 'filter-info',
	props: {
		listData: Array,
	},
	data() {
		return {
			// listData: [
			// 	{
			// 		title: 'РК',
			// 		count: '476',
			// 	},
			// 	{
			// 		title: 'РС',
			// 		count: '2980',
			// 	},
			// ],
		}
	},
}