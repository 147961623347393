import cellMixin from "@/app/mixins/table/cell-mixin";

import CellEditable from "@/app/components/table/cell-types/cell-editable/CellEditable.vue";
import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'cell-edit-link',
	mixins: [ cellMixin ],
	emits:['change'],
	components: {
		CellEditable, SvgIcon,
	},
	methods: {
		change(data){
			this.$emit('change', data);
		},

		openLink(){
			window.open(this.fieldData, "_blank");
		}
	},
}