import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "edit-comment" }
const _hoisted_2 = { class: "edit-comment__head" }
const _hoisted_3 = { class: "edit-comment__head-label" }
const _hoisted_4 = { class: "edit-comment__content" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_DiEditTextarea = _resolveComponent("DiEditTextarea")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1 /* TEXT */),
      _createVNode(_component_SvgIcon, {
        class: "edit-comment__head-edit",
        name: "14-edit-pen",
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.isVisibleDialog=true))
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass(["edit-comment__text", { '--is-text-show': _ctx.isShowText }]),
        ref: "refTextComment"
      }, _toDisplayString(_ctx.comment), 3 /* TEXT, CLASS */),
      _createVNode(_Transition, {
        name: "el-fade-in",
        mode: "out-in",
        persisted: ""
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", {
            class: "edit-comment__toggle-show",
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.isShowText = !_ctx.isShowText))
          }, _toDisplayString(_ctx.labelToggleBtn), 513 /* TEXT, NEED_PATCH */), [
            [_vShow, _ctx.isShowToggleBtn]
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createVNode(_component_DiEditTextarea, {
      visible: _ctx.isVisibleDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.isVisibleDialog) = $event)),
      titleDialog: _ctx.titleDialog,
      fieldValue: _ctx.comment,
      onChange: _ctx.changeEdit
    }, null, 8 /* PROPS */, ["visible", "titleDialog", "fieldValue", "onChange"])
  ]))
}