import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "side-queue-popup__content" }
const _hoisted_2 = { class: "side-queue-popup__wrap" }
const _hoisted_3 = {
  key: 0,
  class: "side-queue-popup__divider"
}
const _hoisted_4 = {
  key: 1,
  class: "side-queue-popup__wrap"
}
const _hoisted_5 = { class: "side-queue-popup__action-buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SideQueueItem = _resolveComponent("SideQueueItem")
  const _component_SideQueuePersonalItem = _resolveComponent("SideQueuePersonalItem")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "side-queue-popup",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.isVisible) = $event)),
    "append-to-body": true,
    "destroy-on-close": true,
    title: _ctx.title,
    width: "620",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.firstQueueList, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
              (!item.inSelectedTargetProgram)
                ? (_openBlock(), _createBlock(_component_SideQueueItem, {
                    key: 0,
                    class: "side-queue-popup__item",
                    data: item
                  }, null, 8 /* PROPS */, ["data"]))
                : (_openBlock(), _createBlock(_component_SideQueuePersonalItem, {
                    key: 1,
                    class: "side-queue-popup__item",
                    data: item
                  }, null, 8 /* PROPS */, ["data"]))
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        (_ctx.list?.queue.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("v-if", true),
        (_ctx.list?.queue.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.queue, (queueItem) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: queueItem }, [
                  (!queueItem.inSelectedTargetProgram)
                    ? (_openBlock(), _createBlock(_component_SideQueueItem, {
                        key: 0,
                        class: "side-queue-popup__item",
                        data: queueItem
                      }, null, 8 /* PROPS */, ["data"]))
                    : (_openBlock(), _createBlock(_component_SideQueuePersonalItem, {
                        key: 1,
                        class: "side-queue-popup__item",
                        data: queueItem
                      }, null, 8 /* PROPS */, ["data"]))
                ], 64 /* STABLE_FRAGMENT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BsBtn, {
          type: "primary",
          tag: "div",
          size: "small",
          onClick: _ctx.cancel
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Ок")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}