import { defineStore } from 'pinia';
import dayjs from 'dayjs';

import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';

const fakeOptions = [
	{
		value: 2,
		label: 'Значение 2',
	},
	{
		value: 3,
		label: 'Значение 3',
	},
	{
		value: 4,
		label: 'Значение 4',
	},
	{
		value: 5,
		label: 'Значение 5',
	},
	{
		value: 6,
		label: 'Значение 6',
	},
];

export const usePricingDetailStore = defineStore('pricingDetailStore', {
	state() {
		return {
			isViewLoader: false,
			isAsideLoader: false,

			summaryData: {
				id: 122733,
				title: 'Название прайсовой политики',
				createDate: new Date(),
				updateDate: new Date(),
				status: { value: 1, options: [
					{ value: 1, label: 'Черновик' },
					...fakeOptions,
				]},
				departments: {
					value: [1, 20, 30],
					options: [
						{ value: 1, label: 'ФРА' },
						{ value: 20, label: 'ОП Москва' },
						{ value: 30, label: 'ОП Санкт-Петербург' },
						...fakeOptions,
					],
				},
				customers: { value: [], options: fakeOptions },
				isShowAll: true,
				responsibleManager: {
					value: 16,
					options: [
						{
							id: 16,
							value: 16,
							label: 'Алексеев Артур',
							firstName: "Артур",
							lastName: "Алексеев",
							department: null,
							phone: '899999999',
							tgId: 'artur',
							email: 'test@test.ru',
						},
						...fakeOptions,
					],
				},
				comment: 'Не следует, однако забывать, что консультация с широким активом обеспечивает широкому кругу (специалистов) участие в формировании форм развития.',
				isBanOnChange: true,
			},

			filterData: {
				department: '',
				role: '',
				user: '',
				city: '',
				constructType: '',
				side: '',
				size: '',
				mechanism: '',
				operator: '',
				gids: '',
				fractionEther: '',
				daysInMonth: '',
				monthType: '',
				promotion: '',
				incompletePeriod: '',
			},

			tableData: [
				{
					id: 123,
					status: true,
					pos: 1,
					createdAt: new Date(),
					updatedAt: new Date(),
					avgPriceMonth: 52339,
					countStructure: {
						structs: 7600,
						sides: 14000,
						surfaces: 29000
					},
					priceMonth: 6983,
					targetDiscount: 56,
					maxDiscount: 70,
					curMonthDiscount: 60,
					coefficient: 1,
					isMultipCoefficient: true,
					comment: 'Не следует, однако забывать,что консультация с широким активом обеспечивает широкому кругу (специалистов) участие в формировании форм развития.',
					rules: {
						"department": 19,
						"customer": 2508,
						"customerLegalAddService": 2747,
						"customerCategory": 4,
						"cities": [ 50, 34, 61, 121, 62, 10, 49, 44, 91, 75, 100 ],
						"structureType": 2,
						"direction": 2,
						"size": 14,
						"mechanism": 3,
						"operator": 7,
						"net": "local",
						"fractionEther": "123"
					}
				},
			],
		}
	},

	getters: {
		countFilters(){
			let count = 0;

			for(const [key, value] of Object.entries(this.filterData)){
				if(value || value?.length) count++;
			}

			return count;
		}
	},
	actions: {
		clearFilters(){
			for(const [key, value] of Object.entries(this.filterData)){
				this.filterData[key] = '';
			}
		}
	},
});