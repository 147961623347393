import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "import-place-periods" }
const _hoisted_2 = {
  key: 0,
  class: "import-place-periods__wrap"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "import-place-periods__employ"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ImportCellStatus = _resolveComponent("ImportCellStatus")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.fieldData?.value?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.periods, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "import-place-periods__item",
              onClick: $event => (_ctx.clickPeriod(item))
            }, _toDisplayString(item.format.from) + "-" + _toDisplayString(item.format.to), 9 /* TEXT, PROPS */, _hoisted_3))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, "–")),
    _createVNode(_component_ImportCellStatus, {
      data: { row: _ctx.rowData },
      groupKey: _ctx.groupKey,
      fieldKey: _ctx.fieldKey
    }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
  ]))
}