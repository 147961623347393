import { ElMessageBox } from 'element-plus';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import TargetManager from '@/app/components/target-programs/target-summary/target-manager/TargetManager.vue';
import DiEditSelect from '@/app/components/controls/edit-field/di-edit-select/DiEditSelect.vue';

export default {
	name: 'target-manager-list',
	components: {
		SvgIcon, TargetManager, DiEditSelect,
	},
	props:{
		fieldData: {
			type: Object,
			default: {
				value: [],
				options: [],
			}
		},
	},
	emits: ['change', 'onRemove'],
	data() {
		return {
			isVisible: false,
		}
	},
	computed: {
		managerList(){
			const { value, options } = this.fieldData;
			console.log(this.fieldData);

			if(value && Array.isArray(value)){
				return value.map(id=>({
					value: [id],
					options,
				}));
			} else if(!value){
				return [];
			}
		},
	},
	methods: {
		clickAdd(){
			this.isVisible = true;
		},
		changeEdit(value){
			this.$emit('change', { value, fieldData: this.fieldData });
		},
		remove(id){
			// console.log(id);
			ElMessageBox.confirm(null, null, {
				title: 'Внимание',
				message: 'Убрать менеджера?',
				showClose: false,
				// dangerouslyUseHTMLString: true,
				customClass: 'bs-message-box',
				type: 'warning',
			}).then(() => {
				this.$emit('onRemove', {id, fieldData: this.fieldData});
			}).catch(() => {

			})
		}
	},
}