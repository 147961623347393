import dayjs from "dayjs";
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import helper from "@/app/utils/helper";

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'datepicker-month-range',
	components: {
		SvgIcon,
	},
	props: {
		period: {
			type: Object,
			default: {},
		}
	},
	emits: ['update:period'],
	data() {
		return {
			datepicker: null,
			selectPeriod: null,
			viewDate: '',
		}
	},
	computed: {
		compPeriod(){
			let { from, to } = this.period;
			let year = dayjs().year();
			let month = dayjs().month();

			if(!from) from = dayjs({ year, month, day: 1 }).format('YYYY-MM-DD');
			if(!to) to = dayjs(from).add(11, 'month').format('YYYY-MM-DD');

			return {
				from, to,
				format:{
					from: dayjs(from).format('DD.MM'),
					to: dayjs(to).format('DD.MM'),
				}
			};
		},
		year(){
			let { from } = this.compPeriod;

			if(this.viewDate) from = this.viewDate;
			return dayjs(from).format('YYYY');
		},
	},
	methods: {
		initDatepicker(){
			const { datepickerRef } = this.$refs;
			const { from, to } = this.compPeriod;

			this.datepicker = new AirDatepicker(datepickerRef, {
				inline: true,
				range: true,
				view: 'months',
				minView: 'months',
				// dateFormat: 'MMMM yyyy',
				startDate: from,
				// minDate, maxDate,
				selectedDates: [
					dayjs(from).toDate(),
					dayjs(to).toDate(),
				],

				onRenderCell: (data)=>{
					// console.log(data);
					const { date } = data;
					let month = dayjs(date).format('MMM');
					month = helper.upFirstLetter(month).replace('.', '');

					return {
						html: `<div class="cell-month">${ helper.upFirstLetter(month) }</div>`,
						// classes: this.getClassStatus(date),
					};
				},
				onSelect: (data)=>{
					console.log(data);
					const { date } = data;
					if(!date?.length) return;

					const [ from, to ] = date;
					if(date?.length==1){
						this.selectPeriod = {
							from,
							to: from,
						}
					}
					else if(date?.length==2){
						this.selectPeriod = { from, to };
					}
					else {
						this.selectPeriod = this.compPeriod;
					}

					this.$emit('update:period', this.selectPeriod);
				}
			});
		},

		clickPrev(){
			this.datepicker.prev();
			this.viewDate = this.datepicker.viewDate;
		},
		clickNext(){
			this.datepicker.next();
			this.viewDate = this.datepicker.viewDate;
		}
	},
	mounted() {
		this.initDatepicker();
	},
}