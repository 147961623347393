import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "tprog-all-table" }
const _hoisted_2 = {
  key: 0,
  class: "tprog-all-table__head-cell"
}
const _hoisted_3 = {
  key: 1,
  class: "tprog-all-table__head-cell"
}
const _hoisted_4 = { class: "tprog-all-table__cell cell-type-date" }
const _hoisted_5 = { class: "tprog-all-table__cell cell-type-title" }
const _hoisted_6 = {
  key: 0,
  class: "tprog-all-table__cell cell-type-responsible"
}
const _hoisted_7 = {
  key: 1,
  class: "tprog-all-table__cell cell-type-responsible"
}
const _hoisted_8 = { class: "tprog-all-table__cell cell-type-city" }
const _hoisted_9 = { class: "tprog-all-table__cell" }
const _hoisted_10 = { class: "tprog-all-table__cell cell-type-status" }
const _hoisted_11 = { class: "tprog-all-table__cell cell-type-price" }
const _hoisted_12 = { class: "tprog-all-table__cell cell-type-notific" }
const _hoisted_13 = { class: "tprog-all-table__cell cell-type-other" }
const _hoisted_14 = {
  key: 0,
  class: "tprog-all-table__cell"
}
const _hoisted_15 = {
  key: 1,
  class: "tprog-all-table__cell"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_HeadBtnSort = _resolveComponent("HeadBtnSort")
  const _component_TprogCellDate = _resolveComponent("TprogCellDate")
  const _component_TprogCellTitleLink = _resolveComponent("TprogCellTitleLink")
  const _component_TprogCellCity = _resolveComponent("TprogCellCity")
  const _component_TprogCellPeriod = _resolveComponent("TprogCellPeriod")
  const _component_TprogCellStatus = _resolveComponent("TprogCellStatus")
  const _component_CellPrice = _resolveComponent("CellPrice")
  const _component_TprogCellNotific = _resolveComponent("TprogCellNotific")
  const _component_TprogCellOther = _resolveComponent("TprogCellOther")
  const _component_ElTableColumn = _resolveComponent("ElTableColumn")
  const _component_ElTable = _resolveComponent("ElTable")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElTable, {
      class: "tprog-all-table__container",
      stripe: "",
      data: _ctx.tableData,
      onRowDblclick: _ctx.dbClickRow,
      onSelectionChange: _ctx.changeTable
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colConfigList, (col) => {
          return (_openBlock(), _createBlock(_component_ElTableColumn, {
            key: col.key,
            prop: col.key,
            label: col.label,
            "min-width": col.width,
            resizable: !['designInfo', 'employ', 'queue'].includes(col.key),
            fixed: (col.key=='designInfo')?'left':false,
            "class-name": !['notification', 'other'].includes(col.key)?'tprog-all-table__cell-padx-16':'',
            "header-align": "left",
            align: "left"
          }, _createSlots({
            header: _withCtx((scope) => [
              (['notification', 'other'].includes(col.key))
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (col.key=='notification')
                      ? (_openBlock(), _createBlock(_component_SvgIcon, {
                          key: 0,
                          class: "icon-bell",
                          name: "16-bell"
                        }))
                      : _createCommentVNode("v-if", true),
                    (col.key=='other')
                      ? (_openBlock(), _createBlock(_component_SvgIcon, {
                          key: 1,
                          class: "icon-more-dots",
                          name: "14-more-dots"
                        }))
                      : _createCommentVNode("v-if", true)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createTextVNode(_toDisplayString(col.label), 1 /* TEXT */),
                    (col.sort)
                      ? (_openBlock(), _createBlock(_component_HeadBtnSort, {
                          key: 0,
                          modelValue: col.order,
                          "onUpdate:modelValue": $event => ((col.order) = $event),
                          icoName: "14-caret-bottom",
                          onChange: $event => (_ctx.sortTable(col.order, col.keyId))
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onChange"]))
                      : _createCommentVNode("v-if", true)
                  ]))
            ]),
            _: 2 /* DYNAMIC */
          }, [
            (['createdDate', 'updatedDate'].includes(col.key))
              ? {
                  name: "default",
                  fn: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_TprogCellDate, {
                        data: scope,
                        fieldKey: col.key
                      }, null, 8 /* PROPS */, ["data", "fieldKey"])
                    ])
                  ]),
                  key: "0"
                }
              : (col.key=='title')
                ? {
                    name: "default",
                    fn: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_TprogCellTitleLink, {
                          data: scope,
                          fieldKey: col.key
                        }, null, 8 /* PROPS */, ["data", "fieldKey"])
                      ])
                    ]),
                    key: "1"
                  }
                : (col.key=='responsible')
                  ? {
                      name: "default",
                      fn: _withCtx((scope) => [
                        (scope.row[col.key])
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(scope.row[col.key]), 1 /* TEXT */))
                          : (_openBlock(), _createElementBlock("div", _hoisted_7, "–"))
                      ]),
                      key: "2"
                    }
                  : (col.key=='city')
                    ? {
                        name: "default",
                        fn: _withCtx((scope) => [
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_TprogCellCity, {
                              data: scope,
                              fieldKey: col.key
                            }, null, 8 /* PROPS */, ["data", "fieldKey"])
                          ])
                        ]),
                        key: "3"
                      }
                    : (col.key=='period')
                      ? {
                          name: "default",
                          fn: _withCtx((scope) => [
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_TprogCellPeriod, {
                                data: scope,
                                fieldKey: col.key
                              }, null, 8 /* PROPS */, ["data", "fieldKey"])
                            ])
                          ]),
                          key: "4"
                        }
                      : (col.key=='status')
                        ? {
                            name: "default",
                            fn: _withCtx((scope) => [
                              _createElementVNode("div", _hoisted_10, [
                                _createVNode(_component_TprogCellStatus, {
                                  data: scope,
                                  fieldKey: col.key
                                }, null, 8 /* PROPS */, ["data", "fieldKey"])
                              ])
                            ]),
                            key: "5"
                          }
                        : (col.key=='priceNotVAT')
                          ? {
                              name: "default",
                              fn: _withCtx((scope) => [
                                _createElementVNode("div", _hoisted_11, [
                                  _createVNode(_component_CellPrice, {
                                    data: scope,
                                    fieldKey: col.key
                                  }, null, 8 /* PROPS */, ["data", "fieldKey"])
                                ])
                              ]),
                              key: "6"
                            }
                          : (col.key=='notification')
                            ? {
                                name: "default",
                                fn: _withCtx((scope) => [
                                  _createElementVNode("div", _hoisted_12, [
                                    _createVNode(_component_TprogCellNotific, {
                                      data: scope,
                                      fieldKey: col.key
                                    }, null, 8 /* PROPS */, ["data", "fieldKey"])
                                  ])
                                ]),
                                key: "7"
                              }
                            : (col.key=='other')
                              ? {
                                  name: "default",
                                  fn: _withCtx((scope) => [
                                    _createElementVNode("div", _hoisted_13, [
                                      _createVNode(_component_TprogCellOther, {
                                        data: scope,
                                        fieldKey: col.key
                                      }, null, 8 /* PROPS */, ["data", "fieldKey"])
                                    ])
                                  ]),
                                  key: "8"
                                }
                              : {
                                  name: "default",
                                  fn: _withCtx((scope) => [
                                    (scope.row[col.key] != undefined)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(scope.row[col.key]), 1 /* TEXT */))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_15, "–"))
                                  ]),
                                  key: "9"
                                }
          ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "min-width", "resizable", "fixed", "class-name"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "onRowDblclick", "onSelectionChange"])
  ]))
}