import { mapActions, mapState, mapWritableState } from 'pinia';

import { useAllTargetProgStore } from "@/app/stores/all-target-prog-store";
import { useDetailTargetProgStore } from "@/app/stores/detail-target-prog-store";

import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'tprog-cell-title-link',
	mixins: [ cellMixin ],
	computed: {
		...mapWritableState(useAllTargetProgStore, [ 'isViewLoader' ]),
		...mapWritableState(useDetailTargetProgStore, [ 'detailTargetGridData' ]),

		link(){
			const { path } = this.$route ?? {};
			const { id } = this.rowData ?? {};
			return `${path}/${id}`;
		}
	},
	methods: {
		...mapActions(useDetailTargetProgStore, ['clearFilters']),

		clickLink(e){
			// Проверяем, была ли нажата клавиша Ctrl или Shift
			if (e.ctrlKey || e.shiftKey || e.metaKey) {
				console.log('Комбинация клавиш для открытия в новой вкладке!');
				// Здесь можно добавить свой код для обработки комбинации
			} else {
				console.log('Обычный клик по ссылке');
				// Можно добавить дополнительную логику, если не было комбинации
				this.isViewLoader = true;
				this.clearFilters(); // очистка фильтров детальной АП
				this.detailTargetGridData = [];
			}

		}
	},
}