import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "design-search-gid" }
const _hoisted_2 = { class: "design-search-gid__head-ico" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = { class: "control" }
const _hoisted_5 = { class: "control__group" }
const _hoisted_6 = { class: "control design-search-gid__control-submit" }
const _hoisted_7 = { class: "control__group group-row row-space-between gap-24" }
const _hoisted_8 = {
  key: 0,
  class: "design-search-gid__info"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsRadioGroup = _resolveComponent("BsRadioGroup")
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_InfoSearchGid = _resolveComponent("InfoSearchGid")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["design-search-gid__head", { '--is-open-form': _ctx.isOpenForm }]),
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.openForm && _ctx.openForm(...args)))
    }, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "design-search-gid__head-label" }, "Выборка по гидам", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
      ])
    ], 2 /* CLASS */),
    _createVNode(_component_ElCollapseTransition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("form", {
          class: "design-search-gid__form",
          onSubmit: _cache[4] || (_cache[4] = _withModifiers((...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BsInput, {
              type: "textarea",
              rows: 8,
              modelValue: _ctx.textGid,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.textGid) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "control__label" }, "Области поиска", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_BsRadioGroup, {
                modelValue: _ctx.searchArea.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.searchArea.value) = $event)),
                options: _ctx.searchArea.options
              }, null, 8 /* PROPS */, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_BsCheckbox, {
                modelValue: _ctx.allowPartialMatch,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.allowPartialMatch) = $event))
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Разрешить неполное соответствие")
                ])),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"]),
              _createVNode(_component_BsBtn, {
                type: "primary",
                "native-type": "submit",
                disabled: !_ctx.textGid
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Проверить")
                ])),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"])
            ])
          ]),
          _createVNode(_Transition, {
            name: "el-fade-in",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.infoList)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_InfoSearchGid, { data: _ctx.infoList }, null, 8 /* PROPS */, ["data"])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
          [_vShow, _ctx.isOpenForm]
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}