import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, KeepAlive as _KeepAlive, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "target-program-item-view__container" }
const _hoisted_2 = { class: "target-program-item-view__action-panel" }
const _hoisted_3 = {
  key: 0,
  class: "target-program-item-view__action-item item-agreement-panel"
}
const _hoisted_4 = { class: "target-program-item-view__action-item item-views" }
const _hoisted_5 = { class: "target-program-item-view__action-item item-filter" }
const _hoisted_6 = { class: "target-program-item-view__action-item item-client" }
const _hoisted_7 = { class: "target-program-item-view__action-item item-manager" }
const _hoisted_8 = { class: "target-program-item-view__action-item item-import" }
const _hoisted_9 = { class: "target-program-item-view__action-item item-command-panel" }
const _hoisted_10 = {
  key: 0,
  class: "target-program-item-view__action-item item-calendar"
}
const _hoisted_11 = {
  key: 1,
  class: "target-program-item-view__action-item item-select-month"
}
const _hoisted_12 = {
  key: 2,
  class: "target-program-item-view__action-item item-addition-month"
}
const _hoisted_13 = {
  key: 3,
  class: "target-program-item-view__action-item item-command-panel"
}
const _hoisted_14 = { class: "target-program-item-view__table" }
const _hoisted_15 = { class: "target-programs-view__loader" }
const _hoisted_16 = {
  key: 0,
  class: "target-program-item-view__pagination"
}
const _hoisted_17 = { class: "target-program-item-view__map" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TargetAgrPanel = _resolveComponent("TargetAgrPanel")
  const _component_ActionPanel = _resolveComponent("ActionPanel")
  const _component_BtnAction = _resolveComponent("BtnAction")
  const _component_ElBadge = _resolveComponent("ElBadge")
  const _component_MapSearchPanel = _resolveComponent("MapSearchPanel")
  const _component_TargetSelectMonth = _resolveComponent("TargetSelectMonth")
  const _component_TableComPanel = _resolveComponent("TableComPanel")
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_TprogDetailTable = _resolveComponent("TprogDetailTable")
  const _component_TprogMonthMatrix = _resolveComponent("TprogMonthMatrix")
  const _component_GridPagination = _resolveComponent("GridPagination")
  const _component_MapTarget = _resolveComponent("MapTarget")
  const _component_MapTargetMarker = _resolveComponent("MapTargetMarker")
  const _component_MapTargetCluster = _resolveComponent("MapTargetCluster")
  const _component_MapMarkerSearch = _resolveComponent("MapMarkerSearch")
  const _component_TgMassStatus = _resolveComponent("TgMassStatus")
  const _component_MassExtReserve = _resolveComponent("MassExtReserve")
  const _component_TgMassDiscount = _resolveComponent("TgMassDiscount")
  const _component_TgMassPrice = _resolveComponent("TgMassPrice")
  const _component_TgMassPeriod = _resolveComponent("TgMassPeriod")
  const _component_TgMassData = _resolveComponent("TgMassData")
  const _component_TargetImport = _resolveComponent("TargetImport")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["target-program-item-view", { 'view-map': _ctx.activeTargetView=='map' }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.activeTargetView=='grid' && _ctx.countAgr && _ctx.isVisibleAgrPanel)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  true
                    ? (_openBlock(), _createBlock(_component_TargetAgrPanel, {
                        key: 0,
                        list: _ctx.coordinationData,
                        onClose: _cache[0] || (_cache[0] = $event => (_ctx.isVisibleAgrPanel=false))
                      }, null, 8 /* PROPS */, ["list"]))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ActionPanel, {
            listType: ['map', 'grid'],
            typeCheck: "radio",
            activeKey: _ctx.activeTargetView,
            onOnChange: _ctx.changeViews
          }, null, 8 /* PROPS */, ["activeKey", "onOnChange"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ElBadge, {
            value: _ctx.countDetailTargetFilter,
            hidden: !_ctx.countDetailTargetFilter
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BtnAction, {
                name: "16-data-analysis",
                active: !_ctx.isShowSecondAside,
                onClick: _ctx.openAsideFilter
              }, null, 8 /* PROPS */, ["active", "onClick"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value", "hidden"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_BtnAction, {
            name: "icon-excel",
            title: "Экспорт для клиента",
            disabled: _ctx.isLoadingExportClient,
            loading: _ctx.isLoadingExportClient,
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.exportFile('client')))
          }, null, 8 /* PROPS */, ["disabled", "loading"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_BtnAction, {
            name: "icon-excel",
            title: "Экспорт для менеджера",
            disabled: _ctx.isLoadingExportManager,
            loading: _ctx.isLoadingExportManager,
            onClick: _cache[2] || (_cache[2] = $event => (_ctx.exportFile('manager')))
          }, null, 8 /* PROPS */, ["disabled", "loading"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_BtnAction, {
            name: "16-upload",
            active: _ctx.isTargetImportDialog,
            title: "Импорт АП",
            onClick: _ctx.clickImport
          }, null, 8 /* PROPS */, ["active", "onClick"]),
          _createElementVNode("input", {
            class: "target-program-item-view__import",
            type: "file",
            ref: "refTargetImport",
            onChange: _cache[3] || (_cache[3] = (...args) => (_ctx.changeImportFile && _ctx.changeImportFile(...args)))
          }, null, 544 /* NEED_HYDRATION, NEED_PATCH */)
        ]),
        (_ctx.activeTargetView=='map')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_MapSearchPanel, {
                onOnSearch: _ctx.onSearchAddress,
                onChange: _ctx.onChangeMapSearch
              }, null, 8 /* PROPS */, ["onOnSearch", "onChange"]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_BtnAction, {
                  name: "10-map-marker",
                  title: "Смена типа отображения маркеров",
                  active: _ctx.checkMapMarkerView,
                  onClick: _ctx.clickMapMarkerView
                }, null, 8 /* PROPS */, ["active", "onClick"])
              ])
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true),
        (_ctx.activeTargetView=='grid')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.isDevServer)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_BtnAction, {
                      name: "16-calendar",
                      active: _ctx.isShowViewMatrix,
                      onClick: _ctx.clickBtnMonthMatrix
                    }, null, 8 /* PROPS */, ["active", "onClick"])
                  ]))
                : _createCommentVNode("v-if", true),
              (!_ctx.isShowViewMatrix)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_TargetSelectMonth, {
                      onChange: _cache[4] || (_cache[4] = () => {}),
                      modelValue: _ctx.detailMonthSelectData.value,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.detailMonthSelectData.value) = $event)),
                      options: _ctx.detailMonthSelectData.options
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]))
                : _createCommentVNode("v-if", true),
              (!_ctx.isShowViewMatrix)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_BtnAction, {
                      name: "calendar-plus",
                      onClick: _ctx.additionMonth
                    }, null, 8 /* PROPS */, ["onClick"])
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.activeTargetView=='grid')
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_Transition, {
                      name: "el-fade-in",
                      mode: "out-in"
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.isVisibleAgrPanel && _ctx.countAgr)
                          ? (_openBlock(), _createBlock(_component_ElBadge, {
                              key: 0,
                              value: _ctx.countAgr,
                              hidden: !_ctx.countAgr
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BtnAction, {
                                  name: "16-warning-filled",
                                  title: "Согласования",
                                  onClick: _cache[6] || (_cache[6] = $event => (_ctx.isVisibleAgrPanel=true))
                                })
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["value", "hidden"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_Transition, {
                      name: "el-fade-in",
                      mode: "out-in"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.selectedTableRow?.length)
                          ? (_openBlock(), _createBlock(_component_TableComPanel, {
                              key: 0,
                              commandList: _ctx.tableCommandList,
                              count: _ctx.selectedTableRow?.length,
                              onAction: _ctx.actionCommandPanel
                            }, null, 8 /* PROPS */, ["commandList", "count", "onAction"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true)
      ]),
      (_ctx.activeTargetView=='grid')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_Transition, {
                name: "el-fade-in",
                mode: "out-in",
                persisted: ""
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_LoaderSpinner)
                  ], 512 /* NEED_PATCH */), [
                    [_vShow, _ctx.isViewLoader]
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              (_openBlock(), _createBlock(_KeepAlive, null, [
                (!_ctx.isShowViewMatrix)
                  ? (_openBlock(), _createBlock(_component_TprogDetailTable, {
                      key: 0,
                      onSelectionChange: _ctx.selectChangeTable
                    }, null, 8 /* PROPS */, ["onSelectionChange"]))
                  : _createCommentVNode("v-if", true)
              ], 1024 /* DYNAMIC_SLOTS */)),
              (_ctx.isShowViewMatrix)
                ? (_openBlock(), _createBlock(_component_TprogMonthMatrix, {
                    key: 0,
                    onSelectionChange: _cache[7] || (_cache[7] = () => {})
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            (_ctx.detailTargetPaginationData?.last_page && _ctx.detailTargetPaginationData?.last_page > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createVNode(_component_GridPagination, {
                    currentPage: _ctx.detailTargetPaginationData?.current_page,
                    total: _ctx.detailTargetPaginationData?.total,
                    sizeList: _ctx.paginationSizeList,
                    onChange: _ctx.changePagination
                  }, null, 8 /* PROPS */, ["currentPage", "total", "sizeList", "onChange"])
                ]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true)
    ]),
    (_ctx.activeTargetView=='map')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_MapTarget)
          ]),
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markerList, (item) => {
              return (_openBlock(), _createBlock(_component_MapTargetMarker, {
                key: item.code,
                data: item
              }, null, 8 /* PROPS */, ["data"]))
            }), 128 /* KEYED_FRAGMENT */))
          ], 1024 /* DYNAMIC_SLOTS */)),
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clusterList, (item) => {
              return (_openBlock(), _createBlock(_component_MapTargetCluster, {
                key: item.clusterId,
                data: item
              }, null, 8 /* PROPS */, ["data"]))
            }), 128 /* KEYED_FRAGMENT */))
          ], 1024 /* DYNAMIC_SLOTS */)),
          (_ctx.searchMarker)
            ? (_openBlock(), _createBlock(_component_MapMarkerSearch, {
                key: 0,
                data: _ctx.searchMarker
              }, null, 8 /* PROPS */, ["data"]))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_TgMassStatus, {
      visible: _ctx.isVisibleMassStatus,
      "onUpdate:visible": _cache[8] || (_cache[8] = $event => ((_ctx.isVisibleMassStatus) = $event)),
      statusOptions: _ctx.statusList,
      onChange: _ctx.changeMassStatus
    }, null, 8 /* PROPS */, ["visible", "statusOptions", "onChange"]),
    _createVNode(_component_MassExtReserve, {
      visible: _ctx.isVisibleMassExtReserve,
      "onUpdate:visible": _cache[9] || (_cache[9] = $event => ((_ctx.isVisibleMassExtReserve) = $event)),
      onChange: _ctx.changeExtReserve
    }, null, 8 /* PROPS */, ["visible", "onChange"]),
    _createVNode(_component_TgMassDiscount, {
      visible: _ctx.isVisibleMassDiscount,
      "onUpdate:visible": _cache[10] || (_cache[10] = $event => ((_ctx.isVisibleMassDiscount) = $event)),
      onChange: _ctx.changeMassDiscount
    }, null, 8 /* PROPS */, ["visible", "onChange"]),
    _createVNode(_component_TgMassPrice, {
      visible: _ctx.isVisibleMassPrice,
      "onUpdate:visible": _cache[11] || (_cache[11] = $event => ((_ctx.isVisibleMassPrice) = $event)),
      onChange: _ctx.changeMassPrice
    }, null, 8 /* PROPS */, ["visible", "onChange"]),
    _createVNode(_component_TgMassPeriod, {
      visible: _ctx.isVisibleMassPeriod,
      "onUpdate:visible": _cache[12] || (_cache[12] = $event => ((_ctx.isVisibleMassPeriod) = $event)),
      onChange: _ctx.changeMassPeriod
    }, null, 8 /* PROPS */, ["visible", "onChange"]),
    _createVNode(_component_TgMassData, {
      visible: _ctx.isVisibleMassData,
      "onUpdate:visible": _cache[13] || (_cache[13] = $event => ((_ctx.isVisibleMassData) = $event)),
      onChange: _ctx.changeMassData
    }, null, 8 /* PROPS */, ["visible", "onChange"]),
    _createVNode(_component_TargetImport, {
      visible: _ctx.isTargetImportDialog,
      "onUpdate:visible": _cache[14] || (_cache[14] = $event => ((_ctx.isTargetImportDialog) = $event)),
      onDone: _ctx.doneImport
    }, null, 8 /* PROPS */, ["visible", "onDone"])
  ], 2 /* CLASS */))
}