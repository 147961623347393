export const colConfigList = [
	// {
	// 	key: 'selection',
	// 	fixed: true,
	// 	width: 40,
	// },
	{
		key: 'rowIndex',
		keyGroup: '',
		keyProp: 'rowIndex',
		title: 'Номер строки',
		order: '',
		sort: true,
		fixed: true,
		width: 80,
	},
	{
		key: 'partnerGid',
		keyGroup: '',
		keyProp: 'partnerGid',
		title: 'ГИД РС партнера',
		order: '',
		sort: true,
		fixed: true,
		width: 160,
	},
	{
		key: 'sideCode',
		keyGroup: '',
		keyProp: 'sideCode',
		title: 'Сторона',
		order: '',
		sort: true,
		width: 90,
	},
	{
		key: 'esparId',
		keyGroup: '',
		keyProp: 'esparId',
		title: 'EsparID',
		order: '',
		sort: true,
		width: 120,
	},
	{
		key: 'address',
		keyGroup: '',
		keyProp: 'address',
		title: 'Адрес',
		order: '',
		sort: true,
		width: 270,
	},
	{
		key: 'periods',
		keyGroup: '',
		keyProp: 'periods',
		title: 'Период',
		order: '',
		sort: true,
		width: 210,
	},
	{
		key: 'blockAndSlot',
		keyGroup: '',
		keyProp: 'blockAndSlot',
		title: 'Блок/Слот',
		order: '',
		sort: true,
		width: 120,
	},
	{
		key: 'duration',
		keyGroup: '',
		keyProp: 'duration',
		title: 'Хронометраж',
		order: '',
		sort: true,
		width: 120,
	},
	{
		key: 'showsInHour',
		keyGroup: '',
		keyProp: 'showsInHour',
		title: 'Частотность',
		order: '',
		sort: true,
		width: 120,
	},
	{
		key: 'cost',
		keyGroup: '',
		keyProp: 'cost',
		title: 'Стоимость',
		order: '',
		sort: true,
		width: 140,
	},
	{
		key: 'customerLegalEntity',
		keyGroup: '',
		keyProp: 'customerLegalEntity',
		title: 'Юр лицо клиента',
		order: '',
		sort: true,
		width: 200,
	},
	{
		key: 'product',
		keyGroup: '',
		keyProp: 'product',
		title: 'Продукт',
		order: '',
		sort: true,
		width: 200,
	},
	{
		key: 'matching',
		keyGroup: '',
		keyProp: 'matching',
		title: 'Результат',
		order: '',
		sort: true,
		class: 'import-result-cell',
		width: 340,
	},
	{
		key: 'designInfo',
		keyGroup: '',
		keyProp: 'designInfo',
		title: 'РК/РС',
		order: '',
		sort: true,
		width: 470,
	},
];