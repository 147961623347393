import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tprog-cell-period"
}
const _hoisted_2 = {
  key: 1,
  class: "tprog-cell-period"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_ctx.fieldData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.label), 1 /* TEXT */))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, "–"))
}