import cellMixin from "@/app/mixins/table/cell-mixin";

import { ElSwitch } from "element-plus";

export default {
	name: 'cell-switch',
	mixins: [cellMixin],
	components: {
		ElSwitch,
	},
	emits: ['change'],
	computed:{
		value: {
			get(){
				return !!this.fieldData;
			},
			set(is){
				this.$emit('change', {
					rowData: this.rowData,
					groupKey: this.groupKey,
					fieldKey: this.fieldKey,
					fieldData: is,
				});
			}
		},
	},
}