import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tprog-month-matrix" }
const _hoisted_2 = {
  key: 1,
  class: "tprog-month-matrix__head-cell"
}
const _hoisted_3 = {
  key: 0,
  class: "tprog-month-matrix__cell cell-design-info"
}
const _hoisted_4 = {
  key: 1,
  class: "tprog-month-matrix__cell cell-status-month"
}
const _hoisted_5 = {
  key: 0,
  class: "tprog-detail-table__cell"
}
const _hoisted_6 = {
  key: 1,
  class: "tprog-detail-table__cell"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TprogHeadDesign = _resolveComponent("TprogHeadDesign")
  const _component_TprogHeadDefault = _resolveComponent("TprogHeadDefault")
  const _component_TprogDesignInfo = _resolveComponent("TprogDesignInfo")
  const _component_TprogMonthStatus = _resolveComponent("TprogMonthStatus")
  const _component_ElTableColumn = _resolveComponent("ElTableColumn")
  const _component_ElTable = _resolveComponent("ElTable")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElTable, {
      class: "tprog-month-matrix__container",
      border: "",
      data: _ctx.detailTargetGridData,
      "header-cell-class-name": "tprog-month-matrix__th",
      "cell-class-name": "tprog-month-matrix__td",
      onSelectionChange: _ctx.selectionChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colConfigList, (col) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: col.key
          }, [
            (col.show)
              ? (_openBlock(), _createBlock(_component_ElTableColumn, {
                  key: 0,
                  prop: col.key,
                  label: col.label,
                  width: col.width,
                  resizable: !['designInfo'].includes(col.key),
                  fixed: (col.key=='designInfo')?'left':false,
                  "class-name": _ctx.getCellClass(col),
                  "header-align": "center",
                  align: "center"
                }, {
                  header: _withCtx((scope) => [
                    (col.key=='designInfo')
                      ? (_openBlock(), _createBlock(_component_TprogHeadDesign, {
                          key: 0,
                          colData: col,
                          notActions: true
                        }, null, 8 /* PROPS */, ["colData"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(_component_TprogHeadDefault, {
                            modelValue: col.order,
                            "onUpdate:modelValue": $event => ((col.order) = $event),
                            label: col.label,
                            sort: col.sort,
                            onChange: $event => (_ctx.sortTable($event, col.key))
                          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "sort", "onChange"])
                        ]))
                  ]),
                  default: _withCtx((scope) => [
                    (col.key=='designInfo')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_TprogDesignInfo, {
                            data: scope,
                            fieldKey: col.keyProp
                          }, null, 8 /* PROPS */, ["data", "fieldKey"])
                        ]))
                      : (col.key=='statusMonth')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_component_TprogMonthStatus, {
                              data: scope,
                              fieldKey: col.keyProp
                            }, null, 8 /* PROPS */, ["data", "fieldKey"])
                          ]))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            (scope.row?.[col.keyGroup]?.[col.keyProp] != undefined)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                  _createElementVNode("span", null, _toDisplayString(scope.row?.[col.keyGroup]?.[col.keyProp]), 1 /* TEXT */)
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_6, "–"))
                          ], 64 /* STABLE_FRAGMENT */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "width", "resizable", "fixed", "class-name"]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "onSelectionChange"])
  ]))
}