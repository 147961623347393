import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pr-rules-edit-item" }
const _hoisted_2 = { class: "pr-rules-edit-item__label" }
const _hoisted_3 = { class: "pr-rules-edit-item__group-control" }
const _hoisted_4 = {
  key: 0,
  class: "pr-rules-edit-item__control"
}
const _hoisted_5 = {
  key: 1,
  class: "pr-rules-edit-item__control"
}
const _hoisted_6 = {
  key: 2,
  class: "pr-rules-edit-item__control"
}
const _hoisted_7 = {
  key: 3,
  class: "pr-rules-edit-item__control"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data?.label), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.data?.type=='single-select')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_BsSelectV2, {
              placeholder: _ctx.data?.placeholder,
              modelValue: _ctx.data.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.data.value) = $event)),
              options: _ctx.selectOptions,
              clearable: true,
              filterable: true,
              "popper-class": "pr-rules-edit__select-popper",
              "item-height": 28
            }, null, 8 /* PROPS */, ["placeholder", "modelValue", "options"])
          ]))
        : (_ctx.data?.type=='multi-select')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_BsSelectV2, {
                placeholder: _ctx.data?.placeholder,
                modelValue: _ctx.data.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.data.value) = $event)),
                options: _ctx.selectOptions,
                clearable: true,
                filterable: true,
                "popper-class": "pr-rules-edit__select-popper",
                "item-height": 28,
                "collapse-tags": "",
                "collapse-tags-tooltip": "",
                "max-collapse-tags": 2,
                multiple: "",
                showCheckBox: true
              }, null, 8 /* PROPS */, ["placeholder", "modelValue", "options"])
            ]))
          : (_ctx.data?.type=='input-number')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_BsInput, {
                  placeholder: _ctx.data?.placeholder,
                  modelValue: _ctx.data.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.data.value) = $event)),
                  clearable: true,
                  type: "number",
                  size: "small",
                  min: "1",
                  step: "1"
                }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7)),
      _createElementVNode("div", {
        class: "pr-rules-edit-item__btn-remove",
        title: "Удалить",
        onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.removeRule && _ctx.removeRule(...args)))
      }, [
        _createVNode(_component_SvgIcon, { name: "16-close" })
      ])
    ])
  ]))
}