import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "target-programs-view" }
const _hoisted_2 = { class: "target-programs-view__container" }
const _hoisted_3 = { class: "target-programs-view__action-panel" }
const _hoisted_4 = { class: "target-programs-view__action-item item-btn-filter" }
const _hoisted_5 = { class: "target-programs-view__action-item item-tab-filter" }
const _hoisted_6 = {
  key: 0,
  class: "target-programs-view__action-item item-command-panel"
}
const _hoisted_7 = { class: "target-programs-view__table" }
const _hoisted_8 = { class: "target-programs-view__loader" }
const _hoisted_9 = {
  key: 0,
  class: "target-programs-view__pagination"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BtnAction = _resolveComponent("BtnAction")
  const _component_ElBadge = _resolveComponent("ElBadge")
  const _component_TargetTabFilter = _resolveComponent("TargetTabFilter")
  const _component_TableComPanel = _resolveComponent("TableComPanel")
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_TprogAllTable = _resolveComponent("TprogAllTable")
  const _component_GridPagination = _resolveComponent("GridPagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ElBadge, {
            value: _ctx.countFilters,
            hidden: !_ctx.countFilters
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BtnAction, {
                name: "16-filters",
                active: !_ctx.isShowSecondAside,
                onClick: _ctx.toggleFilter
              }, null, 8 /* PROPS */, ["active", "onClick"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value", "hidden"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_TargetTabFilter, {
            tabList: _ctx.tabList,
            activeTab: "all",
            onTabChange: _ctx.changeTabFilter
          }, null, 8 /* PROPS */, ["tabList", "onTabChange"])
        ]),
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.selectedTableRow?.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_TableComPanel, {
                    commandList: _ctx.tableCommandList,
                    count: _ctx.selectedTableRow?.length,
                    onAction: _cache[0] || (_cache[0] = () => {})
                  }, null, 8 /* PROPS */, ["commandList", "count"])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in",
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_8, [
              _createVNode(_component_LoaderSpinner)
            ], 512 /* NEED_PATCH */), [
              [_vShow, _ctx.isViewLoader]
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_TprogAllTable, {
          tableData: _ctx.allTargetTableData,
          "onUpdate:tableData": _cache[1] || (_cache[1] = $event => ((_ctx.allTargetTableData) = $event)),
          onChangeTable: _ctx.changeTable,
          onDbClickRow: _ctx.dbClickTableRow
        }, null, 8 /* PROPS */, ["tableData", "onChangeTable", "onDbClickRow"])
      ]),
      (_ctx.allTargetPaginationData?.last_page && _ctx.allTargetPaginationData?.last_page > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_GridPagination, {
              currentPage: _ctx.allTargetPaginationData?.current_page,
              total: _ctx.allTargetPaginationData?.total,
              onChange: _ctx.changePagination
            }, null, 8 /* PROPS */, ["currentPage", "total", "onChange"])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}