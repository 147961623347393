import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { usePricingListStore } from '@/app/stores/pricing-policies/pricing-list-store';

import { ElBadge } from 'element-plus';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import GridPagination from '@/app/components/grid/grid-pagination/GridPagination.vue';
import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';
import PricingListTable from '@/app/components/pricing-policies/pricing-list-table/PricingListTable.vue';

export default {
	name: 'pricing-list-view',
	components: {
		ElBadge,
		LoaderSpinner, GridPagination, BtnAction,
		PricingListTable,
	},
	data() {
		return {
			countMessage: 3,
		}
	},
	computed: {
		...mapState(useRootStore, ['isDevServer', 'devMode', 'isShowSecondAside']),
		...mapState(usePricingListStore, ['countFilters']),
		...mapWritableState(usePricingListStore, ['isViewLoader', 'tableData']),
	},
	methods: {
		...mapActions(useRootStore, ['setShowSecondAside' ]),

		openAsideFilter(){
			this.setShowSecondAside(!this.isShowSecondAside);
		},
	},
}