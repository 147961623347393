import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cell-number" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.fieldData !== undefined)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, "–"))
  ]))
}