import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "edit-field"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = {
  key: 1,
  class: "edit-field"
}
const _hoisted_4 = ["title"]
const _hoisted_5 = {
  key: 2,
  class: "edit-field type-multi-select"
}
const _hoisted_6 = ["title"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 3,
  class: "edit-field"
}
const _hoisted_9 = ["title"]
const _hoisted_10 = {
  class: "edit-field__btn-edit",
  title: "Изменить"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_DiEditInput = _resolveComponent("DiEditInput")
  const _component_DiEditSelect = _resolveComponent("DiEditSelect")
  const _component_PoEditMonthRange = _resolveComponent("PoEditMonthRange")

  return (_ctx.editType=='input')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "edit-field__label",
          title: _ctx.label
        }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_2),
        _createElementVNode("div", {
          class: "edit-field__btn-edit",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickEdit && _ctx.clickEdit(...args))),
          title: "Изменить"
        }, [
          _createVNode(_component_SvgIcon, { name: "14-edit-pen" })
        ]),
        _createVNode(_component_DiEditInput, {
          visible: _ctx.isVisible,
          "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.isVisible) = $event)),
          titleDialog: _ctx.titleDialog,
          fieldValue: _ctx.label,
          labelActiveButton: _ctx.labelActiveButton,
          onChange: _ctx.changeEdit
        }, null, 8 /* PROPS */, ["visible", "titleDialog", "fieldValue", "labelActiveButton", "onChange"])
      ]))
    : (_ctx.editType=='select')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "edit-field__label",
            title: _ctx.fieldLabel
          }, _toDisplayString(_ctx.fieldLabel), 9 /* TEXT, PROPS */, _hoisted_4),
          _createElementVNode("div", {
            class: "edit-field__btn-edit",
            onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.clickEdit && _ctx.clickEdit(...args))),
            title: "Изменить"
          }, [
            _createVNode(_component_SvgIcon, { name: "14-edit-pen" })
          ]),
          _createVNode(_component_DiEditSelect, {
            visible: _ctx.isVisible,
            "onUpdate:visible": _cache[3] || (_cache[3] = $event => ((_ctx.isVisible) = $event)),
            titleDialog: _ctx.titleDialog,
            fieldData: _ctx.fieldData,
            labelActiveButton: _ctx.labelActiveButton,
            onChange: _ctx.changeEdit
          }, null, 8 /* PROPS */, ["visible", "titleDialog", "fieldData", "labelActiveButton", "onChange"])
        ]))
      : (_ctx.editType=='multi-select')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "edit-field__label",
              title: _ctx.fieldLabel
            }, [
              (Array.isArray(_ctx.fieldLabel))
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.fieldLabel, (item) => {
                    return (_openBlock(), _createElementBlock("span", { key: item }, _toDisplayString(item), 1 /* TEXT */))
                  }), 128 /* KEYED_FRAGMENT */))
                : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.fieldLabel), 1 /* TEXT */))
            ], 8 /* PROPS */, _hoisted_6),
            _createElementVNode("div", {
              class: "edit-field__btn-edit",
              onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.clickEdit && _ctx.clickEdit(...args))),
              title: "Изменить"
            }, [
              _createVNode(_component_SvgIcon, { name: "14-edit-pen" })
            ]),
            _createVNode(_component_DiEditSelect, {
              visible: _ctx.isVisible,
              "onUpdate:visible": _cache[5] || (_cache[5] = $event => ((_ctx.isVisible) = $event)),
              typeSelect: "multi",
              titleDialog: _ctx.titleDialog,
              fieldData: _ctx.fieldData,
              labelActiveButton: _ctx.labelActiveButton,
              onChange: _ctx.changeEdit
            }, null, 8 /* PROPS */, ["visible", "titleDialog", "fieldData", "labelActiveButton", "onChange"])
          ]))
        : (_ctx.editType=='month-range')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", {
                class: "edit-field__label",
                title: _ctx.labelMonthRange
              }, _toDisplayString(_ctx.labelMonthRange), 9 /* TEXT, PROPS */, _hoisted_9),
              _createVNode(_component_PoEditMonthRange, {
                fieldData: _ctx.fieldData,
                onChange: _ctx.changeEdit
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_SvgIcon, { name: "14-edit-pen" })
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["fieldData", "onChange"])
            ]))
          : _createCommentVNode("v-if", true)
}