const colConfigList = [
	{
		key: 'designInfo',
		keyProp: 'sideData',
		label: 'РП/РС',
		order: '',
		sort: false,
		show: true,
		width: 470,
		notSettings: true,
	}
];

export { colConfigList };