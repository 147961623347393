import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "label-info" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElTooltip = _resolveComponent("ElTooltip")

  return (_openBlock(), _createBlock(_component_ElTooltip, {
    class: "label-info-tooltip",
    placement: "right",
    effect: "light"
  }, {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_SvgIcon, { name: "16-info-filled" })
      ])
    ]),
    _: 3 /* FORWARDED */
  }))
}