import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "import-cell-status" }
const _hoisted_2 = {
  key: 0,
  class: "import-cell-status__bg-error"
}
const _hoisted_3 = {
  key: 1,
  class: "import-cell-status__status status-check"
}
const _hoisted_4 = {
  key: 2,
  class: "import-cell-status__status status-warn"
}
const _hoisted_5 = {
  key: 3,
  class: "import-cell-status__status status-error"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.fieldData?.status === 'error')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("v-if", true),
    (_ctx.fieldData?.status === 'success')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_SvgIcon, { name: "circle-check-stroke" })
        ]))
      : (_ctx.fieldData?.status === 'warning')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_SvgIcon, { name: "question-filled" })
          ]))
        : (_ctx.fieldData?.status === 'error')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_SvgIcon, { name: "circle-close-stroke" })
            ]))
          : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */)), [
    [_vShow, _ctx.fieldData?.status]
  ])
}