import { mapState, mapActions, mapWritableState } from 'pinia';
import { useMapDesignStore } from '@/app/stores/map-design-store';

import { ElMessage } from 'element-plus';


import { ElCollapseTransition, ElBadge } from 'element-plus';


import cellMixin from "@/app/mixins/table/cell-mixin";
import helper from '@/app/utils/helper';
import api from '@/app/utils/service-api';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import TprogCommentPopover from '@/app/components/target-programs/tprog-detail-table/tprog-comment-popover/TprogCommentPopover.vue';

export default {
	name: 'tprog-design-info',
	mixins: [ cellMixin ],
	components: {
		ElCollapseTransition, ElBadge,
		SvgIcon, TprogCommentPopover,
	},
	props: {
		cityMap: {
			type: Object,
			default: {},
		},
		compact: {
			type: Boolean,
			default: false,
		}
	},
	emits: ['changeComment'],
	data() {
		return {
			isOpen: false,
			isShowActions: false,
			isVisibleComment: false,
			comment: '',
		}
	},
	computed: {
		showActions(){
			return this.isShowActions || this.isOpen;
		},
		orderData(){
			return this.rowData?.orderData ?? {};
		},
		isDigital(){
			return this.fieldData?.is_digital;
		},
		blockDuration(){
			if(!this.fieldData?.blockDuration) return;
			const { blockDuration } = this.fieldData

			return `${blockDuration} мин`;
		},
		slotDuration(){
			if(!this.fieldData?.slotDuration) return;
			const { slotDuration } = this.fieldData

			return `${slotDuration} сек`;
		},
		isComment(){
			return !!this.orderData?.comment;
		},
		labelCity(){
			const { city_id } = this.fieldData;
			return this.cityMap?.[city_id]?.label ?? '';
		},

		grp(){
			if(Number(this.rowData?.orderData?.grp) === 0) return '–';
			return this.rowData?.orderData?.grp;
		},
		ots(){
			if(Number(this.rowData?.orderData?.ots) === 0) return '–';
			return this.rowData?.orderData?.ots;
		}
	},
	methods: {
		...mapActions( useMapDesignStore, ['getTypeDesignIcon'] ),

		mouseEnter(){
			this.isShowActions = true;
		},
		mouseLeave(){
			this.isShowActions = false;
		},
		toggleWrap(){
			this.isOpen = !this.isOpen;
		},
		async copyAddress(){
			const address = this.fieldData?.address;
			console.log('copyAddress', address);

			try {
				if(!address) throw new Error('адрес пустой')
				await helper.copyTextBuffer(address);

				ElMessage({
					type: 'success',
					message: 'Адрес успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Адрес не удалось скопировать',
				});
			}
		},

		openDesign(){
			const { origin } = window.location;
			const { city_id, structure_id, sideId } = this.fieldData;

			if(!(city_id && structure_id)) {
				ElMessage({
					type: 'error',
					message: 'Ошибка открытия РК',
				});

				return;
			}

			const data = {
				structureId: structure_id,
				sideId,
				city: [city_id],
			}
			const params = api.buildParams(data).toString();
			const link = `${origin}/structures?${params}`;

			window.open(link, "_blank");
		},
		openTechReq(){
			const { technical_requirements_url } = this.fieldData;

			if(!technical_requirements_url){
				ElMessage({
					type: 'error',
					message: 'Ошибка открытия. Ссылка на TT пуста',
				});

				return;
			}

			window.open(technical_requirements_url, "_blank");
		},
		changeComment(comment){
			this.$emit('changeComment', {
				comment,
				rowData: this.rowData,
			});
		}
	},
}