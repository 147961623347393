import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "direct-side-info" }
const _hoisted_2 = { class: "direct-side-info__type" }
const _hoisted_3 = { class: "direct-side-info__count" }
const _hoisted_4 = { class: "direct-side-info__percent" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sideList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "direct-side-info__item",
        key: item.key
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.key) + ":", 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, _toDisplayString(item.value), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_4, "(" + _toDisplayString(item.percent) + "%)", 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}