import { mapActions, mapState, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';
import { useFiltersStore } from '@/app/stores/filters-store';

import { ElDialog, ElTabs, ElTabPane, ElInputNumber } from "element-plus";
import 'element-plus/es/components/dialog/style/css';


import 'element-plus/es/components/input-number/style/css';

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import BsInput from "@/app/components/controls/bs-input/BsInput.vue";
import BsMultiSelect from "@/app/components/controls/selects/bs-multi-select/BsMultiSelect.vue";
import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';

export default {
	name: 'tg-mass-data',
	components: {
		ElDialog, ElTabs, ElTabPane, ElInputNumber,
		BsBtn, BsInput, BsMultiSelect, BsSelectV2,
	},
	props: {
		visible:{
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:visible', 'change'],
	data() {
		return {
			months: '',
			activeTab: 'customer',

			customerFieldData: {
				customer: '',
				rimLegalEntity: '',
				operator: '',
				printer: '',
				installer: '',
				designer: '',
				photoReporter: '',
			},
			serviceFieldData: {
				installPrice: '',
				addInstallPrice: '',
				printPrice: '',
				printExpPrice: '',
				designPrice: '',
				photoReportPrice: '',
			},
			otherFieldData: {
				placementType: '',
				customerCategory: '',
				promo: '',
				product: '',
				respManager: '',
				engManager: '',
				coefficient: '',
				agentCommission: '',
				customerCommission: '',
			},
		}
	},
	computed: {
		...mapState( useDetailTargetProgStore, ['detailMonthSelectData'] ),

		isVisible: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			},
		},

		getChangeFields(){
			const fields = {};

			for( const data of [ this.customerFieldData, this.serviceFieldData, this.otherFieldData ] ){
				for(const [ key, value ] of Object.entries(data)){
					if(value) fields[key] = value;
				}
			}

			return fields;
		},

		disabledBtnChange(){
			const isMonth = !!this.months?.length;
			const fields = this.getChangeFields;

			return !(isMonth && !!Object.keys(fields).length);
		},


	},
	methods: {
		...mapActions(useFiltersStore, ['getOptions']),

		clickChange(){
			this.$emit('change', {
				months: this.months,
				fields: this.getChangeFields,
			});
			this.isVisible = false;
		},
		clearFields(){
			for( const data of [ this.customerFieldData, this.serviceFieldData, this.otherFieldData ] ){
				for(const [ key, value ] of Object.entries(data)){
					data[key] = '';
				}
			}
		}
	},
	watch: {
		isVisible(is){
			if(is){
				this.months = [ this.detailMonthSelectData.value ];
			} else {
				this.months = '';
				this.clearFields();
			}
		}
	},
}