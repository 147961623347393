import dayjs from 'dayjs';
import cellMixin from "@/app/mixins/table/cell-mixin";

import ShortSelect from '@/app/components/controls/selects/short-select/ShortSelect.vue';

export default {
	name: 'tprog-cell-place-type',
	mixins: [ cellMixin ],
	components: {
		ShortSelect,
	},
	props: {
		options: {
			type: Array,
			default: [],
		}
	},
	emits: ['change'],
	data() {
		return {
			fieldValue: '',
		}
	},
	computed: {
		deadlineDate(){
			return this.fieldData?.date;
		},
		formatDeadline(){
			if(!this.deadlineDate) return '';
			return dayjs(this.deadlineDate).format('DD.MM.YY');
		},
		getPlaceStatus(){
			if(!this.deadlineDate) return '';

			const today = new Date();
			const countDays = dayjs(this.deadlineDate).diff(today, 'day');

			if(countDays>0 && countDays<30) return 'status-warning';
			else if(countDays<=0) return 'status-bad';
			else return '';
		},
	},
	methods: {
		changeSelect(value){
			this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: value,
			});
		}
	},
	watch: {
		fieldData(val){
			this.fieldValue = this.fieldData ?? '';
		}
	},
	created() {
		if(this.fieldData) this.fieldValue = this.fieldData;
	},
}