// Views
import SignInView from '@/app/views/auth/sign-in/SignInView.vue';
import HomeView from '@/app/views/HomeView.vue';
import AllDesignsView from '@/app/views/all-designs-view/AllDesignsView.vue';
import TargetProgramsView from '@/app/views/target-programs-view/TargetProgramsView.vue';
import TargetProgramItemView from '@/app/views/target-program-item-view/TargetProgramItemView.vue';
import OperatorsView from '@/app/views/operators-view/OperatorsView.vue';
import OperatorItemView from '@/app/views/operator-item-view/OperatorItemView.vue';
import PricingDetailView from '@/app/views/pricing-policies/pricing-detail-view/PricingDetailView.vue';
import PricingListView from '@/app/views/pricing-policies/pricing-list-view/PricingListView.vue';

// Asides
import DesignAside from '@/app/components/second-asides/design-aside/DesignAside.vue';
import TargetAside from '@/app/components/second-asides/target-aside/TargetAside.vue';
import TargetProgramsAside from '@/app/components/second-asides/target-programs-aside/TargetProgramsAside.vue';
import PricingDetailAside from '@/app/components/second-asides/pricing-policies/pricing-detail-aside/PricingDetailAside.vue';
import PricingListAside from '@/app/components/second-asides/pricing-policies/pricing-list-aside/PricingListAside.vue';

export const routes = [
	{
		path: '/sign-in',
		name: 'sign-in',
		component: SignInView,
		meta: {
			layout: 'auth-layout'
		}
	},
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: {
			breadcrumb:{
				title: 'Home',
			},
		},
		redirect: {
			name: 'structures',
		}
	},
	{
		path: '/structures',
		name: 'structures',
		components: {
			default: AllDesignsView,
			secondAside: DesignAside
		},
		meta: {
			title: 'Все конструкции',
			secondAside: true,
			breadcrumb: {
				title: 'Все конструкции',
				parent: 'home',
			}
		}
	},
	{
		path: '/target-programs',
		name: 'target-programs',
		components: {
			default: TargetProgramsView,
			secondAside: TargetProgramsAside
		},
		meta: {
			title: 'Адресные программы',
			secondAside: true,
			breadcrumb: {
				title: 'Адресные программы',
				parent: 'home',
			}
		}
	},
	{
		path: '/target-programs/:id',
		name: 'target-program-item',
		components: {
			default: TargetProgramItemView,
			secondAside: TargetAside
		},
		meta: {
			// title: 'Адресная программа - детальная',
			secondAside: true,
			breadcrumb: {
				isDynamicTitle: true,
				parent: 'target-programs',
			}
		}
	},
	{
		path: '/operators',
		name: 'operators',
		components: {
			default: OperatorsView,
			// secondAside: TargetProgramsAside
		},
		meta: {
			title: 'Операторы',
			// secondAside: true,
			breadcrumb: {
				title: 'Операторы',
				parent: 'home',
			}
		}
	},
	{
		path: '/operators/:id',
		name: 'operator-item',
		components: {
			default: OperatorItemView,
			secondAside: DesignAside
		},
		meta: {
			// title: 'Операторы',
			secondAside: true,
			breadcrumb: {
				isDynamicTitle: true,
				parent: 'operators',
			}
		}
	},
	{
		path: '/pricing-policies',
		name: 'pricing-policies',
		components: {
			default: PricingListView,
			secondAside: PricingListAside
		},
		meta: {
			title: 'Прайсовые политики',
			secondAside: true,
			breadcrumb: {
				title: 'Операторы',
				parent: 'home',
			}
		}
	},
	{
		path: '/pricing-policies/:id',
		name: 'pricing-policies-item',
		components: {
			default: PricingDetailView,
			secondAside: PricingDetailAside
		},
		meta: {
			// title: 'Операторы',
			secondAside: true,
			breadcrumb: {
				isDynamicTitle: true,
				parent: 'pricing-policies',
			}
		}
	},


	{
		path: '/:queryParams(.*)',
		name: 'redirect',
		component: HomeView,
		props: true,
	},
];