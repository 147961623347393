import dayjs from "dayjs";
import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'tprog-cell-date',
	mixins: [ cellMixin ],
	computed: {
		label(){
			return dayjs(this.fieldData).format('DD.MM.YYYY, HH:mm');
		}
	},
	created(){
		// console.log(this.data);
	}
}