import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "datepicker-month-period-v2" }
const _hoisted_2 = { class: "datepicker-month-period-v2__header" }
const _hoisted_3 = {
  class: "datepicker-month-period-v2__main",
  ref: "datepickerRef"
}
const _hoisted_4 = { class: "datepicker-month-period-v2__footer" }
const _hoisted_5 = { class: "datepicker-month-period-v2__footer-all-check" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.year), 1 /* TEXT */),
      _createElementVNode("span", null, _toDisplayString(_ctx.month), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_3, null, 512 /* NEED_PATCH */),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BsCheckbox, {
          modelValue: _ctx.isAllMonthSelected,
          onChange: _ctx.onAllChecked
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Выбрать все дни месяца")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "onChange"])
      ])
    ])
  ]))
}