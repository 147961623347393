import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import DiEditTextarea from '../edit-field/di-edit-textarea/DiEditTextarea.vue';


export default {
	name: 'edit-comment',
	components: {
		SvgIcon, DiEditTextarea
	},
	props: {
		comment: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: 'Комментарий',
		},
		titleDialog: String,
		labelActiveButton: String,
	},
	emits: ['change'],
	data() {
		return {
			heightText: 0,
			isShowText: false,
			isVisibleDialog: false,
		}
	},
	computed: {
		isShowToggleBtn(){
			return this.heightText >= 44;
		},
		labelToggleBtn(){
			return this.heightText > 44 ? 'Скрыть' : 'Далее';
		}
	},
	methods: {
		watchHeightText(){
			const { refTextComment } = this.$refs;
			this.heightText = refTextComment.offsetHeight;
			// console.log(this.heightText);
		},
		changeEdit(value){
			this.$emit('change', value);
		}
	},
	watch: {
		async isShowText(){
			await this.$nextTick();
			this.watchHeightText();
		},
		async comment(){
			await this.$nextTick();
			this.watchHeightText();
		}
	},
	async mounted(){
		await this.$nextTick();
		this.watchHeightText();
	}
}