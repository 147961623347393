import dayjs from 'dayjs';

import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'tprog-deadline-date',
	mixins: [ cellMixin ],
	computed: {
		formatDate(){
			return dayjs(this.fieldData).format('DD.MM.YYYY HH:MM');
		},
	},
}