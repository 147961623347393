import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "side-queue" }
const _hoisted_2 = {
  key: 1,
  class: "side-queue__wrap"
}
const _hoisted_3 = { class: "side-queue__item" }
const _hoisted_4 = { class: "side-queue__wrap-middle" }
const _hoisted_5 = { class: "side-queue__item" }
const _hoisted_6 = {
  key: 2,
  class: "side-queue__wrap"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_SideQueueItem = _resolveComponent("SideQueueItem")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.middleItems)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "side-queue__head",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickOpenQueue && _ctx.clickOpenQueue(...args)))
        }, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "side-queue__head-label" }, "Очередь", -1 /* HOISTED */)),
          (_ctx.list?.length>2)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["side-queue__head-open-btn", { '--is-open': _ctx.isOpenQueue }])
              }, [
                _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.middleItems)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SideQueueItem, { data: _ctx.firstItem }, null, 8 /* PROPS */, ["data"])
          ]),
          _createVNode(_component_ElCollapseTransition, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.middleItems, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "side-queue__item",
                    key: item.id
                  }, [
                    _createVNode(_component_SideQueueItem, { data: item }, null, 8 /* PROPS */, ["data"])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ], 512 /* NEED_PATCH */), [
                [_vShow, _ctx.isOpenQueue]
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_SideQueueItem, { data: _ctx.lastItem }, null, 8 /* PROPS */, ["data"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "side-queue__item",
              key: item.id
            }, [
              _createVNode(_component_SideQueueItem, { data: item }, null, 8 /* PROPS */, ["data"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
  ]))
}