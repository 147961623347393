import { mapActions, mapState, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import dayjs from 'dayjs';

import { ElDialog } from "element-plus";
import 'element-plus/es/components/dialog/style/css';

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import BsInput from "@/app/components/controls/bs-input/BsInput.vue";
import BsMultiSelect from "@/app/components/controls/selects/bs-multi-select/BsMultiSelect.vue";
import BsDatePicker from '@/app/components/controls/bs-date-picker/BsDatePicker.vue';

export default {
	name: 'mass-ext-reserve',
	components: {
		ElDialog,
		BsBtn, BsInput, BsMultiSelect,
		BsDatePicker,
	},
	props: {
		visible:{
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:visible', 'change'],
	data() {
		return {
			countDay: 0,
			date: '',
			months: '',
		}
	},
	computed: {
		...mapState( useDetailTargetProgStore, ['detailMonthSelectData'] ),

		isVisible: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			},
		},
	},
	methods: {
		clickChange(){
			this.$emit('change', {
				countDay: Number(this.countDay),
				// date: this.date,
				months: this.months,
			});
			this.isVisible = false;
		},

		inputChange(val){
			const countDay = Number(val);
			if(countDay < 0) this.countDay = 0;
		},

		disabledDate(data){
			const today = dayjs().format('YYYY-MM-DD');
			let nextDate = dayjs(today).toDate();

			return nextDate > data;
		},
	},
	watch: {
		isVisible(is){
			if(is){
				this.months = [ this.detailMonthSelectData.value ];
			} else {
				this.countDay = 0;
				this.months = '';
				this.date = '';
			}
		}
	},
}