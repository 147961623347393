import { mapState, mapActions, mapWritableState } from 'pinia';
import { useGridDesignStore } from '@/app/stores/grid-design-store';

import GridDesignsTable from '@/app/components/grid/grid-designs-table/GridDesignsTable.vue';
import GridDesignsTable2 from '@/app/components/grid/grid-designs-table-2/GridDesignsTable2.vue';
import GridPagination from '@/app/components/grid/grid-pagination/GridPagination.vue';
import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';

export default {
	name: 'grid-designs',
	props: {

	},
	components: {
		LoaderSpinner,
		GridDesignsTable,
		GridDesignsTable2,
		GridPagination,
	},
	data() {
		return {
			table: 1,
		}
	},
	computed: {
		...mapState(useGridDesignStore, ['isLoaderGrid']),
		...mapWritableState(useGridDesignStore, ['gridTableData', 'gridPaginationData', 'colConfigList']),
	},
	methods: {
		...mapActions(useGridDesignStore, ['changeSelectionTable', 'getDesignGridData']),

		async changePagination(data){
			const { page, sizeChunk } = data;
			const res = await this.getDesignGridData({
				params: {
					page: page,
					per_page: sizeChunk,
				}
			});

			// Очищаем сортировку столбцов
			for(let item of this.colConfigList){
				item.order = '';
			}
		},
	},
	created() {
		const { query } = this.$route;
		if( query?.table == 2 ) this.table = 2;
		// console.log(this.gridPaginationData);
	},
}