import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tag-list__wrap" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tag-list", { 'tag-list--open': _ctx.isOpenList }])
  }, [
    _withDirectives(_createElementVNode("div", {
      class: "tag-list__collapse-btn",
      style: _normalizeStyle({ left: `${_ctx.leftPosCollapseBtn}px` }),
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.openList && _ctx.openList(...args)))
    }, [
      _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
    ], 4 /* STYLE */), [
      [_vShow, _ctx.isShowCollapseBtn]
    ]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "tag-list__item",
          key: item,
          ref_for: true,
          ref: "tagItem"
        }, _toDisplayString(item), 1 /* TEXT */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 2 /* CLASS */))
}