import { mapState, mapActions, mapWritableState } from 'pinia';
import { useMapDesignStore } from "@/app/stores/map-design-store";
import { useRootStore } from '@/app/stores/root-store';

import helper from '@/app/utils/helper';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';


export default {
	name: 'map-marker',
	components: {
		SvgIcon,
	},
	props: {
		types: Array, // 'free', 'busy', 'reserved'
		id: [String, Number],
		data: Object,
	},
	data() {
		return {
			isShow: false,
		}
	},
	computed: {
		...mapState(useMapDesignStore, ['detailDesignData']),
		...mapWritableState(useRootStore, ['isShowSecondAside'] ),

		markerSelector(){
			return `#marker-${this.data.code}`;
		},
		typeClassList(){
			const list = this.types.map(type=>`type-${type}`);
			if(this.data.added) list.push('type-added');
			if(this.isActive) list.push('map-marker--active');

			return list;
		},
		isActive(){
			return this.detailDesignData?.id == this.id;
		}
	},
	methods: {
		...mapActions(useMapDesignStore, ['getStructure']),
		...mapActions(useRootStore, ['setShowSecondAside']),

		async click(){
			console.log(this.id, this.data);

			await this.getStructure({ id: this.id });
			if(!this.isShowSecondAside) this.setShowSecondAside(true);
		},
		hasMarkerContainer(){
			const markerContainer = document.querySelector(this.markerSelector);
			this.isShow = !!markerContainer;
		},
	},
	mounted() {
		const { placemark, map } = this.data;
		this.hasMarkerContainer();

		placemark.events.add('click', (e)=>{
			this.click();
		});
		$(window).on(`update:map.${this.data.code}`, ()=>{
			this.hasMarkerContainer();
		});
	},
	created() {

	},
	unmounted() {
		const { placemark, map } = this.data;
		placemark.events.remove('click', (e)=>{
			this.click();
		});
		$(window).off(`update:map.${this.data.code}`);
	},
}