import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "cell-edit-link__edit" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CellEditable = _resolveComponent("CellEditable")
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cell-edit-link", { '--is-field-empty':!_ctx.fieldData }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CellEditable, {
        typeField: "string",
        data: _ctx.data,
        groupKey: _ctx.groupKey,
        fieldKey: _ctx.fieldKey,
        onChange: _ctx.change
      }, {
        default: _withCtx(() => [
          (_ctx.fieldData)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "cell-edit-link__edit-value",
                title: _ctx.fieldData
              }, _toDisplayString(_ctx.fieldData), 9 /* TEXT, PROPS */, _hoisted_2))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, "–"))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
    ]),
    (_ctx.fieldData)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "cell-edit-link__open",
          title: "Открыть ссылку",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.openLink && _ctx.openLink(...args)))
        }, [
          _createVNode(_component_SvgIcon, { name: "12-open" })
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}