export const colConfigList = [
	{
		key: 'designInfo',
		keyProp: 'sideData',
		label: 'РП/РС',
		order: '',
		sort: false,
		show: true,
		width: 550,
		notSettings: true,
	},
	{
		key: 'photo',
		keyProp: 'photoList',
		keyGroup: 'sideData',
		label: 'Фото',
		order: '',
		sort: false,
		show: true,
		width: 100,
		collapsed: true,
	},
	{
		key: 'createdAt',
		keyProp: 'created_at',
		keyGroup: 'orderData',
		label: 'Добавлена',
		order: '',
		sort: true,
		show: false,
		width: 180,
		collapsed: true,
	},
	{
		key: 'priceMonth',
		label: 'Прайс/месяц',
		order: '',
		sort: false,
		show: true,
		// width: 170,
		// disabledCheck: true,
		collapsed: true,
		group: [
			{
				key: 'priceMonthNotVAT',
				keyProp: 'priceMonthNotVat',
				keyGroup: 'orderData',
				label: 'Без НДС',
				order: '',
				sort: true,
				show: true,
				width: 140,
				className: 'th-fill-red',
			},
			{
				key: 'priceMonthIncVAT',
				keyProp: 'priceMonthVat',
				keyGroup: 'orderData',
				label: 'c НДС',
				order: '',
				sort: true,
				show: true,
				width: 140,
				className: 'th-fill-green',
			},
		],
	},
	{
		key: 'pricePeriodAll',
		label: 'Прайс/все периоды',
		order: '',
		sort: false,
		show: true,
		// width: 170,
		collapsed: true,
		group: [
			{
				key: 'pricePeriodAllNotVAT',
				keyProp: 'pricePeriodNotVat',
				keyGroup: 'orderData',
				label: 'Без НДС',
				order: '',
				sort: true,
				show: true,
				width: 140,
				className: 'th-fill-red',
			},
			{
				key: 'pricePeriodAllIncVAT',
				keyProp: 'pricePeriodVat',
				keyGroup: 'orderData',
				label: 'c НДС',
				order: '',
				sort: true,
				show: true,
				width: 140,
				className: 'th-fill-green',
			},
		],
	},
	{
		key: 'placementMonths',
		keyProp: 'placementMonths',
		keyGroup: 'orderData',
		label: 'Месяцы размещения',
		order: '',
		sort: false,
		show: true,
		width: 440,
		collapsed: true,
	},
	{
		key: 'placementPeriods',
		keyProp: 'periods',
		keyGroup: 'orderData',
		label: 'Периоды размещения',
		order: '',
		sort: false,
		show: true,
		width: 200,
		collapsed: true,
	},
	{
		key: 'totalPricePlacement',
		label: 'Общая стоимость размещения',
		order: '',
		sort: false,
		show: true,
		collapsed: true,
		group: [
			{
				key: 'totalPricePlacementNotVAT',
				keyProp: 'costNotVat',
				keyGroup: 'orderData',
				label: 'Без НДС',
				order: '',
				sort: true,
				show: true,
				width: 120,
				className: 'th-fill-red',
			},
			{
				key: 'totalPricePlacementIncVAT',
				keyProp: 'costVat',
				keyGroup: 'orderData',
				label: 'c НДС',
				order: '',
				sort: true,
				show: true,
				width: 120,
				className: 'th-fill-green',
			},
		],
	},
	{
		key: 'totalPricePlacementInstall',
		keyProp: 'costWithInstallation',
		keyGroup: 'orderData',
		label: 'Общая стоимость размещения + доп.услуги',
		order: '',
		sort: true,
		show: true,
		width: 240,
		// disabledCheck: true,
		collapsed: true,
	},
	{
		key: 'averageDiscount',
		keyProp: 'avgDiscount',
		keyGroup: 'orderData',
		label: 'Средняя скидка (%)',
		order: '',
		sort: true,
		show: true,
		width: 140,
		collapsed: true,
	},
	{
		key: 'totalAgentDiscount',
		keyProp: 'sumAgentCommission',
		keyGroup: 'orderData',
		label: 'Общее агентское СК',
		order: '',
		sort: true,
		show: true,
		width: 190,
		collapsed: true,
	},
	{
		key: 'totalClientDiscount',
		keyProp: 'sumCustomerCommission',
		keyGroup: 'orderData',
		label: 'Общее клиентское СК',
		order: '',
		sort: true,
		show: true,
		width: 190,
		collapsed: true,
	},
	{
		key: 'avgPriceMonthPlace',
		keyProp: 'avgMonthCost',
		keyGroup: 'orderData',
		label: 'Средняя месячная стоимость размещения',
		order: '',
		sort: true,
		show: true,
		width: 190,
		collapsed: true,
	},
	{
		key: 'avgPriceMonthInstall',
		keyProp: 'avgMonthInstallation',
		keyGroup: 'orderData',
		label: 'Средняя месячная стоимость монтажа',
		order: '',
		sort: true,
		show: true,
		width: 190,
		collapsed: true,
	},
	{
		key: 'avgPriceMonthPrint',
		keyProp: 'avgMonthPrint',
		keyGroup: 'orderData',
		label: 'Средняя месячная стоимость печати',
		order: '',
		sort: true,
		show: true,
		width: 190,
		collapsed: true,
	},
	{
		key: 'deadlineDate',
		keyProp: 'dateContractEnd',
		keyGroup: 'orderData',
		label: 'Дата окончания разрешения',
		order: '',
		sort: true,
		show: true,
		width: 140,
		collapsed: true,
	},
	{
		key: 'avgEtherShare',
		keyProp: 'avgFractionEther',
		keyGroup: 'orderData',
		label: 'Средняя доля эфира',
		order: '',
		sort: true,
		show: true,
		width: 140,
		// disabledCheck: true,
		collapsed: true,
	},
	{
		key: 'grp',
		keyProp: 'grp',
		keyGroup: 'orderData',
		label: 'GRP',
		order: '',
		sort: true,
		show: true,
		width: 100,
		collapsed: true,
	},
	{
		key: 'ots',
		keyProp: 'ots',
		keyGroup: 'orderData',
		label: 'OTS',
		order: '',
		sort: true,
		show: true,
		width: 100,
		collapsed: true,
	},

	// //==================== Месяц размещения ====================
	{
		key: 'hr',
	},

	{
		key: 'status',
		keyProp: 'statusCol',
		keyGroup: 'monthData',
		label: 'Статус',
		order: '',
		sort: false,
		show: true,
		width: 240,
		className: 'th-fill-blue',
	},
	{
		key: 'sideNumber',
		keyProp: 'surfaceNumber',
		keyGroup: 'monthData',
		label: 'Номер РП',
		order: '',
		sort: true,
		show: true,
		width: 100,
		className: 'th-fill-blue',
	},
	// {
	// 	key: 'owner',
	// 	keyProp: 'owner',
	// 	keyGroup: 'monthData',
	// 	label: 'Собственник',
	// 	order: '',
	// 	sort: true,
	// 	show: true,
	// 	width: 160,
	// 	className: 'th-fill-blue',
	// },
	{
		key: 'periodInMonthDays',
		keyProp: 'periods',
		keyGroup: 'monthData',
		label: 'Периоды в месяце',
		order: '',
		sort: false,
		show: true,
		width: 160,
		className: 'th-fill-blue',
	},
	{
		key: 'placeType',
		keyProp: 'placementType',
		keyGroup: 'monthData',
		label: 'Тип размещения',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-blue',
	},
	{
		key: 'customerCategory',
		keyProp: 'customerCategory',
		keyGroup: 'monthData',
		label: 'Категория клиента',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-blue',
	},
	// new
	{
		key: 'promotion',
		keyProp: 'promotion',
		keyGroup: 'monthData',
		label: 'Акция',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-blue',
	},
	{
		key: 'product',
		keyProp: 'product',
		keyGroup: 'monthData',
		label: 'Продукт',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-blue',
	},
	{
		key: 'manager',
		keyProp: 'manager',
		keyGroup: 'monthData',
		label: 'Менеджер',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-blue',
	},
	// new
	{
		key: 'engManager',
		keyProp: 'engManager',
		keyGroup: 'monthData',
		label: 'Менеджер привлечения',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-blue',
	},
	// new
	{
		key: 'dealLink',
		keyProp: 'bitrix_deal_url',
		keyGroup: 'monthData',
		label: 'Ссылка на сделку',
		order: '',
		sort: true,
		show: true,
		width: 220,
		className: 'th-fill-blue',
	},
	// new
	{
		key: 'taskLink',
		keyProp: 'bitrix_task_url',
		keyGroup: 'monthData',
		label: 'Ссылка на задачу',
		order: '',
		sort: true,
		show: true,
		width: 220,
		className: 'th-fill-blue',
	},
	// new
	{
		key: 'photoReportLink',
		keyProp: 'photoReportLink',
		keyGroup: 'monthData',
		label: 'Фотоотчет',
		order: '',
		sort: true,
		show: true,
		width: 220,
		className: 'th-fill-blue',
	},


	/* пока скрываем */
	// {
	// 	key: 'documents',
	// 	label: 'Документы',
	// 	order: '',
	// 	sort: false,
	// 	show: true,
	// 	width: 220,
	// },

	{
		key: 'frequency',
		keyProp: 'frequency',
		keyGroup: 'monthData',
		label: 'Количество показов в час',
		order: '',
		sort: true,
		show: true,
		width: 160,
		editable: true,
		className: 'th-fill-blue',
	},
	{
		key: 'etherShare',
		keyProp: 'fractionEther',
		keyGroup: 'monthData',
		label: 'Доля эфира',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-blue',
	},
	{
		key: 'coefficient',
		keyProp: 'coefficient',
		keyGroup: 'monthData',
		label: 'Коэффициент',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-blue',
	},
	{
		key: 'cpt',
		keyProp: 'cpt',
		keyGroup: 'monthData',
		label: 'CPT',
		order: '',
		sort: true,
		show: true,
		width: 80,
		className: 'th-fill-blue',
	},
	{
		key: 'isVAT',
		keyProp: 'vat',
		keyGroup: 'monthData',
		label: 'НДС',
		order: '',
		sort: true,
		show: true,
		width: 140,
		className: 'th-fill-blue',
	},
	{
		key: 'pricePeriodOne',
		label: 'Прайс/период',
		order: '',
		sort: false,
		show: true,
		className: 'th-fill-blue',
		group: [
			{
				key: 'pricePeriodOneNotVat',
				keyProp: 'pricePeriodOneNotVat',
				keyGroup: 'monthData',
				label: 'Без НДС',
				order: '',
				sort: true,
				show: true,
				width: 140,
				className: 'th-fill-red',
			},
			{
				key: 'pricePeriodOneVat',
				keyProp: 'pricePeriodOneVat',
				keyGroup: 'monthData',
				label: 'c НДС',
				order: '',
				sort: true,
				show: true,
				width: 140,
				className: 'th-fill-green',
			},
		],
	},
	{
		key: 'pricePlacePeriod',
		label: 'Стоимость размещения/период',
		order: '',
		sort: false,
		show: true,
		className: 'th-fill-blue',
		group: [
			{
				key: 'pricePlacePeriodDiscount',
				keyProp: 'discount',
				keyGroup: 'monthData',
				label: 'Cкидка (%)',
				order: '',
				sort: true,
				show: true,
				width: 100,
				className: 'th-fill-blue',
				editable: true,
			},
			{
				key: 'pricePlacePeriodNotVAT',
				keyProp: 'cost',
				keyGroup: 'monthData',
				label: 'Без НДС',
				order: '',
				sort: true,
				show: true,
				width: 140,
				className: 'th-fill-red',
				editable: true,
			},
			{
				key: 'pricePlacePeriodIncVAT',
				keyProp: 'costWithVat',
				keyGroup: 'monthData',
				label: 'c НДС',
				order: '',
				sort: true,
				show: true,
				width: 140,
				className: 'th-fill-green',
			},
			{
				key: 'pricePlacePeriodCheck',
				keyProp: '',
				keyGroup: '',
				label: 'В счёт',
				order: '',
				sort: true,
				show: true,
				width: 140,
				className: 'th-fill-blue',
			},
		],
	},
	{
		key: 'agentCommission',
		keyProp: 'agent_commission',
		keyGroup: 'monthData',
		label: 'Агентское СК',
		order: '',
		sort: true,
		show: true,
		width: 140,
		className: 'th-fill-blue',
		editable: true,
	},
	{
		key: 'clientCommission',
		keyProp: 'customer_commission',
		keyGroup: 'monthData',
		label: 'Клиентское СК',
		order: '',
		sort: true,
		show: true,
		width: 140,
		className: 'th-fill-blue',
		editable: true,
	},

	//=================== Дополнительные услуги без НДС =====================
	{
		key: 'hr',
	},

	{
		key: 'install',
		keyProp: 'installation_summary',
		keyGroup: 'monthData',
		label: 'Монтаж',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-orange td-fill-orange',
		editable: true,
	},
	{
		key: 'addInstall',
		keyProp: 'additional_installation_summary',
		keyGroup: 'monthData',
		label: 'Доп. монтаж',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-orange td-fill-orange',
		editable: true,
	},
	{
		key: 'print',
		keyProp: 'print_summary',
		keyGroup: 'monthData',
		label: 'Печать',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-orange td-fill-orange',
		editable: true,
	},
	{
		key: 'speedPrint',
		keyProp: 'urgent_print_summary',
		keyGroup: 'monthData',
		label: 'Срочная печать',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-orange td-fill-orange',
		editable: true,
	},
	{
		key: 'design',
		keyProp: 'design_summary',
		keyGroup: 'monthData',
		label: 'Дизайн',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-orange td-fill-orange',
		editable: true,
	},
	{
		key: 'photoReport',
		keyProp: 'photo_report_summary',
		keyGroup: 'monthData',
		label: 'Фотоотчет',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-orange td-fill-orange',
		editable: true,
	},
	{
		key: 'totalPriceAddService',
		keyProp: 'servicesPrice',
		keyGroup: 'monthData',
		label: 'Общая стоимость доп. услуг',
		order: '',
		sort: true,
		show: true,
		width: 160,
		className: 'th-fill-orange td-fill-orange',
		// editable: true,
	},
	{
		key: 'pricePlaceAddService',
		label: 'Стоимость размещения + доп. услуги',
		order: '',
		sort: false,
		show: true,
		className: 'th-fill-blue',
		group: [
			{
				key: 'pricePlaceAddServiceNotVAT',
				keyProp: 'costWithServicesPrice',
				keyGroup: 'monthData',
				label: 'Без НДС',
				order: '',
				sort: true,
				show: true,
				width: 120,
				className: 'th-fill-red',
				// editable: true,
			},
			{
				key: 'pricePlaceAddServiceIncVAT',
				keyProp: 'costWithServicesPriceWithVat',
				keyGroup: 'monthData',
				label: 'c НДС',
				order: '',
				sort: true,
				show: true,
				width: 120,
				className: 'th-fill-green',
			},
		],
	},

	// //========================================

	{
		key: 'clientPerson',
		keyProp: 'customer',
		keyGroup: 'monthData',
		keyOptions: 'customer',
		label: 'Юр. лицо клиента',
		order: '',
		sort: true,
		show: true,
		width: 200,
		className: 'th-fill-red td-fill-red',
	},
	{
		key: 'rimPerson',
		keyProp: 'rimLegalEntity',
		keyGroup: 'monthData',
		keyOptions: 'rim_legal_entity',
		label: 'Юр. лицо МГ РИМ',
		order: '',
		sort: true,
		show: true,
		width: 200,
		className: 'th-fill-red td-fill-red',
	},
	// new
	{
		key: 'operator',
		keyProp: 'operator',
		keyGroup: 'monthData',
		keyOptions: 'operator',
		label: 'Оператор',
		order: '',
		sort: true,
		show: true,
		width: 200,
		className: 'th-fill-red td-fill-red',
	},
	{
		key: 'printPerson',
		keyProp: 'printer',
		keyGroup: 'monthData',
		keyOptions: 'printer',
		label: 'Печать',
		order: '',
		sort: true,
		show: true,
		width: 200,
		className: 'th-fill-red td-fill-red',
	},
	{
		key: 'installPerson',
		keyProp: 'installer',
		keyGroup: 'monthData',
		keyOptions: 'installer',
		label: 'Монтаж',
		order: '',
		sort: true,
		show: true,
		width: 200,
		className: 'th-fill-red td-fill-red',
	},
	{
		key: 'designPerson',
		keyProp: 'designer',
		keyGroup: 'monthData',
		keyOptions: 'designer',
		label: 'Дизайн',
		order: '',
		sort: true,
		show: true,
		width: 200,
		className: 'th-fill-red td-fill-red',
	},
	{
		key: 'photoReportPerson',
		keyProp: 'photoReporter',
		keyGroup: 'monthData',
		keyOptions: 'photo_reporter',
		label: 'Фотоотчет',
		order: '',
		sort: true,
		show: true,
		width: 200,
		className: 'th-fill-red td-fill-red',
	},
];