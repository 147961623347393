import { columns } from "./table-config";

import TableGrid from "@/app/components/table-grid/TableGrid.vue";
import CellSwitch from "@/app/components/table/cell-types/cell-switch/CellSwitch.vue";
import PrCellEditNum from "@/app/components/pricing-policies/type-cell/pr-cell-edit-num/PrCellEditNum.vue";
import PrCellDate from "@/app/components/pricing-policies/type-cell/pr-cell-date/PrCellDate.vue";
import CellPrice from "@/app/components/table/cell-types/cell-price/CellPrice.vue";
import PrCellCountStruct from "@/app/components/pricing-policies/type-cell/pr-cell-count-struct/PrCellCountStruct.vue";
import PrCellEditPrice from "@/app/components/pricing-policies/type-cell/pr-cell-edit-price/PrCellEditPrice.vue";
import PrCellEditPercent from "@/app/components/pricing-policies/type-cell/pr-cell-edit-percent/PrCellEditPercent.vue";
import PrCellCoef from "@/app/components/pricing-policies/type-cell/pr-cell-coef/PrCellCoef.vue";
import PrCellComment from "@/app/components/pricing-policies/type-cell/pr-cell-comment/PrCellComment.vue";
import PrCellRules from "@/app/components/pricing-policies/type-cell/pr-cell-rules/PrCellRules.vue";
import CellMassActions from "@/app/components/table/cell-types/cell-mass-actions/CellMassActions.vue";

export default {
	name: 'pricing-detail-table',
	components: {
		TableGrid,
		CellSwitch, PrCellEditNum, PrCellDate, CellPrice,
		PrCellCountStruct, PrCellEditPrice, PrCellEditPercent,
		PrCellCoef, PrCellComment, PrCellRules, CellMassActions,
	},
	props: {
		tableData: {
			type: Array,
			default: [],
		}
	},
	data() {
		return {
			columns,
			actionList: [
				{
					key: 'status',
					label: 'Сменить статус',
				},
				{
					key: 'depart',
					label: 'Сменить отделы',
				},
				{
					key: 'manager',
					label: 'Сменить менеджера',
				},
				{
					key: 'remove',
					label: 'Удалить',
				},
			],
			selectedRows: [],
		}
	},
	computed: {

	},
	methods: {
		async changeDefaultCell({ rowData, groupKey, fieldKey, fieldData }){
			console.log({ rowData, groupKey, fieldKey, fieldData });

			rowData[fieldKey] = fieldData;
		},

		// Массовые действия
		changeMass(key){
			console.log('changeMass', {key});
		},

		// Изменение строки
		changeRow(key, rowData){
			console.log('changeRow', {key, rowData});
		},

		changeSelectedRows(list){
			// console.log('changeSelectedRows', list);
			this.selectedRows = list;
		},

		generatedRows(){
			for (let i = 0; i < 50; i++) {
				const row = {
					id: 123,
					status: true,
					pos: 1,
					createdAt: new Date(),
					updatedAt: new Date(),
					avgPriceMonth: 52339,
					countStructure: {
						structs: 7600,
						sides: 14000,
						surfaces: 29000
					},
					priceMonth: 6983,
					targetDiscount: 56,
					maxDiscount: 70,
					curMonthDiscount: 60,
					coefficient: 1,
					isMultipCoefficient: true,
					comment: 'Не следует, однако забывать,что консультация с широким активом обеспечивает широкому кругу (специалистов) участие в формировании форм развития.',
				};

				this.rows.push(row);
			}
		}
	},
	created() {
		// this.generatedRows();
	},
}