import cellMixin from "@/app/mixins/table/cell-mixin";

import ShortSelect from '@/app/components/controls/selects/short-select/ShortSelect.vue';

export default {
	name: 'tprog-cell-brand',
	mixins: [ cellMixin ],
	components: {
		ShortSelect,
	},
	props: {
		options: {
			type: Array,
			default: [],
		}
	},
	emits: ['change'],
	data() {
		return {
			fieldValue: '',
		}
	},
	methods: {
		changeSelect(value){
			this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: value,
			});
		}
	},
	watch: {
		fieldData(val){
			this.fieldValue = this.fieldData;
		}
	},
	created() {
		if(this.fieldData) this.fieldValue = this.fieldData;
	},
}