import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bs-header__left" }
const _hoisted_2 = { class: "bs-header__logo" }
const _hoisted_3 = {
  key: 0,
  class: "bs-header__breadcrumb"
}
const _hoisted_4 = { class: "bs-header__middle" }
const _hoisted_5 = {
  key: 0,
  class: "h1 bs-header__head-title"
}
const _hoisted_6 = { class: "bs-header__right" }
const _hoisted_7 = { class: "bs-header__notif" }
const _hoisted_8 = {
  key: 0,
  class: "bs-header__user-info"
}
const _hoisted_9 = {
  key: 1,
  class: "bs-header__user-info"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeadLogo = _resolveComponent("MainHeadLogo")
  const _component_BsBreadcrumb = _resolveComponent("BsBreadcrumb")
  const _component_BtnHeadNotif = _resolveComponent("BtnHeadNotif")
  const _component_HeadUserInfo = _resolveComponent("HeadUserInfo")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElDropdownItem = _resolveComponent("ElDropdownItem")
  const _component_ElDropdownMenu = _resolveComponent("ElDropdownMenu")
  const _component_ElDropdown = _resolveComponent("ElDropdown")
  const _component_ElHeader = _resolveComponent("ElHeader")

  return (_openBlock(), _createBlock(_component_ElHeader, { class: "bs-header" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MainHeadLogo)
        ]),
        (_ctx.$route.path != '/')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_BsBreadcrumb)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.headTitle)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.headTitle), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_BtnHeadNotif, {
            onClick: _cache[0] || (_cache[0] = () => {})
          })
        ]),
        (_ctx.isDevServer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_ElDropdown, {
                trigger: "click",
                onCommand: _ctx.actionUserMenu
              }, {
                dropdown: _withCtx(() => [
                  _createVNode(_component_ElDropdownMenu, { class: "bs-header__dropdown-menu" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ElDropdownItem, {
                        class: "bs-header__dropdown-menu-item",
                        command: "sign-out"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SvgIcon, { name: "sign-out" }),
                          _cache[3] || (_cache[3] = _createElementVNode("span", null, "Выйти", -1 /* HOISTED */))
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_HeadUserInfo, {
                    onClick: _cache[1] || (_cache[1] = () => {}),
                    userData: _ctx.user
                  }, null, 8 /* PROPS */, ["userData"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onCommand"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_HeadUserInfo, {
                onClick: _cache[2] || (_cache[2] = () => {}),
                userData: _ctx.user
              }, null, 8 /* PROPS */, ["userData"])
            ]))
      ])
    ]),
    _: 1 /* STABLE */
  }))
}