import { ElDialog, ElMessageBox } from "element-plus";
import 'element-plus/es/components/dialog/style/css';

import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsSingleSelect from '@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue';
import BsMultiSelect from '@/app/components/controls/selects/bs-multi-select/BsMultiSelect.vue';
import BsSelectV2 from "@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue";

export default {
	name: 'di-edit-select',
	components:{
		ElDialog,
		BsBtn, BsSingleSelect, BsMultiSelect,
		BsSelectV2,
	},
	props:{
		titleDialog: String,
		labelActiveButton: {
			type: String,
			default: 'Изменить',
		},
		visible: {
			type: Boolean,
			default: false,
		},
		fieldData: {
			type: Object,
			default: {},
		},
		typeSelect: {
			type: String,
			default: 'single', // 'multi,
		}
	},
	emits:['update:visible','change'],
	data() {
		return {
			isVisible: this.visible,
			fieldValue: this.fieldData?.value || '',
			fieldOptions: this.fieldData?.options || [],
		}
	},
	computed: {
		isBtnEditDisabled(){
			return this.fieldValue == this.fieldData?.value;
		}
	},
	methods: {
		clickEdit(){
			if(this.isBtnEditDisabled) return;

			ElMessageBox.confirm(null, null, {
				title: 'Внимание',
				message: 'Вы точно хотите применить изменения?',
				showClose: false,
				// dangerouslyUseHTMLString: true,
				customClass: 'bs-message-box',
				type: 'warning',
			}).then(() => {
				this.$emit('change', this.fieldValue);
				this.clickCancel();
			}).catch(() => {

			})
		},
		clickCancel(){
			this.isVisible = false;
			this.fieldValue = '';
		}
	},
	watch: {
		isVisible(is){
			this.$emit('update:visible', is);
			if(is) this.fieldValue = this.fieldData?.value ?? '';
		},
		visible(is){
			this.isVisible = is;
		},
		fieldData(data){
			// console.log(data);
			const { value, options } = data;
			this.fieldValue = value ?? '';
			this.fieldOptions = options ?? [];
		}
	},
	created(){

	},
}