import dayjs from 'dayjs';

import cellMixin from "@/app/mixins/table/cell-mixin";
import helper from '@/app/utils/helper';

export default {
	name: 'tprog-place-periods',
	mixins: [ cellMixin ],
	emits: ['clickPeriod'],
	data() {
		return {

		}
	},
	computed: {
		periods(){
			const list = [];
			if(this.fieldData?.length) if(this.fieldData) for(let period of this.fieldData){
				if(!period?.length) continue;

				const [ from, to ] = period;
				list.push({
					id: helper.hash,
					from, to,
					format: {
						from: dayjs(from).format('DD.MM.YYYY'),
						to: dayjs(to).format('DD.MM.YYYY'),
					}
				});
			}
			return list;
		}
	},
	methods: {
		clickPeriod(item){
			this.$emit('clickPeriod', item);
		}
	},
}