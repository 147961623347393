import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "map-info-ap" }
const _hoisted_2 = { class: "map-info-ap__main-label" }
const _hoisted_3 = { class: "map-info-ap__arrow" }
const _hoisted_4 = { class: "map-info-ap__all-result" }
const _hoisted_5 = { class: "side-label" }
const _hoisted_6 = { class: "side-value" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_InfoApMonth = _resolveComponent("InfoApMonth")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["map-info-ap__main js-info-ap-main", { '--is-open': _ctx.isOpenMain }]),
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickOpenMain && _ctx.clickOpenMain(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createTextVNode("Сводка по АП ")),
        _createElementVNode("b", null, "(" + _toDisplayString(_ctx.allCountSize) + ")", 1 /* TEXT */),
        _cache[2] || (_cache[2] = _createTextVNode(":"))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SvgIcon, { name: "14-caret-bottom" })
      ])
    ], 2 /* CLASS */),
    _createVNode(_component_ElCollapseTransition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["map-info-ap__wrap", { '--is-open': _ctx.isOpenMain }])
        }, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allResult, (item, key) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "map-info-ap__all-result-side",
                key: key
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(key) + ":", 1 /* TEXT */),
                _createElementVNode("span", _hoisted_6, _toDisplayString(item.value) + " (" + _toDisplayString(item.percent) + "%)", 1 /* TEXT */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.months, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "map-info-ap__result-month",
              key: item.date
            }, [
              _createVNode(_component_InfoApMonth, {
                data: item,
                open: _ctx.openMonthMap[item.date],
                onOpenChange: _ctx.changeOpenMonth
              }, null, 8 /* PROPS */, ["data", "open", "onOpenChange"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */), [
          [_vShow, _ctx.isOpenMain]
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}