import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "po-edit-month-range__datepicker" }
const _hoisted_2 = { class: "po-edit-month-range__btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DatepickerMonthRange = _resolveComponent("DatepickerMonthRange")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createBlock(_component_ElPopover, {
    class: "po-edit-month-range",
    "popper-class": "po-edit-month-range__popover",
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.isVisible) = $event)),
    persistent: false,
    placement: "bottom",
    width: 'auto',
    trigger: "click"
  }, {
    reference: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DatepickerMonthRange, {
          period: _ctx.period,
          "onUpdate:period": _cache[0] || (_cache[0] = $event => ((_ctx.period) = $event))
        }, null, 8 /* PROPS */, ["period"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BsBtn, {
          tag: "div",
          onClick: _ctx.clickCancel
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Отмена")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_BsBtn, {
          type: "primary",
          tag: "div",
          onClick: _ctx.clickSave
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Ок")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["visible"]))
}