import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tprog-design-info__main" }
const _hoisted_2 = { class: "tprog-design-info__block" }
const _hoisted_3 = { class: "tprog-design-info__group group-row gap-16 row-space-between" }
const _hoisted_4 = { class: "tprog-design-info__group" }
const _hoisted_5 = { class: "tprog-design-info__head" }
const _hoisted_6 = { class: "head-gid" }
const _hoisted_7 = {
  key: 0,
  class: "head-lighting"
}
const _hoisted_8 = { class: "tprog-design-info__head" }
const _hoisted_9 = { class: "head-inner-id" }
const _hoisted_10 = { class: "tprog-design-info__type" }
const _hoisted_11 = { class: "tprog-design-info__group group-row" }
const _hoisted_12 = { class: "tprog-design-info__type-construct" }
const _hoisted_13 = { class: "tprog-design-info__type-size" }
const _hoisted_14 = { class: "tprog-design-info__type-construct-icon" }
const _hoisted_15 = {
  key: 0,
  class: "tprog-design-info__type-badge"
}
const _hoisted_16 = { class: "tprog-design-info__group group-row gap-16 row-space-between" }
const _hoisted_17 = ["title"]
const _hoisted_18 = { class: "tprog-design-info__btn-actions" }
const _hoisted_19 = { class: "tprog-design-info__btn-action-item" }
const _hoisted_20 = {
  class: "tprog-design-info__btn-action-item item-comment",
  title: "Комментарий"
}
const _hoisted_21 = { class: "item-comment-badge" }
const _hoisted_22 = { class: "tprog-design-info__btn-actions" }
const _hoisted_23 = { class: "tprog-design-info__btn-action-item" }
const _hoisted_24 = {
  class: "tprog-design-info__btn-action-item item-comment",
  title: "Комментарий"
}
const _hoisted_25 = { class: "item-comment-badge" }
const _hoisted_26 = { class: "tprog-design-info__main" }
const _hoisted_27 = { class: "tprog-design-info__block" }
const _hoisted_28 = { class: "tprog-design-info__group group-row row-space-between gap-16" }
const _hoisted_29 = { class: "tprog-design-info__group group-row gap-16" }
const _hoisted_30 = { class: "tprog-design-info__head" }
const _hoisted_31 = { class: "head-gid" }
const _hoisted_32 = { class: "head-inner-id" }
const _hoisted_33 = {
  key: 0,
  class: "head-lighting"
}
const _hoisted_34 = { class: "tprog-design-info__group group-row gap-16" }
const _hoisted_35 = { class: "tprog-design-info__type" }
const _hoisted_36 = {
  key: 0,
  class: "tprog-design-info__type-badge"
}
const _hoisted_37 = { class: "tprog-design-info__group group-row" }
const _hoisted_38 = { class: "tprog-design-info__type-construct" }
const _hoisted_39 = { class: "tprog-design-info__type-size" }
const _hoisted_40 = { class: "tprog-design-info__type-construct-icon" }
const _hoisted_41 = ["title"]
const _hoisted_42 = {
  key: 0,
  class: "tprog-design-info__wrap"
}
const _hoisted_43 = {
  key: 0,
  class: "tprog-design-info__group group-row gap-32"
}
const _hoisted_44 = { class: "tprog-design-info__group" }
const _hoisted_45 = {
  key: 0,
  class: "tprog-design-info__group-value"
}
const _hoisted_46 = {
  key: 1,
  class: "tprog-design-info__group-value"
}
const _hoisted_47 = { class: "tprog-design-info__group" }
const _hoisted_48 = {
  key: 0,
  class: "tprog-design-info__group-value"
}
const _hoisted_49 = {
  key: 1,
  class: "tprog-design-info__group-value"
}
const _hoisted_50 = { class: "tprog-design-info__group" }
const _hoisted_51 = { class: "tprog-design-info__group-value" }
const _hoisted_52 = { class: "tprog-design-info__group group-row gap-32" }
const _hoisted_53 = { class: "tprog-design-info__group" }
const _hoisted_54 = { class: "tprog-design-info__group-value" }
const _hoisted_55 = ["title"]
const _hoisted_56 = { class: "tprog-design-info__group-value" }
const _hoisted_57 = { class: "tprog-design-info__group" }
const _hoisted_58 = { class: "tprog-design-info__group-value" }
const _hoisted_59 = {
  key: 0,
  class: "tprog-design-info__group"
}
const _hoisted_60 = { class: "tprog-design-info__group-value" }
const _hoisted_61 = {
  key: 1,
  class: "tprog-design-info__group"
}
const _hoisted_62 = { class: "tprog-design-info__group-value" }
const _hoisted_63 = { class: "tprog-design-info__group group-row gap-32" }
const _hoisted_64 = { class: "tprog-design-info__group" }
const _hoisted_65 = { class: "tprog-design-info__group-value" }
const _hoisted_66 = { class: "tprog-design-info__group" }
const _hoisted_67 = { class: "tprog-design-info__group-value" }
const _hoisted_68 = {
  key: 1,
  class: "tprog-design-info__group"
}
const _hoisted_69 = { class: "tprog-design-info__group-value" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_TprogCommentPopover = _resolveComponent("TprogCommentPopover")
  const _component_ElBadge = _resolveComponent("ElBadge")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tprog-design-info", { 'tprog-design-info--open': _ctx.isOpen, 'tprog-design-info--is-compact': _ctx.compact }]),
    onMouseenter: _cache[9] || (_cache[9] = (...args) => (_ctx.mouseEnter && _ctx.mouseEnter(...args))),
    onMouseleave: _cache[10] || (_cache[10] = (...args) => (_ctx.mouseLeave && _ctx.mouseLeave(...args)))
  }, [
    (_ctx.compact)
      ? (_openBlock(), _createBlock(_component_ElBadge, {
          key: 0,
          class: "tprog-design-info__badge",
          value: 2,
          hidden: true
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.fieldData?.innerId), 1 /* TEXT */),
                      (_ctx.fieldData?.lighting)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_SvgIcon, { name: "lamp" })
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.fieldData?.gid), 1 /* TEXT */)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.fieldData?.structureType), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.fieldData?.size), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_SvgIcon, {
                          name: _ctx.getTypeDesignIcon(_ctx.fieldData?.structureType)
                        }, null, 8 /* PROPS */, ["name"])
                      ])
                    ]),
                    (_ctx.fieldData?.type)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.fieldData?.type), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", {
                    class: "a tprog-design-info__address",
                    title: _ctx.fieldData?.address,
                    onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.copyAddress && _ctx.copyAddress(...args)))
                  }, _toDisplayString(_ctx.labelCity), 9 /* TEXT, PROPS */, _hoisted_17),
                  _createVNode(_Transition, {
                    name: "el-fade-in",
                    mode: "out-in",
                    persisted: ""
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("div", _hoisted_18, [
                        _createVNode(_Transition, {
                          name: "el-fade-in",
                          mode: "out-in",
                          persisted: ""
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("div", _hoisted_19, [
                              _createVNode(_component_SvgIcon, { name: "16-refresh" })
                            ], 512 /* NEED_PATCH */), [
                              [_vShow, !_ctx.isComment || _ctx.showActions]
                            ])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_Transition, {
                          name: "el-fade-in",
                          mode: "out-in",
                          persisted: ""
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("div", {
                              class: "tprog-design-info__btn-action-item",
                              title: "Открыть РК",
                              onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.openDesign && _ctx.openDesign(...args)))
                            }, [
                              _createVNode(_component_SvgIcon, { name: "16-share" })
                            ], 512 /* NEED_PATCH */), [
                              [_vShow, !_ctx.isComment || _ctx.showActions]
                            ])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_Transition, {
                          name: "el-fade-in",
                          mode: "out-in",
                          persisted: ""
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("div", {
                              class: "tprog-design-info__btn-action-item",
                              title: "Открыть тех.требования",
                              onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.openTechReq && _ctx.openTechReq(...args)))
                            }, [
                              _createVNode(_component_SvgIcon, { name: "16-collection" })
                            ], 512 /* NEED_PATCH */), [
                              [_vShow, !_ctx.isComment || _ctx.showActions]
                            ])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_TprogCommentPopover, {
                          visible: _ctx.isVisibleComment,
                          "onUpdate:visible": _cache[3] || (_cache[3] = $event => ((_ctx.isVisibleComment) = $event)),
                          comment: _ctx.orderData?.comment,
                          onChange: _ctx.changeComment
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_20, [
                              _withDirectives(_createElementVNode("div", _hoisted_21, null, 512 /* NEED_PATCH */), [
                                [_vShow, _ctx.isComment]
                              ]),
                              _createVNode(_component_SvgIcon, { name: "16-comment" })
                            ])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["visible", "comment", "onChange"])
                      ], 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.showActions || _ctx.isVisibleComment || _ctx.isComment]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_Transition, {
            name: "el-fade-in",
            mode: "out-in",
            persisted: ""
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_22, [
                _createVNode(_Transition, {
                  name: "el-fade-in",
                  mode: "out-in",
                  persisted: ""
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("div", _hoisted_23, [
                      _createVNode(_component_SvgIcon, { name: "16-refresh" })
                    ], 512 /* NEED_PATCH */), [
                      [_vShow, !_ctx.isComment || _ctx.showActions]
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_Transition, {
                  name: "el-fade-in",
                  mode: "out-in",
                  persisted: ""
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("div", {
                      class: "tprog-design-info__btn-action-item",
                      title: "Открыть РК",
                      onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.openDesign && _ctx.openDesign(...args)))
                    }, [
                      _createVNode(_component_SvgIcon, { name: "16-share" })
                    ], 512 /* NEED_PATCH */), [
                      [_vShow, !_ctx.isComment || _ctx.showActions]
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_Transition, {
                  name: "el-fade-in",
                  mode: "out-in",
                  persisted: ""
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("div", {
                      class: "tprog-design-info__btn-action-item",
                      title: "Открыть тех.требования",
                      onClick: _cache[5] || (_cache[5] = (...args) => (_ctx.openTechReq && _ctx.openTechReq(...args)))
                    }, [
                      _createVNode(_component_SvgIcon, { name: "16-collection" })
                    ], 512 /* NEED_PATCH */), [
                      [_vShow, !_ctx.isComment || _ctx.showActions]
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_TprogCommentPopover, {
                  visible: _ctx.isVisibleComment,
                  "onUpdate:visible": _cache[6] || (_cache[6] = $event => ((_ctx.isVisibleComment) = $event)),
                  comment: _ctx.orderData?.comment,
                  onChange: _ctx.changeComment
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_24, [
                      _withDirectives(_createElementVNode("div", _hoisted_25, null, 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.isComment]
                      ]),
                      _createVNode(_component_SvgIcon, { name: "16-comment" })
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["visible", "comment", "onChange"])
              ], 512 /* NEED_PATCH */), [
                [_vShow, _ctx.showActions || _ctx.isVisibleComment || _ctx.isComment]
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ElBadge, {
            class: "tprog-design-info__badge",
            value: 2,
            hidden: true
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.fieldData?.innerId), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.fieldData?.gid), 1 /* TEXT */),
                        (_ctx.fieldData?.lighting)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                              _createVNode(_component_SvgIcon, { name: "lamp" })
                            ]))
                          : _createCommentVNode("v-if", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("div", {
                        class: "tprog-design-info__btn-open",
                        onClick: _cache[7] || (_cache[7] = (...args) => (_ctx.toggleWrap && _ctx.toggleWrap(...args)))
                      }, [
                        _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        (_ctx.fieldData?.type)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_36, _toDisplayString(_ctx.fieldData?.type), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true),
                        _createElementVNode("div", _hoisted_37, [
                          _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.fieldData?.structureType), 1 /* TEXT */),
                          _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.fieldData?.size), 1 /* TEXT */),
                          _createElementVNode("div", _hoisted_40, [
                            _createVNode(_component_SvgIcon, {
                              name: _ctx.getTypeDesignIcon(_ctx.fieldData?.structureType)
                            }, null, 8 /* PROPS */, ["name"])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", {
                  class: "a tprog-design-info__address",
                  title: _ctx.fieldData?.address,
                  onClick: _cache[8] || (_cache[8] = (...args) => (_ctx.copyAddress && _ctx.copyAddress(...args)))
                }, _toDisplayString(_ctx.fieldData?.address), 9 /* TEXT, PROPS */, _hoisted_41)
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ElCollapseTransition, null, {
            default: _withCtx(() => [
              (_ctx.isOpen)
                ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                    (_ctx.isDigital)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                          _createElementVNode("div", _hoisted_44, [
                            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "tprog-design-info__group-label" }, "Блок/слот", -1 /* HOISTED */)),
                            (_ctx.blockDuration || _ctx.slotDuration)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_45, _toDisplayString(_ctx.blockDuration) + "/" + _toDisplayString(_ctx.slotDuration), 1 /* TEXT */))
                              : (_openBlock(), _createElementBlock("div", _hoisted_46, "-"))
                          ]),
                          _createElementVNode("div", _hoisted_47, [
                            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "tprog-design-info__group-label" }, "Длина ролика", -1 /* HOISTED */)),
                            (_ctx.rowData?.orderData?.duration)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_48, _toDisplayString(_ctx.rowData?.orderData?.duration) + " секунд", 1 /* TEXT */))
                              : (_openBlock(), _createElementBlock("div", _hoisted_49, "-"))
                          ]),
                          _createElementVNode("div", _hoisted_50, [
                            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "tprog-design-info__group-label" }, "WiFi ловушка", -1 /* HOISTED */)),
                            _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.fieldData?.wifiTrap || '–'), 1 /* TEXT */)
                          ])
                        ]))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", _hoisted_52, [
                      _createElementVNode("div", _hoisted_53, [
                        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "tprog-design-info__group-label" }, "Оператор", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.fieldData?.operator || '–'), 1 /* TEXT */)
                      ]),
                      _createElementVNode("div", {
                        class: "tprog-design-info__group tprog-design-info__owner",
                        title: _ctx.fieldData?.owner
                      }, [
                        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "tprog-design-info__group-label" }, "Собственник", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.fieldData?.owner || '–'), 1 /* TEXT */)
                      ], 8 /* PROPS */, _hoisted_55),
                      _createElementVNode("div", _hoisted_57, [
                        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "tprog-design-info__group-label" }, "ESPAR ID", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_58, _toDisplayString(_ctx.fieldData?.esparId || '–'), 1 /* TEXT */)
                      ]),
                      (_ctx.isDigital)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "tprog-design-info__group-label" }, "Времени работы", -1 /* HOISTED */)),
                            _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.fieldData?.workingHours || '–'), 1 /* TEXT */)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_61, [
                            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "tprog-design-info__group-label" }, "Материал", -1 /* HOISTED */)),
                            _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.fieldData?.staticMaterial || '–'), 1 /* TEXT */)
                          ]))
                    ]),
                    _createElementVNode("div", _hoisted_63, [
                      _createElementVNode("div", _hoisted_64, [
                        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "tprog-design-info__group-label" }, "GRP", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.grp), 1 /* TEXT */)
                      ]),
                      _createElementVNode("div", _hoisted_66, [
                        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "tprog-design-info__group-label" }, "OTS", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_67, _toDisplayString(_ctx.ots), 1 /* TEXT */)
                      ])
                    ]),
                    (_ctx.fieldData?.techComment)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "tprog-design-info__group-label" }, "Технический комментарий", -1 /* HOISTED */)),
                          _createElementVNode("div", _hoisted_69, _toDisplayString(_ctx.fieldData?.techComment), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ], 64 /* STABLE_FRAGMENT */))
  ], 34 /* CLASS, NEED_HYDRATION */))
}