import cellMixin from "@/app/mixins/table/cell-mixin";

import helper from "@/app/utils/helper";

export default {
	name: 'tprog-check-price',
	mixins: [ cellMixin ],
	computed: {
		vat(){
			return this.rowData?.monthData?.vat;
		},
		pricePlacePeriodIncVAT(){
			return this.rowData?.monthData?.costWithVat;
		},
		pricePlacePeriodNotVAT(){
			return this.rowData?.monthData?.cost;
		},
		label(){
			let sum = '–';

			if(this.vat === 'yes'){
				if(this.pricePlacePeriodIncVAT !== undefined) sum = `${helper.getMoney(this.pricePlacePeriodIncVAT)} ₽`;
			} else {
				if(this.pricePlacePeriodNotVAT !== undefined) sum = `${helper.getMoney(this.pricePlacePeriodNotVAT)} ₽`;
			}

			return sum;
		}
	},
}