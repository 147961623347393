import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'tprog-cell-status',
	mixins: [ cellMixin ],
	computed: {
		label(){
			if(this.fieldData=='active') return 'Активная';
			return this.fieldData;
		}
	},
}