import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tprog-period-month-days__icon" }
const _hoisted_2 = {
  key: 0,
  class: "tprog-period-month-days__wrap"
}
const _hoisted_3 = { class: "tprog-period-month-days__item" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 1,
  class: "tprog-period-month-days__empty"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_DatepickerMonthPeriod = _resolveComponent("DatepickerMonthPeriod")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tprog-period-month-days", { '--is-status-busy': _ctx.status=='busy' }])
  }, [
    _createVNode(_component_ElPopover, {
      trigger: "click",
      visible: _ctx.isVisiblePopover,
      "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.isVisiblePopover) = $event)),
      offset: 8,
      "hide-after": 100,
      persistent: false,
      width: "auto",
      placement: "bottom-start",
      "popper-class": "tprog-period-month-days__popover"
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_SvgIcon, { name: "16-calendar" })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DatepickerMonthPeriod, {
          periods: _ctx.periods,
          startDate: _ctx.startDate,
          multiple: true,
          mode: "add",
          onCancel: _cache[0] || (_cache[0] = $event => (_ctx.isVisiblePopover=false)),
          onAdd: _ctx.addPeriod
        }, null, 8 /* PROPS */, ["periods", "startDate", "onAdd"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"]),
    (_ctx.periods?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.periods, (item, index) => {
            return (_openBlock(), _createBlock(_component_ElPopover, {
              key: item.id,
              trigger: "click",
              visible: item.isVisiblePopover,
              "onUpdate:visible": $event => ((item.isVisiblePopover) = $event),
              offset: 8,
              "hide-after": 100,
              persistent: false,
              width: "auto",
              placement: "bottom-start",
              "popper-class": "tprog-period-month-days__popover"
            }, {
              reference: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (item.from==item.to)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.format.from), 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.format.from) + "-" + _toDisplayString(item.format.to), 1 /* TEXT */))
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_DatepickerMonthPeriod, {
                  periods: [ item ],
                  selected: false,
                  onCancel: $event => (_ctx.cancelDatepicker(item)),
                  onRemove: $event => (_ctx.removePeriod(item, index))
                }, null, 8 /* PROPS */, ["periods", "onCancel", "onRemove"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["visible", "onUpdate:visible"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, "–"))
  ], 2 /* CLASS */))
}