import { mapActions, mapState, mapWritableState } from 'pinia';
import { useAllTargetProgStore } from '@/app/stores/all-target-prog-store';
import { useFiltersStore } from '@/app/stores/filters-store';

import dayjs from 'dayjs';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import SearchPanel from '@/app/components/controls/search-panel/SearchPanel.vue';
import BsSingleSelect from '@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue';
import BsMultiSelect from '@/app/components/controls/selects/bs-multi-select/BsMultiSelect.vue';
import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';
import BsDatePicker from '@/app/components/controls/bs-date-picker/BsDatePicker.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';

export default {
	name: 'target-programs-aside',
	components: {
		LoaderSpinner, SvgIcon,
		SearchPanel, BsSingleSelect, BsMultiSelect, BsSelectV2,
		BsDatePicker, BsBtn, BsInput,
	},
	data() {
		return {

		}
	},
	computed: {
		...mapState(useAllTargetProgStore, ['isAsideLoader', 'compareOptions', 'expiringStatusOptions']),
		...mapWritableState(useAllTargetProgStore, ['allTargetFilterData']),
	},
	methods: {
		...mapActions(useAllTargetProgStore, ['getAllTargetListData', 'getSubmitFilterData', 'clearFilters']),
		...mapActions(useFiltersStore, ['getOptions']),

		disabledDateTo(data){
			const { from, to } = this.allTargetFilterData.placePeriod;
			const today = dayjs().format('YYYY-MM-DD');
			let nextDate = dayjs(today).add(1, 'days').toDate();

			if(from) nextDate = dayjs(from).add(1, 'days').toDate();

			return nextDate > data;
		},

		changeDatePickerFrom(){
			const { from, to } = this.allTargetFilterData.placePeriod;
			const dateFrom = dayjs(from).toDate();
			const dateTo = dayjs(to).toDate();

			if(dateFrom > dateTo) this.allTargetFilterData.placePeriod.to = dayjs(dateFrom).add(1, 'days').format('YYYY-MM-DD');
		},

		submit(){
			const filters = this.getSubmitFilterData();
			let query = null;

			if(Object.keys(filters)?.length){
				query = {
					filters: JSON.stringify(filters),
				}
			}

			let currentQuery = this.$route.query
			if(currentQuery && currentQuery.sort) {
				query.sort = this.$route.query.sort
			}

			this.$router.push({
				name: 'target-programs',
				query: {
					...query,
				},
			});

			this.getAllTargetListData();
		},

		clickResetFilters(){
			this.clearFilters();

			this.$router.push({
				name: 'target-programs',
				query: { },
			});
			this.getAllTargetListData();
		}
	},
}