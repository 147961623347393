import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "target-filter" }
const _hoisted_2 = { class: "target-filter__container" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = { class: "control__group" }
const _hoisted_5 = { class: "control" }
const _hoisted_6 = { class: "control__group" }
const _hoisted_7 = { class: "control target-filter__control-search-gid" }
const _hoisted_8 = { class: "control target-filter__place-period" }
const _hoisted_9 = { class: "control__group group-col" }
const _hoisted_10 = { class: "control__group group-row" }
const _hoisted_11 = { class: "control" }
const _hoisted_12 = { class: "control__group group-row" }
const _hoisted_13 = { class: "control full-free-width" }
const _hoisted_14 = { class: "control__group" }
const _hoisted_15 = { class: "control full-free-width" }
const _hoisted_16 = { class: "control__group" }
const _hoisted_17 = { class: "control" }
const _hoisted_18 = { class: "control__group group-row" }
const _hoisted_19 = { class: "control full-free-width" }
const _hoisted_20 = { class: "control__group" }
const _hoisted_21 = { class: "control full-free-width" }
const _hoisted_22 = { class: "control__group" }
const _hoisted_23 = { class: "control" }
const _hoisted_24 = { class: "control__group" }
const _hoisted_25 = { class: "control" }
const _hoisted_26 = { class: "control__group" }
const _hoisted_27 = { class: "control" }
const _hoisted_28 = { class: "control__group" }
const _hoisted_29 = { class: "control" }
const _hoisted_30 = { class: "control__group group-row" }
const _hoisted_31 = { class: "control full-free-width" }
const _hoisted_32 = { class: "control__group" }
const _hoisted_33 = { class: "control full-free-width" }
const _hoisted_34 = { class: "control__group" }
const _hoisted_35 = { class: "control" }
const _hoisted_36 = { class: "control__group" }
const _hoisted_37 = { class: "control" }
const _hoisted_38 = { class: "control__group group-row" }
const _hoisted_39 = { class: "control full-free-width" }
const _hoisted_40 = { class: "control__group" }
const _hoisted_41 = { class: "control full-free-width" }
const _hoisted_42 = { class: "control__group" }
const _hoisted_43 = { class: "control target-filter__builder-compare" }
const _hoisted_44 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_45 = { class: "control" }
const _hoisted_46 = { class: "control__group" }
const _hoisted_47 = { class: "control full-free-width" }
const _hoisted_48 = { class: "control__group" }
const _hoisted_49 = { class: "control target-filter__builder-compare" }
const _hoisted_50 = { class: "control__group group-row" }
const _hoisted_51 = { class: "control full-free-width" }
const _hoisted_52 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_53 = { class: "control" }
const _hoisted_54 = { class: "control__group" }
const _hoisted_55 = { class: "control full-free-width" }
const _hoisted_56 = { class: "control__group" }
const _hoisted_57 = { class: "control full-free-width" }
const _hoisted_58 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_59 = { class: "control" }
const _hoisted_60 = { class: "control__group" }
const _hoisted_61 = { class: "control full-free-width" }
const _hoisted_62 = { class: "control__group" }
const _hoisted_63 = { class: "control target-filter__builder-compare" }
const _hoisted_64 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_65 = { class: "control" }
const _hoisted_66 = { class: "control__group" }
const _hoisted_67 = { class: "control full-free-width" }
const _hoisted_68 = { class: "control__group" }
const _hoisted_69 = { class: "control" }
const _hoisted_70 = { class: "control__head-arrow" }
const _hoisted_71 = { class: "control__group group-col gap-24" }
const _hoisted_72 = { class: "control" }
const _hoisted_73 = { class: "control__group group-row" }
const _hoisted_74 = { class: "control full-free-width" }
const _hoisted_75 = { class: "control__group" }
const _hoisted_76 = { class: "control full-free-width" }
const _hoisted_77 = { class: "control__group" }
const _hoisted_78 = { class: "control" }
const _hoisted_79 = { class: "control__group group-row" }
const _hoisted_80 = { class: "control full-free-width" }
const _hoisted_81 = { class: "control__group" }
const _hoisted_82 = { class: "control full-free-width" }
const _hoisted_83 = { class: "control__group" }
const _hoisted_84 = { class: "control" }
const _hoisted_85 = { class: "control__group group-row" }
const _hoisted_86 = { class: "control full-free-width" }
const _hoisted_87 = { class: "control__group" }
const _hoisted_88 = { class: "control" }
const _hoisted_89 = { class: "control__head-arrow" }
const _hoisted_90 = { class: "control__group group-col gap-24" }
const _hoisted_91 = { class: "control" }
const _hoisted_92 = { class: "control__group" }
const _hoisted_93 = { class: "control" }
const _hoisted_94 = { class: "control__group" }
const _hoisted_95 = { class: "control" }
const _hoisted_96 = { class: "control__group" }
const _hoisted_97 = { class: "control" }
const _hoisted_98 = { class: "control__group" }
const _hoisted_99 = { class: "control" }
const _hoisted_100 = { class: "control__group" }
const _hoisted_101 = { class: "control" }
const _hoisted_102 = { class: "control__group" }
const _hoisted_103 = { class: "control" }
const _hoisted_104 = { class: "control__group" }
const _hoisted_105 = { class: "control" }
const _hoisted_106 = { class: "control__head-arrow" }
const _hoisted_107 = { class: "control__group group-col gap-24" }
const _hoisted_108 = { class: "control" }
const _hoisted_109 = { class: "control__group" }
const _hoisted_110 = { class: "control" }
const _hoisted_111 = { class: "control__group" }
const _hoisted_112 = { class: "control" }
const _hoisted_113 = { class: "control__group group-row" }
const _hoisted_114 = { class: "control full-free-width" }
const _hoisted_115 = { class: "control__group" }
const _hoisted_116 = { class: "control full-free-width" }
const _hoisted_117 = { class: "control__group" }
const _hoisted_118 = { class: "control" }
const _hoisted_119 = { class: "control__group" }
const _hoisted_120 = { class: "control" }
const _hoisted_121 = { class: "control__head-arrow" }
const _hoisted_122 = { class: "control__group group-col gap-24" }
const _hoisted_123 = { class: "control" }
const _hoisted_124 = { class: "control__group group-row" }
const _hoisted_125 = { class: "control full-free-width target-filter__builder-compare" }
const _hoisted_126 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_127 = { class: "control" }
const _hoisted_128 = { class: "control__group" }
const _hoisted_129 = { class: "control full-free-width" }
const _hoisted_130 = { class: "control__group" }
const _hoisted_131 = { class: "control full-free-width target-filter__builder-compare" }
const _hoisted_132 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_133 = { class: "control" }
const _hoisted_134 = { class: "control__group" }
const _hoisted_135 = { class: "control full-free-width" }
const _hoisted_136 = { class: "control__group" }
const _hoisted_137 = { class: "control" }
const _hoisted_138 = { class: "control__group group-row" }
const _hoisted_139 = { class: "control full-free-width target-filter__builder-compare" }
const _hoisted_140 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_141 = { class: "control" }
const _hoisted_142 = { class: "control__group" }
const _hoisted_143 = { class: "control full-free-width" }
const _hoisted_144 = { class: "control__group" }
const _hoisted_145 = { class: "control full-free-width target-filter__builder-compare" }
const _hoisted_146 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_147 = { class: "control" }
const _hoisted_148 = { class: "control__group" }
const _hoisted_149 = { class: "control full-free-width" }
const _hoisted_150 = { class: "control__group" }
const _hoisted_151 = { class: "control" }
const _hoisted_152 = { class: "control__group group-row" }
const _hoisted_153 = { class: "control full-free-width target-filter__builder-compare" }
const _hoisted_154 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_155 = { class: "control" }
const _hoisted_156 = { class: "control__group" }
const _hoisted_157 = { class: "control full-free-width" }
const _hoisted_158 = { class: "control__group" }
const _hoisted_159 = { class: "control full-free-width target-filter__builder-compare" }
const _hoisted_160 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_161 = { class: "control" }
const _hoisted_162 = { class: "control__group" }
const _hoisted_163 = { class: "control full-free-width" }
const _hoisted_164 = { class: "control__group" }
const _hoisted_165 = { class: "control target-filter__builder-compare" }
const _hoisted_166 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_167 = { class: "control" }
const _hoisted_168 = { class: "control__group" }
const _hoisted_169 = { class: "control full-free-width" }
const _hoisted_170 = { class: "control__group" }
const _hoisted_171 = { class: "control" }
const _hoisted_172 = { class: "control__group group-row" }
const _hoisted_173 = { class: "control full-free-width target-filter__builder-compare" }
const _hoisted_174 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_175 = { class: "control" }
const _hoisted_176 = { class: "control__group" }
const _hoisted_177 = { class: "control full-free-width" }
const _hoisted_178 = { class: "control__group" }
const _hoisted_179 = { class: "control full-free-width target-filter__builder-compare" }
const _hoisted_180 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_181 = { class: "control" }
const _hoisted_182 = { class: "control__group" }
const _hoisted_183 = { class: "control full-free-width" }
const _hoisted_184 = { class: "control__group" }
const _hoisted_185 = { class: "target-filter__btn-submit" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsMultiSelect = _resolveComponent("BsMultiSelect")
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_DesignSearchGid = _resolveComponent("DesignSearchGid")
  const _component_BsRadioGroup = _resolveComponent("BsRadioGroup")
  const _component_BsDatePicker = _resolveComponent("BsDatePicker")
  const _component_BsCheckboxGroup = _resolveComponent("BsCheckboxGroup")
  const _component_BsSingleSelect = _resolveComponent("BsSingleSelect")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")
  const _component_BsBtn = _resolveComponent("BsBtn")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        class: "target-filter__form",
        onSubmit: _cache[70] || (_cache[70] = _withModifiers((...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _cache[71] || (_cache[71] = _createElementVNode("div", { class: "control__label" }, "Статус согласования", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BsMultiSelect, {
              placeholder: "Введите или выберите из списка",
              modelValue: _ctx.detailTargetFilterData.statusAgreement.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.detailTargetFilterData.statusAgreement.value) = $event)),
              options: _ctx.detailTargetFilterData.statusAgreement.options,
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 8
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[72] || (_cache[72] = _createElementVNode("div", { class: "control__label" }, "Город", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Введите или выберите из списка",
              modelValue: _ctx.detailTargetFilterData.city.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.detailTargetFilterData.city.value) = $event)),
              options: _ctx.getOptions('city'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 8,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_DesignSearchGid, {
            data: _ctx.detailTargetFilterData.searchGidData,
            "onUpdate:data": _cache[2] || (_cache[2] = $event => ((_ctx.detailTargetFilterData.searchGidData) = $event)),
            isOpen: _ctx.isOpenSearchGid,
            onOpen: _ctx.openSearchGid,
            onSubmit: _cache[3] || (_cache[3] = () => {})
          }, null, 8 /* PROPS */, ["data", "isOpen", "onOpen"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[73] || (_cache[73] = _createElementVNode("div", { class: "control__label" }, "Период размещения", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_BsRadioGroup, {
              modelValue: _ctx.placePeriodType.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.placePeriodType.value) = $event)),
              options: _ctx.placePeriodType.options
            }, null, 8 /* PROPS */, ["modelValue", "options"]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_BsDatePicker, {
                modelValue: _ctx.detailTargetFilterData.placePeriodDate.from,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.detailTargetFilterData.placePeriodDate.from) = $event)),
                placeholder: `${_ctx.placeholderDatePicker}, С`,
                type: _ctx.periodDatePicker,
                size: "large",
                format: "DD.MM.YYYY",
                "value-format": "YYYY-MM-DD",
                clearable: true,
                "disabled-date": _ctx.disabledDateFrom,
                onChange: _ctx.changeDatePickerFrom
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "type", "disabled-date", "onChange"]),
              _createVNode(_component_BsDatePicker, {
                modelValue: _ctx.detailTargetFilterData.placePeriodDate.to,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.detailTargetFilterData.placePeriodDate.to) = $event)),
                placeholder: `${_ctx.placeholderDatePicker}, По`,
                type: _ctx.periodDatePicker,
                size: "large",
                format: "DD.MM.YYYY",
                "value-format": "YYYY-MM-DD",
                clearable: true,
                "disabled-date": _ctx.disabledDateTo,
                onChange: _ctx.changeDatePickerTo
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "type", "disabled-date", "onChange"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _cache[74] || (_cache[74] = _createElementVNode("div", { class: "control__label" }, "Направление стороны", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_BsCheckboxGroup, {
                  modelValue: _ctx.detailTargetFilterData.direction.value,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.detailTargetFilterData.direction.value) = $event)),
                  onChange: _cache[8] || (_cache[8] = () => {}),
                  list: _ctx.getOptions('direction')
                }, null, 8 /* PROPS */, ["modelValue", "list"])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[75] || (_cache[75] = _createElementVNode("div", { class: "control__label" }, "Категория рекламной конструкции", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Все",
                  modelValue: _ctx.detailTargetFilterData.structureCategory.value,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.detailTargetFilterData.structureCategory.value) = $event)),
                  options: _ctx.getOptions('structureCategories'),
                  clearable: true,
                  filterable: true,
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 5,
                  size: "large",
                  multiple: "",
                  showCheckBox: true
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _cache[76] || (_cache[76] = _createElementVNode("div", { class: "control__label" }, "Тип рекламной конструкции", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_BsMultiSelect, {
                  placeholder: "Все",
                  modelValue: _ctx.detailTargetFilterData.constructType.value,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.detailTargetFilterData.constructType.value) = $event)),
                  options: _ctx.getOptions('structureType'),
                  clearable: true,
                  filterable: true,
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 3
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _cache[77] || (_cache[77] = _createElementVNode("div", { class: "control__label" }, "Размер РС (Ш x Д, м)", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Выберите из списка",
                  modelValue: _ctx.detailTargetFilterData.size.value,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.detailTargetFilterData.size.value) = $event)),
                  options: _ctx.getOptions('size'),
                  clearable: true,
                  filterable: true,
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 8,
                  size: "large",
                  multiple: "",
                  showCheckBox: true
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _cache[78] || (_cache[78] = _createElementVNode("div", { class: "control__label" }, "Механизм РС", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_24, [
            _createVNode(_component_BsCheckboxGroup, {
              modelValue: _ctx.detailTargetFilterData.mechanism.value,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.detailTargetFilterData.mechanism.value) = $event)),
              onChange: _cache[13] || (_cache[13] = () => {}),
              list: _ctx.getOptions('mechanism')
            }, null, 8 /* PROPS */, ["modelValue", "list"])
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _cache[79] || (_cache[79] = _createElementVNode("div", { class: "control__label" }, "Освещение", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_BsCheckboxGroup, {
              modelValue: _ctx.detailTargetFilterData.lighting.value,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.detailTargetFilterData.lighting.value) = $event)),
              onChange: _cache[15] || (_cache[15] = () => {}),
              list: _ctx.detailTargetFilterData.lighting.options
            }, null, 8 /* PROPS */, ["modelValue", "list"])
          ])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _cache[80] || (_cache[80] = _createElementVNode("div", { class: "control__label" }, "Статус", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_28, [
            _createVNode(_component_BsMultiSelect, {
              placeholder: "Выберите из списка",
              modelValue: _ctx.detailTargetFilterData.status.value,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.detailTargetFilterData.status.value) = $event)),
              options: _ctx.getOptions('bookingStatus'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 3
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _cache[81] || (_cache[81] = _createElementVNode("div", { class: "control__label" }, "Сервисный статус", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_32, [
                _createVNode(_component_BsMultiSelect, {
                  placeholder: "Выберите из списка",
                  modelValue: _ctx.detailTargetFilterData.serviceStatus.value,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.detailTargetFilterData.serviceStatus.value) = $event)),
                  options: _ctx.getOptions('serviceStatus'),
                  clearable: true,
                  filterable: true,
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 3
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_33, [
              _cache[82] || (_cache[82] = _createElementVNode("div", { class: "control__label" }, "Истекающие статусы", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_34, [
                _createVNode(_component_BsSingleSelect, {
                  placeholder: "Выберите из списка",
                  modelValue: _ctx.detailTargetFilterData.expiringStatus.value,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.detailTargetFilterData.expiringStatus.value) = $event)),
                  options: _ctx.expiringStatusOptions,
                  clearable: true,
                  filterable: true
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_35, [
          _cache[83] || (_cache[83] = _createElementVNode("div", { class: "control__label" }, "Сеть", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_36, [
            _createVNode(_component_BsCheckboxGroup, {
              modelValue: _ctx.detailTargetFilterData.nets.value,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => ((_ctx.detailTargetFilterData.nets.value) = $event)),
              onChange: _cache[20] || (_cache[20] = () => {}),
              list: _ctx.getOptions('net')
            }, null, 8 /* PROPS */, ["modelValue", "list"])
          ])
        ]),
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              _cache[84] || (_cache[84] = _createElementVNode("div", { class: "control__label" }, "Оператор", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_40, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Выберите из списка",
                  modelValue: _ctx.detailTargetFilterData.operator.value,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => ((_ctx.detailTargetFilterData.operator.value) = $event)),
                  options: _ctx.getOptions('operator'),
                  clearable: true,
                  filterable: true,
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 3,
                  size: "large",
                  multiple: "",
                  showCheckBox: true
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_41, [
              _cache[85] || (_cache[85] = _createElementVNode("div", { class: "control__label" }, "Собственник", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_42, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Выберите из списка",
                  modelValue: _ctx.detailTargetFilterData.owner.value,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => ((_ctx.detailTargetFilterData.owner.value) = $event)),
                  options: _ctx.getOptions('owner'),
                  clearable: true,
                  filterable: true,
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 3,
                  size: "large",
                  multiple: "",
                  showCheckBox: true
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_43, [
          _cache[86] || (_cache[86] = _createElementVNode("div", { class: "control__label" }, "Прайс/месяц (без НДС)", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_44, [
            _createElementVNode("div", _hoisted_45, [
              _createElementVNode("div", _hoisted_46, [
                _createVNode(_component_BsSingleSelect, {
                  placeholder: "=",
                  modelValue: _ctx.detailTargetFilterData.priceMonth.select,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => ((_ctx.detailTargetFilterData.priceMonth.select) = $event)),
                  options: _ctx.compareOptions,
                  clearable: true
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_47, [
              _createElementVNode("div", _hoisted_48, [
                _createVNode(_component_BsInput, {
                  placeholder: "Введите данные",
                  modelValue: _ctx.detailTargetFilterData.priceMonth.input,
                  "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => ((_ctx.detailTargetFilterData.priceMonth.input) = $event)),
                  type: "number",
                  min: "0",
                  step: "0.01"
                }, null, 8 /* PROPS */, ["modelValue"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_49, [
          _createElementVNode("div", _hoisted_50, [
            _createElementVNode("div", _hoisted_51, [
              _cache[87] || (_cache[87] = _createElementVNode("div", { class: "control__label" }, "OTS", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_52, [
                _createElementVNode("div", _hoisted_53, [
                  _createElementVNode("div", _hoisted_54, [
                    _createVNode(_component_BsSingleSelect, {
                      placeholder: "=",
                      modelValue: _ctx.detailTargetFilterData.ots.select,
                      "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => ((_ctx.detailTargetFilterData.ots.select) = $event)),
                      options: _ctx.compareOptions,
                      clearable: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_55, [
                  _createElementVNode("div", _hoisted_56, [
                    _createVNode(_component_BsInput, {
                      placeholder: "Введите данные",
                      modelValue: _ctx.detailTargetFilterData.ots.input,
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => ((_ctx.detailTargetFilterData.ots.input) = $event)),
                      type: "number",
                      min: "0",
                      step: "0.01"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_57, [
              _cache[88] || (_cache[88] = _createElementVNode("div", { class: "control__label" }, "GRP", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_58, [
                _createElementVNode("div", _hoisted_59, [
                  _createElementVNode("div", _hoisted_60, [
                    _createVNode(_component_BsSingleSelect, {
                      placeholder: "=",
                      modelValue: _ctx.detailTargetFilterData.grp.select,
                      "onUpdate:modelValue": _cache[27] || (_cache[27] = $event => ((_ctx.detailTargetFilterData.grp.select) = $event)),
                      options: _ctx.compareOptions,
                      clearable: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_61, [
                  _createElementVNode("div", _hoisted_62, [
                    _createVNode(_component_BsInput, {
                      placeholder: "Введите данные",
                      modelValue: _ctx.detailTargetFilterData.grp.input,
                      "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => ((_ctx.detailTargetFilterData.grp.input) = $event)),
                      type: "number",
                      min: "0",
                      step: "0.01"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_63, [
          _cache[89] || (_cache[89] = _createElementVNode("div", { class: "control__label" }, "Стоимость размещения", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_64, [
            _createElementVNode("div", _hoisted_65, [
              _createElementVNode("div", _hoisted_66, [
                _createVNode(_component_BsSingleSelect, {
                  placeholder: "=",
                  modelValue: _ctx.detailTargetFilterData.pricePlacement.select,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = $event => ((_ctx.detailTargetFilterData.pricePlacement.select) = $event)),
                  options: _ctx.compareOptions,
                  clearable: true
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_67, [
              _createElementVNode("div", _hoisted_68, [
                _createVNode(_component_BsInput, {
                  placeholder: "Введите данные",
                  modelValue: _ctx.detailTargetFilterData.pricePlacement.input,
                  "onUpdate:modelValue": _cache[30] || (_cache[30] = $event => ((_ctx.detailTargetFilterData.pricePlacement.input) = $event)),
                  type: "number",
                  min: "0",
                  step: "0.01"
                }, null, 8 /* PROPS */, ["modelValue"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_69, [
          _createElementVNode("div", {
            class: _normalizeClass(["control__head", { '--is-open-group': _ctx.isOpenDigitalGroup }]),
            onClick: _cache[31] || (_cache[31] = $event => (_ctx.isOpenDigitalGroup = !_ctx.isOpenDigitalGroup))
          }, [
            _cache[90] || (_cache[90] = _createElementVNode("div", { class: "control__head-label" }, "Настройки для Диджитал", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_70, [
              _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
            ])
          ], 2 /* CLASS */),
          _createVNode(_component_ElCollapseTransition, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_71, [
                _createElementVNode("div", _hoisted_72, [
                  _createElementVNode("div", _hoisted_73, [
                    _createElementVNode("div", _hoisted_74, [
                      _cache[91] || (_cache[91] = _createElementVNode("div", { class: "control__label" }, "Частота выходов", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_75, [
                        _createVNode(_component_BsMultiSelect, {
                          placeholder: "Любая",
                          modelValue: _ctx.detailTargetFilterData.outFrequency.value,
                          "onUpdate:modelValue": _cache[32] || (_cache[32] = $event => ((_ctx.detailTargetFilterData.outFrequency.value) = $event)),
                          options: _ctx.getOptions('frequency'),
                          clearable: true,
                          filterable: true,
                          "collapse-tags": "",
                          "collapse-tags-tooltip": "",
                          "max-collapse-tags": 8
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_76, [
                      _cache[92] || (_cache[92] = _createElementVNode("div", { class: "control__label" }, "Размер диджитал блока", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_77, [
                        _createVNode(_component_BsMultiSelect, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.detailTargetFilterData.blockDuration.value,
                          "onUpdate:modelValue": _cache[33] || (_cache[33] = $event => ((_ctx.detailTargetFilterData.blockDuration.value) = $event)),
                          options: _ctx.getOptions('blockDuration'),
                          clearable: true,
                          filterable: true,
                          "collapse-tags": "",
                          "collapse-tags-tooltip": "",
                          "max-collapse-tags": 8
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_78, [
                  _createElementVNode("div", _hoisted_79, [
                    _createElementVNode("div", _hoisted_80, [
                      _cache[93] || (_cache[93] = _createElementVNode("div", { class: "control__label" }, "Размер диджитал слота", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_81, [
                        _createVNode(_component_BsMultiSelect, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.detailTargetFilterData.slotDuration.value,
                          "onUpdate:modelValue": _cache[34] || (_cache[34] = $event => ((_ctx.detailTargetFilterData.slotDuration.value) = $event)),
                          options: _ctx.getOptions('slotDuration'),
                          clearable: true,
                          filterable: true,
                          "collapse-tags": "",
                          "collapse-tags-tooltip": "",
                          "max-collapse-tags": 8
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_82, [
                      _cache[94] || (_cache[94] = _createElementVNode("div", { class: "control__label" }, "Длина ролика", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_83, [
                        _createVNode(_component_BsInput, {
                          placeholder: "Введите кол-во секунд",
                          modelValue: _ctx.detailTargetFilterData.digitalVideoLength,
                          "onUpdate:modelValue": _cache[35] || (_cache[35] = $event => ((_ctx.detailTargetFilterData.digitalVideoLength) = $event)),
                          type: "number",
                          min: "0",
                          step: "0.01"
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_84, [
                  _createElementVNode("div", _hoisted_85, [
                    _createElementVNode("div", _hoisted_86, [
                      _cache[95] || (_cache[95] = _createElementVNode("div", { class: "control__label" }, "WiFi ловушка", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_87, [
                        _createVNode(_component_BsSingleSelect, {
                          placeholder: "Неважно",
                          modelValue: _ctx.detailTargetFilterData.wifiTrap.value,
                          "onUpdate:modelValue": _cache[36] || (_cache[36] = $event => ((_ctx.detailTargetFilterData.wifiTrap.value) = $event)),
                          options: _ctx.getOptions('wifiTrap'),
                          clearable: true,
                          filterable: true
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _cache[96] || (_cache[96] = _createElementVNode("div", { class: "control full-free-width" }, null, -1 /* HOISTED */))
                  ])
                ])
              ], 512 /* NEED_PATCH */), [
                [_vShow, _ctx.isOpenDigitalGroup]
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", _hoisted_88, [
          _createElementVNode("div", {
            class: _normalizeClass(["control__head", { '--is-open-group': _ctx.isOpenPersonGroup }]),
            onClick: _cache[37] || (_cache[37] = $event => (_ctx.isOpenPersonGroup = !_ctx.isOpenPersonGroup))
          }, [
            _cache[97] || (_cache[97] = _createElementVNode("div", { class: "control__head-label" }, "Юридические лица", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_89, [
              _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
            ])
          ], 2 /* CLASS */),
          _createVNode(_component_ElCollapseTransition, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_90, [
                _createElementVNode("div", _hoisted_91, [
                  _cache[98] || (_cache[98] = _createElementVNode("div", { class: "control__label" }, "Юр. лицо МГ РИМ", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_92, [
                    _createVNode(_component_BsSelectV2, {
                      placeholder: "Выберите из списка",
                      modelValue: _ctx.detailTargetFilterData.rimPerson.value,
                      "onUpdate:modelValue": _cache[38] || (_cache[38] = $event => ((_ctx.detailTargetFilterData.rimPerson.value) = $event)),
                      options: _ctx.getOptions('rim_legal_entity'),
                      clearable: true,
                      filterable: true,
                      "collapse-tags": "",
                      "collapse-tags-tooltip": "",
                      "max-collapse-tags": 3,
                      size: "large",
                      multiple: "",
                      showCheckBox: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_93, [
                  _cache[99] || (_cache[99] = _createElementVNode("div", { class: "control__label" }, "Юр. лицо клиента", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_94, [
                    _createVNode(_component_BsSelectV2, {
                      placeholder: "Выберите из списка",
                      modelValue: _ctx.detailTargetFilterData.clientPerson.value,
                      "onUpdate:modelValue": _cache[39] || (_cache[39] = $event => ((_ctx.detailTargetFilterData.clientPerson.value) = $event)),
                      options: _ctx.getOptions('customer_legal_entity'),
                      clearable: true,
                      filterable: true,
                      "collapse-tags": "",
                      "collapse-tags-tooltip": "",
                      "max-collapse-tags": 3,
                      size: "large",
                      multiple: "",
                      showCheckBox: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_95, [
                  _cache[100] || (_cache[100] = _createElementVNode("div", { class: "control__label" }, "Монтаж", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_96, [
                    _createVNode(_component_BsSelectV2, {
                      placeholder: "Выберите из списка",
                      modelValue: _ctx.detailTargetFilterData.installPerson.value,
                      "onUpdate:modelValue": _cache[40] || (_cache[40] = $event => ((_ctx.detailTargetFilterData.installPerson.value) = $event)),
                      options: _ctx.getOptions('installer'),
                      clearable: true,
                      filterable: true,
                      "collapse-tags": "",
                      "collapse-tags-tooltip": "",
                      "max-collapse-tags": 3,
                      size: "large",
                      multiple: "",
                      showCheckBox: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_97, [
                  _cache[101] || (_cache[101] = _createElementVNode("div", { class: "control__label" }, "Печать", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_98, [
                    _createVNode(_component_BsSelectV2, {
                      placeholder: "Выберите из списка",
                      modelValue: _ctx.detailTargetFilterData.printPerson.value,
                      "onUpdate:modelValue": _cache[41] || (_cache[41] = $event => ((_ctx.detailTargetFilterData.printPerson.value) = $event)),
                      options: _ctx.getOptions('printer'),
                      clearable: true,
                      filterable: true,
                      "collapse-tags": "",
                      "collapse-tags-tooltip": "",
                      "max-collapse-tags": 3,
                      size: "large",
                      multiple: "",
                      showCheckBox: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_99, [
                  _cache[102] || (_cache[102] = _createElementVNode("div", { class: "control__label" }, "Срочная печать", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_100, [
                    _createVNode(_component_BsSelectV2, {
                      placeholder: "Выберите из списка",
                      modelValue: _ctx.detailTargetFilterData.expPrintPerson.value,
                      "onUpdate:modelValue": _cache[42] || (_cache[42] = $event => ((_ctx.detailTargetFilterData.expPrintPerson.value) = $event)),
                      options: _ctx.getOptions('expressPrinters'),
                      clearable: true,
                      filterable: true,
                      "collapse-tags": "",
                      "collapse-tags-tooltip": "",
                      "max-collapse-tags": 3,
                      size: "large",
                      multiple: "",
                      showCheckBox: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_101, [
                  _cache[103] || (_cache[103] = _createElementVNode("div", { class: "control__label" }, "Дизайн", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_102, [
                    _createVNode(_component_BsSelectV2, {
                      placeholder: "Выберите из списка",
                      modelValue: _ctx.detailTargetFilterData.designPerson.value,
                      "onUpdate:modelValue": _cache[43] || (_cache[43] = $event => ((_ctx.detailTargetFilterData.designPerson.value) = $event)),
                      options: _ctx.getOptions('designer'),
                      clearable: true,
                      filterable: true,
                      "collapse-tags": "",
                      "collapse-tags-tooltip": "",
                      "max-collapse-tags": 3,
                      size: "large",
                      multiple: "",
                      showCheckBox: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_103, [
                  _cache[104] || (_cache[104] = _createElementVNode("div", { class: "control__label" }, "Фотоотчет", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_104, [
                    _createVNode(_component_BsSelectV2, {
                      placeholder: "Выберите из списка",
                      modelValue: _ctx.detailTargetFilterData.photoReportPerson.value,
                      "onUpdate:modelValue": _cache[44] || (_cache[44] = $event => ((_ctx.detailTargetFilterData.photoReportPerson.value) = $event)),
                      options: _ctx.getOptions('photo_reporter'),
                      clearable: true,
                      filterable: true,
                      "collapse-tags": "",
                      "collapse-tags-tooltip": "",
                      "max-collapse-tags": 3,
                      size: "large",
                      multiple: "",
                      showCheckBox: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ])
              ], 512 /* NEED_PATCH */), [
                [_vShow, _ctx.isOpenPersonGroup]
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", _hoisted_105, [
          _createElementVNode("div", {
            class: _normalizeClass(["control__head", { '--is-open-group': _ctx.isOpenOtherGroup }]),
            onClick: _cache[45] || (_cache[45] = $event => (_ctx.isOpenOtherGroup = !_ctx.isOpenOtherGroup))
          }, [
            _cache[105] || (_cache[105] = _createElementVNode("div", { class: "control__head-label" }, "Остальное", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_106, [
              _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
            ])
          ], 2 /* CLASS */),
          _createVNode(_component_ElCollapseTransition, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_107, [
                _createElementVNode("div", _hoisted_108, [
                  _cache[106] || (_cache[106] = _createElementVNode("div", { class: "control__label" }, "Тэги", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_109, [
                    _createVNode(_component_BsSelectV2, {
                      placeholder: "Выберите из списка",
                      modelValue: _ctx.detailTargetFilterData.tags.value,
                      "onUpdate:modelValue": _cache[46] || (_cache[46] = $event => ((_ctx.detailTargetFilterData.tags.value) = $event)),
                      options: _ctx.getOptions('tag'),
                      clearable: true,
                      filterable: true,
                      "collapse-tags": "",
                      "collapse-tags-tooltip": "",
                      "max-collapse-tags": 8,
                      size: "large",
                      multiple: "",
                      showCheckBox: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_110, [
                  _cache[107] || (_cache[107] = _createElementVNode("div", { class: "control__label" }, "Менеджер", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_111, [
                    _createVNode(_component_BsSelectV2, {
                      placeholder: "Выберите из списка",
                      modelValue: _ctx.detailTargetFilterData.manager.value,
                      "onUpdate:modelValue": _cache[47] || (_cache[47] = $event => ((_ctx.detailTargetFilterData.manager.value) = $event)),
                      options: _ctx.getOptions('managers'),
                      clearable: true,
                      filterable: true,
                      "collapse-tags": "",
                      "collapse-tags-tooltip": "",
                      "max-collapse-tags": 3,
                      size: "large",
                      multiple: "",
                      showCheckBox: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_112, [
                  _createElementVNode("div", _hoisted_113, [
                    _createElementVNode("div", _hoisted_114, [
                      _cache[108] || (_cache[108] = _createElementVNode("div", { class: "control__label" }, "Тип размещения", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_115, [
                        _createVNode(_component_BsMultiSelect, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.detailTargetFilterData.placementType.value,
                          "onUpdate:modelValue": _cache[48] || (_cache[48] = $event => ((_ctx.detailTargetFilterData.placementType.value) = $event)),
                          options: _ctx.getOptions('placement_type'),
                          clearable: true,
                          filterable: true,
                          "collapse-tags": "",
                          "collapse-tags-tooltip": "",
                          "max-collapse-tags": 3
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_116, [
                      _cache[109] || (_cache[109] = _createElementVNode("div", { class: "control__label" }, "Категория клиента", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_117, [
                        _createVNode(_component_BsMultiSelect, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.detailTargetFilterData.customerCategory.value,
                          "onUpdate:modelValue": _cache[49] || (_cache[49] = $event => ((_ctx.detailTargetFilterData.customerCategory.value) = $event)),
                          options: _ctx.getOptions('customer_category'),
                          clearable: true,
                          filterable: true,
                          "collapse-tags": "",
                          "collapse-tags-tooltip": "",
                          "max-collapse-tags": 3
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_118, [
                  _cache[110] || (_cache[110] = _createElementVNode("div", { class: "control__label" }, "Продукт", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_119, [
                    _createVNode(_component_BsSelectV2, {
                      placeholder: "Выберите из списка",
                      modelValue: _ctx.detailTargetFilterData.product.value,
                      "onUpdate:modelValue": _cache[50] || (_cache[50] = $event => ((_ctx.detailTargetFilterData.product.value) = $event)),
                      options: _ctx.getOptions('product'),
                      clearable: true,
                      filterable: true,
                      "collapse-tags": "",
                      "collapse-tags-tooltip": "",
                      "max-collapse-tags": 3,
                      size: "large",
                      multiple: "",
                      showCheckBox: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ])
              ], 512 /* NEED_PATCH */), [
                [_vShow, _ctx.isOpenOtherGroup]
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", _hoisted_120, [
          _createElementVNode("div", {
            class: _normalizeClass(["control__head", { '--is-open-group': _ctx.isOpenServiceGroup }]),
            onClick: _cache[51] || (_cache[51] = $event => (_ctx.isOpenServiceGroup = !_ctx.isOpenServiceGroup))
          }, [
            _cache[111] || (_cache[111] = _createElementVNode("div", { class: "control__head-label" }, "Стоимость доп. услуг", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_121, [
              _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
            ])
          ], 2 /* CLASS */),
          _createVNode(_component_ElCollapseTransition, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_122, [
                _createElementVNode("div", _hoisted_123, [
                  _createElementVNode("div", _hoisted_124, [
                    _createElementVNode("div", _hoisted_125, [
                      _cache[112] || (_cache[112] = _createElementVNode("div", { class: "control__label" }, "Монтаж", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_126, [
                        _createElementVNode("div", _hoisted_127, [
                          _createElementVNode("div", _hoisted_128, [
                            _createVNode(_component_BsSingleSelect, {
                              placeholder: "=",
                              modelValue: _ctx.detailTargetFilterData.installPrice.select,
                              "onUpdate:modelValue": _cache[52] || (_cache[52] = $event => ((_ctx.detailTargetFilterData.installPrice.select) = $event)),
                              options: _ctx.compareOptions,
                              clearable: true
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_129, [
                          _createElementVNode("div", _hoisted_130, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите данные",
                              modelValue: _ctx.detailTargetFilterData.installPrice.input,
                              "onUpdate:modelValue": _cache[53] || (_cache[53] = $event => ((_ctx.detailTargetFilterData.installPrice.input) = $event)),
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_131, [
                      _cache[113] || (_cache[113] = _createElementVNode("div", { class: "control__label" }, "Доп. монтаж", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_132, [
                        _createElementVNode("div", _hoisted_133, [
                          _createElementVNode("div", _hoisted_134, [
                            _createVNode(_component_BsSingleSelect, {
                              placeholder: "=",
                              modelValue: _ctx.detailTargetFilterData.addInstallPrice.select,
                              "onUpdate:modelValue": _cache[54] || (_cache[54] = $event => ((_ctx.detailTargetFilterData.addInstallPrice.select) = $event)),
                              options: _ctx.compareOptions,
                              clearable: true
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_135, [
                          _createElementVNode("div", _hoisted_136, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите данные",
                              modelValue: _ctx.detailTargetFilterData.addInstallPrice.input,
                              "onUpdate:modelValue": _cache[55] || (_cache[55] = $event => ((_ctx.detailTargetFilterData.addInstallPrice.input) = $event)),
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_137, [
                  _createElementVNode("div", _hoisted_138, [
                    _createElementVNode("div", _hoisted_139, [
                      _cache[114] || (_cache[114] = _createElementVNode("div", { class: "control__label" }, "Печать", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_140, [
                        _createElementVNode("div", _hoisted_141, [
                          _createElementVNode("div", _hoisted_142, [
                            _createVNode(_component_BsSingleSelect, {
                              placeholder: "=",
                              modelValue: _ctx.detailTargetFilterData.printPrice.select,
                              "onUpdate:modelValue": _cache[56] || (_cache[56] = $event => ((_ctx.detailTargetFilterData.printPrice.select) = $event)),
                              options: _ctx.compareOptions,
                              clearable: true
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_143, [
                          _createElementVNode("div", _hoisted_144, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите данные",
                              modelValue: _ctx.detailTargetFilterData.printPrice.input,
                              "onUpdate:modelValue": _cache[57] || (_cache[57] = $event => ((_ctx.detailTargetFilterData.printPrice.input) = $event)),
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_145, [
                      _cache[115] || (_cache[115] = _createElementVNode("div", { class: "control__label" }, "Срочная печать", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_146, [
                        _createElementVNode("div", _hoisted_147, [
                          _createElementVNode("div", _hoisted_148, [
                            _createVNode(_component_BsSingleSelect, {
                              placeholder: "=",
                              modelValue: _ctx.detailTargetFilterData.printExpPrice.select,
                              "onUpdate:modelValue": _cache[58] || (_cache[58] = $event => ((_ctx.detailTargetFilterData.printExpPrice.select) = $event)),
                              options: _ctx.compareOptions,
                              clearable: true
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_149, [
                          _createElementVNode("div", _hoisted_150, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите данные",
                              modelValue: _ctx.detailTargetFilterData.printExpPrice.input,
                              "onUpdate:modelValue": _cache[59] || (_cache[59] = $event => ((_ctx.detailTargetFilterData.printExpPrice.input) = $event)),
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_151, [
                  _createElementVNode("div", _hoisted_152, [
                    _createElementVNode("div", _hoisted_153, [
                      _cache[116] || (_cache[116] = _createElementVNode("div", { class: "control__label" }, "Дизайн", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_154, [
                        _createElementVNode("div", _hoisted_155, [
                          _createElementVNode("div", _hoisted_156, [
                            _createVNode(_component_BsSingleSelect, {
                              placeholder: "=",
                              modelValue: _ctx.detailTargetFilterData.designPrice.select,
                              "onUpdate:modelValue": _cache[60] || (_cache[60] = $event => ((_ctx.detailTargetFilterData.designPrice.select) = $event)),
                              options: _ctx.compareOptions,
                              clearable: true
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_157, [
                          _createElementVNode("div", _hoisted_158, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите данные",
                              modelValue: _ctx.detailTargetFilterData.designPrice.input,
                              "onUpdate:modelValue": _cache[61] || (_cache[61] = $event => ((_ctx.detailTargetFilterData.designPrice.input) = $event)),
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_159, [
                      _cache[117] || (_cache[117] = _createElementVNode("div", { class: "control__label" }, "Фотоотчет", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_160, [
                        _createElementVNode("div", _hoisted_161, [
                          _createElementVNode("div", _hoisted_162, [
                            _createVNode(_component_BsSingleSelect, {
                              placeholder: "=",
                              modelValue: _ctx.detailTargetFilterData.photoReportPrice.select,
                              "onUpdate:modelValue": _cache[62] || (_cache[62] = $event => ((_ctx.detailTargetFilterData.photoReportPrice.select) = $event)),
                              options: _ctx.compareOptions,
                              clearable: true
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_163, [
                          _createElementVNode("div", _hoisted_164, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите данные",
                              modelValue: _ctx.detailTargetFilterData.photoReportPrice.input,
                              "onUpdate:modelValue": _cache[63] || (_cache[63] = $event => ((_ctx.detailTargetFilterData.photoReportPrice.input) = $event)),
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_165, [
                  _cache[118] || (_cache[118] = _createElementVNode("div", { class: "control__label" }, "Общая стоимость", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_166, [
                    _createElementVNode("div", _hoisted_167, [
                      _createElementVNode("div", _hoisted_168, [
                        _createVNode(_component_BsSingleSelect, {
                          placeholder: "=",
                          modelValue: _ctx.detailTargetFilterData.totalPrice.select,
                          "onUpdate:modelValue": _cache[64] || (_cache[64] = $event => ((_ctx.detailTargetFilterData.totalPrice.select) = $event)),
                          options: _ctx.compareOptions,
                          clearable: true
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_169, [
                      _createElementVNode("div", _hoisted_170, [
                        _createVNode(_component_BsInput, {
                          placeholder: "Введите данные",
                          modelValue: _ctx.detailTargetFilterData.totalPrice.input,
                          "onUpdate:modelValue": _cache[65] || (_cache[65] = $event => ((_ctx.detailTargetFilterData.totalPrice.input) = $event)),
                          type: "number",
                          min: "0",
                          step: "0.01"
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_171, [
                  _createElementVNode("div", _hoisted_172, [
                    _createElementVNode("div", _hoisted_173, [
                      _cache[119] || (_cache[119] = _createElementVNode("div", { class: "control__label" }, "СК агентское", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_174, [
                        _createElementVNode("div", _hoisted_175, [
                          _createElementVNode("div", _hoisted_176, [
                            _createVNode(_component_BsSingleSelect, {
                              placeholder: "=",
                              modelValue: _ctx.detailTargetFilterData.agentCommission.select,
                              "onUpdate:modelValue": _cache[66] || (_cache[66] = $event => ((_ctx.detailTargetFilterData.agentCommission.select) = $event)),
                              options: _ctx.compareOptions,
                              clearable: true
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_177, [
                          _createElementVNode("div", _hoisted_178, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите данные",
                              modelValue: _ctx.detailTargetFilterData.agentCommission.input,
                              "onUpdate:modelValue": _cache[67] || (_cache[67] = $event => ((_ctx.detailTargetFilterData.agentCommission.input) = $event)),
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_179, [
                      _cache[120] || (_cache[120] = _createElementVNode("div", { class: "control__label" }, "СК клиентское", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_180, [
                        _createElementVNode("div", _hoisted_181, [
                          _createElementVNode("div", _hoisted_182, [
                            _createVNode(_component_BsSingleSelect, {
                              placeholder: "=",
                              modelValue: _ctx.detailTargetFilterData.customerCommission.select,
                              "onUpdate:modelValue": _cache[68] || (_cache[68] = $event => ((_ctx.detailTargetFilterData.customerCommission.select) = $event)),
                              options: _ctx.compareOptions,
                              clearable: true
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_183, [
                          _createElementVNode("div", _hoisted_184, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите данные",
                              modelValue: _ctx.detailTargetFilterData.customerCommission.input,
                              "onUpdate:modelValue": _cache[69] || (_cache[69] = $event => ((_ctx.detailTargetFilterData.customerCommission.input) = $event)),
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ], 512 /* NEED_PATCH */), [
                [_vShow, _ctx.isOpenServiceGroup]
              ])
            ]),
            _: 1 /* STABLE */
          })
        ])
      ], 32 /* NEED_HYDRATION */),
      _cache[122] || (_cache[122] = _createElementVNode("div", { class: "target-filter__spacer" }, null, -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_185, [
        _createVNode(_component_BsBtn, {
          type: "primary",
          onClick: _ctx.submit
        }, {
          default: _withCtx(() => _cache[121] || (_cache[121] = [
            _createTextVNode("Выбрать")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ])
  ]))
}