import { ElCarousel, ElCarouselItem, ElImage } from "element-plus";
import 'element-plus/es/components/carousel/style/css';
import 'element-plus/es/components/carousel-item/style/css';
import 'element-plus/es/components/image/style/css';

export default {
	name: 'bs-photo-slider',
	components: {
		ElCarousel, ElCarouselItem, ElImage,
	},
	props: {
		list: Array,
	}
}