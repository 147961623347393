import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "side-queue-v2" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SideQueuePopup = _resolveComponent("SideQueuePopup")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.data)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "side-queue-v2__btn",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.openPopup && _ctx.openPopup(...args)))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.getActionTitle()), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.isOpenPopup)
      ? (_openBlock(), _createBlock(_component_SideQueuePopup, {
          key: 1,
          modelValue: _ctx.isOpenPopup,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.isOpenPopup) = $event)),
          list: _ctx.data,
          title: _ctx.getPopupTitle()
        }, null, 8 /* PROPS */, ["modelValue", "list", "title"]))
      : _createCommentVNode("v-if", true)
  ]))
}