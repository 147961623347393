import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useUserStore } from '@/app/stores/user-store';

import { ElHeader, ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus';
import 'element-plus/es/components/header/style/css';
import 'element-plus/es/components/dropdown/style/css';
import 'element-plus/es/components/dropdown-menu/style/css';
import 'element-plus/es/components/dropdown-item/style/css';

import MainHeadLogo from '@/app/components/main-head-logo/MainHeadLogo.vue';
import BsBreadcrumb from '@/app/components/bs-breadcrumb/BsBreadcrumb.vue';
import BtnHeadNotif from '@/app/components/controls/buttons/btn-head-notif/BtnHeadNotif.vue';
import HeadUserInfo from '@/app/components/head-user-info/HeadUserInfo.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'bs-header',
	components: {
		ElHeader, ElDropdown, ElDropdownMenu, ElDropdownItem,
		MainHeadLogo, BsBreadcrumb, SvgIcon,
		BtnHeadNotif, HeadUserInfo,
	},
	props: {
		headTitle: String,
	},
	computed: {
		...mapState( useRootStore, ['isDevServer'] ),
		...mapState( useUserStore, ['user'] ),

	},
	methods: {
		actionUserMenu(command){
			console.log(command);
		}
	},
}