import { toRaw } from "vue";
import { mapWritableState, mapState } from "pinia";
import { useMapDesignStore } from "@/app/stores/map-design-store";

import common from "@/app/utils/common";
import helper from "@/app/utils/helper";

export default {
	name: 'map-designs',
	data() {
		return {
			map: null,
			clusterer: null,
			isInitMap: false,
		}
	},
	computed: {
		...mapState(useMapDesignStore, ['designsData']),
		...mapWritableState(useMapDesignStore, ['markerList', 'clusterList', 'isLoaderMap', 'searchMarker']),
	},
	methods: {
		async initMap(){
			const ymaps = await common.ymaps;
			const map = new ymaps.Map( this.$el , {
				center: [ 55.800186, 49.117466 ],
				zoom: 10,
				// autoFitToViewport: 'always',
				// controls: ['zoomControl', 'fullscreenControl'],
				controls: [
					// 'trafficControl', // пробки
					// 'zoomControl',
					'rulerControl',
				],
			});

			map.events.add([
				'actionbegin', 'actionend', 'actiontick',
				'wheel','sizechange', 'mouseup', 'mousedown', 'dblclick',
				'hintopen', 'balloonopen', 'boundschange', 'sizechange',
			], this.triggerMapUpdate);


			// const collection = new ymaps.GeoObjectCollection();
			const clusterContainer = ymaps.templateLayoutFactory.createClass('', {
				build(){
					clusterContainer.superclass.build.call(this);

					const elCluster = this.getParentElement();
					const cluster = this.getData().geoObject;
					const id = helper.hash;

					cluster.clusterId = id;
					$(elCluster).find('ymaps').append(`<div id="cluster-${id}"></div>`);
				}
			});
			const clusterer = new ymaps.Clusterer({
				clusterIconLayout: clusterContainer,
				clusterIconShape: {type: 'Circle', coordinates: [0, 0], radius: 28},
				gridSize: 128,
				minClusterSize: 3,
				maxZoom: 10,
				// clusterNumbers: [10],
				// clusterIconContentLayout: null
			});

			this.map = map;
			this.clusterer = clusterer;
			this.isInitMap = true;

			map.geoObjects.add(clusterer);
			if(this.searchMarker){
				const { label, coords } = this.searchMarker;
				this.addSearchMarker({ coords, label });
			}
			this.updatePlacemark();
		},
		initEventMap(){
			const map = toRaw(this.map);
			$(window).off('collapse:aside').on('collapse:aside',()=>{
				map.container.fitToViewport();
			});

			$(window).off('set-center:map').on('set-center:map', async (event, data)=>{
				const { type, coords, strSearch } = data ?? {};
				if(!coords) return;

				// console.log(event, data);
				if( type=='search' ){
					map.setCenter(coords, 17, {
						checkZoomRange: true,
					});
					this.addSearchMarker({ coords, label: strSearch });
				}
				else map.setCenter(coords, 15, {
					checkZoomRange: true
				});
			});

			$(window).off(`visibilitychange.render_map`).on(`visibilitychange.render_map`, async()=>{
				// console.log(document.visibilityState);
				if(document.visibilityState=='visible') this.triggerMapUpdate();
			});
		},

		async addSearchMarker({ coords, label }={}){
			if(this.searchMarker) this.searchMarker = null;

			const map = toRaw(this.map);
			const ymaps = await common.ymaps;

			const code = helper.hash;
			const placemarkContainer = ymaps.templateLayoutFactory.createClass(`<div id="search-marker-${code}"></div>`);
			const placemark = new ymaps.Placemark(coords, {name: `search-marker-${code}`}, {
				iconLayout: placemarkContainer,
				iconShape: {type: 'Circle', coordinates: [0, -25], radius: 16},
				zIndex: 9999,
			});
			placemark['markerCode'] = code;

			map.geoObjects.add(placemark);
			this.searchMarker = { code, placemark, label, coords };
			// console.log(this.searchMarker);
		},

		triggerMapUpdate(){
			helper.debounce({
				keyTimer: 'map-designs:update',
				duration: 50,
				action: ()=>{
					// const map = toRaw(this.map);
					// const zoom = map.getZoom();
					// console.log(zoom);

					$(window).trigger('update:map');
					this.updateClusterList();
				}
			});
		},
		async updateClusterList(){
			if(!this.clusterer) return;
			const clusterList = this.clusterer.getClusters();
			this.clusterList = clusterList;
		},
		async updatePlacemark(){
			const ymaps = await common.ymaps;
			const map = toRaw(this.map);
			const clusterer = toRaw(this.clusterer);
			const collection = [];
			const markerList = [];
			const { query } = this.$route;

			this.isLoaderMap = true;

			if(clusterer.getGeoObjects()?.length) clusterer.removeAll();
			for(let item of this.designsData){
				const placemarkContainer = ymaps.templateLayoutFactory.createClass(`<div id="marker-${item.code}"></div>`);
				const placemark = new ymaps.Placemark(item.coords, {name: `marker-${item.code}`}, {
					iconLayout: placemarkContainer,
					iconShape: {type: 'Circle', coordinates: [0, -35], radius: 24},
				});
				placemark['markerCode'] = item.code;

				// collection.add(placemark);
				collection.push(placemark);
				markerList.push({
					placemark,
					...item,
				});
			}

			try {
				clusterer.add(collection);
				let bounds = clusterer.getBounds();

				// если показ маркера по ссылке
				if(query?.structureId){
					const id = query.structureId;
					const findItem = markerList.find(item=>item.id==id);

					// console.log(id, findItem);
					if(findItem){
						const { placemark, coords } = findItem;
						// console.log(findItem);
						map.setCenter(coords, 15, {
							checkZoomRange: true
						});
					}
				}
				else if(bounds) map.setBounds(bounds, {
					duration: 500,
					checkZoomRange: true,
				});

			} catch (error) {
				console.error(error);
			}

			await helper.pause(markerList.length);
			// await helper.pause(markerList.length / 3);
			this.markerList = markerList;

			this.updateClusterList();
			this.isLoaderMap = false;
		}
	},
	watch: {
		designsData(){
			this.updatePlacemark();
		}
	},
	async mounted(){
		await this.initMap();
		this.initEventMap();
	},
	unmounted() {
		const map = toRaw(this.map);

		this.markerList = [];
		map.events.remove([
			'actionbegin', 'actionend', 'actiontick',
			'wheel','sizechange', 'mouseup', 'mousedown', 'dblclick',
			'hintopen', 'balloonopen', 'boundschange', 'sizechange',
		], this.triggerMapUpdate);
	},
}