import dayjs from 'dayjs';

export default {
	name: 'footer-cell-deadline',
	props: {
		dates: {
			type: Array,
			default: [],
		},
	},
	computed: {
		countStatuses(){
			const today = new Date;
			const statusData = {
				good: 0,
				warning: 0,
				bad: 0,
			};

			for( const date of this.dates ){
				const countDay = dayjs(date).diff(today, 'day');

				if(countDay >= 30) statusData.good++;
				else if(countDay > 0 && countDay < 30) statusData.warning++;
				else statusData.bad++;
			}

			return statusData
		}
	},
}