import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import PrRulesInfoItem from "./pr-rules-info-item/PrRulesInfoItem.vue";

export default {
	name: 'pr-rules-info',
	props: {
		list: {
			type: Array,
			default: [
				// { key, label, value }
			],
		}
	},
	emits: ['edit'],
	components: {
		SvgIcon, PrRulesInfoItem,
	},
	methods: {
		clickEdit(){
			this.$emit('edit');
		}
	},
}