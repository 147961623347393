import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cell-coefficient__input"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElInputNumber = _resolveComponent("ElInputNumber")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cell-coefficient", { '--is-not-data': _ctx.isNotData, [`js-cell-coefficient-${_ctx.hash}`]: _ctx.hash }])
  }, [
    (_ctx.isVisibleInput)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ElInputNumber, {
            modelValue: _ctx.inputData,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.inputData) = $event)),
            ref: "bsInputRef",
            "controls-position": "right",
            size: "small",
            min: 0.1,
            step: 0.1,
            onKeydown: _withKeys(_ctx.change, ["enter"])
          }, null, 8 /* PROPS */, ["modelValue", "onKeydown"])
        ]))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", {
      class: "cell-coefficient__cell",
      onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clickCell && _ctx.clickCell(...args)))
    }, [
      (this.fieldData)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.getNum(this.fieldData)), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, "–"))
    ], 512 /* NEED_PATCH */), [
      [_vShow, !_ctx.isVisibleInput]
    ])
  ], 2 /* CLASS */))
}