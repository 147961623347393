import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "summary-month__head" }
const _hoisted_2 = { class: "summary-month__month-label" }
const _hoisted_3 = { class: "summary-month__month-btn" }
const _hoisted_4 = { class: "summary-month__code" }
const _hoisted_5 = { class: "summary-month__container" }
const _hoisted_6 = { class: "summary-month__info" }
const _hoisted_7 = { class: "summary-month__info-group" }
const _hoisted_8 = { class: "summary-month__info-value" }
const _hoisted_9 = { class: "summary-month__info-group" }
const _hoisted_10 = { class: "summary-month__info-value" }
const _hoisted_11 = { class: "summary-month__info-group" }
const _hoisted_12 = { class: "summary-month__info-value" }
const _hoisted_13 = { class: "summary-month__info-group" }
const _hoisted_14 = { class: "summary-month__info-value" }
const _hoisted_15 = { class: "summary-month__total" }
const _hoisted_16 = { class: "summary-month__total-group" }
const _hoisted_17 = { class: "summary-month__total-value" }
const _hoisted_18 = { class: "summary-month__total-group" }
const _hoisted_19 = { class: "summary-month__total-value" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["summary-month", { '--is-open': _ctx.isOpen }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "summary-month__month",
        onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickCollapse && _ctx.clickCollapse(...args)))
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.monthLabel), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_SvgIcon, { name: "16-arrow-down" })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, "№" + _toDisplayString(_ctx.code), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ElCollapseTransition, null, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "summary-month__info-label" }, "По прайсу", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getMoney(_ctx.price)) + " ₽", 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "summary-month__info-label" }, "Скидка", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getMoney(_ctx.discount)) + " ₽ (" + _toDisplayString(_ctx.discountPercent) + "%)", 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "summary-month__info-label" }, "СК агентства", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.getMoney(_ctx.agencyDiscount)) + " ₽", 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "summary-month__info-label" }, "СК Клиента", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.getMoney(_ctx.personDiscount)) + " ₽", 1 /* TEXT */)
            ])
          ], 512 /* NEED_PATCH */), [
            [_vShow, _ctx.isOpen]
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "summary-month__total-label" }, "Итого (без НДС)", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.getMoney(_ctx.totalPriceNotVat)) + " ₽", 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_18, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "summary-month__total-label" }, "Итого (с НДС)", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.getMoney(_ctx.totalPrice)) + " ₽", 1 /* TEXT */)
        ])
      ])
    ])
  ], 2 /* CLASS */))
}