import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tprog-employ-info"
}
const _hoisted_2 = { class: "tprog-employ-info__month-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "tprog-employ-info__surface"
}
const _hoisted_5 = { class: "tprog-employ-info__surface-month-list" }
const _hoisted_6 = ["title"]
const _hoisted_7 = {
  key: 1,
  class: "tprog-employ-info"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_ctx.fieldData?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "tprog-employ-info__month-item",
              key: item.date,
              onClick: $event => (_ctx.clickMonth(item.date))
            }, _toDisplayString(item.title), 9 /* TEXT, PROPS */, _hoisted_3))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        (_ctx.occupancy)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.date,
                    class: _normalizeClass(["tprog-employ-info__surface-month-item", `type-${item.color}`]),
                    title: _ctx.colorMap[item.color]
                  }, null, 10 /* CLASS, PROPS */, _hoisted_6))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, "–"))
}