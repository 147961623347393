import dayjs from 'dayjs';

import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'tprog-deadline-date',
	mixins: [ cellMixin ],
	computed: {
		formatDate(){
			return dayjs(this.fieldData).format('DD.MM.YYYY');
		},
		getStatusClass(){
			// 'status-good', 'status-warning', 'status-bad'
			const today = new Date;
			const countDay = dayjs(this.fieldData).diff(today, 'day');

			if( countDay >= 30 ) return 'status-good';
			else if(countDay > 0 && countDay < 30) return 'status-warning';
			else return 'status-bad';
		}
	},
}