import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "head-design-info" }
const _hoisted_2 = { class: "head-design-info__label" }
const _hoisted_3 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1 /* TEXT */),
    (!_ctx.notActions)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["head-design-info__btn-collapsed", { '--is-collapsed': _ctx.isCompact }]),
          title: _ctx.isCompact? 'Развернуть':'Свернуть',
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickCompact && _ctx.clickCompact(...args)))
        }, [
          _createVNode(_component_SvgIcon, { name: "14-arrow-left" })
        ], 10 /* CLASS, PROPS */, _hoisted_3))
      : _createCommentVNode("v-if", true)
  ]))
}