import cellMixin from "@/app/mixins/table/cell-mixin";
import helper from "@/app/utils/helper";

import BsInput from "@/app/components/controls/bs-input/BsInput.vue";

export default {
	name: 'pr-cell-edit-price',
	mixins: [cellMixin],
	components: {
		BsInput,
	},
	emits: ['change'],
	computed: {
		value: {
			get(){
				let num = 0;
				if(this.fieldData) num = helper.getNum(this.fieldData);
				return num;
			},
			set(val){
				const num = helper.getNum(val);
				this.$emit('change', {
					rowData: this.rowData,
					groupKey: this.groupKey,
					fieldKey: this.fieldKey,
					fieldData: num,
				});
			}
		}
	},
}