import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "target-tab-filter" }
const _hoisted_2 = { class: "target-tab-filter__tab-pane-label" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElBadge = _resolveComponent("ElBadge")
  const _component_ElTabPane = _resolveComponent("ElTabPane")
  const _component_ElTabs = _resolveComponent("ElTabs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElTabs, {
      class: "target-tab-filter__tabs",
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.tab) = $event)),
      onTabClick: _cache[1] || (_cache[1] = () => {}),
      onTabChange: _ctx.changeActiveTab
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (item) => {
          return (_openBlock(), _createBlock(_component_ElTabPane, {
            class: "target-tab-filter__tab-pane",
            key: item.key+item.count,
            label: item.title,
            name: item.key
          }, {
            label: _withCtx(() => [
              _createVNode(_component_ElBadge, {
                class: "target-tab-filter__badge",
                max: 9999,
                value: item.count
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(item.title), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "name"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "onTabChange"])
  ]))
}