import dayjs from 'dayjs';

import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'tprog-cell-documents',
	mixins: [ cellMixin ],
	computed: {
		docList(){
			return this.fieldData;
		}
	},
	methods: {
		formatDate(date){
			return dayjs(date).format('DD.MM.YY');
		}
	},
}