import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "info-search-gid" }
const _hoisted_2 = { class: "info-search-gid__head-ico" }
const _hoisted_3 = {
  key: 0,
  class: "info-search-gid__wrap"
}
const _hoisted_4 = { class: "info-search-gid__item-title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["info-search-gid__head", { '--is-open-info': _ctx.isOpenList }]),
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.openList && _ctx.openList(...args)))
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "info-search-gid__head-label" }, "Информация о результате поиска", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
      ])
    ], 2 /* CLASS */),
    _createVNode(_component_ElCollapseTransition, null, {
      default: _withCtx(() => [
        (_ctx.isOpenList)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["info-search-gid__item", { 'item-is-error': !item.founded }]),
                  key: item.gid
                }, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(item.gid), 1 /* TEXT */),
                  _withDirectives(_createElementVNode("span", { class: "info-search-gid__item-info" }, _toDisplayString(_ctx.getInfoTitle(item)), 513 /* TEXT, NEED_PATCH */), [
                    [_vShow, item.accurate]
                  ])
                ], 2 /* CLASS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}