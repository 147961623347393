import { ElCollapseTransition } from "element-plus";

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';

export default {
	name: 'target-agr-panel',
	components: {
		ElCollapseTransition,
		SvgIcon, BsInput, BsBtn
	},
	props: {
		list: {
			type: Array,
			default: [],
		}
	},
	emits: ['close'],
	data() {
		return {
			comment: '',
			isOpenForm: false,
		}
	},
	computed: {
		count(){
			return this.list.length;
		}
	},
	methods: {
		openCommentForm(){
			this.isOpenForm = true;
		},
		closeCommentForm(){
			this.isOpenForm = false;
		},
		closePanel(){
			this.$emit('close');
		}
	},
}