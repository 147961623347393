import { mapActions, mapState, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import dayjs from 'dayjs';
import helper from '@/app/utils/helper';

import { ElPopover } from 'element-plus';
import 'element-plus/es/components/popover/style/css';

import cellMixin from "@/app/mixins/table/cell-mixin";
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import DatepickerMonthPeriod from '@/app/components/datepickers/datepicker-month-period/DatepickerMonthPeriod.vue';

export default {
	name: 'tprog-period-month-days',
	mixins: [ cellMixin ],
	components: {
		SvgIcon, ElPopover,
		DatepickerMonthPeriod,
	},
	emits: ['change'],
	data() {
		return {
			periods: [],
			isVisiblePopover: false,
		}
	},
	computed: {
		...mapState(useDetailTargetProgStore, ['detailMonthSelectData']),

		status(){
			return this.rowData?.status;
		},
		startDate(){
			const { value } = this.detailMonthSelectData ?? {};
			let startDate = '';

			if(value) startDate = dayjs(value).date(1).format('YYYY-MM-DD');

			return startDate;
		},
	},
	methods: {
		cancelDatepicker(item){
			item.isVisiblePopover = false;
		},
		removePeriod(item, index){
			let periods = this.periods.filter(period=>period.id!=item.id).map(item=>{
				const { from, to } = item;
				return [from, to];
			});
			this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: periods,
			});
		},
		addPeriod(periods){
			console.log(periods);
			this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: periods,
			});
		},
		buildPeriods(){
			const list = [];
			if(this.fieldData) for(let period of this.fieldData){
				const [ from, to ] = period;
				list.push({
					id: helper.hash,
					from, to,
					isVisiblePopover: false,
					format: {
						from: dayjs(from).format('DD'),
						to: dayjs(to).format('DD'),
					}
				});
			}
			this.periods = list;
		}
	},
	watch: {
		fieldData(){
			this.buildPeriods();
		}
	},
	created() {
		// console.log(this.fieldData);
		this.buildPeriods();
	},
}