import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useMapDesignStore } from '@/app/stores/map-design-store';
import { useDialogStore } from '@/app/stores/dialog-store';
import { useDesignFilterStore } from '@/app/stores/design-filter-store';

import { ElMessage, ElCollapseTransition } from 'element-plus';

import cellMixin from '@/app/mixins/table/cell-mixin.js';
import helper from '@/app/utils/helper';
import api from '@/app/utils/service-api';

import SideSelect from '@/app/components/map/map-design-info/side-select/SideSelect.vue';
import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'import-designs-info',
	mixins: [ cellMixin ],
	components: {
		ElCollapseTransition,
		SideSelect, BtnAction, BsBtn, SvgIcon,
	},
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		...mapState( useDesignFilterStore, ['targetProgram', 'city'] ),
		...mapWritableState( useDesignFilterStore, ['isFilterDataLoad'] ),

		sideData(){
			return this.rowData.side;
		},

		linkDesign(){
			const { origin } = window.location;
			const { cityId, structureId, id } = this.sideData ?? {};

			const data = {
				structureId: structureId ?? '',
				sideId: id ?? '',
				city: [cityId ?? ''],
			}
			console.log(data);
			const params = api.buildParams(data).toString();
			const link = `${origin}/structures?${params}`;

			return link;
		},
	},
	methods: {
		...mapActions( useMapDesignStore, ['getTypeDesignIcon'] ),

		toggleWrap(){
			this.isOpen = !this.isOpen;
		},

		replaceSide(){
			this.rowData['side'] = null;
			this.rowData['foundedInTp'] = false;
			this.rowData['matching'] = 'none';

			this.rowData['blockAndSlot'] = {
				value: {
					block: '',
					slot: '',
				},
				status: '',
			};
			this.rowData['duration'] = {
				value: '',
				status: ''
			};
			this.rowData['showsInHour'] = {
				value: '',
				status: ''
			};
		}
	},
	created() {
		// console.log(this.data);
	},
}