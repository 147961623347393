import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "target-import__header" }
const _hoisted_2 = { class: "target-import__header-title" }
const _hoisted_3 = { class: "target-import__header-month" }
const _hoisted_4 = { class: "target-import__header-target-id" }
const _hoisted_5 = { class: "target-import__content" }
const _hoisted_6 = { class: "target-import__bottom" }
const _hoisted_7 = { class: "target-import__info" }
const _hoisted_8 = { class: "target-import__info-item" }
const _hoisted_9 = { class: "info-item-count" }
const _hoisted_10 = { class: "target-import__info-item" }
const _hoisted_11 = { class: "info-item-count" }
const _hoisted_12 = { class: "target-import__action-buttons" }
const _hoisted_13 = { class: "import-bs__inner" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TargetImportTable = _resolveComponent("TargetImportTable")
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "target-import",
    modelValue: _ctx.isTargetImportDialog,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.isTargetImportDialog) = $event)),
    "append-to-body": true,
    "destroy-on-close": true,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "show-close": false,
    "align-center": "",
    onClosed: _ctx.clickCancel
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.targetTitle), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.yearMonthFormat), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_4, "ID АП: " + _toDisplayString(_ctx.targetProgramId), 1 /* TEXT */)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_TargetImportTable, {
          tableData: _ctx.tableImportData,
          onChangeSelectionTable: _ctx.changeSelectionTable
        }, null, 8 /* PROPS */, ["tableData", "onChangeSelectionTable"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_BsCheckbox, {
            modelValue: _ctx.isSetReserved,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.isSetReserved) = $event))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Поставить в резерв")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"]),
          _createVNode(_component_BsCheckbox, {
            modelValue: _ctx.isReplaceMatches,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.isReplaceMatches) = $event))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Заменить совпадающие")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"]),
          _createElementVNode("div", _hoisted_8, [
            _cache[5] || (_cache[5] = _createTextVNode("Найдено в Реестре:")),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.countFindDs), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[6] || (_cache[6] = _createTextVNode("Найдено в АП:")),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.countFindTr), 1 /* TEXT */)
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_BsBtn, {
            class: "import-bs",
            onClick: _ctx.clickCancel
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_SvgIcon, { name: "down-arrow" }),
                _cache[7] || (_cache[7] = _createElementVNode("span", null, "Экспорт результата анализа", -1 /* HOISTED */))
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]),
          _createVNode(_component_BsBtn, {
            class: "import-bs",
            onClick: _ctx.closeImport
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("Закрыть")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]),
          _createVNode(_component_BsBtn, {
            type: "primary",
            onClick: _ctx.importData
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode("Импортировать в АП")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onClosed"]))
}