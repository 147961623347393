import dayjs from 'dayjs';

import SideQueuePopup from '@/app/components/modals/side-queue-popup/SideQueuePopup.vue';

export default {
	name: 'side-queue-v2',
	components: {
		SideQueuePopup,
	},
	props: {
		data: Object,
		sideInfo: Object,
		showActionMonth: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			isOpenPopup: false,
		}
	},
	computed: {
	},
	methods: {
		openPopup(){
			this.isOpenPopup = true;
		},
		getPopupTitle() {
			if(this.sideInfo?.period) {
				const formatDate = dayjs(this.sideInfo.period).format('MMMM YYYY');
				return `Очередь (${this.sideInfo?.gid}) ${formatDate}`;
			}
			return 'Очередь';
		},
		getActionTitle() {
			let title = '';
			if(this.showActionMonth) title = this.getActionMonthDate();
			title += this.data.title;

			return title;
		},
		getActionMonthDate() {
			if(this.sideInfo?.period) {
				const date = dayjs(this.sideInfo.period, 'YYYY-MM');
				const monthFull = date.format('MMMM');
				const monthShort = date.format('MMM').replace('.', '');

				// Если полное название месяца длиннее 4 символов, используем краткое название
				const month =
					monthFull.length > 4 ? monthShort : monthFull;

				const year = date.format('YYYY');

				return `${month}'${year.slice(2,4)} — `;
			}
			return ''
		},
	},
}