import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "save-filtertemp-form__content" }
const _hoisted_2 = { class: "save-filtertemp-form__wrap-controls" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = { class: "control__group" }
const _hoisted_5 = { class: "save-filtertemp-form__action-buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "save-filtertemp-form",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.isVisible) = $event)),
    title: _ctx.headTitle,
    width: "480",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "control__label" }, "Название шаблона", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BsInput, {
                placeholder: "Введите название",
                modelValue: _ctx.nameTemp,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.nameTemp) = $event)),
                clearable: true
              }, null, 8 /* PROPS */, ["modelValue"])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BsBtn, {
          type: "primary",
          tag: "div",
          size: "small",
          disabled: _ctx.isSaveBtnDisabled,
          onClick: _ctx.saveTemp
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Создать")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"]),
        _createVNode(_component_BsBtn, {
          tag: "div",
          size: "small",
          onClick: _ctx.cancel
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Отмена")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}