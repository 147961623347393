import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid-cell-queue"
}
const _hoisted_2 = {
  key: 1,
  class: "grid-cell-queue"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SideQueueV2 = _resolveComponent("SideQueueV2")

  return (_ctx.calendar)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendar, (queue, key) => {
          return (_openBlock(), _createBlock(_component_SideQueueV2, {
            data: queue,
            sideInfo: _ctx.getSideInfo(key)
          }, null, 8 /* PROPS */, ["data", "sideInfo"]))
        }), 256 /* UNKEYED_FRAGMENT */))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, "–"))
}