import { mapActions, mapState, mapWritableState } from 'pinia';
import { useDesignFilterStore } from '@/app/stores/design-filter-store';

import { ElCollapseTransition } from 'element-plus';

import helper from '@/app/utils/helper';

import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsRadioGroup from '@/app/components/controls/bs-radio-group/BsRadioGroup.vue';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import InfoSearchGid from '@/app/components/design-search-gid/info-search-gid/InfoSearchGid.vue';

export default {
	name: 'design-search-gid',
	components: {
		ElCollapseTransition,
		BsInput, BsCheckbox, BsBtn, BsRadioGroup,
		SvgIcon, InfoSearchGid,
	},
	props: {
		data: [String, Object],
		isOpen: {
			type: Boolean,
			default: false,
		}
	},
	emits: ['update:data', 'open','submit'],
	data() {
		return {
			isOpenForm: this.isOpen,
			allowPartialMatch: true,

			textGid: '',
			searchArea: {
				value: 'rim_gid',
				options: [
					{
						value: 'rim_gid',
						label: 'Гиды РИМа',
					},
					{
						value: 'gid',
						label: 'Партнерские гиды',
					},
					{
						value: 'code_espar',
						label: 'ESPAR ID',
					},
				],
			},

			infoList: '',
		}
	},
	computed: {
		gids(){
			const separators = [';', ',', '\n'];
			const regex = new RegExp('[' + separators.join('') + ']');
			const gids = this.textGid.split(regex)
				.map(item => item.trim())
				.filter(item => item?.length);

			console.log(gids);
			return gids;
		},
		submitData(){
			return {
				gids: this.gids,
				gidSearchBy: this.searchArea.value,
				allowPartialMatch: this.allowPartialMatch,
			}
		}
	},
	methods: {
		...mapActions(useDesignFilterStore, ['getCheckGids'] ),

		openForm(){
			this.isOpenForm = !this.isOpenForm;
			this.$emit('open', this.isOpenForm);
		},
		async submit(){
			console.log('submit', this.submitData);
			const res = await this.getCheckGids(this.submitData);

			// rim_gid: KZN-BB-00277-A1; KZN-BB-00322; KZN-BB-006;
			// gid: 0003-KAZ-A; 0006-KAZ-B; DBB 0010-ББ-B;
			// code_espar: KZN426021; KZN426022; KZN436444;
			if(res) this.infoList = Object.entries(res).map(([key, value])=>{
				return {
					gid: key,
					...value,
				}
			}).sort((a, b)=> a.founded - b.founded);
			console.log(this.infoList);

			this.$emit('submit', res);
		},

		buildGidData(){
			if(!this.data) return;
			const { gids, gidSearchBy, allowPartialMatch } = this.data;

			if(gids?.length){
				this.textGid = gids.join(';');
				this.isOpenForm = true;
			}
			if(gidSearchBy) this.searchArea.value = gidSearchBy;

			this.allowPartialMatch = !!allowPartialMatch;
		}
	},
	watch: {
		data(data, oldData){
			if(data && (JSON.stringify(data) !== JSON.stringify(oldData))){
				// this.buildGidData();
			}
			if(!data) {
				this.isOpenForm = true;
				this.allowPartialMatch = true;
				this.textGid = '';
				this.searchArea.value = 'rim_gid';
				this.infoList = '';
			}
		},
		submitData(data){
			// console.log(data);
			if(data?.gids?.length) this.$emit('update:data', data);
			else this.$emit('update:data', '');
		},
		isOpen(is){
			this.isOpenForm = is;
		}
	},

	created(){
		if(this.data) this.buildGidData();
	},
}