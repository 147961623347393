import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cell-switch" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElSwitch = _resolveComponent("ElSwitch")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElSwitch, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.value) = $event)),
      size: "small"
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}