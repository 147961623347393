import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message-import-result" }
const _hoisted_2 = { class: "message-import-result__item item-imported" }
const _hoisted_3 = { class: "message-import-result__item-value" }
const _hoisted_4 = { class: "message-import-result__item item-added" }
const _hoisted_5 = { class: "message-import-result__item-value" }
const _hoisted_6 = { class: "message-import-result__item item-replaced" }
const _hoisted_7 = { class: "message-import-result__item-value" }
const _hoisted_8 = { class: "message-import-result__item item-not-imported" }
const _hoisted_9 = { class: "message-import-result__item-value" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "message-import-result__item-label" }, "Импортировано", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.data.importedCount || 0), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "message-import-result__item-label" }, "Добавлено", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.data.addedCount || 0), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "message-import-result__item-label" }, "Заменено", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.data.replacedCount || 0), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "message-import-result__item-label" }, "Не импортировано", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.data.failedCount || 0), 1 /* TEXT */)
    ])
  ]))
}