import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tprog-category-customer" }
const _hoisted_2 = { class: "tprog-category-customer__short-select" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ShortSelect = _resolveComponent("ShortSelect")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ShortSelect, {
        modelValue: _ctx.fieldValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.fieldValue) = $event)),
        options: _ctx.options,
        filtrable: true,
        onChange: _ctx.changeSelect
      }, null, 8 /* PROPS */, ["modelValue", "options", "onChange"])
    ])
  ]))
}