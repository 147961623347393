import { ElTooltip } from 'element-plus';
import 'element-plus/es/components/tooltip/style/css';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'label-info',
	components: {
		ElTooltip, SvgIcon,
	}
}