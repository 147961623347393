import dayjs from 'dayjs';

import cellMixin from '@/app/mixins/table/cell-mixin';
import helper from '@/app/utils/helper';

import ImportCellStatus from '@/app/components/target-programs/target-import/import-cell-types/import-cell-status/ImportCellStatus.vue';

export default {
	name: 'import-place-periods',
	mixins: [ cellMixin ],
	components: {
		ImportCellStatus,
	},
	emits: ['click'],
	data() {
		return {

		}
	},
	computed: {
		periods(){
			const list = [];
			if(this.fieldData?.value?.length) for(let period of this.fieldData.value){
				if(!period?.length) continue;

				const [ from, to ] = period;
				list.push({
					id: helper.hash,
					from, to,
					format: {
						from: dayjs(from).format('DD.MM.YYYY'),
						to: dayjs(to).format('DD.MM.YYYY'),
					}
				});
			}
			return list;
		}
	},
	methods: {
		clickPeriod(item){
			this.$emit('click', item);
		}
	},
}