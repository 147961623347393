import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tprog-edit-date-popover__container" }
const _hoisted_2 = { class: "tprog-edit-date-popover__datepicker" }
const _hoisted_3 = { class: "tprog-edit-date-popover__btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsDatePicker = _resolveComponent("BsDatePicker")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createBlock(_component_ElPopover, {
    trigger: "click",
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.isVisible) = $event)),
    offset: 8,
    persistent: false,
    width: "auto",
    placement: "right-start",
    "popper-class": "tprog-edit-date-popover"
  }, {
    reference: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "tprog-edit-date-popover__title" }, "Изменить дату продления", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BsDatePicker, {
            modelValue: _ctx.date,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.date) = $event)),
            size: "large",
            teleported: false,
            format: "DD.MM.YYYY",
            type: "date",
            "value-format": "YYYY-MM-DD",
            "disabled-date": _ctx.disabledDateFrom
          }, null, 8 /* PROPS */, ["modelValue", "disabled-date"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BsBtn, {
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.isVisible=false))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Отмена")
            ])),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_BsBtn, {
            type: "primary",
            disabled: !this.date,
            onClick: _ctx.clickOk
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Ок")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "onClick"])
        ])
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["visible"]))
}