import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "target-manager" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "target-manager__soc-links" }
const _hoisted_4 = { class: "target-manager__soc-links" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_DiEditSelect = _resolveComponent("DiEditSelect")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "target-manager__name",
      title: _ctx.label
    }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_2),
    (_ctx.isListAddition)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: "target-manager__btn-remove",
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickRemove && _ctx.clickRemove(...args))),
            title: "Убрать менеджера"
          }, [
            _createVNode(_component_SvgIcon, { name: "14-circle-close-filled" })
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.contacts?.phone)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "target-manager__soc-item item-phone",
                  onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clickPhone && _ctx.clickPhone(...args))),
                  title: "Копировать номер телефона"
                }, [
                  _createVNode(_component_SvgIcon, { name: "16-gg-phone" })
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.contacts?.email)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "target-manager__soc-item item-email",
                  onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.clickEmail && _ctx.clickEmail(...args))),
                  title: "Копировать email"
                }, [
                  _createVNode(_component_SvgIcon, { name: "16-email-solid" })
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.contacts?.telegram)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "target-manager__soc-item item-telegram",
                  onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.clickTelegram && _ctx.clickTelegram(...args))),
                  title: "Копировать telegram"
                }, [
                  _createVNode(_component_SvgIcon, { name: "12-telegram" })
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", {
            class: "target-manager__btn-add",
            onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.clickAdd && _ctx.clickAdd(...args))),
            title: "Добавить менеджера"
          }, [
            _createVNode(_component_SvgIcon, { name: "14-plus" })
          ]),
          _createVNode(_component_DiEditSelect, {
            visible: _ctx.isVisibleList,
            "onUpdate:visible": _cache[5] || (_cache[5] = $event => ((_ctx.isVisibleList) = $event)),
            titleDialog: _ctx.titleDialog,
            fieldData: _ctx.fieldData,
            typeSelect: _ctx.typeSelect,
            onChange: _ctx.changeEdit
          }, null, 8 /* PROPS */, ["visible", "titleDialog", "fieldData", "typeSelect", "onChange"])
        ], 64 /* STABLE_FRAGMENT */))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", {
            class: "target-manager__btn-edit",
            onClick: _cache[6] || (_cache[6] = (...args) => (_ctx.clickEdit && _ctx.clickEdit(...args))),
            title: "Изменить"
          }, [
            _createVNode(_component_SvgIcon, { name: "14-edit-pen" })
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.contacts?.phone)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "target-manager__soc-item item-phone",
                  onClick: _cache[7] || (_cache[7] = (...args) => (_ctx.clickPhone && _ctx.clickPhone(...args))),
                  title: "Копировать номер телефона"
                }, [
                  _createVNode(_component_SvgIcon, { name: "16-gg-phone" })
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.contacts?.email)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "target-manager__soc-item item-email",
                  onClick: _cache[8] || (_cache[8] = (...args) => (_ctx.clickEmail && _ctx.clickEmail(...args))),
                  title: "Копировать email"
                }, [
                  _createVNode(_component_SvgIcon, { name: "16-email-solid" })
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.contacts?.telegram)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "target-manager__soc-item item-telegram",
                  onClick: _cache[9] || (_cache[9] = (...args) => (_ctx.clickTelegram && _ctx.clickTelegram(...args))),
                  title: "Копировать telegram"
                }, [
                  _createVNode(_component_SvgIcon, { name: "12-telegram" })
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _createVNode(_component_DiEditSelect, {
            visible: _ctx.isVisible,
            "onUpdate:visible": _cache[10] || (_cache[10] = $event => ((_ctx.isVisible) = $event)),
            titleDialog: _ctx.titleDialog,
            fieldData: _ctx.fieldData,
            typeSelect: _ctx.typeSelect,
            onChange: _ctx.changeEdit
          }, null, 8 /* PROPS */, ["visible", "titleDialog", "fieldData", "typeSelect", "onChange"])
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}