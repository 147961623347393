import cellMixin from "@/app/mixins/table/cell-mixin";

import helper from '@/app/utils/helper';

import BsInput from '@/app/components/controls/bs-input/BsInput.vue';

export default {
	name: 'cell-editable',
	mixins: [ cellMixin ],
	components: {
		BsInput,
	},
	props: {
		typeField: {
			type: String,
			default: 'price' // percent, string
		}
	},
	emits:['change'],
	data() {
		return {
			isVisibleInput: false,
			inputData: '',
		}
	},
	computed: {
		isNotData(){
			return this.fieldData === undefined;
		}
	},
	methods: {
		async clickCell(){
			if(this.isNotData) return;

			this.isVisibleInput = true;
			await this.$nextTick();

			const { bsInputRef } = this.$refs;
			// console.log(bsInputRef);
			if(bsInputRef.$el){
				const elInput = bsInputRef.$el.querySelector('input');
				elInput.focus();
			}
		},
		inputBlur(){
			this.isVisibleInput = false;

			if(this.typeField=='price'){
				let num = Number(this.inputData);
				if(num < 0) num = 0;
				this.inputData = num;
			}
			else if(this.typeField=='percent'){
				let num = Number(this.inputData);
				// console.log(num);
				if(num < 0) num = 0;
				if(num > 100) num = 100;
				this.inputData = num;
			}
			else if(this.typeField=='string'){
				if(this.fieldData !== this.inputData) this.$emit('change', {
					rowData: this.rowData,
					groupKey: this.groupKey,
					fieldKey: this.fieldKey,
					fieldData: this.inputData ?? '',
				});
			}

			if(helper.getNum(this.fieldData) !== helper.getNum(this.inputData)) this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: this.inputData,
			});
		}
	},
	created(){
		if(!this.isNotData) this.inputData = this.fieldData;
	}
}