import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "short-select" }
const _hoisted_2 = { class: "short-select__btn-active" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "short-select__container" }
const _hoisted_5 = {
  key: 0,
  class: "short-select__search"
}
const _hoisted_6 = { class: "short-select__search-icon" }
const _hoisted_7 = {
  key: 0,
  class: "short-select__wrap"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 1,
  class: "short-select__wrap-empty"
}
const _hoisted_10 = {
  key: 0,
  class: "short-select__wrap"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 1,
  class: "short-select__wrap-empty"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_ElPopover = _resolveComponent("ElPopover")
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElPopover, {
      visible: _ctx.isVisiblePopover,
      "onUpdate:visible": _cache[3] || (_cache[3] = $event => ((_ctx.isVisiblePopover) = $event)),
      placement: _ctx.placement,
      width: 290,
      "hide-after": 100,
      "popper-class": "short-select__popover",
      persistent: false,
      trigger: "click"
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["short-select__label", { 'type-placeholder': _ctx.label==_ctx.placeholder }]),
            title: _ctx.label
          }, _toDisplayString(_ctx.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_3),
          _createElementVNode("div", {
            class: _normalizeClass(["short-select__arrow", { '--is-select-open': _ctx.isVisiblePopover }])
          }, [
            _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
          ], 2 /* CLASS */)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.filtrable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_Transition, {
                  name: "el-fade-in",
                  mode: "out-in",
                  persisted: ""
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_SvgIcon, { name: "16-search" })
                    ], 512 /* NEED_PATCH */), [
                      [_vShow, !(_ctx.isInputFocus || _ctx.strSearch)]
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_BsInput, {
                  modelValue: _ctx.strSearch,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.strSearch) = $event)),
                  clearable: true,
                  onFocus: _cache[1] || (_cache[1] = $event => (_ctx.isInputFocus=true)),
                  onBlur: _cache[2] || (_cache[2] = $event => (_ctx.isInputFocus=false))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.options?.length > _ctx.maxCount)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.searchList?.length)
                  ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchList, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass(["short-select__item", { '--is-item-active': (_ctx.selectValue && item.value==_ctx.selectValue), '--is-item-disabled': item?.disabled }]),
                          key: item.value,
                          onClick: $event => (_ctx.clickItem(item))
                        }, _toDisplayString(item.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_8))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])), [
                      [_directive_infinite_scroll, _ctx.load]
                    ])
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, "Нет данных"))
              ], 64 /* STABLE_FRAGMENT */))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (_ctx.searchList?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchList, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass(["short-select__item", { '--is-item-active': (_ctx.selectValue && item.value==_ctx.selectValue), '--is-item-disabled': item?.disabled }]),
                          key: item.value,
                          onClick: $event => (_ctx.clickItem(item))
                        }, _toDisplayString(item.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_11))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_12, "Нет данных"))
              ], 64 /* STABLE_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible", "placement"])
  ]))
}