import cellMixin from "@/app/mixins/table/cell-mixin";

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import BsInput from "@/app/components/controls/bs-input/BsInput.vue";
import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";

export default {
	name: 'pr-cell-comment',
	mixins: [cellMixin],
	components: {
		SvgIcon, BsInput, BsBtn,
	},
	emits: ['change'],
	data() {
		return {
			isEdit: false,
			comment: '',
		}
	},
	methods: {
		clickEdit(){
			this.isEdit = true;
			this.comment = (this.fieldData ?? '').trim();
		},
		clickCancel(){
			this.isEdit = false;
		},
		clickSave(){
			this.isEdit = false;

			this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: this.comment,
			});
		}
	},
}