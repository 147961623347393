import helper from "@/app/utils/helper";

import { ElPopover } from "element-plus";

import BtnAction from "@/app/components/controls/buttons/btn-action/BtnAction.vue";
import BsCheckbox from "@/app/components/controls/bs-checkbox/BsCheckbox.vue";
import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";

export default {
	name: 'pr-setting-table',
	components: {
		ElPopover,
		BtnAction, BsCheckbox, BsBtn,
	},
	props: {
		keyLocStore: [String],
		columns: {
			type: Array,
			default: [],
		}
	},
	emits: ['save'],
	data() {
		return {
			isVisiblePopover: false,
			tmpColumns: [],
		}
	},
	computed: {
		isSaveDisabled(){
			return JSON.stringify(this.columns) == JSON.stringify(this.tmpColumns);
		}
	},
	methods: {
		cleanHtmlString: helper.cleanHtmlString,

		clickShowAll(){
			for(const item of this.tmpColumns){
				if(!item.show) item.show = true;
			}
		},
		clickCancel(){
			this.isVisiblePopover = false;
		},
		clickSave(){
			for( const item of this.tmpColumns ){
				const { key, show } = item;
				const findItem = this.columns.find(item=>item.key==key);

				if(findItem) findItem.show = show;
			}

			this.$emit('save', this.tmpColumns.map(item=>{
				const { key, show } = item;
				return { key, show };
			}));
			this.isVisiblePopover = false;
		}
	},
	watch: {
		isVisiblePopover(is){
			if(is) this.tmpColumns = helper.clone(this.columns);
		}
	},
}