import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tprog-cell-status__bg" }
const _hoisted_2 = {
  class: "tprog-cell-status__bg-icon",
  title: "Продлить резерв"
}
const _hoisted_3 = {
  key: 0,
  class: "tprog-cell-status__select-label"
}
const _hoisted_4 = {
  key: 1,
  class: "tprog-cell-status__short-select"
}
const _hoisted_5 = { class: "tprog-cell-status__queue" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "tprog-cell-status__queue-wrap" }
const _hoisted_10 = {
  key: 1,
  class: "tprog-cell-status__queue"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_TprogEditDatePopover = _resolveComponent("TprogEditDatePopover")
  const _component_ShortSelect = _resolveComponent("ShortSelect")
  const _component_SideQueueItem2 = _resolveComponent("SideQueueItem2")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tprog-cell-status", _ctx.statusData.className])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.statusData?.icon)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (this.status=='reserved' || _ctx.status =='temporary_approved')
              ? (_openBlock(), _createBlock(_component_TprogEditDatePopover, {
                  key: 0,
                  visible: _ctx.isOpenEditDatePopover,
                  "onUpdate:visible": _cache[0] || (_cache[0] = $event => ((_ctx.isOpenEditDatePopover) = $event)),
                  onChange: _ctx.extendReserve
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_SvgIcon, {
                        name: _ctx.statusData.icon
                      }, null, 8 /* PROPS */, ["name"])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["visible", "onChange"]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "tprog-cell-status__bg-icon",
                  onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clickIcon && _ctx.clickIcon(...args)))
                }, [
                  _createVNode(_component_SvgIcon, {
                    name: _ctx.statusData.icon
                  }, null, 8 /* PROPS */, ["name"])
                ]))
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true)
    ]),
    (!_ctx.isMy && _ctx.status=='approved')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.label), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_ShortSelect, {
            modelValue: _ctx.fieldValue,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.fieldValue) = $event)),
            options: _ctx.options,
            placement: "bottom-start",
            placeholder: "Выбрать",
            requiredLabel: _ctx.label,
            onChange: _ctx.changeSelect
          }, null, 8 /* PROPS */, ["modelValue", "options", "requiredLabel", "onChange"])
        ])),
    (_ctx.status=='reserved' || _ctx.status=='temporary_approved')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.queue?.queueList?.length)
            ? (_openBlock(), _createBlock(_component_ElPopover, {
                key: 0,
                "popper-class": "tprog-cell-status__queue-popover",
                width: "286",
                offset: 6,
                trigger: "click",
                placement: "top-start"
              }, {
                reference: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.queue?.title?.position==1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                          (_ctx.formatDate.length)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Истекает " + _toDisplayString(_ctx.formatDate), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                        ]))
                      : (_ctx.queue?.title?.position>1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Вы " + _toDisplayString(_ctx.queue?.title?.position) + " в очереди (" + _toDisplayString(_ctx.formatDate) + ")", 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queue.queueList, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "tprog-cell-status__queue-item",
                        key: item.id
                      }, [
                        _createVNode(_component_SideQueueItem2, { data: item }, null, 8 /* PROPS */, ["data"])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_ctx.queue?.title?.position==1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                      (_ctx.formatDate.length)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Истекает " + _toDisplayString(_ctx.formatDate), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : (_ctx.queue?.title?.position>1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, "Вы " + _toDisplayString(_ctx.queue?.title?.position) + " в очереди (" + _toDisplayString(_ctx.formatDate) + ")", 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
              ]))
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}