import { ElCollapseTransition } from "element-plus";

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import InfoApMonth from '@/app/components/map/map-info-ap/info-ap-month/InfoApMonth.vue';

export default {
	name: 'map-info-ap',
	components: {
		SvgIcon, ElCollapseTransition, InfoApMonth,
	},
	props: {
		data: Object,
	},
	data() {
		return {
			// data: {
			// 	months: [
			// 		{
			// 			date: '2024-01',
			// 			sideList: [
			// 				{
			// 					id: '',
			// 					constructType: '', // 'Ситиборд', 'Пиллар', 'Панель-кронштейн', 'Щит'
			// 					size: '', // '6х3', '8х4'
			// 					mech: '', // 'Статика', 'Призма', 'Digital'
			// 					direct: '', // 'A','B', 'C'
			// 				},
			// 			]
			// 		},
			// 	]
			// },

			isOpenMain: false,
			openMonthMap: null,
		}
	},
	computed: {
		allSideList(){
			const list = [];

			for(let month of this.data.months){
				list.push(...month.sideList)
			}

			return list;
		},

		allCountSize(){
			return this.allSideList.length;
		},

		allResult(){
			const countDirect = {
				A: 0,
				B: 0,
				C: 0
			};
			const result = {};

			for(let item of this.allSideList){
				if(item.direct == 'A') countDirect.A++;
				if(item.direct == 'B') countDirect.B++;
				if(item.direct == 'C') countDirect.C++;
			}

			for(let [key, value] of Object.entries(countDirect)){
				result[key] = {
					value,
					percent: Math.floor( value/this.allSideList.length * 100 )
				}
			}

			// console.log(result);

			return result;
		},
	},
	methods: {
		buildOpenMonthMap(){
			const map = {};
			for( let item of this.data.months ){
				map[item.date] = false;
			}

			this.openMonthMap = map;
		},
		clickOpenMain(){
			this.isOpenMain = !this.isOpenMain;
		},
		changeOpenMonth({ open, month }){
			for( let [key , value] of Object.entries(this.openMonthMap)){
				this.openMonthMap[key] = false;
			}
			this.openMonthMap[month] = open;
		},
	},
	created() {
		this.buildOpenMonthMap();
	},
	watch: {
		allSideList(list){
			this.buildOpenMonthMap();
		},
		isOpenMain(is){
			if(!is){
				for( let [key , value] of Object.entries(this.openMonthMap)){
					this.openMonthMap[key] = false;
				}
			}
		}
	},
}