import cellMixin from "@/app/mixins/table/cell-mixin";

import BsCheckbox from "@/app/components/controls/bs-checkbox/BsCheckbox.vue";
import PrCellEditNum from "@/app/components/pricing-policies/type-cell/pr-cell-edit-num/PrCellEditNum.vue";


export default {
	name: 'pr-cell-coef',
	mixins: [cellMixin],
	components: {
		BsCheckbox, PrCellEditNum,
	},
	emits: ['changeCoef', 'changeMultip'],
	computed: {
		isMultipCoefficient(){
			return this.rowData?.isMultipCoefficient;
		}
	},
	methods: {
		changeNum(data){
			this.$emit('changeCoef', data)
		},
		changeMultip(is){
			this.$emit('changeMultip', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: 'isMultipCoefficient',
				fieldData: is,
			});
		}
	},
}