import { mapState, mapActions, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';
import { useFiltersStore } from '@/app/stores/filters-store';
import { useMapTargetStore } from '@/app/stores/map-target-store';

import dayjs from 'dayjs';

import { ElCollapseTransition } from 'element-plus';

import BsSingleSelect from '@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue';
import BsMultiSelect from '@/app/components/controls/selects/bs-multi-select/BsMultiSelect.vue';
import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';
import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';
import BsCheckboxGroup from '@/app/components/controls/bs-checkbox-group/BsCheckboxGroup.vue';
import BsRadioGroup from '@/app/components/controls/bs-radio-group/BsRadioGroup.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsDatePicker from '@/app/components/controls/bs-date-picker/BsDatePicker.vue';
import DesignSearchGid from '@/app/components/design-search-gid/DesignSearchGid.vue';

export default {
	name: 'target-filter',
	components: {
		ElCollapseTransition,
		SvgIcon,
		BsSingleSelect, BsMultiSelect, BsSelectV2,
		BsCheckbox, BsInput, BsBtn,
		BsCheckboxGroup, BsRadioGroup, BsDatePicker,
		DesignSearchGid,
	},
	data() {
		return {
			isOpenPersonGroup: false,
			isOpenDigitalGroup: false,
			isOpenOtherGroup: false,
			isOpenServiceGroup: false,
			isOpenSearchGid: false,

			testSelectData: {
				value: '',
				options: [
					{
						value: '1',
						label: 'Значение 1',
					},
					{
						value: '2',
						label: 'Значение 2',
					},
					{
						value: '3',
						label: 'Значение 3',
					},
					{
						value: '4',
						label: 'Значение 4',
					},
					{
						value: '5',
						label: 'Значение 5',
					},
					{
						value: '6',
						label: 'Значение 6',
					},
				],
			},
		}
	},
	computed: {
		...mapState( useDetailTargetProgStore, ['compareOptions', 'expiringStatusOptions'] ),
		...mapWritableState(useDetailTargetProgStore, ['placePeriodType', 'detailTargetFilterData', 'detailMonthSelectData'] ),

		periodDatePicker(){
			if(this.placePeriodType?.value=='month') return 'month';
			return 'date';
		},
		placeholderDatePicker(){
			if(this.placePeriodType?.value=='month') return 'Месяц';
			return 'Дата';
		},
	},
	methods: {
		...mapActions(useDetailTargetProgStore, ['getDetailTargetGridData', 'getSubmitDetailFilterData']),
		...mapActions(useFiltersStore, ['getOptions']),
		...mapActions(useMapTargetStore, ['getMapData']),

		changeLighting(data){
			for(const item of this.detailTargetFilterData.lighting){
				if(data.key != item.key) item.isCheck = false;
			}
		},

		submit(){
			console.log('submit');
			const { params, query } = this.$route;
			const filters = this.getSubmitDetailFilterData();
			let queryParams = { ...query };

			console.log(filters);
			if(Object.keys(filters)?.length){
				let queryFilter = {};

				if(Object.keys(filters)?.length) queryFilter = {
					filters: JSON.stringify(filters),
				}

				queryParams = {
					...queryParams,
					...queryFilter,
				}
			} else {
				delete queryParams.filters;
			}

			this.$router.push({
				name: 'target-program-item',
				params,
				query: queryParams,
			});

			if(query?.view=='map'){
				this.getMapData({
					id: params?.id,
				});
			} else {
				this.getDetailTargetGridData({
					id: params?.id,
					monthDate: this.detailMonthSelectData.value,
				});
			}
		},

		disabledDateFrom(data){
			const periodType = this.placePeriodType.value;
			const today = new Date();
			// let nextDate = dayjs(today).add(1, 'months').toDate();
			let nextDate = dayjs(today).toDate();

			if(periodType=='day') nextDate = dayjs(nextDate).startOf('month').toDate();

			return nextDate > data;
		},
		disabledDateTo(data){
			const { from, to } = this.detailTargetFilterData.placePeriodDate;
			const periodType = this.placePeriodType.value;
			const today = new Date();
			let nextDate = dayjs(today).add(1, 'months').toDate();
			nextDate = dayjs(nextDate).startOf('month').toDate();
			nextDate = dayjs(nextDate).add(14, 'days').toDate();

			// if(periodType=='day') nextDate = dayjs(nextDate).startOf('month').toDate();
			// if(periodType=='day'){
			// 	nextDate = dayjs(today).add(1, 'months').toDate();
			// 	nextDate = dayjs(nextDate).startOf('month').toDate();
			// 	nextDate = dayjs(nextDate).add(14, 'days').toDate();
			// 	// nextDate = dayjs(nextDate).startOf('month').toDate();
			// }
			if(from){
				const fromDate = dayjs(from).toDate();
				const monthDays = dayjs(from).daysInMonth();
				// if( fromDate > nextDate ) nextDate = dayjs(fromDate).add(1, 'months').toDate();
				if( fromDate > nextDate ){
					nextDate = dayjs(from).startOf('month').toDate();
					nextDate = dayjs(nextDate).add(14, 'days').toDate();
				}
			}

			return nextDate > data;
		},
		changeDatePickerFrom(){
			const { from, to } = this.detailTargetFilterData.placePeriodDate;
			const dateFrom = dayjs(from).toDate();
			const dateTo = dayjs(to).toDate();

			if(dateFrom > dateTo){
				const monthDays = dayjs(from).daysInMonth();
				// this.placePeriodDate.to = dayjs(dateFrom).add(1, 'months').format('YYYY-MM-DD');
				this.detailTargetFilterData.placePeriodDate.to = dayjs(from).date(monthDays).format('YYYY-MM-DD');
			}
		},
		changeDatePickerTo(){
			const { to } = this.detailTargetFilterData.placePeriodDate;
			const periodType = this.placePeriodType.value;

			if(periodType=='month'){
				const monthDays = dayjs(to).daysInMonth();
				this.detailTargetFilterData.placePeriodDate.to = dayjs(to).date(monthDays).format('YYYY-MM-DD');
			}
		},
		openSearchGid(is){
			try {
				window.localStorage.setItem('open-search-gid-target', is);
			} catch (error) {
				console.error(error);
			}
		},
	},
	created() {
		this.isOpenSearchGid = window?.localStorage?.getItem('open-search-gid-target')=='true';
	},
}