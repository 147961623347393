import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "map-marker-search__body" }
const _hoisted_2 = { class: "map-marker-search__icon" }
const _hoisted_3 = {
  key: 0,
  class: "map-marker-search__label"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: _ctx.markerSelector
      }, [
        _createElementVNode("div", {
          class: "map-marker-search",
          onMouseenter: _cache[0] || (_cache[0] = (...args) => (_ctx.mouseEnter && _ctx.mouseEnter(...args))),
          onMouseleave: _cache[1] || (_cache[1] = (...args) => (_ctx.mouseLeave && _ctx.mouseLeave(...args)))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_SvgIcon, { name: "marker-empty-color" })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_SvgIcon, { name: "24-map-direct" })
          ]),
          _createVNode(_Transition, {
            name: "el-fade-in",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.isHover)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.addressLabel), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ], 32 /* NEED_HYDRATION */)
      ], 8 /* PROPS */, ["to"]))
    : _createCommentVNode("v-if", true)
}