import dayjs from 'dayjs';

import helper from '@/app/utils/helper';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import DiEditInput from '@/app/components/controls/edit-field/di-edit-input/DiEditInput.vue';
import DiEditSelect from '@/app/components/controls/edit-field/di-edit-select/DiEditSelect.vue';
import PoEditMonthRange from '@/app/components/controls/edit-field/po-edit-month-range/PoEditMonthRange.vue';

export default {
	name: 'edit-field',
	components: {
		SvgIcon, DiEditInput, DiEditSelect, PoEditMonthRange,
	},
	props: {
		label: String,
		titleDialog: String,
		labelActiveButton: String,
		editType: {
			type: String,
			default: 'input', // 'input', 'select', 'multi-select', 'month-range'
		},
		fieldData: Object,
	},
	emits: ['change'],
	data() {
		return {
			isVisible: false,
		}
	},
	computed: {
		fieldLabel(){
			let label = this.label;
			const { value, options } = this.fieldData || {};

			if(value && options?.length){
				if(this.editType=='select'){
					const findItem = options.find(item=>item.value==value);
					if(findItem) return helper.upFirstLetter(findItem.label);
				}
				else if(this.editType=='multi-select' && value?.length && Array.isArray(value)){
					const tmpArr = options.filter(item=>value.includes(item.value));
					return tmpArr.map(item=>item.label);
				}
			}

			return label;
		},
		labelMonthRange(){
			let label = this.label;
			const { from, to } = this.fieldData || {};

			if(from || to){
				const formatFrom = dayjs(from).format(`MMMM'YY`);
				const formatTo = dayjs(to).format(`MMMM'YY`);

				if(formatFrom == formatTo) return formatFrom;
				label = `${formatFrom} - ${formatTo}`;
			}

			return label;
		}
	},
	methods: {
		clickEdit(){
			// console.log('clickEdit');
			this.isVisible = true;
		},
		changeEdit(value){
			if(this.editType=='input') this.$emit('change', value);
			else if(['select', 'multi-select'].includes(this.editType)) this.$emit('change', { value, fieldData: this.fieldData });
			else if(this.editType=='month-range' ) this.$emit('change', value);
		}
	},
}