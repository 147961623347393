import cellMixin from "@/app/mixins/table/cell-mixin";
import helper from "@/app/utils/helper";

import ImportCellStatus from "@/app/components/target-programs/target-import/import-cell-types/import-cell-status/ImportCellStatus.vue";

export default {
	name: 'import-cell-price',
	mixins: [cellMixin],
	components: {
		ImportCellStatus,
	},
	computed: {
		label(){
			return `${helper.getMoney(this.fieldData?.value ?? 0)} ₽`;
		},
	},
}