import { h } from 'vue';
import dayjs from 'dayjs';

import { mapActions, mapState, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';
import { useRootStore } from '@/app/stores/root-store';
import { useFiltersStore } from '@/app/stores/filters-store';

import { ElPopover, ElButton, ElTable, ElTableColumn, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/table/style/css';


import { colConfigList } from "./table-config";
import helper from "@/app/utils/helper";

import TprogHeadDefault from '@/app/components/target-programs/tprog-detail-table/header-cells/tprog-head-default/TprogHeadDefault.vue'
import TprogHeadDesign from '@/app/components/target-programs/tprog-detail-table/header-cells/tprog-head-design/TprogHeadDesign.vue';
import TprogDesignInfo from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-design-info/TprogDesignInfo.vue';
// import GridCellPhoto from '@/app/components/grid/grid-cell-types/grid-cell-photo/GridCellPhoto.vue';
import TprogCellPhoto from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-cell-photo/TprogCellPhoto.vue';
import TprogEmployInfo from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-employ-info/TprogEmployInfo.vue';
import TprogCellIsVat from '@/app/components/target-programs/tprog-all-table/tprog-cell-types/tprog-cell-is-vat/TprogCellIsVat.vue';
import TprogCellStatus from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-cell-status/TprogCellStatus.vue';
import TprogPlacePeriods from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-place-periods/TprogPlacePeriods.vue';
import TprogPeriodMonthDays from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-period-month-days/TprogPeriodMonthDays.vue';
import TprogCreatedAt from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-created-at/Tprog-CreatedAt.vue'
import TprogDeadlineDate from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-deadline-date/TprogDeadlineDate.vue';
import TprogCellDocuments from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-cell-documents/TprogCellDocuments.vue';
import TprogCellPerson from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-cell-person/TprogCellPerson.vue';
import TprogCellBrand from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-cell-brand/TprogCellBrand.vue';
import TprogCellManager from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-cell-manager/TprogCellManager.vue';
import TprogCellPlaceType from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-cell-place-type/TprogCellPlaceType.vue';
import TprogCategoryCustomer from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-category-customer/TprogCategoryCustomer.vue';
import TprogCellPromotion from './tprog-cell-types/tprog-cell-promotion/TprogCellPromotion.vue';
import TprogCellPercent from '@/app/components/target-programs/tprog-all-table/tprog-cell-types/tprog-cell-percent/TprogCellPercent.vue';
import CellPrice from '@/app/components/table/cell-types/cell-price/CellPrice.vue';
import CellEditable from '@/app/components/table/cell-types/cell-editable/CellEditable.vue';
import CellEditLink from '@/app/components/table/cell-types/cell-edit-link/CellEditLink.vue';
import CellCoefficient from '@/app/components/table/cell-types/cell-coefficient/CellCoefficient.vue';
import TprogCheckPrice from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-check-price/TprogCheckPrice.vue';
import TprogCellFreq from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-cell-freq/TprogCellFreq.vue';
import CellNumber from '@/app/components/table/cell-types/cell-number/CellNumber.vue';
import TprogEditCountPrice from '@/app/components/target-programs/tprog-detail-table/tprog-cell-types/tprog-edit-count-price/TprogEditCountPrice.vue';

import FooterCellDefault from '@/app/components/target-programs/tprog-detail-table/footer-cells/footer-cell-default/FooterCellDefault.vue';
import FooterCellStatus from '@/app/components/target-programs/tprog-detail-table/footer-cells/footer-cell-status/FooterCellStatus.vue';
import FooterCellDeadline from '@/app/components/target-programs/tprog-detail-table/footer-cells/footer-cell-deadline/FooterCellDeadline.vue';


export default {
	name: 'tprog-detail-table',
	components: {
		ElTable, ElTableColumn, ElPopover, ElButton,
		TprogHeadDefault, TprogHeadDesign,
		TprogCellPercent, CellPrice, CellNumber, CellEditable, CellCoefficient,
		CellEditLink, TprogCheckPrice,
		TprogDesignInfo, TprogCellPhoto,
		TprogEmployInfo, TprogCellIsVat,
		TprogCellStatus, TprogPlacePeriods, TprogCategoryCustomer,
		TprogCellPromotion,
		TprogPeriodMonthDays, TprogCreatedAt, TprogDeadlineDate, TprogCellDocuments,
		TprogCellBrand, TprogCellManager, TprogCellPlaceType,
		TprogCellPerson, TprogCellFreq, TprogEditCountPrice,
	},
	emits: ['selection-change'],
	data() {
		return {
			colConfigList,
			isDesignInfoCompact: false,
			coordinationData: [],
			popover: {
				// Здесь можно указать параметры поповера, если нужно
			}
		}
	},
	computed: {
		...mapState(useRootStore, ['isDevServer', 'isShowSecondAside']),
		...mapState(useDetailTargetProgStore, ['detailTargetOptionsData', 'managerList', 'statusList', 'vatOptions' ]),
		...mapWritableState(useDetailTargetProgStore, ['detailMonthSelectData', 'detailTargetGridData']),

		countRow(){
			return this.detailTargetGridData?.length ?? 0;
		},
		allColList(){
			const allColList = [];
			for( const item of colConfigList ){
				if(item?.group?.length) allColList.push(...item.group);
				else allColList.push(item);
			}
			return allColList;
		},
		colConfigMap(){
			const data = {
				key: {},
				prop: {},
			}

			for(const item of this.allColList){
				const { key, keyProp } = item;
				if(key) data.key[key] = item;
				if(keyProp) data.prop[keyProp] = item;
			}

			return data;
		},
		cityMap(){
			const map = {};
			const list = this.getOptions('city');

			for( const item of list){
				map[item.value] = item;
			}

			return map;
		}
	},
	methods: {
		...mapActions(useDetailTargetProgStore, ['changeFieldOrderMonth', 'changeAdditionalServiceData', 'updateDetailTargetRowData',
			'changeFieldComment', 'changeStatusUntil', 'getDetailTargetGridData', 'setDetailSortData', 'setCoordinationData', 'getCoordinationData'
		]),
		...mapActions(useFiltersStore, ['getOptions']),

		getMoney: helper.getMoney,
		CheckRow(data) {
			const rowClass = []
			const discountIsNeedToCoordination = data.row.monthData.allowedDiscount < data.row.monthData.discount
			if(discountIsNeedToCoordination) {
				rowClass.push('coordination-yellow')
				rowClass.push('coordination-yellow-border')
			}

			return rowClass
		},
		getPlacePeriodClass(column, col, index) {
			const classList = ['coordination-cell'];
			// console.log(column, col, index);

			if(index === 0) classList.push('coordination-cell-first');
			if(index === (col.group.length - 1)) classList.push('coordination-cell-last');

			if(column?.className) classList.push(column.className);

			return classList.join(' ');
		},
		hasCoordinationComment(scope) {
			const discountIsNeedToCoordination = scope.row.monthData.allowedDiscount < scope.row.monthData.discount
			return discountIsNeedToCoordination ? true : false;
		},
		getCellClass(col){
			const classList = [];
			if(col?.className) classList.push(col.className);
			if(col.key=='designInfo') classList.push('td-design-info');

			return classList.join(' ');
		},
		getPriceField(key){
			return [
				'totalPricePlacementInstall', 'totalAgentDiscount', 'totalClientDiscount',
				'priceMonthNotVAT', 'priceMonthIncVAT', 'pricePeriodAllNotVAT', 'pricePeriodAllIncVAT',
				'totalPricePlacementNotVAT', 'totalPricePlacementIncVAT', 'totalAgentDiscount', 'totalClientDiscount',
				'avgPriceMonthPlace', 'avgPriceMonthInstall', 'avgPriceMonthPrint',
				'pricePeriodOneNotVat', 'pricePeriodOneVat', 'pricePlacePeriodNotVAT', 'pricePlacePeriodIncVAT',
				'agentCommission', 'clientCommission',

				// доп. услуги
				'totalPriceAddService',
				'pricePlaceAddServiceNotVAT', 'pricePlaceAddServiceIncVAT',
			].includes(key);
		},
		getEditCountPriceField(key){
			return ['install', 'addInstall', 'print', 'speedPrint', 'design', 'photoReport' ].includes(key);
		},
		getAvgField(key){
			return ['avgPriceMonthPlace', 'avgPriceMonthInstall', 'avgPriceMonthPrint'].includes(key);
		},
		getPercentField(key){
			return ['averageDiscount', 'avgEtherShare', 'etherShare', 'pricePlacePeriodDiscount'].includes(key);
		},
		getPersonField(key){
			return ['clientPerson', 'rimPerson', 'printPerson', 'installPerson', 'designPerson',
				'photoReportPerson', 'operator',
			].includes(key);
		},
		getEditLinkField(key){
			return [ 'dealLink', 'taskLink', 'photoReportLink' ].includes(key);
		},

		scrollToStatus(){
			const { refCellStatus } = this.$refs;
			const elCell = refCellStatus[0];
			const elRow = elCell.closest('tr');
			const elScrollWrap = this.$el.querySelector('.el-scrollbar__wrap');
			const left = helper.getDistanceFromParentLeft(elRow, elCell);
			let paddingLeft = 650;
			if(this.isShowSecondAside) paddingLeft = 1190;
			if(elScrollWrap) elScrollWrap.scrollLeft = left - paddingLeft;
			// console.log(left);
		},
		changeCompactHeadDesign(is){
			const findCol = this.colConfigList.find(item=>item.key=='designInfo');

			this.isDesignInfoCompact = is;
			if(!findCol){
				this.isDesignInfoCompact = false;
				return;
			}

			findCol.width = is ? 290 : 550;
		},
		clickEmployMonth(date){
			// console.log(date);
			const findMonth = this.detailMonthSelectData.options.find(item=>item.value==date);

			if(findMonth){
				this.detailMonthSelectData.value = date;
				this.scrollToStatus();
			}
		},
		clickItemPeriod(item){
			// console.log(item);
			const { from } = item;
			const monthDate = dayjs(from).format('YYYY-MM');
			const findMonth = this.detailMonthSelectData.options.find(item=>item.value==monthDate);

			if(findMonth){
				this.detailMonthSelectData.value = monthDate;
				this.scrollToStatus();
			}
		},
		selectionChange(list){
			console.log(list);
			this.$emit('selection-change', list);
		},

		updateFieldState({ action, data, sucMessage, errAction, errMessage }={}){
			if(data?.success){
				if(action) action();
				ElMessage({
					message: sucMessage ?? 'Данные успешно изменены',
					type: 'success',
				});
			} else {
				if(data?.message) errMessage = data.message;
				if(errAction) errAction();

				ElMessageBox.alert(null, null, {
					type: 'warning',
					title: 'Внимание!',
					message: errMessage ?? 'Что-то пошло не так...',
					customClass: 'bs-message-box',
				}).then(() => { }).catch(() => { });
			}
		},

		async changeEditableCell({ rowData, groupKey, fieldKey, fieldData }){
			const { key } = this.colConfigMap.prop?.[fieldKey] ?? {};
			const orderId = rowData?.orderData?.id;
			const orderMonthId = rowData?.monthData?.id;
			let res = null;

			helper.debounce({
				keyTimer: 'changeEditableCell',
				action: async ()=>{
					if(['install', 'addInstall', 'print', 'speedPrint', 'design', 'photoReport'].includes(key)){
						res = await this.changeAdditionalServiceData({orderId, orderMonthId, key, fieldData});
					} else {
						res = await this.changeFieldOrderMonth({orderId, orderMonthId, key, fieldData});
					}

					// console.log({ groupKey, fieldKey, fieldData });
					this.updateFieldState({ data: res, action: ()=>{
						this.updateDetailTargetRowData({ rowData, data: res?.data });

						// if(rowData?.[groupKey]?.[fieldKey] !== undefined) rowData[groupKey][fieldKey] = fieldData;
					} });

					// Получаем массив на согласовании скидок
					if(['coefficient', 'pricePlacePeriodDiscount'].includes(key)) this.getCoordinationData();
				}
			});
		},
		async changeDefaultCell({ rowData, groupKey, fieldKey, fieldData }){
			const { key } = this.colConfigMap.prop?.[fieldKey] ?? {};
			const orderId = rowData?.orderData?.id;
			const orderMonthId = rowData?.monthData?.id;

			console.log({ rowData, groupKey, fieldKey, fieldData });

			// if(rowData?.[groupKey]?.[fieldKey] !== undefined){
			// 	// console.log(key);
			// 	if(key=='status') rowData[groupKey][fieldKey]['status'] = fieldData;
			// 	else rowData[groupKey][fieldKey] = fieldData;
			// }

			const res = await this.changeFieldOrderMonth({orderId, orderMonthId, key, fieldData});
			this.updateFieldState({ data: res, action: ()=>{
				this.updateDetailTargetRowData({ rowData, data: res?.data });
			} });
		},

		async changeComment({ rowData, comment }){
			const orderId = rowData?.orderData?.id;
			//changeFieldComment
			console.log({ rowData, comment });

			const res = await this.changeFieldComment({ orderId, comment });
			this.updateFieldState({ data: res, action: ()=>{
				rowData.orderData['comment'] = comment;
			} });
		},

		async extendStatusReserve({ rowData, date }){
			const orderId = rowData?.orderData?.id;
			const orderMonthId = rowData?.monthData?.id;

			const res = await this.changeStatusUntil({ orderId, orderMonthId, date });
			this.updateFieldState({ data: res, action: ()=>{
				this.updateDetailTargetRowData({ rowData, data: res?.data });
			} });
		},

		/** Построение ячеек футера таблицы */
		getSummaries(param) {
			// console.log(param);
			const { columns, data } = param;
			const sums = [];
			const getSum = (values)=>{
				return values.reduce((prev, curr) => {
					const value = Number(curr)
					if (!Number.isNaN(value)) {
						return Number(prev) + Number(curr);
					} else {
						return Number(prev);
					}
				}, 0);
			}

			if(columns?.length) for(let [index, col] of Object.entries(columns) ){
				// console.log(col);
				const keyCol = col.property;
				const { keyProp, keyGroup } = this.colConfigMap.key?.[keyCol] ?? {};
				const values = data.map((item) => item?.[keyGroup]?.[keyProp] ?? '');
				// console.log(keyCol, values);

				if( index == 0 && false ) {
					sums[index] = '';
				}
				else if(keyCol == 'designInfo') sums[index] = h( FooterCellDefault, {
					label: 'Количество:',
					value: this.countRow,
				});
				else if(keyCol == 'deadlineDate') sums[index] = h( FooterCellDeadline, {
					dates: values,
				});
				// Денежные поля
				else if(this.getPriceField(keyCol) && !values.every((value) => Number.isNaN(value))) {
					const sum = getSum(values);

					if(this.getAvgField(keyCol)) sums[index] = h( FooterCellDefault, {
						label: 'Средняя:',
						value: `${this.getMoney(sum / this.countRow)} ₽`,
						className: 'align-center',
					});
					else sums[index] = h( FooterCellDefault, {
						label: 'Сумма:',
						value: `${this.getMoney(sum)} ₽`,
						className: 'align-center',
					});
				}
				// Процентные поля
				else if(['etherShare', 'pricePlacePeriodDiscount'].includes(keyCol)) {
					sums[index] = '';
					if(!values.every((value) => Number.isNaN(value))){
						const sum = getSum(values);
						sums[index] = h( FooterCellDefault, {
							label: 'Средняя:',
							value: `${helper.getFormatNum(sum / this.countRow)}%`,
							className: 'align-center',
						});
					}
				}
				// Статус
				else if(keyCol == 'status') {
					const data = [];
					const countStatus = {
						free: 0,
						reserved: 0,
						confirm: 0,
						busy: 0,
					};

					if(values?.length) for(const item of values){
						const { status, my } = item;
						if(status == 'free') countStatus.free++;
						else if(status == 'reserved') countStatus.reserved++;
						else if(!my && status=='approved') countStatus.busy++;
						else if(status=='approved') countStatus.confirm++;
					}

					sums[index] = h( FooterCellStatus, {
						countStatus,
						className: 'align-center direct-row',
					});
				}
				else {
					sums[index] = '';
				}
			}

			return sums;
		},

		sortTable(order, key){
			console.log({order, key});
			const tableData = [...this.detailTargetGridData];
			/* const { keyProp, keyGroup } = this.colConfigMap.key?.[key] ?? {};

			for(let item of this.allColList){
				if(item.key == key) continue;
				item.order = '';
			}

			if(key=='deadlineDate') {
				const date = (rowDate)=>dayjs(rowDate?.[keyGroup]?.[keyProp]).toDate();

				if(order=='asc') tableData.sort((a,b)=> date(a) - date(b));
				else if(order=='desc') tableData.sort((a,b)=>date(b) - date(a));
			}
			else if([ 'sideNumber', ].includes(key)){
				const data = (rowData)=>rowData?.[keyGroup]?.[keyProp]?.toLowerCase() ?? '';

				if(order=='asc') tableData.sort((a,b)=>data(a).localeCompare(data(b)));
				else if(order=='desc') tableData.sort((a,b)=>data(b).localeCompare(data(a)));
			}
			else {
				if(order=='asc') tableData.sort((a,b)=>a?.[keyGroup]?.[keyProp] - b?.[keyGroup]?.[keyProp]);
				else if(order=='desc') tableData.sort((a,b)=>b?.[keyGroup]?.[keyProp] - a?.[keyGroup]?.[keyProp]);
			}

			if(!order){
				const date = (rowDate)=>dayjs(rowDate?.['orderData']?.['created_at']).toDate();
				tableData.sort((a,b)=>date(a) - date(b));
			} */
			let sort = {
				[key]: order
			}
			const { params, query } = this.$route;

			let updatedParams = {
				...query,
				sort: JSON.stringify(sort),
			};

			if(!order || !order.length) {
				sort = null
				delete updatedParams.sort
			}
			this.$router.push({ query: updatedParams });
			this.setDetailSortData(sort)
			this.getDetailTargetGridData({id: params?.id, sort: sort})

			// this.detailTargetGridData = tableData;
		},
	},

	async created() {
		// console.log(this.colConfigList);
	},
}