import { toRaw } from 'vue';
import { ElPopover } from 'element-plus';
import 'element-plus/es/components/popover/style/css';

import helper from '@/app/utils/helper';
import common from '@/app/utils/common';

import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';

export default {
	name: 'map-search-panel',
	components: {
		ElPopover,
		BtnAction, BsInput,
	},
	props: {
		duration: {
			type: Number,
			default: 500,
		}
	},
	emits: ['onSearch', 'change'],
	data() {
		return {
			strSearch: '',
			ymaps: null,
			suggestData: [],
			width: '',
			isVisibleSuggestWrap: false,
		}
	},
	methods: {
		async onSearch(){
			if(!this.strSearch) return;

			const ymaps = toRaw(this.ymaps);
			const geocode = await ymaps.geocode(this.strSearch);
			const coords = geocode.geoObjects.get(0).geometry.getCoordinates();

			// console.log('onSearch', this.strSearch, geocode, coords);
			this.$emit('onSearch', {
				geocode, coords,
				strSearch: this.strSearch,
			});
		},
		onChange(){
			// console.log('onChange', this.strSearch);
			this.$emit('change', this.strSearch);
		},
		async getSuggestData(str){
			const ymaps = toRaw(this.ymaps);
			const res = await ymaps.suggest(str);
			console.log(res);
			this.suggestData = res.map(item=>{
				return {
					id: helper.hash,
					...item,
				}
			});

			return res;
		},
		clickSuggestItem(item){
			this.strSearch = item.value;
			this.isVisibleSuggestWrap = false;

			this.onSearch();
		},
	},
	async mounted() {
		this.width = this.$el.offsetWidth;
		this.ymaps = await common.ymaps;

		$(window).off('map_search_panel:clear').on('map_search_panel:clear', ()=>{
			this.strSearch = '';
		})
	},
	watch: {
		strSearch(val){
			if(this.suggestData?.length){
				const findItem = this.suggestData.find(item=>item.value==val);
				if(findItem) return;
			}

			if(val.trim()?.length > 2) helper.debounce({
				keyTimer: 'map-search-panel: change',
				duration: this.duration,
				action: async ()=>{
					const res = await this.getSuggestData(val);
					if(res?.length) this.isVisibleSuggestWrap = true;

					this.onChange();
				}
			})
			else {
				this.isVisibleSuggestWrap = false;
				this.onChange();
			}
		},
	},
}