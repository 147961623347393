import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';

export default {
	name: 'search-panel',
	components: {
		BtnAction, BsInput,
	},
	props: {
		placeholder: {
			type: String,
			default: 'Найти',
		}
	},
	emits: ['onSearch', 'onChange'],
	data() {
		return {
			strSearch: '',
			isFocus: false,
		}
	},
	methods: {
		async onSearch(){
			if(!this.strSearch) return;

			this.$emit('onSearch', this.strSearch.trim());
		},
	},
	watch: {
		strSearch(val){
			if(val.trim()?.length > 2) this.$emit('onChange', val);
		},
	},
}