export const colConfigList = [
	{
		key: 'id',
		keyId: 'id',
		label: 'ID',
		order: '',
		sort: true,
		width: 80,
	},
	{
		key: 'createdDate',
		keyId: 'created_at',
		label: 'Создана',
		order: '',
		sort: true,
		width: 150,
	},
	{
		key: 'updatedDate',
		keyId: 'updated_at',
		label: 'Обновлена',
		order: '',
		sort: true,
		width: 150,
	},
	{
		key: 'title',
		keyId: 'name',
		label: 'Название',
		order: '',
		sort: true,
		width: 250,
	},
	{
		key: 'structuresCount',
		keyId: 'structuresCount',
		label: 'Кол-во РК',
		order: '',
		sort: true,
		width: 120,
	},
	{
		key: 'sidesCount',
		keyId: 'sidesCount',
		label: 'Кол-во РC',
		order: '',
		sort: true,
		width: 120,
	},
	{
		key: 'responsible',
		keyId: 'manager',
		label: 'Ответственный менеджер',
		order: '',
		sort: true,
		width: 160,
	},
	{
		key: 'city',
		keyId: 'cities',
		label: 'Города',
		order: '',
		sort: true,
		width: 250,
	},
	{
		key: 'period',
		keyId: 'period',
		label: 'Период',
		order: '',
		sort: false,
		width: 220,
	},
	{
		key: 'brand',
		keyId: 'brand',
		label: 'Бренд',
		order: '',
		sort: true,
		width: 160,
	},
	{
		key: 'client',
		keyId: 'customer',
		label: 'Клиент',
		order: '',
		sort: true,
		width: 160,
	},
	{
		key: 'status',
		keyId: 'status',
		label: 'Статус',
		order: '',
		sort: false,
		width: 130,
	},
	{
		key: 'priceNotVAT',
		keyId: 'cost',
		label: 'Сумма (без НДС)',
		order: '',
		sort: true,
		width: 170,
	},
	// {
	// 	key: 'notification',
	// 	label: 'Оповещения',
	// 	order: '',
	// 	sort: false,
	// 	width: 50,
	// },
	// {
	// 	key: 'other',
	// 	label: 'другое',
	// 	order: '',
	// 	sort: false,
	// 	width: 40,
	// },
];