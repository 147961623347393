import { ElRadioGroup, ElRadio } from 'element-plus';
import 'element-plus/es/components/radio-group/style/css';
import 'element-plus/es/components/radio/style/css';

export default {
	name: 'bs-radio-group',
	components: {
		ElRadioGroup, ElRadio,
	},
	props:{
		modelValue: [String, Number],
		options: Array,
	},
	emits: ['update:modelValue'],
	data() {
		return {
			// value: this.modelValue,
		}
	},
	computed:{
		value: {
			get(){
				return this.modelValue;
			},
			set(val){
				this.$emit('update:modelValue', val);
			}
		}
	},
	watch: {
		// value(val){
		// 	// console.log(val);
		// 	this.$emit('update:modelValue', val);
		// },
	},
}