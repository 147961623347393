import { ElPopover } from 'element-plus';
import 'element-plus/es/components/popover/style/css';

import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';

export default {
	name: 'tprog-comment-popover',
	components: {
		ElPopover,
		BsInput, BsBtn,
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		comment: {
			type: String,
			default: '',
		}
	},
	emits: ['update:visible', 'change'],
	data() {
		return {
			text: this.comment,
		}
	},
	computed: {
		isVisible: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			}
		},
	},
	methods: {
		clickOk(){
			this.isVisible = false;
		}
	},
	watch: {
		isVisible(is){
			if(is){
				this.text = this.comment;
			}

			if(!is){
				if(this.comment !== this.text) this.$emit('change', this.text);
			}
		},
		comment(val){
			this.text = val ?? '';
		}
	},
}