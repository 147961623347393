import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "employ-info-static" }
const _hoisted_2 = {
  class: "employ-info-static__month-list",
  ref: "monthList"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "employ-info-static__surface" }
const _hoisted_5 = { class: "employ-info-static__surface-month-list" }
const _hoisted_6 = {
  key: 0,
  class: "employ-info-static__period"
}
const _hoisted_7 = { class: "employ-info-static__period-from" }
const _hoisted_8 = { class: "employ-info-static__period-to" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["employ-info-static__month-item", { 'type-added': _ctx.isTargetMonthAdded(item), 'type-select': item.date==_ctx.selectedMonth }]),
          key: item.date,
          ref_for: true,
          ref: "monthItem",
          onClick: $event => (_ctx.clickMonth(item.date))
        }, _toDisplayString(item.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_3))
      }), 128 /* KEYED_FRAGMENT */))
    ], 512 /* NEED_PATCH */),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.date,
            class: _normalizeClass(["employ-info-static__surface-month-item", _ctx.getSurfaceMonthClass(_ctx.occupancy, item)])
          }, null, 2 /* CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    (_ctx.periodPosition)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "employ-info-static__period-line",
            style: _normalizeStyle(`width: ${_ctx.periodPosition.width}px; left: ${_ctx.periodPosition.left}px`)
          }, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.compPeriod.format.from), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.compPeriod.format.to), 1 /* TEXT */)
          ], 4 /* STYLE */)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}