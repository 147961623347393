import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';

import dayjs from 'dayjs';

import { ElTag, ElMessage } from "element-plus";
import 'element-plus/es/components/tag/style/css';


import helper from "@/app/utils/helper";

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'side-queue-item',
	components :{
		ElTag, ElMessage,
		SvgIcon,
	},
	props: {
		data: Object,
	},
	data() {
		return {
			statusList: [
				{
					value: 'reserved',
					label: 'Резерв'
				},
				{
					value: 'confirm',
					label: 'Врем. подтверждение'
				},
				{
					value: 'busy',
					label: 'Занято'
				},
				{
					value: 'unavailable',
					label: 'Недоступно'
				},
			],
		}
	},
	/**
	{
		"status": "reserved",
		"userInfo": {
			"name": "Администратор",
			"tgId": null,
			"email": "admin@admin.com",
			"phone": null,
			"lastName": null
		},
		"statusUntil": "2024-05-01 21:09:02",
		"targetProgramId": 1,
		"targetProgramName": "test"
	}
	 */
	computed: {
		...mapState(useRootStore, ['statusMap']),

		tagType(){
			const status = this.data?.status;
			if( ['reserved','approved','temporary_approved'].includes(status)) return 'warning';
			if(['busy', 'unavailable'].includes(status)) return 'danger';

			return 'success';
		},
		statusLabel(){
			const status = this.data?.status;
			let label = this.statusMap?.[status];

			if(!label) label = this.statusMap['free'];

			return label;
		},
		date(){
			if(!this.data?.statusUntil) return;
			return dayjs(this.data.statusUntil).format('DD.MM.YYYY');
		},
		userInfo(){
			return this.data?.userInfo ?? {};
		}
	},
	methods: {
		async clickPhone(){
			const { phone } = this.userInfo;

			try {
				const res = await helper.copyTextBuffer(phone);

				ElMessage({
					type: 'success',
					message: 'Номер телефона успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Номер телефона не удалось скопировать',
				});
			}

			console.log('copyPhone');
		},
		async clickEmail(){
			const { email } = this.userInfo;

			try {
				const res = await helper.copyTextBuffer(email);

				ElMessage({
					type: 'success',
					message: 'Email успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Email не удалось скопировать',
				});
			}

			console.log('copyEmail');
		},
		async clickTelegram(){
			const { tgId } = this.userInfo;
			const link = `https://t.me/${tgId}`;

			try {
				const res = await helper.copyTextBuffer(link);

				ElMessage({
					type: 'success',
					message: 'Ссылка успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Ссылку не удалось скопировать',
				});
			}

			console.log('copyTelegramLink');
		}
	},
}