import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "target-summary" }
const _hoisted_2 = { class: "target-summary__container" }
const _hoisted_3 = { class: "target-summary__form" }
const _hoisted_4 = { class: "target-summary__block block-person" }
const _hoisted_5 = { class: "target-summary__block-container" }
const _hoisted_6 = { class: "control" }
const _hoisted_7 = { class: "control__group group-row gap-24 row-space-between" }
const _hoisted_8 = { class: "control" }
const _hoisted_9 = { class: "control__group" }
const _hoisted_10 = { class: "control" }
const _hoisted_11 = { class: "control__group" }
const _hoisted_12 = { class: "control" }
const _hoisted_13 = { class: "control__group" }
const _hoisted_14 = { class: "control" }
const _hoisted_15 = { class: "control__group group-row gap-24" }
const _hoisted_16 = { class: "control" }
const _hoisted_17 = { class: "control__group" }
const _hoisted_18 = { class: "control" }
const _hoisted_19 = { class: "control__group" }
const _hoisted_20 = { class: "control" }
const _hoisted_21 = { class: "control__group group-row" }
const _hoisted_22 = {
  key: 0,
  class: "target-summary__block block-placement"
}
const _hoisted_23 = { class: "target-summary__block-btn-collapse" }
const _hoisted_24 = { class: "target-summary__block-container" }
const _hoisted_25 = { class: "control__group" }
const _hoisted_26 = { class: "control target-summary__placement-total-price" }
const _hoisted_27 = { class: "control__group group-row gap-16" }
const _hoisted_28 = { class: "target-summary__total" }
const _hoisted_29 = { class: "target-summary__total-value" }
const _hoisted_30 = { class: "target-summary__total" }
const _hoisted_31 = { class: "target-summary__total-value" }
const _hoisted_32 = {
  key: 1,
  class: "target-summary__block block-metrics"
}
const _hoisted_33 = { class: "target-summary__block-container" }
const _hoisted_34 = { class: "control__label target-summary__city-name" }
const _hoisted_35 = { class: "control__group group-row gap-64" }
const _hoisted_36 = { class: "control" }
const _hoisted_37 = { class: "control__value" }
const _hoisted_38 = { class: "control" }
const _hoisted_39 = { class: "control__value" }
const _hoisted_40 = { class: "control" }
const _hoisted_41 = { class: "control__value" }
const _hoisted_42 = { class: "control" }
const _hoisted_43 = { class: "control__group group-row" }
const _hoisted_44 = { class: "control" }
const _hoisted_45 = { class: "control__group group-row" }
const _hoisted_46 = { class: "control" }
const _hoisted_47 = { class: "control__group group-row" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EditField = _resolveComponent("EditField")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_SummaryMonth = _resolveComponent("SummaryMonth")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")
  const _component_TargetManager = _resolveComponent("TargetManager")
  const _component_TargetManagerList = _resolveComponent("TargetManagerList")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "control__label" }, "Клиент", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_EditField, {
                      editType: "select",
                      titleDialog: "Клиент",
                      label: "пусто",
                      fieldData: _ctx.summaryData.client,
                      onChange: _cache[0] || (_cache[0] = $event => (_ctx.changeEditField($event, 'client')))
                    }, null, 8 /* PROPS */, ["fieldData"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "control__label" }, "Бренд", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_EditField, {
                      editType: "select",
                      titleDialog: "Бренд",
                      label: "пусто",
                      fieldData: _ctx.summaryData.brand,
                      onChange: _cache[1] || (_cache[1] = $event => (_ctx.changeEditField($event, 'brand')))
                    }, null, 8 /* PROPS */, ["fieldData"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "control__label" }, "Агентство", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_EditField, {
                      editType: "select",
                      titleDialog: "Агентство",
                      label: "пусто",
                      fieldData: _ctx.summaryData.agency,
                      onChange: _cache[2] || (_cache[2] = $event => (_ctx.changeEditField($event, 'agency')))
                    }, null, 8 /* PROPS */, ["fieldData"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "control__label" }, "Категория клиента", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_EditField, {
                      editType: "select",
                      titleDialog: "Категория клиента",
                      label: "пусто",
                      fieldData: _ctx.summaryData.clientCategory,
                      onChange: _cache[3] || (_cache[3] = $event => (_ctx.changeEditField($event, 'clientCategory')))
                    }, null, 8 /* PROPS */, ["fieldData"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "control__label" }, "Тип размещения", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_EditField, {
                      editType: "select",
                      titleDialog: "Тип размещения",
                      label: "пусто",
                      fieldData: _ctx.summaryData.placementType,
                      onChange: _cache[4] || (_cache[4] = $event => (_ctx.changeEditField($event, 'placementType')))
                    }, null, 8 /* PROPS */, ["fieldData"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "control__label" }, "Период", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_EditField, {
                  editType: "month-range",
                  titleDialog: "Период",
                  label: "пусто",
                  fieldData: _ctx.summaryData.period,
                  onChange: _ctx.changeEditPeriod
                }, null, 8 /* PROPS */, ["fieldData", "onChange"])
              ])
            ])
          ])
        ]),
        (_ctx.placementMonth?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createElementVNode("div", {
                class: _normalizeClass(["target-summary__block-label", { '--is-open': _ctx.isOpenPlacement }]),
                onClick: _cache[5] || (_cache[5] = $event => (_ctx.isOpenPlacement = !_ctx.isOpenPlacement))
              }, [
                _cache[13] || (_cache[13] = _createElementVNode("span", null, "Размещение", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_23, [
                  _createVNode(_component_SvgIcon, { name: "16-arrow-down" })
                ])
              ], 2 /* CLASS */),
              _createVNode(_component_ElCollapseTransition, null, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.placementMonth, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "control",
                        key: item.code
                      }, [
                        _createElementVNode("div", _hoisted_25, [
                          _createVNode(_component_SummaryMonth, { data: item }, null, 8 /* PROPS */, ["data"])
                        ])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ], 512 /* NEED_PATCH */), [
                    [_vShow, _ctx.isOpenPlacement]
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("div", _hoisted_26, [
                _cache[16] || (_cache[16] = _createElementVNode("div", { class: "control__label" }, "Общая стоимость", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "target-summary__total-label" }, "Итого (без НДС)", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.getMoney(_ctx.totalPriceNotVat)) + " ₽", 1 /* TEXT */)
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "target-summary__total-label" }, "Итого (с НДС)", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.getMoney(_ctx.totalPrice)) + " ₽", 1 /* TEXT */)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.cityMetrics?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
              _cache[23] || (_cache[23] = _createElementVNode("div", { class: "target-summary__block-label" }, "Сводка по метрикам", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_33, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityMetrics, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "control",
                    key: item.city
                  }, [
                    _createElementVNode("div", _hoisted_34, _toDisplayString(item.city), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("div", _hoisted_36, [
                        _cache[17] || (_cache[17] = _createElementVNode("div", { class: "control__label" }, "GRP средний", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_37, _toDisplayString(item.grpAvg), 1 /* TEXT */)
                      ]),
                      _createElementVNode("div", _hoisted_38, [
                        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "control__label" }, "GRP суммарный", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_39, _toDisplayString(item.grpSum), 1 /* TEXT */)
                      ]),
                      _createElementVNode("div", _hoisted_40, [
                        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "control__label" }, "OTS суммарный", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_41, _toDisplayString(item.otsSum), 1 /* TEXT */)
                      ])
                    ])
                  ]))
                }), 128 /* KEYED_FRAGMENT */)),
                _createElementVNode("div", _hoisted_42, [
                  _cache[20] || (_cache[20] = _createElementVNode("div", { class: "control__label" }, "Подразделение", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_43, [
                    _createVNode(_component_EditField, {
                      editType: "select",
                      titleDialog: "Подразделение",
                      label: "пусто",
                      fieldData: _ctx.summaryData.department,
                      onChange: _cache[6] || (_cache[6] = $event => (_ctx.changeEditField($event, 'department')))
                    }, null, 8 /* PROPS */, ["fieldData"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_44, [
                  _cache[21] || (_cache[21] = _createElementVNode("div", { class: "control__label" }, "Ответственный менеджер", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_45, [
                    _createVNode(_component_TargetManager, {
                      fieldData: _ctx.summaryData.responsibleManager,
                      titleDialog: "Изменить ответственного",
                      onChange: _ctx.changeEditResponsible
                    }, null, 8 /* PROPS */, ["fieldData", "onChange"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_46, [
                  _cache[22] || (_cache[22] = _createElementVNode("div", { class: "control__label" }, "Менеджеры", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_47, [
                    _createVNode(_component_TargetManagerList, {
                      fieldData: _ctx.summaryData.observerManagers,
                      onChange: _ctx.changeManagerList,
                      onOnRemove: _ctx.removeManager
                    }, null, 8 /* PROPS */, ["fieldData", "onChange", "onOnRemove"])
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}