import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pr-cell-comment" }
const _hoisted_2 = {
  key: 0,
  class: "pr-cell-comment__view-container"
}
const _hoisted_3 = {
  key: 0,
  class: "pr-cell-comment__text"
}
const _hoisted_4 = {
  key: 1,
  class: "pr-cell-comment__empty"
}
const _hoisted_5 = {
  key: 1,
  class: "pr-cell-comment__edit-container"
}
const _hoisted_6 = { class: "pr-cell-comment__textarea" }
const _hoisted_7 = { class: "pr-cell-comment__btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsBtn = _resolveComponent("BsBtn")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "pr-cell-comment__edit-btn",
            title: "Редактировать",
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickEdit && _ctx.clickEdit(...args)))
          }, [
            _createVNode(_component_SvgIcon, { name: "14-edit-pen" })
          ]),
          (_ctx.fieldData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.fieldData), 1 /* TEXT */))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, "Комментария нет"))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_BsInput, {
              modelValue: _ctx.comment,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.comment) = $event)),
              type: "textarea",
              rows: 5
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_BsBtn, {
              size: "small",
              onClick: _ctx.clickCancel
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Отмена")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_BsBtn, {
              type: "primary",
              size: "small",
              disabled: _ctx.fieldData === _ctx.comment,
              onClick: _ctx.clickSave
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Сохранить")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "onClick"])
          ])
        ]))
  ]))
}