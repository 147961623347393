import { mapState, mapActions, mapWritableState } from 'pinia';
import { usePricingDetailStore } from '@/app/stores/pricing-policies/pricing-detail-store';

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';

export default {
	name: 'pricing-filter',
	components: {
		BsBtn, BsSelectV2, BsInput,
	},
	data() {
		return {
			fakeOptions: [
				{
					value: 2,
					label: 'Значение 2',
				},
				{
					value: 3,
					label: 'Значение 3',
				},
				{
					value: 4,
					label: 'Значение 4',
				},
				{
					value: 5,
					label: 'Значение 5',
				},
				{
					value: 6,
					label: 'Значение 6',
				},
			],
		}
	},
	computed: {
		...mapWritableState(usePricingDetailStore, ['filterData']),
	},
	methods: {
		...mapActions(usePricingDetailStore, ['clearFilters']),
		submit(){
			console.log('submit');
		}
	},
}