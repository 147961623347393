import cellMixin from "@/app/mixins/table/cell-mixin";

import SideQueueV2 from '@/app/components/map/map-design-info/side-queue-v2/SideQueueV2.vue';

export default {
	name: 'grid-cell-queue',
	mixins: [ cellMixin ],
	components: {
		SideQueueV2,
	},
	computed: {
		side(){
			return this.rowData?.curSideData;
		},
		calendar(){
			const occupancy = this.side?.queues;
			return occupancy;
		},
		queueData(){
			const checkMonthDate = this.rowData?.checkMonthDate;
			const queue = this.calendar?.[checkMonthDate];
			if(!queue?.queue?.length) return;

			return queue;
		},
		title(){
			return `Очередь (${this.side?.gid})`
		}
	},
	methods: {
		getSideInfo(period) {
			return {
				gid: this.side.gid,
				period,
				serviceStatus: this.side?.serviceStatus,
			}
		},
	},
	created(){
		// console.log(this.rowData);
	}
}