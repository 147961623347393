import { defineStore } from 'pinia';
import dayjs from 'dayjs';

import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';

export const usePricingListStore = defineStore('pricingListStore', {
	state() {
		return {
			isViewLoader: false,
			isAsideLoader: false,

			filterData: {
				title: '',
				status: [],
				type: [],
				department: [],
				responsible: [],
				banOnChange: false,
			},

			tableData: [
				{
					id: 55678,
					status: 'Черновик',
					type: 'По умолчанию',
					title: 'Название АП',
					count: 12,
					createdAt: new Date(),
					updatedAt: new Date(),
					responsible: 'Петров С.К.',
					department: ['Фра', 'ОП Москва', 'ОП Кострома', 'Фра', 'ОП Москва', 'ОП Кострома'],
				},
			],
		}
	},

	getters: {
		countFilters(){
			let count = 0;

			for(const [key, value] of Object.entries(this.filterData)){
				if(Array.isArray(value) && value?.length) count++;
				else if(!Array.isArray(value) && value) count++;
			}

			return count;
		}
	},
	actions: {
		clearFilters(){
			for(const [key, value] of Object.entries(this.filterData)){
				this.filterData[key] = '';
			}
		}
	},
});