import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cell-tags"
}
const _hoisted_2 = { class: "cell-tags__label" }
const _hoisted_3 = { class: "cell-tags__collapse-tag" }
const _hoisted_4 = { class: "cell-tags__list" }
const _hoisted_5 = {
  key: 1,
  class: "cell-tags"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_ctx.fieldData?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1 /* TEXT */),
        (_ctx.collapsedList?.length)
          ? (_openBlock(), _createBlock(_component_ElPopover, {
              key: 0,
              offset: 8,
              persistent: false,
              width: "auto",
              placement: "bottom-end",
              "popper-class": "cell-tags__popover"
            }, {
              reference: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, "+" + _toDisplayString(_ctx.collapsedList?.length), 1 /* TEXT */)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collapsedList, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "cell-tags__item",
                      key: item
                    }, _toDisplayString(item), 1 /* TEXT */))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, "–"))
}