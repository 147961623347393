import { columns } from "./table-config";

import TableGrid from "@/app/components/table-grid/TableGrid.vue";
import CellMassActions from "@/app/components/table/cell-types/cell-mass-actions/CellMassActions.vue";
import PrCellDate from "@/app/components/pricing-policies/type-cell/pr-cell-date/PrCellDate.vue";
import CellTags from "@/app/components/table/cell-types/cell-tags/CellTags.vue";

export default {
	name: 'pricing-list-table',
	components: {
		TableGrid, CellMassActions, PrCellDate,
		CellTags,
	},
	props: {
		tableData: {
			type: Array,
			default: [],
		}
	},
	data() {
		return {
			columns,
			actionList: [
				{
					key: 'status',
					label: 'Сменить статус',
				},
				{
					key: 'depart',
					label: 'Сменить отделы',
				},
				{
					key: 'manager',
					label: 'Сменить менеджера',
				},
				{
					key: 'remove',
					label: 'Удалить',
				},
			],
			selectedRows: [],
		}
	},
	methods: {
		// Массовые действия
		changeMass(key){
			console.log('changeMass', {key});
		},
		// Изменение строки
		changeRow(key, rowData){
			console.log('changeRow', {key, rowData});
		},

		changeSelectedRows(list){
			// console.log('changeSelectedRows', list);
			this.selectedRows = list;
		},
	},
}