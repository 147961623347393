import dayjs from "dayjs";
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import helper from "@/app/utils/helper";

import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';

export default {
	name: 'datepicker-month-period',
	components: {
		BsBtn, BsCheckbox,
	},
	props: {
		startDate: {
			type: String,
			default: '',
		},
		employ: {
			type: Object,
			default: {},
		},
		periods: {
			type: Array,
			default: [],
		},
		selected: {
			type: Boolean,
			default: true,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		mode: {
			type: String,
			default: 'remove', // 'add'
		}
	},
	emits: ['cancel', 'remove', 'add'],
	data() {
		return {
			datepicker: null,
			selectedDates: [],
		}
	},
	computed: {
		firstPeriod(){
			if(this.periods.length){
				return this.periods[0];
			}
			return;
		},
		year(){
			if(!this.firstPeriod) return;
			const { from } = this.firstPeriod;

			return dayjs(from).format('YYYY');
		},
		month(){
			if(!this.firstPeriod) return;
			const { from } = this.firstPeriod;

			const month = dayjs(from).format('MMMM');
			return helper.upFirstLetter(month);
		},
		periodsToDays(){
			const result = [];

			if(this.firstPeriod && this.periods?.length) for(let period of this.periods){
				const { from, to } = period;
				const startDate = new Date(from);
				const endDate = new Date(to);

				let currentDate = startDate;
				while (currentDate <= endDate) {
					result.push(new Date(currentDate));
					currentDate.setDate(currentDate.getDate() + 1);
				}
			}

			// console.log(result);
			return result;
		},
		selectedPeriods(){
			const dates = [...this.selectedDates].sort((a, b) => a - b);
			const result = [];
			let currentPeriod = [dates[0]];

			if(!dates?.length) return result;

			for (let i = 1; i < dates.length; i++) {
				const diffTime = Math.abs(dates[i] - dates[i - 1]);
				const diffDays = diffTime / (1000 * 60 * 60 * 24);

				if (diffDays <= 1) {
					currentPeriod[1] = dates[i]; // Обновляем конечную дату
				} else {
					let startDate = currentPeriod[0];
					let endDate = currentPeriod[1];
					if(endDate===undefined) endDate = startDate;

					result.push([
						dayjs(startDate).format('YYYY-MM-DD'),
						dayjs(endDate).format('YYYY-MM-DD')
					]);
					currentPeriod = [dates[i]];
				}
			}

			let startDate = currentPeriod[0];
			let endDate = currentPeriod[1];
			if(endDate===undefined) endDate = startDate;

			result.push([
				dayjs(startDate).format('YYYY-MM-DD'),
				dayjs(endDate).format('YYYY-MM-DD')
			]);

			return result;
		},
		isAllMonthSelected(){
			const { from } = this.firstPeriod ?? {};
			if(!from) return;

			const countMonthDays = dayjs(from).daysInMonth();
			const firstDay = dayjs(from).date(1).format('YYYY-MM-DD');
			const lastDay = dayjs(from).date(countMonthDays).format('YYYY-MM-DD');
			let is = false;

			if(this.selectedPeriods?.length == 1){
				const [from , to] = this.selectedPeriods[0];
				is = (from==firstDay && to==lastDay);
			}
			console.log('isAllMonthSelected', this.selectedPeriods, {firstDay, lastDay}, is);
			return is;
		}
	},
	methods: {
		initDatepicker(){
			const { datepickerRef } = this.$refs;

			if(this.multiple){
				const { from } = this.firstPeriod ?? {};
				let startDate = from;
				if(!startDate && this.startDate) startDate = this.startDate;

				const countMonthDays = dayjs(startDate).daysInMonth();
				const minDate = dayjs(startDate).date(1);
				const maxDate = dayjs(startDate).date(countMonthDays);

				this.datepicker = new AirDatepicker(datepickerRef, {
					inline: true,
					// range: true,
					multipleDates: true,
					startDate,
					minDate, maxDate,
					selectedDates: this.periodsToDays,

					onRenderCell: (data)=>{
						// console.log(data);
						const { date } = data;
						const countDay = dayjs(date).date();

						return {
							html: `<div class="cell-day">${ countDay }</div>`,
							classes: this.getClassStatus(date),
						};
					},
					onSelect: (data)=>{
						console.log(data);
						const { date } = data;
						this.selectedDates = date.map(item=>{
							const formatDate = dayjs(item).format('YYYY-MM-DD');
							return new Date(formatDate);
						});
					}
				});
			}
			else {
				const { from, to } = this.firstPeriod ?? {};

				this.datepicker = new AirDatepicker(datepickerRef, {
					inline: true,
					range: true,
					// multipleDates: true,
					startDate: from,
					// minDate: from,
					// maxDate: to,
					selectedDates: [from, to],

					onRenderCell: (data)=>{
						// console.log(data);
						const { date } = data;
						const countDay = dayjs(date).date();

						return {
							html: `<div class="cell-day">${ countDay }</div>`,
							// classes: this.getClassStatus(date),
						};
					},
					// onSelect: (data)=>{
					// 	// console.log(data);
					// 	const { date } = data;
					// 	this.selectedDates = date;
					// }
				});
			}
		},
		getClassStatus(date){
			const formatDate = dayjs(date).format('YYYY-MM-DD');
			const dayDate = this?.calendar?.[formatDate];

			if(dayDate?.status) return `type-${dayDate?.status}`;
			return 'type-free';
		},
		cancel(){
			this.$emit('cancel');
		},
		remove(){
			this.$emit('remove');
		},

		createDatesToPeriods(){
			const dates = [...this.selectedDates].sort((a, b) => a - b);
			const result = [];
			let currentPeriod = [dates[0]];

			if(!dates?.length) return result;

			for (let i = 1; i < dates.length; i++) {
				const diffTime = Math.abs(dates[i] - dates[i - 1]);
				const diffDays = diffTime / (1000 * 60 * 60 * 24);

				if (diffDays <= 1) {
					currentPeriod[1] = dates[i]; // Обновляем конечную дату
				} else {
					let startDate = currentPeriod[0];
					let endDate = currentPeriod[1];
					if(endDate===undefined) endDate = startDate;

					result.push([
						dayjs(startDate).format('YYYY-MM-DD'),
						dayjs(endDate).format('YYYY-MM-DD')
					]);
					currentPeriod = [dates[i]];
				}
			}

			let startDate = currentPeriod[0];
			let endDate = currentPeriod[1];
			if(endDate===undefined) endDate = startDate;

			result.push([
				dayjs(startDate).format('YYYY-MM-DD'),
				dayjs(endDate).format('YYYY-MM-DD')
			]);

			return result;
		},
		add(){
			const periods = helper.convertDatesToPeriods(this.selectedDates);
			// console.log(periods);

			this.$emit('add', periods);
			this.cancel();
		},
		mouseLeave(){
			// this.cancel();
		},
		onAllChecked(is){
			// console.log(is);
			if(is){
				let { from } = this.firstPeriod ?? {};
				if(!from && this.startDate) from = this.startDate;
				const countDay = dayjs(from).daysInMonth();
				const minDate = dayjs(from).date(1);
				const selectedDates = [];

				if(is) for(let i=1; i<=countDay; i++){
					let curDay = dayjs(minDate).date(i).toDate();
					selectedDates.push(curDay);
				}

				this.datepicker.update({ selectedDates });
				if(!selectedDates?.length) this.selectedDates = selectedDates;
			} else {
				this.datepicker.update({ selectedDates: this.periodsToDays });
				if(!this.periodsToDays?.length) this.selectedDates = this.periodsToDays;
			}

		}
	},
	mounted() {
		this.initDatepicker();
	},
}