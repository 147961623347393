import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "design-aside" }
const _hoisted_2 = { class: "design-filter__loader" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_DesignFilter = _resolveComponent("DesignFilter")
  const _component_ElTabPane = _resolveComponent("ElTabPane")
  const _component_MapDesignInfo = _resolveComponent("MapDesignInfo")
  const _component_ElTabs = _resolveComponent("ElTabs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "el-fade-in",
      mode: "out-in",
      persisted: ""
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LoaderSpinner)
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.isFilterDataLoad]
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_ElTabs, {
      class: "design-aside__tabs",
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.activeTab) = $event)),
      onTabClick: _cache[1] || (_cache[1] = () => {})
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElTabPane, {
          class: "design-aside__tab-pane",
          label: "Фильтры",
          name: "design-filter"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DesignFilter)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElTabPane, {
          class: "design-aside__tab-pane",
          label: _ctx.designInfoLabel,
          name: "map-design-info"
        }, {
          default: _withCtx(() => [
            (_ctx.activeTab=='map-design-info')
              ? (_openBlock(), _createBlock(_component_MapDesignInfo, { key: 0 }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}