const typeList = [
	{
		key: 'department',
		keyBackOptions: 'department',
		label: 'Отдел пользователя',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'role',
		keyBackOptions: '',
		label: 'Роль пользователя',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'user',
		keyBackOptions: '',
		label: 'Пользователь',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'customer',
		keyBackOptions: 'customer',
		label: 'Клиент',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'customerLegalAddService',
		keyBackOptions: 'customer_legal_entity',
		label: 'Юр. лицо доп. услуг',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'agency',
		keyBackOptions: 'agency',
		label: 'Агентство',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'customerCategory',
		keyBackOptions: 'customer_category',
		label: 'Категория клиента',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'cities',
		keyBackOptions: 'city',
		label: 'Города РС',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'structureType',
		keyBackOptions: 'structureType',
		label: 'Тип РК',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'direction',
		keyBackOptions: 'direction',
		label: 'Сторона',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'size',
		keyBackOptions: 'size',
		label: 'Размер РС',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'mechanism',
		keyBackOptions: 'mechanism',
		label: 'Механизм РС',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'operator',
		keyBackOptions: 'operator',
		label: 'Оператор',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'net',
		keyBackOptions: 'net',
		label: 'Сеть',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'gids',
		keyBackOptions: '',
		label: 'ГИДы',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'fractionEther',
		label: 'Доля эфира',
		type: 'input-number',
		placeholder: 'Введите данные',
	},
	{
		key: 'periodMonth',
		label: 'Период в месяце',
		type: 'input-number',
		placeholder: 'Введите данные',
	},
	{
		key: 'promotion',
		keyBackOptions: 'promo',
		label: 'Акция',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'monthType',
		keyBackOptions: '',
		label: 'Тип месяца',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'incompletePeriod',
		keyBackOptions: '',
		label: 'Неполный период, через середину месяца',
		type: 'single-select',
		placeholder: 'Все',
	},
];

export { typeList };