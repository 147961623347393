import { mapActions, mapState, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';
import { useFiltersStore } from '@/app/stores/filters-store';
import { useMapTargetStore } from '@/app/stores/map-target-store';
import { useTargetImportStore } from '@/app/stores/target-import-store';

import dayjs from 'dayjs';

import helper from '@/app/utils/helper';
import { colConfigList } from '@/app/components/target-programs/tprog-detail-table/table-config';

import { ElBadge, ElButton, ElMessage, ElMessageBox } from 'element-plus';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import ActionPanel from '@/app/components/action-panel/ActionPanel.vue';
import GridPagination from '@/app/components/grid/grid-pagination/GridPagination.vue';
import TableComPanel from '@/app/components/table-com-panel/TableComPanel.vue';
import TargetAgrPanel from '@/app/components/target-programs/target-agreement-panel/TargetAgrPanel.vue';
import TargetSelectMonth from '@/app/components/target-programs/target-select-month/TargetSelectMonth.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

import TprogDetailTable from '@/app/components/target-programs/tprog-detail-table/TprogDetailTable.vue';
import TprogMonthMatrix from '@/app/components/target-programs/tprog-month-matrix/TprogMonthMatrix.vue';
import MapTarget from '@/app/components/map/map-target/MapTarget.vue';
// import MapMarker2 from '@/app/components/map/map-marker-2/MapMarker2.vue';
import MapTargetMarker from '@/app/components/map/map-target-marker/MapTargetMarker.vue';
// import MapCluster from '@/app/components/map/map-cluster/MapCluster.vue';
import MapTargetCluster from '@/app/components/map/map-target-cluster/MapTargetCluster.vue';
import MapSearchPanel from '@/app/components/map/map-search-panel/MapSearchPanel.vue';
import MapMarkerSearch from '@/app/components/map/map-marker-search/MapMarkerSearch.vue';

import TgMassStatus from '@/app/components/target-programs/target-mass-actions/tg-mass-status/TgMassStatus.vue';
import MassExtReserve from '@/app/components/target-programs/target-mass-actions/mass-ext-reserve/MassExtReserve.vue';
import TgMassDiscount from '@/app/components/target-programs/target-mass-actions/tg-mass-discount/TgMassDiscount.vue';
import TgMassPrice from '@/app/components/target-programs/target-mass-actions/tg-mass-price/TgMassPrice.vue';
import TgMassPeriod from '@/app/components/target-programs/target-mass-actions/tg-mass-period/TgMassPeriod.vue';
import TgMassData from '@/app/components/target-programs/target-mass-actions/tg-mass-data/TgMassData.vue';
import TargetImport from "@/app/components/target-programs/target-import/TargetImport.vue"



export default {
	name: 'target-program-item-view',
	components: {
		ElBadge,
		ElButton,
		LoaderSpinner,
		BtnAction, BsBtn, GridPagination, ActionPanel,
		TableComPanel, TargetAgrPanel, TargetSelectMonth,
		TprogDetailTable, TprogMonthMatrix,
		MapTarget, MapTargetMarker, MapTargetCluster, MapSearchPanel, MapMarkerSearch,
		TgMassStatus, MassExtReserve, TgMassDiscount,
		TgMassPrice, TgMassPeriod, TgMassData, SvgIcon, TargetImport
	},
	data() {
		return {
			isVisibleMassStatus: false,
			isVisibleMassExtReserve: false,
			isVisibleMassDiscount: false,
			isVisibleMassPrice: false,
			isVisibleMassPeriod: false,
			isVisibleMassData: false,
			isTargetImportDialog: false,
			// isTargetImportDialog: true,
			isVisibleAgrPanel: false,

			isLoadingExportClient: false,
			isLoadingExportManager: false,

			colConfigList,

			selectedTableRow: [],
			tableCommandList: [
				{
					key: 'change-status',
					label: 'Изменить статус',
				},
				{
					key: 'extend-reserve',
					label: 'Продлить резерв',
				},
				{
					key: 'delete',
					label: 'Удалить',
				},
				{
					key: 'change-discount',
					label: 'Изменить скидку',
				},
				{
					key: 'change-price',
					label: 'Изменить стоимость',
				},
				{
					key: 'change-period',
					label: 'Изменить период',
				},
				{
					key: 'change-data',
					label: 'Изменить данные',
				}
			],

			paginationSizeList: [
				{
					value: '20',
					label: '20',
				},
				{
					value: '30',
					label: '30',
				},
				{
					value: '50',
					label: '50',
				},
				{
					value: '100',
					label: '100',
				},
				{
					value: '150',
					label: '150',
				},
				{
					value: '200',
					label: '200',
				},
			],
		}
	},
	computed: {
		...mapState(useRootStore, ['isDevServer', 'devMode', 'isShowSecondAside']),
		...mapWritableState(useRootStore, ['pageData', 'activeTargetView']),
		...mapState(useDetailTargetProgStore, ['countDetailTargetFilter', 'statusList', 'detailTargetPaginationData', 'coordinationData']),
		...mapWritableState(useDetailTargetProgStore, ['isViewLoader', 'isShowViewMatrix', 'detailMonthSelectData']),
		...mapWritableState(useMapTargetStore, ['clusterList', 'markerList', 'searchMarker', 'checkMapMarkerView']),

		countAgr(){
			return this.coordinationData?.length ?? 0;
		}
	},
	methods: {
		...mapActions(useRootStore, ['setShowSecondAside', ]),
		...mapActions(useDetailTargetProgStore, [
			'getDetailTargetStatsData', 'getDetailTargetFilterData', 'getDetailTargetGridData',
			'getDetailTargetOptionsData', 'getManagerList', 'getPlacementTypeList', 'getStatusList',
			'massChange', 'massOrderDelete', 'setFilterFields', 'getFileData', 'setDetailSortData', 'getCoordinationData'
		]),
		...mapActions(useFiltersStore, ['getBatchFiltersData']),
		...mapActions(useMapTargetStore, ['getMapData']),
		...mapActions(useTargetImportStore, ['importParse']),

		/** Переключение вида отображения */
		async changeViews(data){
			const { params, query } = this.$route;
			const { view } = data;
			this.activeTargetView = view;

			this.$router.push({
				name: 'target-program-item',
				query: {
					...query,
					view,
				}
			});

			if(this.activeTargetView == 'grid'){
				await this.getDetailTargetGridData({ id: params?.id });
			}
			else if(this.activeTargetView == 'map'){
				this.getMapData({ id: params?.id });
			}
		},
		openAsideFilter(){
			this.setShowSecondAside(!this.isShowSecondAside);
		},
		clickBtnMonthMatrix(){
			this.isShowViewMatrix = !this.isShowViewMatrix;
		},

		selectChangeTable(list){
			this.selectedTableRow = list;
		},
		async getStatsData(id){
			const statsData = await this.getDetailTargetStatsData(id);
			const { name } = statsData?.data ?? {};
			this.pageData.title = name;
		},

		changeMonth(value){
			this.detailMonthSelectData.value = value;
		},

		async changePagination(data){
			const { page, sizeChunk } = data;
			const { params } = this.$route;

			const res = await this.getDetailTargetGridData({
				id: params?.id,
				monthDate: this.detailMonthSelectData.value,
				params: {
					page: page,
					per_page: sizeChunk,
				}
			});
			// this.paginationData = data;

			// Очищаем сортировку столбцов
			for(let item of this.colConfigList){
				item.order = '';
			}
		},

		actionCommandPanel(key){
			if(key=='change-status') this.isVisibleMassStatus = true;
			else if(key=='extend-reserve') this.isVisibleMassExtReserve = true;
			else if(key=='change-discount') this.isVisibleMassDiscount = true;
			else if(key=='change-price') this.isVisibleMassPrice = true;
			else if(key=='change-period') this.isVisibleMassPeriod = true;
			else if(key=='change-data') this.isVisibleMassData = true;
			else if(key=='delete') this.massDelete();
		},

		getChangeOrders(months){
			const orders = [];

			for(const item of this.selectedTableRow){
				const { sideData, orderData, monthData } = item;

				if(months?.length) for( const yearMonth of months ){
					orders.push({
						orderId: orderData.id,
						orderMonthId: monthData.id,
						yearMonth,
					});
				} else {
					orders.push({
						orderId: orderData.id,
						orderMonthId: monthData.id,
						yearMonth: this.detailMonthSelectData.value,
					});
				}
			}

			return orders;
		},

		changeOrderState({ action, data, sucMessage, errAction, errMessage }={}){
			if(data){
				// console.log(data);

				if(data?.success){
					if(action) action();
					ElMessage({
						message: sucMessage ?? 'Данные успешно изменены',
						type: 'success',
					});

					const { params } = this.$route;
					this.getDetailTargetGridData({
						id: params?.id,
						monthDate: this.detailMonthSelectData.value,
					});
				} else {
					if(data?.message) errMessage = data.message;
					if(errAction) errAction();

					ElMessageBox.alert(null, null, {
						type: 'warning',
						title: 'Внимание!',
						message: errMessage ?? 'Что-то пошло не так...',
						customClass: 'bs-message-box',
					}).then(() => { }).catch(() => { });
				}

			} else {
				if(errAction) errAction();
				ElMessageBox.alert(null, null, {
					type: 'error',
					title: 'Ошибка!',
					message: errMessage ?? 'Что-то пошло не так...',
					customClass: 'bs-message-box',
				}).then(() => { }).catch(() => { });
			}
		},

		/** Массовое изменение статуса */
		async changeMassStatus({ status, months }){
			const orders = this.getChangeOrders(months);
			const data = { status, orders };

			const res = await this.massChange({ type: 'bulk-change-status', data });
			console.log(res);

			this.changeOrderState({
				data: res,
				action: ()=>{},
				errAction: ()=>{},
			});
		},

		/** Продлить резерв */
		async changeExtReserve({ countDay, months }){
			const orders = this.getChangeOrders(months);
			const data = { statusUntilAddDays: countDay, orders };
			console.log('changeExtReserve', data);

			const res = await this.massChange({ type: 'bulk-add-status-until', data });
			console.log(res);

			this.changeOrderState({
				data: res,
				action: ()=>{},
				errAction: ()=>{},
			});
		},

		/** Массовое изменение скидки */
		async changeMassDiscount({ discount, months }){
			const orders = this.getChangeOrders(months);
			const data = { discount, orders };
			console.log('changeMassDiscount', data);

			const res = await this.massChange({ type: 'bulk-change-discount', data });
			console.log(res);

			this.changeOrderState({
				data: res,
				action: ()=>{},
				errAction: ()=>{},
			});
		},

		/** Массовое изменение стоимости */
		async changeMassPrice({ price, months }){
			const orders = this.getChangeOrders(months);
			const data = { cost: price, orders };
			console.log('changeMassPrice', data);

			const res = await this.massChange({ type: 'bulk-change-cost', data });
			console.log(res);

			this.changeOrderState({
				data: res,
				action: ()=>{},
				errAction: ()=>{},
			});
		},

		/** Массовое изменение периодов */
		async changeMassPeriod(periods){
			const orders = this.getChangeOrders([ this.detailMonthSelectData.value ]);
			const data = { periods, orders };
			console.log('changeMassPeriod', data);

			const res = await this.massChange({ type: 'bulk-change-periods', data });
			console.log(res);

			this.changeOrderState({
				data: res,
				action: ()=>{},
				errAction: ()=>{},
			});
		},

		/** Массовое изменение данных полей */
		async changeMassData({ fields, months }){
			const orders = this.getChangeOrders(months);
			const data = { values: fields, orders };
			console.log('changeMassData', data);

			const res = await this.massChange({ type: 'bulk-change-several-fields', data });
			console.log(res);

			this.changeOrderState({
				data: res,
				action: ()=>{},
				errAction: ()=>{},
			});
		},

		massDelete(){
			const orders = this.getChangeOrders();
			const orderIds = orders.map(item => item.orderId);
			console.log('massDelete', orderIds);

			ElMessageBox.confirm(null, null, {
				type: 'warning',
				title: 'Внимание',
				message: 'Вы действительно хотите удалить выбранные поверхности?',
				customClass: 'bs-message-box',
				confirmButtonText: 'Да',
				cancelButtonText: 'Отмена',
			}).then(async () => {
				const res = await this.massOrderDelete(orderIds);
				console.log(res);

				this.changeOrderState({
					data: res,
					action: ()=>{},
					errAction: ()=>{},
				});
			}).catch(() => { });
		},

		additionMonth(){
			// {"targetProgram":10,"city":[4],"from_to":"2024-09-01;2024-09-30"}
			const { params } = this.$route;
			const targetProgram = Number(params?.id);

			const curMonth = this.detailMonthSelectData.value;
			const countMonthDay = dayjs(curMonth).daysInMonth();
			const from = dayjs(curMonth).date(1).format('YYYY-MM-DD');
			const to = dayjs(curMonth).date(countMonthDay).format('YYYY-MM-DD');

			const filters = JSON.stringify({
				targetProgram,
				city: [],
				from_to: `${from};${to}`
			});

			const { origin } = window.location;
			const link = `${origin}/structures?filters=${encodeURIComponent(filters)}`;

			window.open(link, "_blank" );
		},

		async exportFile(type='client'){
			const { params } = this.$route;
			const targetProgramId = Number(params?.id);
			const targetName = this.pageData.title;
			const todayDate = dayjs().format('YYYY-MM-DD');
			const filename = `export-${type}-${targetName}-${todayDate}`;

			let data = {
				path: `target-program/${targetProgramId}/export-customer-excel`,
				filename,
			}

			if(type=='manager') data = {
				path: `target-program/${targetProgramId}/export-manager-excel`,
				filename,
			}

			type=='client'? this.isLoadingExportClient = true : this.isLoadingExportManager = true;
			try {
				const res = await this.getFileData(data);
				console.log('exportFile', res);

				ElMessage({
					message: 'Экспорт завершился успешно',
					type: 'success',
				});
			} catch (error) {
				ElMessageBox.alert(null, null, {
					type: 'error',
					title: 'Ошибка!',
					message: 'Что-то пошло не так...',
					customClass: 'bs-message-box',
				}).then(() => { }).catch(() => { });
			}
			type=='client'? this.isLoadingExportClient = false : this.isLoadingExportManager = false;
		},

		clickImport(){
			const { refTargetImport } = this.$refs;
			refTargetImport.click();
		},
		async changeImportFile(e){
			const { params } = this.$route;
			const { refTargetImport } = this.$refs;
			const { files } = refTargetImport ?? {};
			console.log(files);
			if(!files?.length) return;

			const file = files[0];
			const targetProgramId = Number(params?.id);
			const yearMonth = this.detailMonthSelectData.value;

			this.isViewLoader = true;
			const res = await this.importParse({ targetProgramId, yearMonth, file });
			if(res) this.isTargetImportDialog = true;
			refTargetImport['value'] = '';
			this.isViewLoader = false;
		},
		doneImport(){
			const { params } = this.$route;

			if(this.activeTargetView == 'grid'){
				this.getDetailTargetGridData({ id: params?.id });
			}
			else if(this.activeTargetView == 'map'){
				this.getMapData({ id: params?.id });
			}
		},

		onSearchAddress(data){
			console.log(data);
			const { geocode, coords, strSearch } = data;
			$(window).trigger('set-center:map', { type:'search', coords, strSearch });
		},

		onChangeMapSearch(query){
			if(!query) this.searchMarker = null;
		},

		clickMapMarkerView(){
			this.checkMapMarkerView = !this.checkMapMarkerView;
		},
	},

	watch: {
		['detailMonthSelectData.value'](valNew, valOld){
			// console.log(valNew, valOld);
			const { params } = this.$route;
			if(valOld && valNew!=valOld) this.getDetailTargetGridData({ id: params?.id, monthDate: valNew });
		},
		async isShowViewMatrix(){
			this.isViewLoader = true;
			await helper.pause(500);
			this.isViewLoader = false;
		},
	},
	mounted(){
	},
	async created() {
		const { params, query } = this.$route;
		console.log(this.$route);

		if(query?.view){
			if(query.view=='map') this.activeTargetView = 'map';
			else this.activeTargetView = 'grid';
		}

		await this.getBatchFiltersData();
		await Promise.allSettled([
			// this.getDetailTargetFilterData(),
			// this.getDetailTargetOptionsData(),
			this.getManagerList(params?.id),
			this.getStatusList(),
			// this.getPlacementTypeList(),
		]);

		if(query?.filters){
			let filters = null;

			try {
				filters = JSON.parse(query.filters);
			} catch (error) {
				console.error(error);
			}

			if(filters) this.setFilterFields(filters);
		}

		if(query?.sort){
			let sort = null;

			try {
				sort = JSON.parse(query.sort);
			} catch (error) {
				console.error(error);
			}

			if(sort) this.setDetailSortData(sort);
		}
		this.getStatsData(params?.id);

		if(this.activeTargetView == 'grid'){
			this.getDetailTargetGridData({ id: params?.id });
		}
		else if(this.activeTargetView == 'map'){
			this.getMapData({ id: params?.id });
		}
	},

	unmounted() {
		this.pageData.title = '';
	},

}