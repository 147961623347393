import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "di-edit-textarea__content" }
const _hoisted_2 = { class: "di-edit-textarea__control" }
const _hoisted_3 = { class: "di-edit-textarea__btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "di-edit-textarea",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.isVisible) = $event)),
    title: _ctx.titleDialog,
    "destroy-on-close": true,
    width: "480",
    "align-center": "",
    onClosed: _ctx.clickCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BsInput, {
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.value) = $event)),
            rows: 4,
            type: "textarea"
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BsBtn, {
            type: "primary",
            disabled: _ctx.isBtnEditDisabled,
            size: "small",
            onClick: _ctx.clickEdit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.labelActiveButton), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "onClick"]),
          _createVNode(_component_BsBtn, {
            size: "small",
            onClick: _ctx.clickCancel
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Отмена")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "onClosed"]))
}