import { mapState, mapActions, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import { ElMessageBox, ElMessage } from 'element-plus';

import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';

export default {
	name: 'target-persons',
	components: {
		BsSelectV2,
	},
	data() {
		return {

		}
	},
	computed: {
		...mapWritableState(useDetailTargetProgStore, ['detailTargetStatsData']),

		personData(){
			return this.detailTargetStatsData?.personData;
		}
	},
	methods: {
		...mapActions(useDetailTargetProgStore, ['updateDetailTargetAsideItem']),

		updateFieldState({ action, data }={}){
			if(data){
				if(action) action();
				ElMessage({
					message: 'Данные успешно изменены.',
					type: 'success',
				});
			} else {
				ElMessageBox.alert(null, null, {
					type: 'error',
					title: 'Ошибка!',
					message: `Что-то пошло не так...`,
					customClass: 'bs-message-box',
				}).then(() => { }).catch(() => { });
			}
		},

		async changeEditField(value, key){
			if(!value) return;
			// console.log(value, fieldData);
			const res = await this.updateDetailTargetAsideItem({ fieldKey: key, value });
			this.updateFieldState({ data: res, action: ()=>{
				this.personData[key].value = value;
			}});
		},
	},
}