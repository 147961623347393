import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "side-queue-item" }
const _hoisted_2 = {
  key: 0,
  class: "side-queue-item__date"
}
const _hoisted_3 = { class: "side-queue-item__target-id" }
const _hoisted_4 = { class: "side-queue-item__user-name" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.date)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.date), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, "[" + _toDisplayString(_ctx.data.targetProgramId) + "]", 1 /* TEXT */),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.userInfo.name), 1 /* TEXT */),
    (_ctx.userInfo.phone)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "side-queue-item__phone",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickPhone && _ctx.clickPhone(...args))),
          title: "Копировать номер телефона"
        }, [
          _createVNode(_component_SvgIcon, { name: "16-gg-phone" })
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.userInfo.email)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "side-queue-item__email",
          onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clickEmail && _ctx.clickEmail(...args))),
          title: "Копировать email"
        }, [
          _createVNode(_component_SvgIcon, { name: "16-email-solid" })
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.userInfo.tgId)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "side-queue-item__telegram",
          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.clickTelegram && _ctx.clickTelegram(...args))),
          title: "Копировать telegram"
        }, [
          _createVNode(_component_SvgIcon, { name: "12-telegram" })
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}