import cellMixin from "@/app/mixins/table/cell-mixin";

import { ElPopover } from "element-plus";

export default {
	name: 'cell-tags',
	mixins: [cellMixin],
	components: {
		ElPopover,
	},
	props: {
		countShow:{
			type: Number,
			default: 3,
		},
	},
	computed:{
		label(){
			return this.fieldData.slice(0, this.countShow).join(', ').trim();
		},
		collapsedList(){
			return this.fieldData.slice(this.countShow);
		}
	}
}