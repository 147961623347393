import { ElPopover } from 'element-plus';
import 'element-plus/es/components/popover/style/css';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';

export default {
	name: 'temp-settings',
	emits: ['update:modelValue', 'onChange'],
	components: {
		SvgIcon, ElPopover, BsCheckbox,
	},
	props: {
		modelValue: Array,
	},
	data() {
		return {
			settingList: this.modelValue,
		}
	},
	methods: {
		changeSettings(){
			// console.log('changeSettings');
			this.$emit('update:modelValue', this.settingList);
			this.$emit('onChange', this.settingList);
		}
	},
	watch:{
		modelValue(data){
			console.log('modelValue');
			this.settingList = data;
		}
	}
}