import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid-designs-table__head-cell cell-design-info"
}
const _hoisted_2 = {
  key: 1,
  class: "grid-designs-table__head-cell"
}
const _hoisted_3 = { class: "grid-designs-table__cell cell-type-info" }
const _hoisted_4 = { class: "grid-designs-table__cell cell-employ-info" }
const _hoisted_5 = { class: "grid-designs-table__cell cell-type-number" }
const _hoisted_6 = { class: "grid-designs-table__cell cell-type-number" }
const _hoisted_7 = { class: "grid-designs-table__cell cell-photo-gallery" }
const _hoisted_8 = { class: "grid-designs-table__cell cell-queue" }
const _hoisted_9 = {
  key: 0,
  class: "grid-designs-table__cell cell-type-number"
}
const _hoisted_10 = {
  key: 1,
  class: "grid-designs-table__cell cell-type-number"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTableColumn = _resolveComponent("ElTableColumn")
  const _component_HeadDesignInfo = _resolveComponent("HeadDesignInfo")
  const _component_HeadBtnSort = _resolveComponent("HeadBtnSort")
  const _component_GridDesignsInfo = _resolveComponent("GridDesignsInfo")
  const _component_GridEmployInfo = _resolveComponent("GridEmployInfo")
  const _component_CellNumber = _resolveComponent("CellNumber")
  const _component_GridCellMoney = _resolveComponent("GridCellMoney")
  const _component_GridCellPhoto = _resolveComponent("GridCellPhoto")
  const _component_GridCellQueue = _resolveComponent("GridCellQueue")
  const _component_ElTable = _resolveComponent("ElTable")

  return (_openBlock(), _createBlock(_component_ElTable, {
    class: "grid-designs-table",
    ref: "gridTable",
    data: _ctx.tableData,
    stripe: "",
    border: "",
    "row-class-name": _ctx.getRowClasses,
    onSelectionChange: _ctx.changeSelectionTable,
    onRowDblclick: _ctx.dbClickRow
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ElTableColumn, {
        type: "selection",
        width: "38",
        fixed: "left",
        "header-align": "center"
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colConfigList, (col) => {
        return (_openBlock(), _createBlock(_component_ElTableColumn, {
          key: col.key,
          prop: col.key,
          label: col.label,
          "min-width": col.width,
          resizable: !['designInfo', 'employ', 'queue'].includes(col.key),
          fixed: (col.key=='designInfo')?'left':false,
          "class-name": col?.class ?? '',
          "header-align": "center",
          align: "center"
        }, _createSlots({
          header: _withCtx((scope) => [
            (col.key=='designInfo')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_HeadDesignInfo, {
                    label: col.label,
                    onChangeCompact: _ctx.changeCompactHeadDesign
                  }, null, 8 /* PROPS */, ["label", "onChangeCompact"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createTextVNode(_toDisplayString(col.label), 1 /* TEXT */),
                  (col.sort)
                    ? (_openBlock(), _createBlock(_component_HeadBtnSort, {
                        key: 0,
                        modelValue: col.order,
                        "onUpdate:modelValue": $event => ((col.order) = $event),
                        onChange: $event => (_ctx.sortTable(col.order, col.key))
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onChange"]))
                    : _createCommentVNode("v-if", true)
                ]))
          ]),
          _: 2 /* DYNAMIC */
        }, [
          (col.key=='designInfo')
            ? {
                name: "default",
                fn: _withCtx((scope) => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_GridDesignsInfo, {
                      data: scope,
                      cityMap: _ctx.cityMap,
                      compact: _ctx.isDesignInfoCompact
                    }, null, 8 /* PROPS */, ["data", "cityMap", "compact"])
                  ])
                ]),
                key: "0"
              }
            : (col.key=='employ')
              ? {
                  name: "default",
                  fn: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_GridEmployInfo, { data: scope }, null, 8 /* PROPS */, ["data"])
                    ])
                  ]),
                  key: "1"
                }
              : (['countFreeDays', 'grp','ots'].includes(col.key))
                ? {
                    name: "default",
                    fn: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_CellNumber, {
                          data: scope,
                          groupKey: "curSideData",
                          fieldKey: col.key
                        }, null, 8 /* PROPS */, ["data", "fieldKey"])
                      ])
                    ]),
                    key: "2"
                  }
                : (_ctx.isMoneyType(col.key))
                  ? {
                      name: "default",
                      fn: _withCtx((scope) => [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_GridCellMoney, {
                            data: scope,
                            groupKey: "curSideData",
                            fieldKey: col.key
                          }, null, 8 /* PROPS */, ["data", "fieldKey"])
                        ])
                      ]),
                      key: "3"
                    }
                  : (col.key=='photo')
                    ? {
                        name: "default",
                        fn: _withCtx((scope) => [
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_GridCellPhoto, { data: scope }, null, 8 /* PROPS */, ["data"])
                          ])
                        ]),
                        key: "4"
                      }
                    : (col.key=='queue')
                      ? {
                          name: "default",
                          fn: _withCtx((scope) => [
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_GridCellQueue, {
                                data: scope,
                                fieldKey: col.key
                              }, null, 8 /* PROPS */, ["data", "fieldKey"])
                            ])
                          ]),
                          key: "5"
                        }
                      : {
                          name: "default",
                          fn: _withCtx((scope) => [
                            (scope.row[col.key] != undefined)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.getMoney(scope.row[col.key])) + " ₽", 1 /* TEXT */))
                              : (_openBlock(), _createElementBlock("div", _hoisted_10, "–"))
                          ]),
                          key: "6"
                        }
        ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "min-width", "resizable", "fixed", "class-name"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data", "row-class-name", "onSelectionChange", "onRowDblclick"]))
}