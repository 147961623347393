import { ElAvatar } from 'element-plus';
import 'element-plus/es/components/avatar/style/css';

export default {
	name: 'bs-avatar',
	components: {
		ElAvatar,
	},
	props:{
		size: {
			type: [String, Number],
			default: '',
		},

		src: {
			type: String,
			default: '',
		},

		name: {
			type: String,
			default: '',
		}
	},
	computed:{
		title(){
			if( !this.name ) return '';
			return this.name.substring(0, 2).toUpperCase();
		}
	}
}