import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import { ElMessageBox } from 'element-plus';

import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';

import { tableImportData, importData } from './fake-data/import-store/fake-import-data';

export const useTargetImportStore = defineStore('targetImportStore', {
	state() {
		return {
			tableImportData: [],
			// tableImportData: importData.orders,
		}
	},

	getters: { },
	actions: {
		async importParse({ targetProgramId, yearMonth, file }={}){
			console.log({ targetProgramId, yearMonth, file });
			if( !(targetProgramId && yearMonth && file) ) return;

			try {
				const res = await api.postFormData({
					model: `target-program/${targetProgramId}/import/parse`,
					data: { yearMonth, file },
				});

				if(res?.orders?.length) this.tableImportData = res.orders;

				console.log('importParse', res);
				return res;
			} catch (error) {
				console.error(error);

				let message = 'Что-то пошло не так...'
				if (error.name === 'HTTPError') {
					const errorJson = await error.response.json();
					console.error(errorJson);
					if(errorJson?.message) message = errorJson.message;
				}

				ElMessageBox.alert(null, null, {
					type: 'error',
					title: 'Ошибка!',
					message,
					customClass: 'bs-message-box',
				}).then(() => { }).catch(() => { });
			}

			return;
		},

		buildImportOrders(){
			const orders = [];
			const storeFields = [
				'side', 'product', 'customerLegalEntity', 'periods',
				'duration', 'showsInHour', 'cost',
			];

			for( const item of this.tableImportData ){
				const obj = {};
				for( const key of storeFields ){
					let { value } = item?.[key] ?? {};
					if(key=='side') value = item?.side?.id;
					obj[key] = value ?? null;
				}
				orders.push(obj);
			}

			return orders;
		},

		async importStore({ targetProgramId, yearMonth, shouldReserve, replaceMatching }={}){
			const orders = this.buildImportOrders();

			if( !(targetProgramId && yearMonth && orders?.length) ) return;
			try {
				const res = await api.postData({
					model: `target-program/${targetProgramId}/import/store`,
					data: { yearMonth, shouldReserve, replaceMatching, orders },
				});

				console.log('importStore', res);
				return res;
			} catch (error) {
				console.error(error);

				let message = 'Что-то пошло не так...'
				if (error.name === 'HTTPError') {
					const errorJson = await error.response.json();
					console.error(errorJson);
					if(errorJson?.message) message = errorJson.message;
				}

				ElMessageBox.alert(null, null, {
					type: 'error',
					title: 'Ошибка!',
					message,
					customClass: 'bs-message-box',
				}).then(() => { }).catch(() => { });
			}

			return;
		},

		async searchQuerySides({targetProgramId, query}){
			if( !(targetProgramId && query?.length) ) return;

			try {
				const res = await api.postData({
					model: `target-program/${targetProgramId}/import/sides`,
					data: {
						query,
						limit: 30
					},
				});

				return res;
			} catch (error) {
				console.error(error);
			}

			return;
		}
	},
});