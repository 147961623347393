import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["footer-cell-status footer-cell-default", _ctx.className])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countStatus, (value, key) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
        value
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["footer-cell-status__item", `status-${key}`])
            }, _toDisplayString(_ctx.type[key]) + "-" + _toDisplayString(value), 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}