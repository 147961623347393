import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "datepicker-month-period__header" }
const _hoisted_2 = {
  class: "datepicker-month-period__main",
  ref: "datepickerRef"
}
const _hoisted_3 = {
  key: 0,
  class: "datepicker-month-period__footer"
}
const _hoisted_4 = { class: "datepicker-month-period__footer-all-check" }
const _hoisted_5 = { class: "datepicker-month-period__footer-btn-actions" }
const _hoisted_6 = {
  key: 1,
  class: "datepicker-month-period__footer"
}
const _hoisted_7 = { class: "datepicker-month-period__footer-btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_BsBtn = _resolveComponent("BsBtn")

  return (_openBlock(), _createElementBlock("div", {
    class: "datepicker-month-period",
    onMouseleave: _cache[1] || (_cache[1] = (...args) => (_ctx.mouseLeave && _ctx.mouseLeave(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.year), 1 /* TEXT */),
      _createElementVNode("span", null, _toDisplayString(_ctx.month), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.selected)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "datepicker-month-period__block-layout",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }))
        : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */),
    (_ctx.mode=='add')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BsCheckbox, {
              modelValue: _ctx.isAllMonthSelected,
              onChange: _ctx.onAllChecked
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Выбрать все дни месяца")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BsBtn, {
              size: "small",
              tag: "div",
              onClick: _ctx.cancel
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Отмена")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_BsBtn, {
              type: "primary",
              size: "small",
              tag: "div",
              onClick: _ctx.add
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Добавить")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.mode=='remove')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_BsBtn, {
              size: "small",
              tag: "div",
              onClick: _ctx.cancel
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Отмена")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_BsBtn, {
              type: "primary",
              size: "small",
              tag: "div",
              onClick: _ctx.remove
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Удалить период")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ], 32 /* NEED_HYDRATION */))
}