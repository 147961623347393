import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "import-designs-info" }
const _hoisted_2 = { class: "import-designs-info__block" }
const _hoisted_3 = { class: "import-designs-info__group group-row row-space-between" }
const _hoisted_4 = { class: "import-designs-info__group group-col gap-8" }
const _hoisted_5 = { class: "import-designs-info__group group-row gap-8" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "head-gid" }
const _hoisted_8 = { class: "head-inner-id" }
const _hoisted_9 = {
  key: 0,
  class: "import-designs-info__found-tp"
}
const _hoisted_10 = { class: "import-designs-info__address" }
const _hoisted_11 = ["title"]
const _hoisted_12 = { class: "import-designs-info__construct" }
const _hoisted_13 = { class: "import-designs-info__construct-info" }
const _hoisted_14 = { class: "import-designs-info__construct-type" }
const _hoisted_15 = { class: "import-designs-info__type-mech" }
const _hoisted_16 = {
  key: 0,
  class: "import-designs-info__block"
}
const _hoisted_17 = {
  key: 0,
  class: "import-designs-info__block"
}
const _hoisted_18 = { class: "import-designs-info__group group-row gap-48" }
const _hoisted_19 = { class: "import-designs-info__group" }
const _hoisted_20 = { class: "import-designs-info__group-value" }
const _hoisted_21 = { class: "import-designs-info__group" }
const _hoisted_22 = { class: "import-designs-info__group-value" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { class: "import-designs-info__group" }
const _hoisted_26 = { class: "import-designs-info__group-value" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { class: "import-designs-info__block" }
const _hoisted_30 = { class: "import-designs-info__group group-row gap-32 align-start" }
const _hoisted_31 = { class: "import-designs-info__group" }
const _hoisted_32 = ["title"]
const _hoisted_33 = {
  key: 1,
  class: "import-designs-info__group-value"
}
const _hoisted_34 = { class: "import-designs-info__group" }
const _hoisted_35 = ["title"]
const _hoisted_36 = {
  key: 1,
  class: "import-designs-info__group-value"
}
const _hoisted_37 = { class: "import-designs-info__group" }
const _hoisted_38 = { class: "import-designs-info__group-value" }
const _hoisted_39 = { key: 0 }
const _hoisted_40 = { key: 1 }
const _hoisted_41 = {
  key: 0,
  class: "import-designs-info__group"
}
const _hoisted_42 = { class: "import-designs-info__group-value" }
const _hoisted_43 = ["title"]
const _hoisted_44 = { key: 1 }
const _hoisted_45 = { class: "import-designs-info__block" }
const _hoisted_46 = { class: "import-designs-info__group" }
const _hoisted_47 = { class: "import-designs-info__group-value" }
const _hoisted_48 = { key: 0 }
const _hoisted_49 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              class: "import-designs-info__head",
              href: _ctx.linkDesign,
              target: "_blank",
              title: "Открыть РК"
            }, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.sideData.innerId), 1 /* TEXT */),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.sideData.gid), 1 /* TEXT */)
            ], 8 /* PROPS */, _hoisted_6),
            (_ctx.sideData?.foundedInTp)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_SvgIcon, {
                    name: "non-categorized",
                    title: "РС найдена в АП"
                  })
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", {
              class: "import-designs-info__side-replace",
              onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.replaceSide && _ctx.replaceSide(...args)))
            }, "Заменить РС")
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", {
              class: "address-info",
              title: _ctx.sideData.address
            }, _toDisplayString(_ctx.sideData.address), 9 /* TEXT, PROPS */, _hoisted_11)
          ])
        ]),
        _createElementVNode("div", {
          class: "import-designs-info__btn-open",
          onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.toggleWrap && _ctx.toggleWrap(...args)))
        }, [
          _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("span", null, _toDisplayString(_ctx.sideData.structureType), 1 /* TEXT */),
            _createElementVNode("span", null, _toDisplayString(_ctx.sideData.sideSize), 1 /* TEXT */),
            _createVNode(_component_SvgIcon, {
              name: _ctx.getTypeDesignIcon(_ctx.sideData?.structureType)
            }, null, 8 /* PROPS */, ["name"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.sideData.sideMech), 1 /* TEXT */)
          ])
        ])
      ])
    ]),
    _createVNode(_component_ElCollapseTransition, null, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              (_ctx.sideData?.type == 'digital')
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "import-designs-info__group-label" }, "Блок/слот", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.sideData.blockDuration || 0) + " сек/", 1 /* TEXT */),
                          _createElementVNode("span", null, _toDisplayString(_ctx.sideData.slotDuration || 0) + " сек", 1 /* TEXT */)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "import-designs-info__group-label" }, "Время работы", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_22, [
                          (_ctx.sideData?.workingHours)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.sideData?.workingHours), 1 /* TEXT */))
                            : (_openBlock(), _createElementBlock("span", _hoisted_24, "–"))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "import-designs-info__group-label" }, "WiFi ловушка", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_26, [
                          (_ctx.sideData?.wifiTrap)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_ctx.sideData?.wifiTrap), 1 /* TEXT */))
                            : (_openBlock(), _createElementBlock("span", _hoisted_28, "–"))
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "import-designs-info__group-label" }, "Оператор", -1 /* HOISTED */)),
                    (_ctx.sideData?.operator)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "a import-designs-info__group-value",
                          href: "#",
                          title: _ctx.sideData?.operator
                        }, _toDisplayString(_ctx.sideData?.operator), 9 /* TEXT, PROPS */, _hoisted_32))
                      : (_openBlock(), _createElementBlock("div", _hoisted_33, "–"))
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "import-designs-info__group-label" }, "Собственник", -1 /* HOISTED */)),
                    (_ctx.sideData?.owner)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "a import-designs-info__group-value",
                          title: _ctx.sideData?.owner
                        }, _toDisplayString(_ctx.sideData?.owner), 9 /* TEXT, PROPS */, _hoisted_35))
                      : (_openBlock(), _createElementBlock("div", _hoisted_36, "–"))
                  ]),
                  _createElementVNode("div", _hoisted_37, [
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "import-designs-info__group-label" }, "ESPAR ID", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_38, [
                      (_ctx.sideData?.esparId)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_39, _toDisplayString(_ctx.sideData?.esparId), 1 /* TEXT */))
                        : (_openBlock(), _createElementBlock("span", _hoisted_40, "–"))
                    ])
                  ]),
                  (!_ctx.sideData?.digitalData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "import-designs-info__group-label" }, "Материал", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_42, [
                          (_ctx.sideData?.staticMaterial)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                title: _ctx.sideData?.staticMaterial
                              }, _toDisplayString(_ctx.sideData?.staticMaterial), 9 /* TEXT, PROPS */, _hoisted_43))
                            : (_openBlock(), _createElementBlock("span", _hoisted_44, "–"))
                        ])
                      ]))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("div", _hoisted_46, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "import-designs-info__group-label" }, "Технический комментарий:", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_47, [
                    (_ctx.sideData?.techComment)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_48, _toDisplayString(_ctx.sideData?.techComment), 1 /* TEXT */))
                      : (_openBlock(), _createElementBlock("span", _hoisted_49, "–"))
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}