import cellMixin from "@/app/mixins/table/cell-mixin";

import ShortSelect from "@/app/components/controls/selects/short-select/ShortSelect.vue";
import ImportCellStatus from "@/app/components/target-programs/target-import/import-cell-types/import-cell-status/ImportCellStatus.vue";

export default {
	name: 'import-sh-select',
	mixins: [cellMixin],
	components: {
		ShortSelect, ImportCellStatus,
	},
	props: {
		options: {
			type: Array,
			default: [],
		},
		filtrable: {
			type: Boolean,
			default: false,
		}
	},
	emits: ['change'],
	methods: {
		change(value){
			this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: value,
			});
		}
	},
}