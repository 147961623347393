import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pr-rules-info-item__label" }
const _hoisted_2 = { class: "pr-rules-info-item__btn-open" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pr-rules-info-item", { '--is-open-value': _ctx.isOpenValue, '--is-toggle-value': _ctx.heightValue>=28 }])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.data?.label), 1 /* TEXT */),
    _createElementVNode("div", {
      class: "pr-rules-info-item__value",
      ref: "refItemValue",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggleValue && _ctx.toggleValue(...args)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.data?.value), 1 /* TEXT */),
      _createVNode(_Transition, {
        name: "el-fade-in",
        mode: "out-in",
        persisted: ""
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_2, [
            _createVNode(_component_SvgIcon, { name: "8-arrow-down" })
          ], 512 /* NEED_PATCH */), [
            [_vShow, _ctx.heightValue==28]
          ])
        ]),
        _: 1 /* STABLE */
      })
    ], 512 /* NEED_PATCH */)
  ], 2 /* CLASS */))
}