import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "search-panel__btn-action",
  title: "Поиск"
}
const _hoisted_2 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BtnAction = _resolveComponent("BtnAction")
  const _component_BsInput = _resolveComponent("BsInput")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["search-panel", { '--is-focus-panel': _ctx.isFocus }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BtnAction, {
        name: "16-search",
        onClick: _ctx.onSearch
      }, null, 8 /* PROPS */, ["onClick"])
    ]),
    _createElementVNode("div", {
      class: "search-panel__input",
      title: _ctx.strSearch
    }, [
      _createVNode(_component_BsInput, {
        placeholder: _ctx.placeholder,
        modelValue: _ctx.strSearch,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.strSearch) = $event)),
        clearable: true,
        onFocus: _cache[1] || (_cache[1] = $event => (_ctx.isFocus=true)),
        onBlur: _cache[2] || (_cache[2] = $event => (_ctx.isFocus=false))
      }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
    ], 8 /* PROPS */, _hoisted_2)
  ], 2 /* CLASS */))
}