import { ElDatePicker } from 'element-plus';
import 'element-plus/es/components/date-picker/style/css';

export default {
	name: 'bs-date-picker',
	components: {
		ElDatePicker,
	},
	props: {
		modelValue: [String, Date],
		placeholder: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			value: this.modelValue,
		}
	},
	watch: {
		value(val){
			// console.log(val);
			this.$emit('update:modelValue', val);
		},
		modelValue(val){
			this.value = val;
		}
	},
}