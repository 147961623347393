import dayjs from 'dayjs';

import { ElTable, ElTableColumn, valueEquals, } from "element-plus";
import 'element-plus/es/components/table/style/css';


import { colConfigList } from './table-config';
import helper from '@/app/utils/helper';

import HeadBtnSort from '../../grid/grid-designs-table/head-btn-sort/HeadBtnSort.vue';
import GridDesignsInfo from '../../grid/grid-designs-info/GridDesignsInfo.vue';
import GridEmployInfo from '../../grid/grid-employ-info/GridEmployInfo.vue';
import GridCellPhoto from '../../grid/grid-cell-types/grid-cell-photo/GridCellPhoto.vue';
import CellEmployBroadcast from '../type-cell/cell-employ-broadcast/CellEmployBroadcast.vue';
import CellCountQueue from '../type-cell/cell-count-queue/CellCountQueue.vue';
import CellFreeDays from '../type-cell/cell-free-days/CellFreeDays.vue';
import CellMonthQueue from '../type-cell/cell-month-queue/CellMonthQueue.vue';

import EditOperQueuePopup from '../../modals/edit-oper-queue-popup/EditOperQueuePopup.vue';

export default {
	name: 'operator-detail-table',
	components: {
		ElTable, ElTableColumn,
		HeadBtnSort,
		GridDesignsInfo, GridEmployInfo, GridCellPhoto,
		CellEmployBroadcast, CellCountQueue, CellFreeDays,
		CellMonthQueue,
		EditOperQueuePopup,
	},
	props: {
		tableData: {
			type: Array,
			default: [],
		}
	},
	emits: ['update:tableData', 'changeTable'],
	data() {
		return {
			operQueuePopupData: {
				isVisible: false,
				info: null,
			},
		}
	},
	computed: {
		setMonths(){
			const setMonths = new Set();

			for( const rowData of this.tableData ){
				const { occupancy } = rowData?.curSideData ?? {};
				for( const key of Object.keys(occupancy) ){
					setMonths.add(key);
				}
			}

			return setMonths;
		},
		colConfigList(){
			const list = [...colConfigList];
			let color = 'violet';

			for( const monthDate of this.setMonths ){
				let labelMonth = dayjs(monthDate).format('MMMM');
				labelMonth = helper.upFirstLetter(labelMonth);

				if(color=='blue') color = 'violet';
				else if(color=='violet') color='blue';

				list.push({
					key: `month-${monthDate}`,
					label: labelMonth,
					order: '',
					sort: false,
					show: true,
					// width: 170,
					disabledCheck: true,
					className: `th-fill-${color}`,
					group: [
						{
							key: 'freeDays',
							keyProp: 'occupancy',
							keyGroup: 'curSideData',
							month: monthDate,
							label: 'Свободных дней',
							order: '',
							sort: false,
							show: true,
							width: 150,
						},
						{
							key: 'queue',
							keyProp: 'occupancy',
							keyGroup: 'curSideData',
							month: monthDate,
							label: 'Очередь',
							order: '',
							sort: false,
							show: true,
							width: 580,
						},
					],
				});
			}

			return list;
		},
		selectList(){
			const list = [];

			for( const monthDate of this.setMonths ){
				const label = dayjs(monthDate).format(`MMMM’YY`);
				list.push({
					value: monthDate,
					label: helper.upFirstLetter(label),
				});
			}

			return list;
		},
	},
	methods: {
		selectionChange(list){
			// console.log(list);
			this.$emit('changeTable', list);
		},
		sortTable(order, key){
			const tableData = [...this.tableData];
			// this.sortData = { order, key };

			// for(let item of this.colConfigList){
			// 	if(item.key == key) continue;
			// 	item.order = '';
			// }

			// if( ['countConstruct', 'countSide', 'countSurface', ].includes(key) ){
			// 	if(order=='asc') tableData.sort((a,b)=>a[key] - b[key]);
			// 	else if(order=='desc') tableData.sort((a,b)=>b[key] - a[key]);

			// }
			// else if(key=='operator') {
			// 	const date = (row)=>(row[key]['name'].toLowerCase());

			// 	if(order=='asc') tableData.sort((a,b)=>date(a).localeCompare(date(b)));
			// 	else if(order=='desc') tableData.sort((a,b)=>date(b).localeCompare(date(a)));
			// }

			// if(!order) tableData.sort((a,b)=>a['operator']['id'] - b['operator']['id']);

			this.$emit('update:tableData', tableData);
		},

		getCellClass(col){
			const classList = [];

			if(col?.className) classList.push(col.className);
			if(col.key=='designInfo') classList.push('td-design-info');

			return classList.join(' ');
		},

		editMonthQueue(data){
			console.log('editMonthQueue', data);
			this.operQueuePopupData = {
				isVisible: true,
				info: data,
			}
		}
	},
}