import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "action-panel" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BtnAction = _resolveComponent("BtnAction")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.listType)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.dataList, (item) => {
          return (_openBlock(), _createBlock(_component_BtnAction, {
            key: item.id,
            type: item.key,
            active: item.isActive,
            onClick: $event => (_ctx.clickButton(item))
          }, null, 8 /* PROPS */, ["type", "active", "onClick"]))
        }), 128 /* KEYED_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ]))
}