import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")
  const _component_ElSelectV2 = _resolveComponent("ElSelectV2")

  return (_openBlock(), _createBlock(_component_ElSelectV2, {
    class: "bs-select-v2",
    modelValue: _ctx.valueData,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.valueData) = $event)),
    teleported: _ctx.teleported,
    "popper-class": _ctx.popoverClass
  }, _createSlots({ _: 2 /* DYNAMIC */ }, [
    (_ctx.showCheckBox)
      ? {
          name: "default",
          fn: _withCtx(({ item }) => [
            _createVNode(_component_ElCheckbox, {
              "model-value": _ctx.valueData.includes(item.value),
              title: item.label
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["model-value", "title"])
          ]),
          key: "0"
        }
      : {
          name: "default",
          fn: _withCtx(({ item }) => [
            _createElementVNode("span", {
              title: item.label
            }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_1)
          ]),
          key: "1"
        }
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "teleported", "popper-class"]))
}