import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "import-sh-select" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ShortSelect = _resolveComponent("ShortSelect")
  const _component_ImportCellStatus = _resolveComponent("ImportCellStatus")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ShortSelect, {
      modelValue: _ctx.fieldData.value ?? '',
      options: _ctx.options,
      filtrable: _ctx.filtrable,
      onChange: _ctx.change
    }, null, 8 /* PROPS */, ["modelValue", "options", "filtrable", "onChange"]),
    _createVNode(_component_ImportCellStatus, {
      data: { row: _ctx.rowData },
      groupKey: _ctx.groupKey,
      fieldKey: _ctx.fieldKey
    }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
  ]))
}