import dayjs from "dayjs";
import helper from "@/app/utils/helper";
import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'tprog-cell-period',
	mixins: [ cellMixin ],
	computed: {
		label(){
			const { from, to } = this.fieldData;
			const dateFrom = dayjs(from).format('MMM’YY').replace('.', '');
			const dateTo = dayjs(to).format('MMM’YY').replace('.', '');

			if(dateFrom == dateTo) return dateFrom;

			return `${dateFrom} - ${dateTo}`;
		}
	}
}