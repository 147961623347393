import { ElCollapseTransition } from "element-plus";
import dayjs from 'dayjs';

import helper from "@/app/utils/helper";

import SideQueueItem from '@/app/components/map/map-design-info/side-queue/side-queue-item/SideQueueItem.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'side-queue',
	components: {
		ElCollapseTransition, SideQueueItem, SvgIcon,
	},
	props: {
		isOpen: {
			type: Boolean,
			default: false,
		},
		list: Array,
	},
	data() {
		return {
			isOpenQueue: this.isOpen,
		}
	},
	computed: {
		queueList(){
			const list = this.list.toSorted((a,b)=>{
				const dateA = dayjs(a.date, 'DD.MM.YYYY').toDate();
				const dateB = dayjs(b.date, 'DD.MM.YYYY').toDate();
				return dateA - dateB;
			});

			return Object.entries(list)
				.map(([index, item])=>{
					return {
						index: Number(index)+1,
						...item,
					}
				})
				.toSorted((a,b)=>b.index-a.index);
		},
		middleItems(){
			if(this.queueList.length<=2) return;
			let copyList = helper.clone(this.queueList);
			copyList.shift();
			copyList.pop();

			return copyList;
		},
		firstItem(){
			if(!this.middleItems) return;
			return this.queueList[0];
		},
		lastItem(){
			if(!this.middleItems) return;
			const { length } = this.queueList;
			return this.queueList[length-1];
		},
	},
	methods: {
		clickOpenQueue(){
			this.isOpenQueue = !this.isOpenQueue;
		}
	},
}