import { mapActions, mapState, mapWritableState } from 'pinia';

import cellMixin from '@/app/mixins/table/cell-mixin';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'head-design-info',
	mixins: [cellMixin],
	components: {
		SvgIcon,
	},
	props:{
		label: {
			type: String,
			default: '',
		},
		notActions: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['changeCompact'],
	data() {
		return {
			isCompact: false,
		}
	},
	computed: {

	},
	methods: {
		clickCompact(){
			this.isCompact = !this.isCompact;

			try {
				window.localStorage.setItem('design-table--is-compact', JSON.stringify(this.isCompact));
			} catch (error) {
				console.error(error);
			}
		},
		setSettingsConfig({ colConfig, isCollapsed, isCompact }){
			for(let item of this.colConfigList){
				const { key } = item;
				if(item.show === undefined || colConfig?.[key]===undefined) continue;
				item.show = colConfig[key];
			}

			this.isColumnCollapse = isCollapsed;
			this.isCompact = isCompact;
		},
	},
	watch: {
		isCompact(is){
			this.$emit('changeCompact', is);
		}
	},
	created() {
		try {
			const res = window.localStorage.getItem('design-table--is-compact');
			if(res !== undefined) this.isCompact = JSON.parse(res);
		} catch (error) {
			console.error(error);
		}
	},
}