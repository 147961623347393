import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "side-design" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", {
    class: "marker-side-system",
    style: _normalizeStyle(`transform: rotate(${_ctx.angle}deg)`)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["marker-side-system__container", `design-type-${_ctx.countSides}`])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SvgIcon, {
          name: _ctx.designType[_ctx.countSides]
        }, null, 8 /* PROPS */, ["name"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sideList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["side-direct", _ctx.getSideClass(item, index)]),
          key: item.id,
          style: _normalizeStyle(_ctx.angleDirect)
        }, _toDisplayString(item.direction), 7 /* TEXT, CLASS, STYLE */))
      }), 128 /* KEYED_FRAGMENT */))
    ], 2 /* CLASS */)
  ], 4 /* STYLE */))
}