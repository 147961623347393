import { typeList } from "../config-rules";

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import BsSelectV2 from "@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue";
import PrRulesEditItem from "./pr-rules-edit-item/PrRulesEditItem.vue";

export default {
	name: 'pr-rules-edit',
	components: {
		BsBtn, BsSelectV2, PrRulesEditItem,
	},
	props: {
		data: {
			type: Object,
			default: {},
		}
	},
	data() {
		return {
			typeList,
			ruleSelectedMap: new Map(),
		}
	},
	emits: ['cancel', 'save'],
	computed: {
		typeData(){
			const options = [];
			const map = new Map();

			for( const item of this.typeList){
				const { key, label } = item;

				map.set(key, item);
				options.push({
					value: key, label,
					disabled: this.ruleSelectedMap.has(key),
				});
			}
			return { options, map };
		},
		ruleSelectedData(){
			const obj = {};
			for( const [key, data] of this.ruleSelectedMap.entries() ){
				const { type, value } = data;
				if(type=='multi-select' && value?.length) obj[key] = value;
				else if(value && !Array.isArray(value)) obj[key] = value;
			}
			return obj;
		},
		disabledBtnSave(){
			return JSON.stringify(this.data) == JSON.stringify(this.ruleSelectedData);
		}
	},
	methods: {
		changeSelectAdd(key){
			const role = this.typeData.map.get(key);
			if(role){
				const { type } = role;
				const tmp = {};

				if(['multi-select'].includes(type)) tmp['value'] = [];
				else tmp['value'] = '';

				this.ruleSelectedMap.set(key, {
					...role,
					...tmp,
				});
			}
		},
		getRole(key){
			return this.ruleSelectedMap.get(key);
		},
		removeRule(key){
			this.ruleSelectedMap.delete(key);
		},
		clickCancel(){
			this.$emit('cancel');
		},
		clickSave(){
			this.$emit('save', this.ruleSelectedData);
		},

		buildMap(){
			const map = new Map();
			for(const item of this.typeList){
				const value = this.data?.[item.key];
				if(value == undefined) continue;

				map.set(item.key, {
					...item,
					value
				});
			}

			this.ruleSelectedMap = map;
		}
	},
	watch: {
		data(){
			this.buildMap();
		}
	},
	created(){
		this.buildMap();
	}
}