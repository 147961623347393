import cellMixin from "@/app/mixins/table/cell-mixin";

import { ElCollapseTransition } from "element-plus";

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";

export default {
	name: 'tprog-cell-city',
	mixins: [ cellMixin ],
	components: {
		ElCollapseTransition,
		BsBtn,
	},
	data() {
		return {
			isOpenFullList: false,
		}
	},
	computed: {
		fullLabel(){
			return this.fieldData.join(', ');
		},
		shortLabel(){
			return this.fieldData.slice(0, 3).join(', ');
		},
		count(){
			return this.fieldData.length - 3;
		}
	},
	methods: {
		openFullList(){
			this.isOpenFullList = true;
		}
	},
}