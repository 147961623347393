import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tprog-cell-city"
}
const _hoisted_2 = {
  key: 0,
  class: "tprog-cell-city__short"
}
const _hoisted_3 = { class: "tprog-cell-city__short-label" }
const _hoisted_4 = {
  key: 0,
  class: "tprog-cell-city__short-count"
}
const _hoisted_5 = {
  key: 0,
  class: "tprog-cell-city__full"
}
const _hoisted_6 = {
  key: 1,
  class: "tprog-cell-city"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")

  return (_ctx.fieldData?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.isOpenFullList)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.shortLabel) + " " + _toDisplayString(_ctx.count>0?'...':''), 1 /* TEXT */),
              (_ctx.count>0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_BsBtn, {
                      size: "small",
                      onClick: _ctx.openFullList
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("+" + _toDisplayString(_ctx.count), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["onClick"])
                  ]))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_ElCollapseTransition, null, {
          default: _withCtx(() => [
            (_ctx.isOpenFullList)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.fullLabel), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, "–"))
}