import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "import-cell-result" }
const _hoisted_2 = { class: "import-cell-result__title" }
const _hoisted_3 = {
  key: 0,
  class: "import-cell-result__subtitle"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["import-cell-result__bg", _ctx.classBgStatus])
    }, null, 2 /* CLASS */),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1 /* TEXT */),
    (_ctx.foundedInTp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Найдено в АП"))
      : _createCommentVNode("v-if", true)
  ]))
}