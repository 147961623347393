import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "target-persons" }
const _hoisted_2 = { class: "target-persons__container" }
const _hoisted_3 = {
  key: 0,
  class: "target-persons__form"
}
const _hoisted_4 = { class: "control" }
const _hoisted_5 = { class: "control__group" }
const _hoisted_6 = { class: "control" }
const _hoisted_7 = { class: "control__group" }
const _hoisted_8 = { class: "control" }
const _hoisted_9 = { class: "control__group" }
const _hoisted_10 = { class: "control" }
const _hoisted_11 = { class: "control__group" }
const _hoisted_12 = { class: "control" }
const _hoisted_13 = { class: "control__group" }
const _hoisted_14 = { class: "control" }
const _hoisted_15 = { class: "control__group" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.personData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "control__label" }, "Юр. лицо МГ РИМ", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Введите или выберите из списка",
                  modelValue: _ctx.personData.rimPerson.value,
                  options: _ctx.personData.rimPerson.options,
                  clearable: true,
                  filterable: true,
                  onChange: _cache[0] || (_cache[0] = $event => (_ctx.changeEditField($event, 'rimPerson'))),
                  size: "large"
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "control__label" }, "Юр. лицо клиента", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Введите или выберите из списка",
                  modelValue: _ctx.personData.clientPerson.value,
                  options: _ctx.personData.clientPerson.options,
                  clearable: true,
                  filterable: true,
                  size: "large",
                  onChange: _cache[1] || (_cache[1] = $event => (_ctx.changeEditField($event, 'clientPerson')))
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "control__label" }, "Печать", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Введите или выберите из списка",
                  modelValue: _ctx.personData.printPerson.value,
                  options: _ctx.personData.printPerson.options,
                  clearable: true,
                  filterable: true,
                  size: "large",
                  onChange: _cache[2] || (_cache[2] = $event => (_ctx.changeEditField($event, 'clientPerson')))
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "control__label" }, "Монтаж", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Введите или выберите из списка",
                  modelValue: _ctx.personData.installPerson.value,
                  options: _ctx.personData.installPerson.options,
                  clearable: true,
                  filterable: true,
                  size: "large",
                  onChange: _cache[3] || (_cache[3] = $event => (_ctx.changeEditField($event, 'clientPerson')))
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "control__label" }, "Дизайн", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Введите или выберите из списка",
                  modelValue: _ctx.personData.designPerson.value,
                  options: _ctx.personData.designPerson.options,
                  clearable: true,
                  filterable: true,
                  size: "large",
                  onChange: _cache[4] || (_cache[4] = $event => (_ctx.changeEditField($event, 'clientPerson')))
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "control__label" }, "Фотоотчет", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Введите или выберите из списка",
                  modelValue: _ctx.personData.photoReportPerson.value,
                  options: _ctx.personData.photoReportPerson.options,
                  clearable: true,
                  filterable: true,
                  size: "large",
                  onChange: _cache[5] || (_cache[5] = $event => (_ctx.changeEditField($event, 'clientPerson')))
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}