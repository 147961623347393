import dayjs from 'dayjs';
import helper from '@/app/utils/helper';

import { ElCollapseTransition } from "element-plus";
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'summary-service-month',
	components:{
		ElCollapseTransition,
		SvgIcon,
	},
	props: {
		data: {
			type: Object,
			default: {},
		}
	},
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		monthLabel(){
			if(!this.data?.month) return '';
			const date = this.data.month;
			const month = dayjs(date).format('MMMM');
			const year = dayjs(date).format('YY');

			return `${helper.upFirstLetter(month)} '${year}`;
		},
		code(){ return this.data?.code },
		totalPriceNotVat(){ return this.data?.totalPriceNotVat },
		totalPrice(){ return this.data?.totalPrice },
		serviceList(){
			return this.data?.list || [];
		},
	},
	methods: {
		getMoney: helper.getMoney,
		upFirstLetter: helper.upFirstLetter,
		clickCollapse(){
			this.isOpen = !this.isOpen;
		}
	},
}