import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "datepicker-month-range" }
const _hoisted_2 = { class: "datepicker-month-range__header" }
const _hoisted_3 = { class: "datepicker-month-range__header-label" }
const _hoisted_4 = {
  class: "datepicker-month-range__main",
  ref: "datepickerRef"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "datepicker-month-range__header-btn",
        onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickPrev && _ctx.clickPrev(...args)))
      }, [
        _createVNode(_component_SvgIcon, { name: "12-d-arrow-left" })
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.year), 1 /* TEXT */),
      _createElementVNode("div", {
        class: "datepicker-month-range__header-btn",
        onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clickNext && _ctx.clickNext(...args)))
      }, [
        _createVNode(_component_SvgIcon, { name: "12-d-arrow-right" })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, null, 512 /* NEED_PATCH */)
  ]))
}