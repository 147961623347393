import dayjs from 'dayjs';

import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'tprog-employ-info',
	mixins: [ cellMixin ],
	props: {
		period: {
			type: Object,
			default: {},
		},
		data: Object,
	},
	emits: ['clickMonth'],
	data() {
		return {
			monthElementData: {},
			// placementMonths:[{"month":"2024-05", "color":}]
			occupancy: {
				'2024-02': {
					status: 'free', //'reserved', 'busy'
				},
				'2024-03': {
					status: 'free',
				},
				'2024-04': {
					status: 'free',
				},
				'2024-05': {
					status: 'reserved',
				},
				'2024-06': {
					status: 'free',
				},
				'2024-07': {
					status: 'busy',
				},
				'2024-08': {
					status: 'free',
				},
				'2024-09': {
					status: 'free',
				},
				'2024-10': {
					status: 'free',
				},
				'2024-11': {
					status: 'busy',
				},
				'2024-12': {
					status: 'free',
				},
				'2025-01': {
					status: 'reserved',
				},
			},
			colorMap: {
				green: 'свобода',
				red: 'занято',
				white: 'недоступно',
				yellow: 'мой резерв',
				pink: 'чужой резерв',
				violet: 'временное подтверждение/подтверждено',
			}
		}
	},
	computed: {
		compPeriod(){
			let { from, to } = this.period;
			let year = dayjs().year();
			let month = dayjs().month();

			if(!from) from = dayjs({ year, month, day: 1 }).format('YYYY-MM-DD');
			if(!to) to = dayjs(from).add(11, 'month').format('YYYY-MM-DD');

			return {
				from, to,
				format:{
					from: dayjs(from).format('DD.MM'),
					to: dayjs(to).format('DD.MM'),
				}
			};
		},
		monthList(){
			if(!this.fieldData?.length) return [];

			return this.fieldData.map(item=>{
				const { month, color } = item;
				const date = dayjs(month);

				return {
					date: date.format('YYYY-MM'),
					title: date.format('MMM').replace('.', ''),
					color, // green, red, yellow, white, violet, pink
				}
			});
		},

		// monthList(){
		// 	const { from } = this.compPeriod;
		// 	const year = dayjs(from).year();
		// 	const month = dayjs(from).month();
		// 	let monthList = [];

		// 	for(let i=0; i<12; i++){
		// 		const date = dayjs({ year, month: ( month + i ), day: 1 });
		// 		let position = null;
		// 		if(this.monthElementData[date.format('YYYY-MM')]) position = this.monthElementData[date.format('YYYY-MM')];

		// 		monthList.push({
		// 			date: date.format('YYYY-MM'),
		// 			title: date.format('MMM').replace('.', ''),
		// 			position,
		// 		});
		// 	}
		// 	// console.log(monthList);
		// 	return monthList;
		// },
	},
	methods: {
		clickMonth(date){
			this.$emit('clickMonth', date);
		}
	},
	watch: {

	},
	async mounted() {

	},
	created() {

	},
}