import cellMixin from "@/app/mixins/table/cell-mixin";
import helper from "@/app/utils/helper";

export default {
	name: 'cell-price',
	mixins: [ cellMixin ],
	computed: {
		label(){
			return this.fieldData === '-' ? '–' : `${helper.getMoney(this.fieldData)} ₽`;
		},
	},
}