import helper from '@/app/utils/helper';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'tag-list',
	components: {
		SvgIcon,
	},
	props:{
		list: Array,
	},
	data() {
		return {
			isOpenList: false,
			isShowCollapseBtn: false,
			leftPosCollapseBtn: 0,
		}
	},
	methods: {
		isElementInViewport(el) {
			const rect = el.getBoundingClientRect();
			const parentRect = this.$el.getBoundingClientRect();

			return (
				rect.top >= parentRect.top &&
				rect.left >= parentRect.left &&
				rect.bottom <= parentRect.bottom &&
				rect.right <= parentRect.right
			);
		},
		getDistanceFromParentLeft(el) {
			const elementRect = el.getBoundingClientRect();
			const parentRect = this.$el.getBoundingClientRect();

			// Расстояние от левого края элемента до левого края родительского контейнера
			let distance = elementRect.left - parentRect.left + el.offsetWidth + 9;
			// console.log(distance);

			return distance;
		},
		initTagData(){
			const { tagItem } = this.$refs;
			let countShow = 0;

			for(let item of tagItem ){
				if(this.isElementInViewport(item)) countShow++;
				else break;
			}

			if(countShow != tagItem.length){
				const lastShowEl = tagItem[countShow-1];

				this.leftPosCollapseBtn = this.getDistanceFromParentLeft(lastShowEl);
				this.isShowCollapseBtn = true;
			}
		},
		openList(){
			this.isOpenList = !this.isOpenList;
		}
	},
	async mounted() {
		await helper.pause(100);
		this.initTagData();
	},
	watch: {
		async list(data){
			if(data.length){
				await helper.pause(100);
				this.initTagData();
			}
		}
	},
}