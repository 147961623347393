import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "map-target-marker__icon"
}
const _hoisted_2 = {
  key: 1,
  class: "map-target-marker__icon"
}
const _hoisted_3 = { class: "map-target-marker__status" }
const _hoisted_4 = {
  key: 0,
  class: "map-target-marker__view type-view-design"
}
const _hoisted_5 = {
  key: 0,
  class: "map-target-marker__view type-view-sides"
}
const _hoisted_6 = {
  key: 2,
  class: "map-target-marker__is-digital"
}
const _hoisted_7 = {
  key: 0,
  class: "map-target-marker__gid-label"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_MarkerSideSystem = _resolveComponent("MarkerSideSystem")

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: _ctx.markerSelector
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["map-target-marker", { 'map-target-marker--active': _ctx.isActive }]),
          onMouseenter: _cache[0] || (_cache[0] = (...args) => (_ctx.mouseEnter && _ctx.mouseEnter(...args))),
          onMouseleave: _cache[1] || (_cache[1] = (...args) => (_ctx.mouseLeave && _ctx.mouseLeave(...args)))
        }, [
          (_ctx.isActive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_SvgIcon, { name: "marker-active-empty" })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_SvgIcon, { name: "marker-empty" })
              ])),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SvgIcon, {
              name: `marker-st-${_ctx.statusIcon}`
            }, null, 8 /* PROPS */, ["name"])
          ]),
          _createVNode(_Transition, {
            name: "el-fade-in",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.typeView=='view-design')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_SvgIcon, { name: _ctx.designIcon }, null, 8 /* PROPS */, ["name"])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_Transition, {
            name: "el-fade-in",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.typeView=='view-sides')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_MarkerSideSystem, { sideList: _ctx.sides }, null, 8 /* PROPS */, ["sideList"])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.isDigital)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_SvgIcon, { name: "8-digital-badge-2" })
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode(_Transition, {
            name: "el-fade-in",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.isHover)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.structData.gid), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ], 34 /* CLASS, NEED_HYDRATION */)
      ], 8 /* PROPS */, ["to"]))
    : _createCommentVNode("v-if", true)
}