import helper from "@/app/utils/helper";

export default {
	name: 'direct-side-info',
	props: {
		data: Object,
	},
	data() {
		return {
			// data: {
			// 	a: 100,
			// 	b: 200,
			// 	c: 500,
			// },
		}
	},
	computed: {
		countTotal(){
			let total = 0;
			if(this?.data) for( let [key, value] of Object.entries(this.data) ){
				total += value;
			}
			return total;
		},
		sideList(){
			const list = [];
			if(this?.data) for( let [key, value] of Object.entries(this.data) ){
				let percent = helper.getNum(value/this.countTotal * 100);

				list.push({
					key: key.toLocaleUpperCase(),
					value, percent,
				});
			}
			return list;
		}
	},
}