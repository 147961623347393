import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "filter-info" }
const _hoisted_2 = { class: "filter-info__entity-title" }
const _hoisted_3 = { class: "filter-info__entity-count" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "filter-info__label" }, "Отфильтровано:", -1 /* HOISTED */)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listData, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "filter-info__entity",
        key: item.title
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.title) + ":", 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, _toDisplayString(item.count), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}