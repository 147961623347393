import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_ElCheckboxGroup = _resolveComponent("ElCheckboxGroup")

  return (_openBlock(), _createBlock(_component_ElCheckboxGroup, { class: "bs-checkbox-group" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createBlock(_component_BsCheckbox, {
          key: item.value,
          value: item.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}