import cellMixin from "@/app/mixins/table/cell-mixin";
import helper from "@/app/utils/helper";

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import BsInput from "@/app/components/controls/bs-input/BsInput.vue";

export default {
	name: 'tprog-edit-count-price',
	mixins: [ cellMixin ],
	components: {
		SvgIcon, BsInput,
	},
	emits:['change'],
	data() {
		return {
			isHoverEmpty: false,
			isEditField: false,
			isBlurOneInput: true,
			isBlurTwoInput: true,

			// fieldData:{
			// 	count: 17,
			// 	price: 123.23,
			// 	total: 2094.91,
			// },

			editData: {
				count: 0,
				price: 0,
			},
		}
	},
	computed: {
		isDataEmpty(){
			const { count, price, total } = this.fieldData;
			return !( count || price || total || this.isEditField);
		},
		isBlurAll(){
			return this.isBlurOneInput && this.isBlurTwoInput;
		},
	},
	methods: {
		getMoney: helper.getMoney,

		emptyMouseEnter(){
			this.isHoverEmpty = true;
		},
		emptyMouseLeave(){
			this.isHoverEmpty = false;
		},
		keyUpEnter(){
			this.isBlurOneInput = true;
			this.isBlurTwoInput = true;
		},
	},
	watch: {
		async isEditField(is){
			if(is){
				const { count, price, total } = this.fieldData;

				this.editData = {
					count: count || 0,
					price: price || 0,
				};

				await helper.pause(10);
				const { refCountInput } = this.$refs;
				// console.log(refCountInput);
				refCountInput.$el.querySelector('input').focus();
			}
		},
		isBlurAll(is){
			helper.debounce({
				keyTimer: 'blur--edit-count-price',
				action: ()=>{
					if(this.isBlurAll){
						const { count, price } = this.fieldData;
						const fieldDataJson = JSON.stringify({ count, price });
						const editDataJson = JSON.stringify(this.editData);

						if(this.fieldData && fieldDataJson != editDataJson){
							this.$emit('change', {
								rowData: this.rowData,
								groupKey: this.groupKey,
								fieldKey: this.fieldKey,
								fieldData: {
									count: Number(this.editData.count),
									price: Number(this.editData.price),
								},
							});
						}
						else if(!this.fieldData && ( this.editData.count || this.editData.price )){
							this.$emit('change', {
								rowData: this.rowData,
								groupKey: this.groupKey,
								fieldKey: this.fieldKey,
								fieldData: {
									count: Number(this.editData.count),
									price: Number(this.editData.price),
								},
							});
						}

						this.isEditField = false;
						this.isHoverEmpty = false;
						this.editData = {
							count: 0,
							price: 0,
						};
					}
				}
			});
		}
	},
}