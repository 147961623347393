import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, KeepAlive as _KeepAlive, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "all-designs-view__action-panel" }
const _hoisted_2 = {
  key: 0,
  class: "all-designs-view__map-search-panel"
}
const _hoisted_3 = {
  key: 0,
  class: "all-designs-view__grid-command-panel"
}
const _hoisted_4 = { class: "all-designs-view__map" }
const _hoisted_5 = { class: "all-designs-view__loader" }
const _hoisted_6 = { class: "all-designs-view__map-legend" }
const _hoisted_7 = { class: "all-designs-view__map-view-marker" }
const _hoisted_8 = { class: "all-designs-view__grid" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BtnAction = _resolveComponent("BtnAction")
  const _component_ElBadge = _resolveComponent("ElBadge")
  const _component_ActionPanel = _resolveComponent("ActionPanel")
  const _component_MapSearchPanel = _resolveComponent("MapSearchPanel")
  const _component_TableComPanel = _resolveComponent("TableComPanel")
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_MapDesigns = _resolveComponent("MapDesigns")
  const _component_MapLegend = _resolveComponent("MapLegend")
  const _component_MapMarker2 = _resolveComponent("MapMarker2")
  const _component_MapCluster = _resolveComponent("MapCluster")
  const _component_MapMarkerSearch = _resolveComponent("MapMarkerSearch")
  const _component_GridDesigns = _resolveComponent("GridDesigns")
  const _component_NewTargprogForm = _resolveComponent("NewTargprogForm")
  const _component_SaveFiltertempForm = _resolveComponent("SaveFiltertempForm")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["default-view all-designs-view", { 'view-map': _ctx.activeDesignView=='map', 'view-grid': _ctx.activeDesignView=='grid' }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ElBadge, {
        value: _ctx.countFilters,
        hidden: !_ctx.countFilters
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BtnAction, {
            name: "16-filters",
            active: !_ctx.isShowSecondAside,
            onClick: _ctx.openFilterAside
          }, null, 8 /* PROPS */, ["active", "onClick"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["value", "hidden"]),
      _createVNode(_component_ActionPanel, {
        listType: ['map', 'grid' ],
        typeCheck: "radio",
        activeKey: _ctx.activeDesignView,
        onOnChange: _ctx.changeBaseActionPanel
      }, null, 8 /* PROPS */, ["activeKey", "onOnChange"]),
      (_ctx.activeDesignView=='map')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_MapSearchPanel, {
              onOnSearch: _ctx.onSearchAddress,
              onChange: _ctx.onChangeMapSearch
            }, null, 8 /* PROPS */, ["onOnSearch", "onChange"])
          ]))
        : _createCommentVNode("v-if", true),
      _createVNode(_Transition, { name: "el-fade-in" }, {
        default: _withCtx(() => [
          (_ctx.activeDesignView=='grid' && _ctx.selectTableData.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.selectTableData.length)
                  ? (_openBlock(), _createBlock(_component_TableComPanel, {
                      key: 0,
                      count: _ctx.selectTableData.length,
                      commandList: _ctx.tableCommandList,
                      onAction: _ctx.actionCommandPanel
                    }, null, 8 /* PROPS */, ["count", "commandList", "onAction"]))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    (_ctx.activeDesignView=='map')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_Transition, {
              name: "el-fade-in",
              mode: "out-in",
              persisted: ""
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_LoaderSpinner)
                ], 512 /* NEED_PATCH */), [
                  [_vShow, _ctx.isLoaderMap]
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_MapDesigns)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_MapLegend)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_BtnAction, {
              name: "10-map-marker",
              title: "Смена типа отображения маркеров",
              active: _ctx.checkMapMarkerView,
              onClick: _ctx.clickMapMarkerView
            }, null, 8 /* PROPS */, ["active", "onClick"])
          ]),
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markerList, (item) => {
              return (_openBlock(), _createBlock(_component_MapMarker2, {
                key: item.code,
                data: item
              }, null, 8 /* PROPS */, ["data"]))
            }), 128 /* KEYED_FRAGMENT */))
          ], 1024 /* DYNAMIC_SLOTS */)),
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clusterList, (item) => {
              return (_openBlock(), _createBlock(_component_MapCluster, {
                key: item.clusterId,
                data: item
              }, null, 8 /* PROPS */, ["data"]))
            }), 128 /* KEYED_FRAGMENT */))
          ], 1024 /* DYNAMIC_SLOTS */)),
          (_ctx.searchMarker)
            ? (_openBlock(), _createBlock(_component_MapMarkerSearch, {
                key: 0,
                data: _ctx.searchMarker
              }, null, 8 /* PROPS */, ["data"]))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    _createVNode(_Transition, {
      name: "el-fade-in",
      persisted: ""
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_8, [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            _createVNode(_component_GridDesigns)
          ], 1024 /* DYNAMIC_SLOTS */))
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.activeDesignView=='grid']
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_NewTargprogForm, {
      modelValue: _ctx.targetProgDialog.isOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.targetProgDialog.isOpen) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_SaveFiltertempForm, {
      modelValue: _ctx.isOpenSaveFiltertempForm,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.isOpenSaveFiltertempForm) = $event))
    }, null, 8 /* PROPS */, ["modelValue"])
  ], 2 /* CLASS */))
}