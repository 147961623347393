import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tprog-cell-documents"
}
const _hoisted_2 = { class: "tprog-cell-documents__wrap" }
const _hoisted_3 = ["href", "download"]
const _hoisted_4 = { class: "tprog-cell-documents__item-date" }
const _hoisted_5 = {
  key: 1,
  class: "tprog-cell-documents"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_ctx.fieldData?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "tprog-cell-documents__item",
              key: item.id
            }, [
              _createElementVNode("a", {
                class: "tprog-cell-documents__item-link",
                href: item.src,
                download: item.title,
                title: "Скачать"
              }, _toDisplayString(item.title), 9 /* TEXT, PROPS */, _hoisted_3),
              _createElementVNode("div", _hoisted_4, "от " + _toDisplayString(_ctx.formatDate(item.date)), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, "–"))
}