import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "tprog-deadline-date"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (this.fieldData)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["tprog-deadline-date", _ctx.getStatusClass])
      }, _toDisplayString(_ctx.formatDate), 3 /* TEXT, CLASS */))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, "–"))
}