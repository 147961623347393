import { ElCollapseTransition } from "element-plus";
import dayjs from "dayjs";

import helper from "@/app/utils/helper";

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'info-ap-month',
	components: {
		SvgIcon, ElCollapseTransition
	},
	props: {
		open: Boolean,
		data: Object
	},
	emits: ['openChange'],
	computed: {
		dateMonth(){
			let str = dayjs(this.data.date).format('MMMM’YY');
			str = helper.upFirstLetter(str);

			return str;
		},
		sideSize(){
			return this.data.sideList.length;
		},
		result(){
			const countDirect = {
				A: 0,
				B: 0,
				C: 0,
			};
			const result = {};

			for(let item of this.data.sideList){
				if(item.direct == 'A') countDirect.A++;
				if(item.direct == 'B') countDirect.B++;
				if(item.direct == 'C') countDirect.C++;
			}

			for(let [key, value] of Object.entries(countDirect)){
				result[key] = {
					value,
					percent: Math.floor( value/this.sideSize * 100 )
				}
			}

			// console.log(result);

			return result;
		},
		sideTypeList(){
			const map = {};
			const list = [];

			for(let item of this.data.sideList){
				const { constructType, size, mech } = item;
				const label = `${constructType} ${size} ${mech}`;

				if(!map[label]) map[label] = 0;
				map[label]++;
			}
			for(let [key, value] of Object.entries(map)){
				list.push({ key, value });
			}

			return list.sort((a,b)=>a.value-b.value);
		},
	},
	methods: {
		clickMain(){
			this.$emit('openChange', {
				open: !this.open,
				month: this.data.date,
			})
		}
	},
}