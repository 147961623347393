import dayjs from "dayjs";
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

import helper from "@/app/utils/helper";

import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';

export default {
	name: 'datepicker-month-period-v2',
	components: {
		BsCheckbox,
	},
	props: {
		monthYear: {
			type: String,
			default: '2024-08',
		}
	},
	emits: ['change'],
	data() {
		return {
			datepicker: null,
			selectedDates: [],
		}
	},
	computed: {
		year(){
			return dayjs(this.monthYear).format('YYYY');
		},
		month(){
			const month = dayjs(this.monthYear).format('MMMM');
			return helper.upFirstLetter(month);
		},
		selectedPeriods(){
			return this.createDatesToPeriods();
		},
		isAllMonthSelected(){
			if(!this.monthYear) return;

			const countMonthDays = dayjs(this.monthYear).daysInMonth();
			const firstDay = dayjs(this.monthYear).date(1).format('YYYY-MM-DD');
			const lastDay = dayjs(this.monthYear).date(countMonthDays).format('YYYY-MM-DD');
			let is = false;

			if(this.selectedPeriods?.length == 1){
				const [from , to] = this.selectedPeriods[0];
				is = (from==firstDay && to==lastDay);
			}
			// console.log('isAllMonthSelected', this.selectedPeriods, {firstDay, lastDay}, is);
			return is;
		}
	},
	methods: {
		initDatepicker(){
			const { datepickerRef } = this.$refs;

			const countMonthDays = dayjs(this.monthYear).daysInMonth();
			const minDate = dayjs(this.monthYear).date(1);
			const maxDate = dayjs(this.monthYear).date(countMonthDays);

			this.datepicker = new AirDatepicker(datepickerRef, {
				inline: true,
				// range: true,
				multipleDates: true,
				startDate: minDate,
				minDate, maxDate,

				onRenderCell: (data)=>{
					// console.log(data);
					const { date } = data;
					const countDay = dayjs(date).date();

					return {
						html: `<div class="cell-day">${ countDay }</div>`,
						classes: this.getClassStatus(date),
					};
				},
				onSelect: (data)=>{
					// console.log(data);
					const { date } = data;
					this.selectedDates = date.map(item=>{
						const formatDate = dayjs(item).format('YYYY-MM-DD');
						return new Date(formatDate);
					});
				}
			});
		},
		getClassStatus(date){
			const formatDate = dayjs(date).format('YYYY-MM-DD');
			const dayDate = this?.calendar?.[formatDate];

			if(dayDate?.status) return `type-${dayDate?.status}`;
			return 'type-free';
		},

		createDatesToPeriods(){
			const dates = [...this.selectedDates].sort((a, b) => a - b);
			const result = [];
			let currentPeriod = [dates[0]];

			if(!dates?.length) return result;

			for (let i = 1; i < dates.length; i++) {
				const diffTime = Math.abs(dates[i] - dates[i - 1]);
				const diffDays = diffTime / (1000 * 60 * 60 * 24);

				if (diffDays <= 1) {
					currentPeriod[1] = dates[i]; // Обновляем конечную дату
				} else {
					let startDate = currentPeriod[0];
					let endDate = currentPeriod[1];
					if(endDate===undefined) endDate = startDate;

					result.push([
						dayjs(startDate).format('YYYY-MM-DD'),
						dayjs(endDate).format('YYYY-MM-DD')
					]);
					currentPeriod = [dates[i]];
				}
			}

			let startDate = currentPeriod[0];
			let endDate = currentPeriod[1];
			if(endDate===undefined) endDate = startDate;

			result.push([
				dayjs(startDate).format('YYYY-MM-DD'),
				dayjs(endDate).format('YYYY-MM-DD')
			]);

			return result;
		},

		onAllChecked(is){
			// console.log(is);
			const countDay = dayjs(this.monthYear).daysInMonth();
			const minDate = dayjs(this.monthYear).date(1);
			const selectedDates = [];

			if(is) for(let i=1; i<=countDay; i++){
				let curDay = dayjs(minDate).date(i).toDate();
				selectedDates.push(curDay);
			}

			this.datepicker.update({ selectedDates });
			this.selectedDates = selectedDates;
		}
	},
	watch: {
		selectedDates(dates){
			helper.debounce({
				keyTimer: 'datepicker-selected-dates',
				duration: 100,
				action: ()=>{
					this.$emit('change', this.selectedPeriods);
				}
			});
		}
	},
	mounted() {
		this.initDatepicker();
	},
}