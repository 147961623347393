import { mapState, mapActions, mapWritableState } from 'pinia';
import { useMapDesignStore } from "@/app/stores/map-design-store";
import { useDesignFilterStore } from '@/app/stores/design-filter-store';

import { ElTabs, ElTabPane } from 'element-plus';



import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import DesignFilter from '@/app/components/design-filter/DesignFilter.vue';
import MapDesignInfo from '@/app/components/map/map-design-info/MapDesignInfo.vue';

export default {
	name: 'design-aside',
	components: {
		ElTabs, ElTabPane,
		LoaderSpinner,
		DesignFilter, MapDesignInfo,
	},
	data() {
		return {
			activeTab: 'design-filter',
			// activeTab: 'map-design-info',
			// activeTab: 'search-gid',

		}
	},
	computed: {
		...mapState(useMapDesignStore, ['detailDesignData'] ),
		...mapState(useDesignFilterStore, ['isFilterDataLoad'] ),

		designInfoLabel(){
			if(this.detailDesignData?.gid) return `РК(${this.detailDesignData.gid})`;
			return 'РК';
		}
	},
	watch: {
		designInfoLabel(){
			this.activeTab = 'map-design-info';
		}
	},
	mounted() {

	},
	unmounted() {

	},
}