import { mapState, mapActions, mapWritableState } from 'pinia';
import { useFiltersStore } from '@/app/stores/filters-store';

import cellMixin from "@/app/mixins/table/cell-mixin";

import PrRulesInfo from "@/app/components/pricing-policies/type-cell/pr-cell-rules/pr-rules-info/PrRulesInfo.vue";
import PrRulesEdit from "@/app/components/pricing-policies/type-cell/pr-cell-rules/pr-rules-edit/PrRulesEdit.vue";

import { typeList } from "./config-rules";

export default {
	name: 'pr-cell-rules',
	mixins: [cellMixin],
	components: {
		PrRulesInfo, PrRulesEdit,
	},
	emits: ['change'],
	data() {
		return {
			typeList,
			isEdit: false,
		}
	},
	computed: {
		rulesList(){
			const list = [];

			for(const item of this.typeList){
				const { key, type, keyBackOptions, label } = item;
				let value = this.fieldData?.[key];
				if(value==undefined) continue;

				if(Array.isArray(value) && value.length){
					const options = this.getOptions(keyBackOptions);
					value = options
						.filter(item=>value.includes(item.value))
						.map(item=>item.label);

					value = value.join('; ').trim();
					// else continue;
				}
				else if(type=='single-select'){
					const options = this.getOptions(keyBackOptions);
					const findItem = options.find(item=>item.value==value);

					value = findItem?.label ?? '';
					// else continue;
				}

				list.push({ key, label, value });
			}

			return list;
		}
	},
	methods: {
		...mapActions(useFiltersStore, ['getOptions']),

		cancelEdit(){
			this.isEdit = false;
		},
		saveRules(data){
			console.log('saveRules', data);
			this.$emit('change',{
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: data,
			});

			this.isEdit = false;
		}
	},
}