import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bs-aside__main-menu" }
const _hoisted_2 = {
  key: 0,
  class: "bs-aside__second-menu"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainMenu = _resolveComponent("MainMenu")
  const _component_ElAside = _resolveComponent("ElAside")

  return (_openBlock(), _createBlock(_component_ElAside, { class: "bs-aside" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MainMenu, { menuList: _ctx.mainMenuData }, null, 8 /* PROPS */, ["menuList"])
      ]),
      _createVNode(_Transition, {
        name: "el-fade-in",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_ctx.$route?.meta?.secondAside)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "default")
              ], 512 /* NEED_PATCH */)), [
                [_vShow, _ctx.isShowSecondAside]
              ])
            : _createCommentVNode("v-if", true)
        ]),
        _: 3 /* FORWARDED */
      })
    ]),
    _: 3 /* FORWARDED */
  }))
}