import { mapState, mapActions, mapWritableState } from 'pinia';
import { useFiltersStore } from '@/app/stores/filters-store';

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import BsSelectV2 from "@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue";
import BsInput from "@/app/components/controls/bs-input/BsInput.vue";

export default {
	name: 'pr-rules-edit-item',
	components: {
		BsSelectV2, SvgIcon, BsInput,
	},
	props: {
		data: {
			type: Object,
			default: {
				// key: 'customer',
				// keyBackOptions: '',
				// label: 'Отдел пользователя',
				// type: 'single-select',
				// placeholder: 'Все',
				// value: [] | '',
			},
		}
	},
	emits: ['remove'],
	computed: {
		selectOptions(){
			const { key, keyBackOptions } = this.data;
			if(keyBackOptions) return this.getOptions(keyBackOptions);

			return [];
		},
	},
	methods: {
		...mapActions(useFiltersStore, ['getOptions']),

		removeRule(){
			this.$emit('remove', this.data.key);
		}
	},
}