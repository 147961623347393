import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "map-search-panel" }
const _hoisted_2 = {
  class: "map-search-panel__btn-action",
  title: "Поиск"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "map-search-panel__suggest-wrap" }
const _hoisted_5 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BtnAction = _resolveComponent("BtnAction")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BtnAction, {
        name: "16-search",
        onClick: _ctx.onSearch
      }, null, 8 /* PROPS */, ["onClick"])
    ]),
    _createElementVNode("div", {
      class: "map-search-panel__input",
      title: _ctx.strSearch
    }, [
      _createVNode(_component_BsInput, {
        placeholder: "Введите адрес",
        modelValue: _ctx.strSearch,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.strSearch) = $event)),
        clearable: true
      }, null, 8 /* PROPS */, ["modelValue"])
    ], 8 /* PROPS */, _hoisted_3),
    _createVNode(_component_ElPopover, {
      visible: _ctx.isVisibleSuggestWrap,
      "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.isVisibleSuggestWrap) = $event)),
      offset: 6,
      trigger: "focus",
      width: _ctx.width,
      "popper-class": "map-search-panel__popover",
      placement: "bottom"
    }, {
      reference: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "map-search-panel__popover-target" }, null, -1 /* HOISTED */)
      ])),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestData, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "map-search-panel__suggest-item",
              key: item.id,
              onClick: $event => (_ctx.clickSuggestItem(item))
            }, _toDisplayString(item.value), 9 /* TEXT, PROPS */, _hoisted_5))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible", "width"])
  ]))
}