
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useTargetImportStore } from '@/app/stores/target-import-store';
import { useFiltersStore } from '@/app/stores/filters-store';

import { colConfigList } from "./table-config";
import common from '@/app/utils/common';
import helper from '@/app/utils/helper';

import { ElAutoResizer, ElTableV2, ElTableColumn } from "element-plus";

import BsCheckbox from "@/app/components/controls/bs-checkbox/BsCheckbox.vue";
import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';
import ImportCellStatus from "@/app/components/target-programs/target-import/import-cell-types/import-cell-status/ImportCellStatus.vue";
import ShortSelect from '@/app/components/controls/selects/short-select/ShortSelect.vue';
import CellPrice from '@/app/components/table/cell-types/cell-price/CellPrice.vue';
import ImportCellResult from '@/app/components/target-programs/target-import/import-cell-types/import-cell-result/ImportCellResult.vue';
import ImportDesignsInfo from '@/app/components/target-programs/target-import/import-cell-types/import-designs-info/ImportDesignsInfo.vue';
import ImportPlacePeriods from '@/app/components/target-programs/target-import/import-cell-types/import-place-periods/ImportPlacePeriods.vue';
import ImportShSelect from '@/app/components/target-programs/target-import/import-cell-types/import-sh-select/ImportShSelect.vue';
import ImportCellPrice from '@/app/components/target-programs/target-import/import-cell-types/import-cell-price/ImportCellPrice.vue';

export default {
	name: "target-import-table",
	components: {
		ElAutoResizer,
		ElTableV2, ElTableColumn,
		BsCheckbox, BsSelectV2,
		ImportCellStatus, ShortSelect,
		CellPrice, ImportCellResult, ImportDesignsInfo, ImportPlacePeriods,
		ImportShSelect, ImportCellPrice,
	},
	props:{
		tableData: {
			type: Array,
			default: [],
		}
	},
	emits: ['changeSelectionTable'],
	data() {
		return {
			colConfigList,
			selectedRowSet: new Set(),
			sidesMap: new Map(),

			frequency: 60,
			timing: 10
		};
	},
	computed: {
		allSelected(){
			const selectCount = this.selectedRowSet.size;
			const totalCount = this.tableData.length;

			return selectCount > 0 && selectCount == totalCount;
		},

		indeterminate(){
			const selectCount = this.selectedRowSet.size;
			const totalCount = this.tableData.length;

			return selectCount > 0 && selectCount < totalCount;
		}
	},
	methods: {
		...mapActions(useFiltersStore, ['getOptions']),
		...mapActions(useTargetImportStore, ['searchQuerySides']),

		getDefaultCell(col){
			return [
				'partnerGid', 'sideCode', 'esparId',
				'address',
			].includes(col.key);
		},

		onChangeAll(is){
			if(is) this.selectedRowSet = new Set(this.tableData);
			else this.selectedRowSet = new Set();

			this.$emit('changeSelectionTable', [...this.selectedRowSet]);
		},
		onChange(is, rowData){
			if(is) this.selectedRowSet.add(rowData);
			else this.selectedRowSet.delete(rowData);

			this.$emit('changeSelectionTable', [...this.selectedRowSet]);
		},
		isCheckedRow(rowData){
			return this.selectedRowSet.has(rowData);
		},

		clickItemPeriod(){
			console.log('clickItemPeriod');
		},

		getDurationOptions(rowData){
			const slotDuration = rowData?.blockAndSlot?.value?.slot;
			const blockDuration = rowData?.blockAndSlot?.value?.block;
			const options = common.createDurationOptions({ slotDuration, blockDuration }).options;
			const showsInHour = rowData?.showsInHour?.value || 60;

			return options.map(item=>{
				const { value, label } = item;

				return {
					value, label,
					disabled: ( 3600/showsInHour ) < value,
				}
			});
		},
		getOutFrequencyOptions(rowData){
			const blockDuration = rowData?.blockAndSlot?.value?.block;
			const options = common.createOutFrequencyOptions({ blockDuration , isShowHours: true }).options;
			const duration = rowData?.duration?.value || 5;

			return options.map(item=>{
				const { value, label } = item;

				return {
					value, label,
					disabled: ( 3600/duration ) < value,
				}
			});
		},

		changeCell(data){
			console.log(data);
			const { rowData, groupKey, fieldKey, fieldData } = data;

			rowData[fieldKey].value = fieldData;
			rowData[fieldKey].status = '';
		},

		searchSides(query, rowData){
			if(query.length < 3) return;
			const { params } = this.$route;
			const targetProgramId = Number(params?.id);

			console.log(rowData);
			helper.debounce({
				keyTimer:'search-query-sides',
				duration: 300,
				action: async ()=>{
					const res = await this.searchQuerySides({ targetProgramId, query });
					const { rowIndex } = rowData;
					console.log(res);
					if(!res?.sides?.length) return;

					this.sidesMap.set(rowIndex, res.sides );
				}
			});
		},

		getSidesOptions(rowData){
			const { rowIndex } = rowData;
			const sides = this.sidesMap.get(rowIndex) ?? [];
			return sides.map(item=>{
				const { id, innerId } = item;

				return {
					value: id,
					label: innerId,
				}
			});
		},
		changeSelectSide(id, rowData){
			const { rowIndex } = rowData;
			const sides = this.sidesMap.get(rowIndex) ?? [];
			const findItem = sides.find(item=>item.id==id);

			if(findItem){
				const { foundedInTp, slotDuration, blockDuration } = findItem ?? {};

				rowData['side'] = findItem;
				rowData['foundedInTp'] = !!foundedInTp;
				rowData['matching'] = 'single';

				if(findItem.type=='digital'){
					rowData['blockAndSlot'] = {
						value: {
							block: blockDuration,
							slot: slotDuration,
						},
						status: null,
					};
					rowData['duration'] = {
						value: slotDuration,
						status: null
					};
					rowData['showsInHour'] = {
						value: Math.ceil(3600/blockDuration),
						status: null
					};
				} else {
					rowData['blockAndSlot'] = {
						value: {
							block: '',
							slot: '',
						},
						status: '',
					};
					rowData['duration'] = {
						value: '',
						status: ''
					};
					rowData['showsInHour'] = {
						value: '',
						status: ''
					};
				}

				this.sidesMap.delete(rowIndex);
			}
		}

	},
	watch: {
		tableData(){
			this.sidesMap = new Map();
		}
	},
	created() {
		console.log('importData', this.tableData);
	},
};
