import { mapState, mapActions, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';
import dayjs from 'dayjs';

import { ElMessageBox, ElMessage, ElCollapseTransition } from 'element-plus';

import helper from '@/app/utils/helper';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import EditField from '@/app/components/controls/edit-field/EditField.vue';
import SummaryMonth from '@/app/components/target-programs/target-summary/summary-month/SummaryMonth.vue';
import SummaryServiceMonth from '@/app/components/target-programs/target-summary/summary-service-month/SummaryServiceMonth.vue';
import TargetManager from '@/app/components/target-programs/target-summary/target-manager/TargetManager.vue';
import TargetManagerList from '@/app/components/target-programs/target-summary/target-manager-list/TargetManagerList.vue';


export default {
	name: 'target-summary',
	components:{
		SvgIcon, ElCollapseTransition,
		EditField, SummaryMonth, SummaryServiceMonth,
		TargetManager, TargetManagerList,
	},
	data() {
		return {
			isOpenPlacement: true,
		}
	},
	computed: {
		...mapWritableState(useDetailTargetProgStore, ['detailTargetStatsData']),

		summaryData(){
			return this.detailTargetStatsData?.summaryData ?? {};
		},
		metricsData(){
			return this.detailTargetStatsData?.metricsData ?? {};
		},
		statsData(){
			return this.detailTargetStatsData?.statsData ?? {};
		},
		placementsData(){
			return this.detailTargetStatsData?.placementsData ?? [];
		},

		cityMetrics(){
			const list = [];

			for(const [key, item] of Object.entries(this.metricsData)){
				list.push({
					city: key,
					grpAvg: helper.getNum(item?.grpAvg ?? 0),
					grpSum: helper.getNum(item?.grpSummary ?? 0),
					otsSum: helper.getNum(item?.otsSummary ?? 0),
				});
			}

			return list;
		},

		placementMonth(){
			return this.placementsData.map(item=>{
				return {
					code: (item?.code ?? '').replace('№', ''),
					month: item?.month ?? '',
					price: item?.price ?? 0,
					discount: item?.discount ?? 0,
					agencyDiscount: item?.agentCommission ?? 0,
					personDiscount: item?.customerCommission ?? 0,
					totalPriceNotVat: item?.cost ?? 0,
					totalPrice: item?.costWithVat ?? 0,
				}
			});
		},

		totalPrice(){
			return this.statsData?.sumCostWithVat ?? 0;
		},
		totalPriceNotVat(){
			return this.statsData?.sumCost ?? 0;
		},
	},
	methods: {
		...mapActions(useDetailTargetProgStore, [
			'updateDetailTargetAsideItem', 'updateDetailTargetAsideResponsible', 'removeDetailTargetAsideManager',
			'addDetailTargetAsideManager', 'buildDetailTargetStatsData',
		]),

		getMoney: helper.getMoney,

		updateFieldState({ action, data, sucMessage, errMessage }={}){
			if(data){
				if(action) action();
				ElMessage({
					message: sucMessage ?? 'Данные успешно изменены',
					type: 'success',
				});
			} else {
				ElMessageBox.alert(null, null, {
					type: 'error',
					title: 'Ошибка!',
					message: errMessage ?? 'Что-то пошло не так...',
					customClass: 'bs-message-box',
				}).then(() => { }).catch(() => { });
			}
		},

		async changeEditField({ value, fieldData }, key){
			// console.log(value, fieldData);
			if(!value) return;

			const res = await this.updateDetailTargetAsideItem({ fieldKey: key, value });
			this.updateFieldState({ data: res, action: ()=>{
				fieldData.value = value;
			}});
		},
		async changeEditPeriod(period){
			const { from, to } = period;
			const formatFrom = dayjs(from).format('YYYY-MM');
			const formatTo = dayjs(to).format('YYYY-MM');

			const res = await this.updateDetailTargetAsideItem({
				fieldKey: 'period',
				value: [formatFrom, formatTo],
			});

			this.updateFieldState({
				data: res,
				action: ()=>{
					this.summaryData.period = period;
					if(res?.data) this.detailTargetStatsData = this.buildDetailTargetStatsData(res.data);
				},
			});
		},
		async changeEditResponsible({ value, fieldData }){
			const res = await this.updateDetailTargetAsideResponsible(value);
			this.updateFieldState({ data: res, action: ()=>{
				fieldData.value = value;
			}});
		},
		async changeManagerList({ value, fieldData }){
			console.log(value, fieldData);
			if(!value?.length){
				const removePromiseList = fieldData.value.map(id=>{
					return this.removeDetailTargetAsideManager(id);
				});
				const res = await Promise.allSettled(removePromiseList);
				const resFulfilled = res.filter(item=>item.status=='fulfilled' && item.value);

				this.updateFieldState({ data: res.length==resFulfilled.length, action: ()=>{
					fieldData.value = [];
				}});
			}
			else if(Array.isArray(value)){
				const [addList, removeList] = helper.compareArrays(fieldData.value, value);
				const removePromiseList = removeList.map(id=>{
					return this.removeDetailTargetAsideManager(id);
				});
				let addPromiseList = [];
				if(addList?.length) addPromiseList = [ this.addDetailTargetAsideManager(addList) ];

				const res = await Promise.allSettled([...addPromiseList, ...removePromiseList]);
				const resFulfilled = res.filter(item=>item.status=='fulfilled' && item.value);

				this.updateFieldState({ data: res.length==resFulfilled.length, action: ()=>{
					fieldData.value = value;
				}});
			} else {
				const res = await this.addDetailTargetAsideManager([value]);
				this.updateFieldState({ data: res, action: ()=>{
					fieldData.value = [value];
				}});
			}
		},
		async removeManager({id, fieldData}){
			console.log({id, fieldData});
			const res = await this.removeDetailTargetAsideManager(id);
			this.updateFieldState({ data: res, action: ()=>{
				const filterList = fieldData.value.filter(item=>item != id);
				fieldData.value = filterList;
			}});
		}
	},
}