import ymaps from 'ymaps';

class Common{
	get ymaps(){
		const apiKey = '8f68bc1c-daa8-4537-9b5c-e0267a8729d0';
		const apiKeySuggest = '4d431857-5531-4ac7-944b-ec851b9167bd';
		const docLang = document.documentElement.lang;

		let lang = 'ru_RU';
		if(docLang=='en') lang = 'en_US';

		return ymaps.load(`https://api-maps.yandex.ru/2.1/?lang=${lang}&apikey=${apiKey}&suggest_apikey=${apiKeySuggest}`);
	}

	/**
	 * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
	 * Статья на тему съедания пространства мобильным меню браузера,
	 * и как это фиксить
	 */
	fixVHMobileDevice(){
		// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
		let vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);

		// We listen to the resize event
		window.addEventListener('resize', () => {
			// We execute the same script as before
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		});
	}

	createDurationOptions({ slotDuration, blockDuration }) {
		const options = [];
		if(!slotDuration) slotDuration = 5;
		if(!blockDuration) blockDuration = 60;

		let count = slotDuration;

		while (count <= blockDuration) {
			options.push({
				value: count,
				label: count
			});

			count += slotDuration;
		}

		return {
			value: slotDuration,
			options,
		};
	}

	/**
	 * Создание опций частоты
	 * isShowHours - показов в час, если false - то частность раз в блок
	 */
	createOutFrequencyOptions({ blockDuration , isShowHours=false}){
		const options = [];
		if(!blockDuration) blockDuration = 60;

		const freqKf = 3600/blockDuration;

		for(let i=0; i<5; i++){
			const value = 1/(i+1);
			const label = Math.ceil(freqKf*value); // = (3600/blockDuration)*value

			if(String(label).includes('.')) continue;
			if(i==0){
				for(let j=4; j>1; j--){
					options.push({
						// value: value*j,
						value: (isShowHours? label: value)*j,
						label: label*j
					});
				}
			}

			options.push({ value: (isShowHours? label: value), label });
		}

		return {
			value: isShowHours? Math.ceil(freqKf): 1,
			options,
		};
	}
}

export default new Common();