import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "pr-setting-table" }
const _hoisted_2 = { class: "pr-setting-table__btn-settings" }
const _hoisted_3 = { class: "pr-setting-table__popover-container" }
const _hoisted_4 = { class: "pr-setting-table__list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "pr-setting-table__item-label" }
const _hoisted_7 = { class: "pr-setting-table__item-check" }
const _hoisted_8 = { class: "pr-setting-table__show-all" }
const _hoisted_9 = { class: "pr-setting-table__btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BtnAction = _resolveComponent("BtnAction")
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElPopover, {
      trigger: "click",
      visible: _ctx.isVisiblePopover,
      "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.isVisiblePopover) = $event)),
      offset: 8,
      persistent: false,
      width: "auto",
      placement: "bottom-start",
      "popper-class": "pr-setting-table__popover"
    }, {
      reference: _withCtx(() => [
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BtnAction, {
                name: "16-setting",
                title: "Настройка столбцов таблицы"
              })
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tmpColumns, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: item.key
              }, [
                (!item?.noSettings)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "pr-setting-table__item",
                      onClick: $event => (item.show = !item.show)
                    }, [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.cleanHtmlString(item.label)), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_BsCheckbox, {
                          modelValue: item.show,
                          "onUpdate:modelValue": $event => ((item.show) = $event),
                          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ], 8 /* PROPS */, _hoisted_5))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_BsBtn, { onClick: _ctx.clickShowAll }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Показать всё")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_BsBtn, { onClick: _ctx.clickCancel }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Отмена")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_BsBtn, {
              type: "primary",
              disabled: _ctx.isSaveDisabled,
              onClick: _ctx.clickSave
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Ок")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled", "onClick"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ]))
}