import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tprog-head-default" }
const _hoisted_2 = { class: "tprog-head-default__label" }
const _hoisted_3 = { class: "tprog-head-default__btn-sort-icon" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_HeadBtnSort = _resolveComponent("HeadBtnSort")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1 /* TEXT */),
    (_ctx.sort)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["tprog-head-default__btn-sort", { '--sort-active': _ctx.modelValue }]),
          onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => (_ctx.clickBtn && _ctx.clickBtn(...args)), ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SvgIcon, { name: "12-filter" })
          ]),
          _createVNode(_component_HeadBtnSort, {
            icoName: "12-sort-down",
            modelValue: _ctx.order,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.order) = $event))
          }, null, 8 /* PROPS */, ["modelValue"])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ]))
}