import dayjs from 'dayjs';

export default {
	computed: {
		compPeriod() {
			let { from, to } = this.period;
			let year = dayjs().year();
			let month = dayjs().month();
			let format = { from: '', to: '' };

			if (!from) from = dayjs({ year, month, day: 1 }).format('YYYY-MM-DD');
			if (!to) to = dayjs(from).add(11, 'month').format('YYYY-MM-DD');
			format = {
				from: dayjs(from).format('DD.MM'),
				to: dayjs(to).format('DD.MM'),
			};

			const monthFrom = dayjs(from).format('MM');
			const monthTo = dayjs(to).format('MM');
			if( monthFrom == monthTo ) format = {
				from: dayjs(from).format('DD'),
				to: dayjs(to).format('DD'),
			};

			return { from, to, format };
		},
		isWithinOneMonth(){
			const { from, to } = this.compPeriod;
			const monthFrom = dayjs(from).format('MM');
			const monthTo = dayjs(to).format('MM');

			return monthFrom == monthTo;
		},
		periodPosition() {
			const { from, to } = this.compPeriod;

			let keyFrom = dayjs(from).format('YYYY-MM');
			let keyTo = dayjs(to).format('YYYY-MM');
			let itemFrom = this.monthList.find(item => item.date == keyFrom);
			let itemTo = this.monthList.find(item => item.date == keyTo);

			// console.log(dayjs(to).diff(from, 'month'));
			if (dayjs(to).diff(from, 'month') > 11) {
				itemTo = this.monthList.at(-1);
			}
			if(keyFrom == keyTo){
				keyTo = dayjs(to).add(1,'months').format('YYYY-MM');
				itemTo = this.monthList.find(item=>item.date==keyTo);
				// console.log({keyTo, itemTo});
			}
			if (!itemFrom?.position || !itemTo?.position) return;

			let left = itemFrom.position.offsetLeft + itemFrom.position.offsetWidth / 2;
			let width = itemTo.position.offsetLeft - itemFrom.position.offsetLeft;

			if(this.isWithinOneMonth){
				left = itemFrom.position.offsetLeft + itemFrom.position.offsetWidth / 8;
				width = width * 0.7;
			}

			// console.log(left, width, itemFrom, itemTo);
			return { left, width };
		},
		monthList(){
			const { from } = this.compPeriod;
			const year = dayjs(from).year();
			const month = dayjs(from).month();
			let monthList = [];

			for(let i=0; i<12; i++){
				const date = dayjs({ year, month: ( month + i ), day: 1 });
				let position = null;
				if(this.monthElementData[date.format('YYYY-MM')]) position = this.monthElementData[date.format('YYYY-MM')];

				monthList.push({
					date: date.format('YYYY-MM'),
					title: date.format('MMM').replace('.', ''),
					position,
				});
			}
			// console.log(monthList);
			return monthList;
		},
	},
}