import { ElPopover } from 'element-plus';
import 'element-plus/es/components/popover/style/css';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'table-com-panel',
	components: {
		ElPopover,
		SvgIcon,
	},
	props: {
		commandList: {
			type: Array,
			default: [],
		},
		count: Number,
	},
	emits: ['action'],
	data() {
		return {
			isOpenOtherMenu: false,
			// count: 15,
			// commandList: [
			// 	{
			// 		key: 'add-in-archive',
			// 		label: 'Добавить в архив',
			// 	},
			// 	{
			// 		key: 'delete',
			// 		label: 'Удалить',
			// 	},
			// 	{
			// 		key: 'add',
			// 		label: 'Обновить',
			// 	},
			// 	{
			// 		key: 'save',
			// 		label: 'Сохранить',
			// 	},
			// 	{
			// 		key: 'com-1',
			// 		label: 'Комманда-1',
			// 	},
			// 	{
			// 		key: 'com-2',
			// 		label: 'Комманда-2',
			// 	},
			// 	{
			// 		key: 'com-3',
			// 		label: 'Комманда-3',
			// 	},
			// ],
		}
	},
	computed: {
		commandVisibleList(){
			return this.commandList.slice(0,3);
		},
		commandOtherList(){
			return this.commandList.slice(3);
		}
	},
	methods: {
		clickCommand(item){
			console.log('clickCommand', item);
			this.$emit('action', item.key);

			this.isOpenOtherMenu = false;
		}
	},
}