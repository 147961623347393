import cellMixin from "@/app/mixins/table/cell-mixin";

import ShortSelect from "@/app/components/controls/selects/short-select/ShortSelect.vue";

export default {
	name: 'tprog-cell-is-vat',
	mixins: [ cellMixin ],
	components: {
		ShortSelect,
	},
	props: {
		options: {
			type: Array,
			default: [],
		}
	},
	emits: ['change'],
	data() {
		return {
			fieldValue: 'unknown',
		}
	},
	methods: {
		changeSelect(value){
			this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: value,
			});
		},
		setField(val){
			if(!val) this.fieldValue = 'unknown';

			this.fieldValue = val;
		}
	},
	watch: {
		fieldData(val){
			this.setField(val);
		}
	},
	created() {
		if(this.fieldData) this.setField(this.fieldData);
	},
}