import { ElDialog } from "element-plus";
import 'element-plus/es/components/dialog/style/css';

import BsBtn from "../../controls/buttons/bs-btn/BsBtn.vue";

export default {
	name: 'oper-error-popup',
	components: {
		ElDialog,
		BsBtn
	},
	props: {
		modelValue:{
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue'],
	computed: {
		visible: {
			get(is){
				return this.modelValue;
			},
			set(is){
				this.$emit('update:modelValue', is);
			},
		},
	},
}