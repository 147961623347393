import { defineStore } from 'pinia';
import dayjs from 'dayjs';

import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';


export const useAllTargetProgStore = defineStore('allTargetProgStore', {
	state() {
		return {
			isAsideLoader: true,
			isViewLoader: true,


			allTargetFilterData: {
				name: {
					value: '',
					keyBack: 'name',
				},
				category: {
					value: '',
					keyBack: 'category',
				},
				placePeriod:{
					from:'',
					to: '',
					keyBack: 'period',
				},
				city: {
					value: '',
					keyBack: 'cities',
				},
				agency: {
					value: '',
					keyBack: 'agency',
				},
				customer: {
					value: '',
					keyBack: 'customers',
				},
				brand: {
					value: '',
					keyBack: 'brands',
				},
				product: {
					value: '',
					keyBack: 'product',
				},
				customerCategories: {
					value: '',
					keyBack: 'customerCategories',
				},

				responsible: {
					value: '',
					keyBack: 'managers',
				},
				department: {
					value: '',
					keyBack: 'departments',
				},
				managers: {
					value: '',
					keyBack: 'otherManagers',
				},
				totalPrice: { select: '', input: '' },
				expiringStatus: {
					value: '',
					keyBack: 'expiringStatus',
				}
			},
			allTargetSortData: null,
			allTargetCategoriesCount: null,
			allTargetPaginationData: null,
			allTargetTableData: [],

			compareOptions: [
				// {
				// 	label: '=',
				// 	value: '='
				// },
				{
					label: '<',
					value: '<'
				},
				{
					label: '>',
					value: '>'
				},
			],

			expiringStatusOptions: [
				{
					value: 'today',
					label: 'Сегодня',
				},
				{
					value: 'tomorrow',
					label: 'Завтра',
				},
				{
					value: 'expired',
					label: 'Просрочен',
				},
			],
		}
	},

	getters: { },
	actions: {
		getSubmitFilterData(){
			let data = {};
			for(const [key, filterData] of Object.entries(this.allTargetFilterData)){
				const { keyBack } = filterData;
				if(key=='placePeriod'){
					const { from, to } = filterData;
					if( from || to ) data[keyBack] = `${from};${to}`;
				}
				else if(key == 'totalPrice'){
					const { select, input } = filterData;
					if(input) data[key] = [ select, input ];
				}
				else {
					const { value } = filterData;
					if(value?.length) data[keyBack] = value;
				}
			}
			return data;
		},

		setFilterField(filters){
			if(!filters) return;

			const addSelectParams = ({ keyBack, value }) => {
				const findFilter = Object.entries(this.allTargetFilterData).find(([key, data])=>{
					return data.keyBack == keyBack;
				});
				if(findFilter){
					const [ key, filterData ] = findFilter;
					filterData.value = value || '';
				}
			}

			for(let [key, value] of Object.entries(filters)){
				if(key=='period'){
					const [ from, to ] = value.split(';');
					if(from) this.allTargetFilterData.placePeriod.from = from;
					if(to) this.allTargetFilterData.placePeriod.to = to;
				}
				else if(key == 'totalPrice'){
					const [ select, input ] = value;
					this.allTargetFilterData.totalPrice = { select, input };
				}
				else {
					addSelectParams({ keyBack: key, value });
				}
			}
		},

		buildAllTargetTableData(data){
			if(!data?.length) return [];
			const allTargetTableData = [];

			for(const item of data){
				allTargetTableData.push({
					...item,
					createdDate: dayjs(item?.created_at).toDate(),
					updatedDate: dayjs(item?.updated_at).toDate(),
					// period: formatPeriod,
					title: item.name,
					responsible: item?.manager,
					city: item?.cities ?? [],
					client: item?.customer,
					priceNotVAT: item.cost,
					// notification: 0,
				});
			}
			console.log('allTargetTableData', allTargetTableData);

			return allTargetTableData;
		},
		async getAllTargetListData({filters, sort, params}={}){
			this.isViewLoader = true;
			let data = {};

			// Установка дефолтных параметров filter
			if(!filters){
				data.filters = this.getSubmitFilterData(),
				console.log(data);
			}

			// Установка дефолтных параметров sort
			if(sort) {
				data.sort = sort
			} else if(this.allTargetSortData && Object.keys(this.allTargetSortData).length > 0) {
				data.sort = this.allTargetSortData;
			}

			try {
				const res = await api.postData({ model: 'target-program/list', data,
					params: {
						// per_page: '20',
						...params,
					}
				});
				console.log('getAllTargetListData', res);

				if(res?.data) this.allTargetTableData = this.buildAllTargetTableData(res.data);
				if(res?.categoriesCount) this.allTargetCategoriesCount = res.categoriesCount;
				if(res?.meta) this.allTargetPaginationData = res.meta;

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
			}

			this.isViewLoader = false;
			return;
		},
		setSortData(sortData){
			this.allTargetSortData = sortData;
		},
		clearFilters(){
			for( const [key, item] of Object.entries(this.allTargetFilterData) ){
				if(key=='placePeriod') this.allTargetFilterData[key] = {
					...item,
					from: '',
					to: '',
				}
				else if(key=='totalPrice') this.allTargetFilterData[key] = { select: '', input: '' };
				else item.value = '';
			}
		}
	},
});