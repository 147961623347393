import { defineStore } from 'pinia';
import api from '@/app/utils/service-api';
import router from '@/app/router';

export const useUserStore = defineStore('userStore', {
	state() {
		return {
			returnUrl: null,
			user: {},
		}
	},
	getters: {
		signed() {
			return !!this.user?.id;
		},
	},
	actions: {
		setReturnUrl(url) {
			this.returnUrl = url;
		},
		getUser() {
			return api.getData({
				model: 'user',
			}).then((res) => {
				this.user = res;
			}).catch((error) => {
				console.error(error);
			});
		},

		async signIn(email, password) {
			try {
				const res = await api.postData({
					model: 'sign-in',
					data: { email, password }
				});

				// console.log(res);
				if(res?.id){
					this.user = res;
					router.push('/structures');
				}

				return res;
			} catch (error) {
				console.error(error);
			}

			return;
		},
	}
})