import { ElImage } from 'element-plus';
import 'element-plus/es/components/image/style/css';

import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'tprog-cell-photo',
	mixins: [ cellMixin ],
	components: {
		ElImage,
	},
	computed: {
		firstPhoto(){
			if(!this.fieldData?.length) return '';
			const firstPhoto = this.fieldData[0];
			// console.log(firstPhoto);
			return firstPhoto;
		},
		gallery(){
			if(!this.fieldData?.length) return [];
			return this.fieldData;
		}
	},
}