
import { mapState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';

export default {
	name: 'side-queue-personal-item',
	components :{
	},
	props: {
		data: Object,
	},
	data() {
		return {
			personalTextClass: ''
		}
	},
	computed: {
		...mapState(useRootStore, ['statusMap2']),
        
		personalText() {
			switch (this.data?.status) {
				case this.statusMap2.temporary_approved:
					this.personalTextClass = 'personal-text-green'
					return `Ваше временное подтверждение (${this.data?.date || ''})`;

				case this.statusMap2.approved:
					this.personalTextClass = 'personal-text-green'
					return `Ваше подтверждение (${this.data?.date || ''})`

				default:
					this.personalTextClass = 'personal-text-blue'
					return `Вы ${this.data?.position || ''} в очереди (${this.data?.date || ''})`
			}
		}
	},
	methods: {
	},
}