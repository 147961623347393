import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tprog-cell-place-type" }
const _hoisted_2 = { class: "tprog-cell-place-type__short-select" }
const _hoisted_3 = {
  key: 0,
  class: "tprog-cell-place-type__info"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ShortSelect = _resolveComponent("ShortSelect")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["tprog-cell-place-type__bg-status", _ctx.getPlaceStatus])
    }, null, 2 /* CLASS */),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ShortSelect, {
        modelValue: _ctx.fieldValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.fieldValue) = $event)),
        options: _ctx.options,
        filtrable: true,
        onChange: _ctx.changeSelect
      }, null, 8 /* PROPS */, ["modelValue", "options", "onChange"])
    ]),
    (_ctx.getPlaceStatus=='status-warning')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Истекает " + _toDisplayString(_ctx.formatDeadline), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}