import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "design-filter" }
const _hoisted_2 = { class: "design-filter__info" }
const _hoisted_3 = { class: "design-filter__info-block" }
const _hoisted_4 = { class: "design-filter__info-btns" }
const _hoisted_5 = { class: "design-filter__container" }
const _hoisted_6 = { class: "design-filter__form" }
const _hoisted_7 = {
  class: "control design-filter__control-temp",
  "data-control-type": "filter-template"
}
const _hoisted_8 = { class: "control__group group-row gap-24 flex-no-wrap" }
const _hoisted_9 = {
  class: "control",
  "data-control-type": "address-program"
}
const _hoisted_10 = { class: "control__group" }
const _hoisted_11 = { class: "control" }
const _hoisted_12 = { class: "control__group" }
const _hoisted_13 = {
  class: "control design-filter__control-period",
  "data-control-type": "place-period"
}
const _hoisted_14 = { class: "control__group group-col" }
const _hoisted_15 = { class: "control__group group-row" }
const _hoisted_16 = { class: "control design-filter__control-side-direct" }
const _hoisted_17 = { class: "control__group" }
const _hoisted_18 = { class: "control design-filter__control-mech" }
const _hoisted_19 = { class: "control__group" }
const _hoisted_20 = { class: "control" }
const _hoisted_21 = { class: "control__group" }
const _hoisted_22 = { class: "control design-filter__control-construct-type" }
const _hoisted_23 = { class: "control__group" }
const _hoisted_24 = { class: "control design-filter__control-side-size" }
const _hoisted_25 = { class: "control__group" }
const _hoisted_26 = { class: "control" }
const _hoisted_27 = { class: "control__group" }
const _hoisted_28 = { class: "control" }
const _hoisted_29 = { class: "control__group group-row" }
const _hoisted_30 = { class: "control full-free-width design-filter__control-status" }
const _hoisted_31 = { class: "control__group" }
const _hoisted_32 = { class: "control full-free-width design-filter__control-status" }
const _hoisted_33 = { class: "control__group" }
const _hoisted_34 = { class: "control design-filter__control-network" }
const _hoisted_35 = { class: "control__group" }
const _hoisted_36 = { class: "control" }
const _hoisted_37 = { class: "control__group group-row" }
const _hoisted_38 = { class: "control full-free-width" }
const _hoisted_39 = { class: "control__group" }
const _hoisted_40 = { class: "control full-free-width" }
const _hoisted_41 = { class: "control__group" }
const _hoisted_42 = { class: "control design-filter__builder" }
const _hoisted_43 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_44 = { class: "control design-filter__builder" }
const _hoisted_45 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_46 = { class: "control design-filter__builder" }
const _hoisted_47 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_48 = {
  key: 0,
  class: "control design-filter__block block-digital"
}
const _hoisted_49 = { class: "control__head-arrow" }
const _hoisted_50 = { class: "control__group group-col" }
const _hoisted_51 = { class: "control" }
const _hoisted_52 = { class: "control__group group-row" }
const _hoisted_53 = { class: "control full-free-width" }
const _hoisted_54 = { class: "control__group" }
const _hoisted_55 = { class: "control full-free-width" }
const _hoisted_56 = { class: "control__group" }
const _hoisted_57 = { class: "control" }
const _hoisted_58 = { class: "control__group group-row" }
const _hoisted_59 = { class: "control full-free-width" }
const _hoisted_60 = { class: "control__group" }
const _hoisted_61 = { class: "control full-free-width" }
const _hoisted_62 = { class: "control__group" }
const _hoisted_63 = { class: "control" }
const _hoisted_64 = { class: "control__group group-row" }
const _hoisted_65 = { class: "control full-free-width" }
const _hoisted_66 = { class: "control__group" }
const _hoisted_67 = { class: "control" }
const _hoisted_68 = { class: "control__group" }
const _hoisted_69 = {
  class: "control design-filter__control-search-gid",
  "data-control-type": "search-gid"
}
const _hoisted_70 = { class: "design-filter__btn-submit" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FilterInfo = _resolveComponent("FilterInfo")
  const _component_DirectSideInfo = _resolveComponent("DirectSideInfo")
  const _component_TempSettings = _resolveComponent("TempSettings")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsSingleSelect = _resolveComponent("BsSingleSelect")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_BsRadioGroup = _resolveComponent("BsRadioGroup")
  const _component_BsDatePicker = _resolveComponent("BsDatePicker")
  const _component_BsCheckboxGroup = _resolveComponent("BsCheckboxGroup")
  const _component_BsMultiSelect = _resolveComponent("BsMultiSelect")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")
  const _component_DesignSearchGid = _resolveComponent("DesignSearchGid")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FilterInfo, { listData: _ctx.filterInfoData }, null, 8 /* PROPS */, ["listData"]),
        _createVNode(_component_DirectSideInfo, { data: _ctx.directInfoData }, null, 8 /* PROPS */, ["data"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_TempSettings, {
          modelValue: _ctx.settingList,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.settingList) = $event)),
          onOnChange: _cache[1] || (_cache[1] = () => {})
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", {
          class: "design-filter__btn-reset",
          title: "Сбросить фильтры",
          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.clear && _ctx.clear(...args)))
        }, [
          _createVNode(_component_SvgIcon, { name: "24-refresh-left" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("form", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[36] || (_cache[36] = _createElementVNode("div", { class: "control__label" }, "Шаблон фильтра", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_BsSingleSelect, {
              placeholder: "Выберите из списка",
              "no-match-text": "Шаблона не найдено",
              modelValue: _ctx.template.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.template.value) = $event)),
              options: _ctx.template.options,
              clearable: true,
              filterable: true,
              showRemoved: true,
              onChange: _ctx.changeFilterTemplate,
              onRemove: _ctx.removeTemplate
            }, null, 8 /* PROPS */, ["modelValue", "options", "onChange", "onRemove"]),
            _createVNode(_component_BsBtn, {
              type: "primary",
              tag: "div",
              onClick: _ctx.clickSaveTemplate
            }, {
              default: _withCtx(() => _cache[35] || (_cache[35] = [
                _createTextVNode("Сохранить")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_9, [
          _cache[37] || (_cache[37] = _createElementVNode("div", { class: "control__label" }, "Использовать АП", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Введите название",
              modelValue: _ctx.targetProgram.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.targetProgram.value) = $event)),
              options: _ctx.getOptions('targetProgram'),
              size: "large",
              clearable: true,
              filterable: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['address-program']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_11, [
          _cache[38] || (_cache[38] = _createElementVNode("div", { class: "control__label" }, "Город", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Введите название",
              modelValue: _ctx.city.value,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.city.value) = $event)),
              options: _ctx.getOptions('city'),
              size: "large",
              multiple: "",
              showCheckBox: true,
              clearable: true,
              filterable: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['city']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_13, [
          _cache[39] || (_cache[39] = _createElementVNode("div", { class: "control__label" }, "Период размещения", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_BsRadioGroup, {
              modelValue: _ctx.placePeriodType.value,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.placePeriodType.value) = $event)),
              options: _ctx.placePeriodType.options
            }, null, 8 /* PROPS */, ["modelValue", "options"]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_BsDatePicker, {
                modelValue: _ctx.placePeriodDate.from,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.placePeriodDate.from) = $event)),
                placeholder: `${_ctx.placeholderDatePicker}, С`,
                type: _ctx.periodDatePicker,
                size: "large",
                format: "DD.MM.YYYY",
                "value-format": "YYYY-MM-DD",
                clearable: false,
                "disabled-date": _ctx.disabledDateFrom,
                onChange: _ctx.changeDatePickerFrom
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "type", "disabled-date", "onChange"]),
              _createVNode(_component_BsDatePicker, {
                modelValue: _ctx.placePeriodDate.to,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.placePeriodDate.to) = $event)),
                placeholder: `${_ctx.placeholderDatePicker}, По`,
                type: _ctx.periodDatePicker,
                size: "large",
                format: "DD.MM.YYYY",
                "value-format": "YYYY-MM-DD",
                clearable: false,
                "disabled-date": _ctx.disabledDateTo,
                onChange: _ctx.changeDatePickerTo
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "type", "disabled-date", "onChange"])
            ])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['place-period']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_16, [
          _cache[40] || (_cache[40] = _createElementVNode("div", { class: "control__label" }, "Направление стороны", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_BsCheckboxGroup, {
              modelValue: _ctx.direction.value,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.direction.value) = $event)),
              list: _ctx.getOptions('direction')
            }, null, 8 /* PROPS */, ["modelValue", "list"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['direction']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_18, [
          _cache[41] || (_cache[41] = _createElementVNode("div", { class: "control__label" }, "Механизм РС", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_BsCheckboxGroup, {
              modelValue: _ctx.mechanism.value,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.mechanism.value) = $event)),
              list: _ctx.getOptions('mechanism')
            }, null, 8 /* PROPS */, ["modelValue", "list"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['side-mech']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_20, [
          _cache[42] || (_cache[42] = _createElementVNode("div", { class: "control__label" }, "Категория рекламной конструкции", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Все",
              modelValue: _ctx.structureCategory.value,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.structureCategory.value) = $event)),
              options: _ctx.getOptions('structureCategories'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 5,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['structureCategory']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_22, [
          _cache[43] || (_cache[43] = _createElementVNode("div", { class: "control__label" }, "Тип рекламной конструкции", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_BsMultiSelect, {
              placeholder: "Все",
              modelValue: _ctx.structureType.value,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.structureType.value) = $event)),
              options: _ctx.getOptions('structureType'),
              clearable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 3
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['structureType']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_24, [
          _cache[44] || (_cache[44] = _createElementVNode("div", { class: "control__label" }, "Размер РС (Ш x Д, м)", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_25, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Все",
              modelValue: _ctx.size.value,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.size.value) = $event)),
              options: _ctx.getOptions('size'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 5,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['side-size']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_26, [
          _cache[45] || (_cache[45] = _createElementVNode("div", { class: "control__label" }, "Освещение", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_BsCheckboxGroup, {
              modelValue: _ctx.lighted.value,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.lighted.value) = $event)),
              list: _ctx.getOptions('lighting')
            }, null, 8 /* PROPS */, ["modelValue", "list"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['lighted']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _withDirectives(_createElementVNode("div", _hoisted_30, [
              _cache[46] || (_cache[46] = _createElementVNode("div", { class: "control__label" }, "Статус", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_BsMultiSelect, {
                  placeholder: "Выберите из списка",
                  modelValue: _ctx.bookingStatus.value,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.bookingStatus.value) = $event)),
                  options: _ctx.getOptions('bookingStatus'),
                  clearable: true,
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 3
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ], 512 /* NEED_PATCH */), [
              [_vShow, _ctx.settingsShowMap['bookingStatus']]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_32, [
              _cache[47] || (_cache[47] = _createElementVNode("div", { class: "control__label" }, "Сервисный статус", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_33, [
                _createVNode(_component_BsMultiSelect, {
                  placeholder: "Выберите из списка",
                  modelValue: _ctx.serviceStatus.value,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.serviceStatus.value) = $event)),
                  options: _ctx.getOptions('serviceStatus'),
                  clearable: true,
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 3
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ], 512 /* NEED_PATCH */), [
              [_vShow, _ctx.settingsShowMap['serviceStatus']]
            ])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['serviceStatus'] || _ctx.settingsShowMap['bookingStatus']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_34, [
          _cache[48] || (_cache[48] = _createElementVNode("div", { class: "control__label" }, "Сеть", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_35, [
            _createVNode(_component_BsCheckboxGroup, {
              modelValue: _ctx.net.value,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.net.value) = $event)),
              list: _ctx.getOptions('net')
            }, null, 8 /* PROPS */, ["modelValue", "list"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['net']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_36, [
          _createElementVNode("div", _hoisted_37, [
            _withDirectives(_createElementVNode("div", _hoisted_38, [
              _cache[49] || (_cache[49] = _createElementVNode("div", { class: "control__label" }, "Оператор", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_39, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Выберите из списка",
                  modelValue: _ctx.operator.value,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.operator.value) = $event)),
                  options: _ctx.getOptions('operator'),
                  clearable: true,
                  filterable: true,
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 3,
                  size: "large",
                  multiple: "",
                  showCheckBox: true
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ], 512 /* NEED_PATCH */), [
              [_vShow, _ctx.settingsShowMap['operator']]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_40, [
              _cache[50] || (_cache[50] = _createElementVNode("div", { class: "control__label" }, "Собственник", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_41, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: "Выберите из списка",
                  modelValue: _ctx.owner.value,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => ((_ctx.owner.value) = $event)),
                  options: _ctx.getOptions('owner'),
                  clearable: true,
                  filterable: true,
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 3,
                  size: "large",
                  multiple: "",
                  showCheckBox: true
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ], 512 /* NEED_PATCH */), [
              [_vShow, _ctx.settingsShowMap['owner']]
            ])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['owner'] || _ctx.settingsShowMap['countFreeSide']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_42, [
          _cache[51] || (_cache[51] = _createElementVNode("div", { class: "control__label" }, "Прайс/месяц (без НДС)", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_43, [
            _createVNode(_component_BsSingleSelect, {
              placeholder: "=",
              modelValue: _ctx.priceBefore.select,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => ((_ctx.priceBefore.select) = $event)),
              options: _ctx.compareOptions,
              clearable: true
            }, null, 8 /* PROPS */, ["modelValue", "options"]),
            _createVNode(_component_BsInput, {
              placeholder: "Введите данные",
              modelValue: _ctx.priceBefore.input,
              "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => ((_ctx.priceBefore.input) = $event)),
              type: "number",
              min: "0",
              step: "0.01",
              clearable: true
            }, null, 8 /* PROPS */, ["modelValue"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['priceBefore']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_44, [
          _cache[52] || (_cache[52] = _createElementVNode("div", { class: "control__label" }, "OTS", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_45, [
            _createVNode(_component_BsSingleSelect, {
              placeholder: "=",
              modelValue: _ctx.otsData.select,
              "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => ((_ctx.otsData.select) = $event)),
              options: _ctx.compareOptions,
              clearable: true
            }, null, 8 /* PROPS */, ["modelValue", "options"]),
            _createVNode(_component_BsInput, {
              placeholder: "Введите данные",
              modelValue: _ctx.otsData.input,
              "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => ((_ctx.otsData.input) = $event)),
              type: "number",
              min: "0",
              step: "0.01",
              clearable: true
            }, null, 8 /* PROPS */, ["modelValue"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['ots']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_46, [
          _cache[53] || (_cache[53] = _createElementVNode("div", { class: "control__label" }, "GRP", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_47, [
            _createVNode(_component_BsSingleSelect, {
              placeholder: "=",
              modelValue: _ctx.grpData.select,
              "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => ((_ctx.grpData.select) = $event)),
              options: _ctx.compareOptions,
              clearable: true
            }, null, 8 /* PROPS */, ["modelValue", "options"]),
            _createVNode(_component_BsInput, {
              placeholder: "Введите данные",
              modelValue: _ctx.grpData.input,
              "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => ((_ctx.grpData.input) = $event)),
              type: "number",
              min: "0",
              step: "0.01",
              clearable: true
            }, null, 8 /* PROPS */, ["modelValue"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['grp']]
        ]),
        _createVNode(_Transition, { name: "el-fade-in" }, {
          default: _withCtx(() => [
            (_ctx.isShowDigitalBlock)
              ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["control__head", { '--is-open-group': _ctx.isOpenDigitalGroup }]),
                    onClick: _cache[26] || (_cache[26] = $event => (_ctx.isOpenDigitalGroup = !_ctx.isOpenDigitalGroup))
                  }, [
                    _cache[54] || (_cache[54] = _createElementVNode("div", { class: "control__head-label" }, "Настройки для Digital", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_49, [
                      _createVNode(_component_SvgIcon, { name: "14-arrow-down" })
                    ])
                  ], 2 /* CLASS */),
                  _createVNode(_component_ElCollapseTransition, null, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("div", _hoisted_50, [
                        _withDirectives(_createElementVNode("div", _hoisted_51, [
                          _createElementVNode("div", _hoisted_52, [
                            _withDirectives(_createElementVNode("div", _hoisted_53, [
                              _cache[55] || (_cache[55] = _createElementVNode("div", { class: "control__label" }, "Размер диджитал блока", -1 /* HOISTED */)),
                              _createElementVNode("div", _hoisted_54, [
                                _createVNode(_component_BsMultiSelect, {
                                  placeholder: "Выберите количество секунд",
                                  modelValue: _ctx.blockDuration.value,
                                  "onUpdate:modelValue": _cache[27] || (_cache[27] = $event => ((_ctx.blockDuration.value) = $event)),
                                  options: _ctx.getOptions('blockDuration'),
                                  clearable: true,
                                  filterable: true,
                                  "collapse-tags": "",
                                  "collapse-tags-tooltip": "",
                                  "max-collapse-tags": 8
                                }, null, 8 /* PROPS */, ["modelValue", "options"])
                              ])
                            ], 512 /* NEED_PATCH */), [
                              [_vShow, _ctx.settingsShowMap['digital-block-size']]
                            ]),
                            _withDirectives(_createElementVNode("div", _hoisted_55, [
                              _cache[56] || (_cache[56] = _createElementVNode("div", { class: "control__label" }, "Частота выходов", -1 /* HOISTED */)),
                              _createElementVNode("div", _hoisted_56, [
                                _createVNode(_component_BsSelectV2, {
                                  placeholder: "Любая",
                                  modelValue: _ctx.frequency.value,
                                  "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => ((_ctx.frequency.value) = $event)),
                                  options: _ctx.getOptions('frequency'),
                                  clearable: true,
                                  filterable: true,
                                  size: "large"
                                }, null, 8 /* PROPS */, ["modelValue", "options"])
                              ])
                            ], 512 /* NEED_PATCH */), [
                              [_vShow, _ctx.settingsShowMap['out-frequency']]
                            ])
                          ])
                        ], 512 /* NEED_PATCH */), [
                          [_vShow, _ctx.settingsShowMap['out-frequency'] || _ctx.settingsShowMap['digital-block-size']]
                        ]),
                        _withDirectives(_createElementVNode("div", _hoisted_57, [
                          _createElementVNode("div", _hoisted_58, [
                            _withDirectives(_createElementVNode("div", _hoisted_59, [
                              _cache[57] || (_cache[57] = _createElementVNode("div", { class: "control__label" }, "Размер digital слота", -1 /* HOISTED */)),
                              _createElementVNode("div", _hoisted_60, [
                                _createVNode(_component_BsMultiSelect, {
                                  placeholder: "Выберите количество секунд",
                                  modelValue: _ctx.slotDuration.value,
                                  "onUpdate:modelValue": _cache[29] || (_cache[29] = $event => ((_ctx.slotDuration.value) = $event)),
                                  options: _ctx.getOptions('slotDuration'),
                                  clearable: true,
                                  filterable: true,
                                  "collapse-tags": "",
                                  "collapse-tags-tooltip": "",
                                  "max-collapse-tags": 8
                                }, null, 8 /* PROPS */, ["modelValue", "options"])
                              ])
                            ], 512 /* NEED_PATCH */), [
                              [_vShow, _ctx.settingsShowMap['digital-slot-size']]
                            ]),
                            _withDirectives(_createElementVNode("div", _hoisted_61, [
                              _cache[58] || (_cache[58] = _createElementVNode("div", { class: "control__label" }, "Длина ролика", -1 /* HOISTED */)),
                              _createElementVNode("div", _hoisted_62, [
                                _createVNode(_component_BsInput, {
                                  placeholder: "Введите количество секунд",
                                  modelValue: _ctx.digitalVideoLength,
                                  "onUpdate:modelValue": _cache[30] || (_cache[30] = $event => ((_ctx.digitalVideoLength) = $event)),
                                  type: "number",
                                  min: "1",
                                  step: "1"
                                }, null, 8 /* PROPS */, ["modelValue"])
                              ])
                            ], 512 /* NEED_PATCH */), [
                              [_vShow, _ctx.settingsShowMap['digital-video-length']]
                            ])
                          ])
                        ], 512 /* NEED_PATCH */), [
                          [_vShow, _ctx.settingsShowMap['digital-block-size'] || _ctx.settingsShowMap['digital-video-length']]
                        ]),
                        _withDirectives(_createElementVNode("div", _hoisted_63, [
                          _createElementVNode("div", _hoisted_64, [
                            _withDirectives(_createElementVNode("div", _hoisted_65, [
                              _cache[59] || (_cache[59] = _createElementVNode("div", { class: "control__label" }, "WiFi ловушка", -1 /* HOISTED */)),
                              _createElementVNode("div", _hoisted_66, [
                                _createVNode(_component_BsSingleSelect, {
                                  placeholder: "Неважно",
                                  modelValue: _ctx.wifiTrap.value,
                                  "onUpdate:modelValue": _cache[31] || (_cache[31] = $event => ((_ctx.wifiTrap.value) = $event)),
                                  options: _ctx.getOptions('wifiTrap'),
                                  clearable: true,
                                  filterable: true
                                }, null, 8 /* PROPS */, ["modelValue", "options"])
                              ])
                            ], 512 /* NEED_PATCH */), [
                              [_vShow, _ctx.settingsShowMap['wifiTrap']]
                            ]),
                            _cache[60] || (_cache[60] = _createElementVNode("div", { class: "control full-free-width" }, null, -1 /* HOISTED */))
                          ])
                        ], 512 /* NEED_PATCH */), [
                          [_vShow, _ctx.settingsShowMap['wifiTrap']]
                        ])
                      ], 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.isOpenDigitalGroup]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _withDirectives(_createElementVNode("div", _hoisted_67, [
          _cache[61] || (_cache[61] = _createElementVNode("div", { class: "control__label" }, "Тэги", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_68, [
            _createVNode(_component_BsMultiSelect, {
              placeholder: "Выберите из списка",
              modelValue: _ctx.tag.value,
              "onUpdate:modelValue": _cache[32] || (_cache[32] = $event => ((_ctx.tag.value) = $event)),
              options: _ctx.tag.options,
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 3
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['tag']]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_69, [
          _createVNode(_component_DesignSearchGid, {
            data: _ctx.searchGidData,
            "onUpdate:data": _cache[33] || (_cache[33] = $event => ((_ctx.searchGidData) = $event)),
            isOpen: _ctx.isOpenSearchGid,
            onOpen: _ctx.openSearchGid,
            onSubmit: _cache[34] || (_cache[34] = () => {})
          }, null, 8 /* PROPS */, ["data", "isOpen", "onOpen"])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.settingsShowMap['search-gid']]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_70, [
      _createVNode(_component_BsBtn, {
        type: "primary",
        onClick: _ctx.submit
      }, {
        default: _withCtx(() => _cache[62] || (_cache[62] = [
          _createTextVNode("Выбрать")
        ])),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ])
  ]))
}