import { defineStore } from 'pinia';
import { useDesignFilterStore } from './design-filter-store';
import { useDetailTargetProgStore } from './detail-target-prog-store';

import dayjs from 'dayjs';

import helper from '@/app/utils/helper';
import api from '@/app/utils/service-api';

export const useMapTargetStore = defineStore('mapTargetStore', {
	state() {
		return {
			isLoaderMap: true,

			markerList: [],
			searchMarker: null,
			designsData: [],
			clusterList: [],
			checkMapMarkerView: false,

			typeDesignIcon: {
				'другое': 'type-other',
				'пиллар': 'type-cityformat',
				'cити-формат': 'type-cityformat',
				'ситиборд': 'type-cityboard',
				'суперсайт': 'type-supersite',
				'арка': 'type-arch',
				'щит': 'type-screen',
				'тумба': 'type-table',
				'медиафасад': 'type-media-facade',
				'остановки': 'type-halt-2',
				'индор': 'type-indore',
			},
		}
	},

	getters: {
		markerMap(state){
			let map = {};
			for( let item of state.markerList ){
				map[item.code] = item;
			}
			return map;
		},

	},
	actions: {
		/**
		 * Получение данных карты
		 * @param {*} data - данные выбранных фильтров
		 */
		async getMapData({id, filterData}){
			const detailTargetStore = useDetailTargetProgStore();

			// Установка дефолтных параметров
			if(!filterData){
				const { getSubmitDetailFilterData } = detailTargetStore;
				filterData = getSubmitDetailFilterData();
			}

			this.isLoaderMap = true;
			try {
				let res = await api.postData({
					model: `target-program/${id}/map`,
					data: filterData,
				});
				console.log('getMapData', res);

				if(res?.data) this.buildMapData(res.data);
				// if(res?.total) filterStore.totalFilters = res.total;

				this.isLoaderMap = false;
				return res;
			} catch (error) {
				console.error(error);
			}

			this.isLoaderMap = false;
			return false;
		},

		/** Форматирование данных маркеров для карты */
		buildMapData(data){
			if(!data) return;
			const list = [];
			const nullCoordMarkerList = [];


			for( let item of data ){
				const { id, gid, latitude, longitude, sides } = item;
				if( !Number(latitude) || !Number(longitude)){
					nullCoordMarkerList.push(item);
					// continue; // с нулевыми координатами пропускать
				}

				list.push({
					code: helper.hash,
					id, gid,
					coords: [ latitude, longitude ],
					types: [],
					structData: item,
					statusInfo: this.getMarkerStatusInfo(item.sides),
				});
			}

			console.log('nullCoordList', nullCoordMarkerList);
			// console.log(list);
			this.designsData = list;
		},

		/** Получение информации статусов занятости для маркера */
		getMarkerStatusInfo(sides){
			const statusMarker = {
				free: false,
				reserve: false,
				busy: false,
			};
			const statusSidesMap = new Map();
			const detailTargetStore = useDetailTargetProgStore();
			const { detailTargetStatsData, detailTargetFilterData } = detailTargetStore;
			const { summaryData } = detailTargetStatsData ?? {};
			const { period } = summaryData ?? {};

			let countMonth = dayjs(period?.to).diff(period?.from, 'month', true);
			countMonth = Math.ceil(countMonth);

			// если период фильтра не выбран то будет показываться период текущего месяца
			if(!countMonth) countMonth = 1;

			for( let i=0; i<countMonth; i++ ){
				const formatDate = dayjs(period?.from).add(i, 'months').format('YYYY-MM');

				if(sides?.length) for(let item of sides){
					const { isDigital, occupancy, id, inTargetProgram } = item;
					let statusSide = statusSidesMap.get(id);
					if(!statusSide) statusSide = {
						free: false,
						reserve: false,
						busy: false,
						added: inTargetProgram,
					}

					const { free, reserve, busy } = statusSide;
					if( free && reserve && busy ) break;

					const monthData = occupancy?.[formatDate];
					if(!monthData){
						statusSidesMap.set(id, statusSide);
						continue;
					}

					if(isDigital){
						const { free, reserved, busy } = monthData;
						const { outFrequency, digitalVideoLength, slotDuration, blockDuration } = detailTargetFilterData;
						let duration = 5;
						let frequency = 1;
						let block = 60;
						let slot = 5;

						if(digitalVideoLength) duration = Number(digitalVideoLength);
						if(outFrequency?.value) frequency = Number(outFrequency.value);

						const countOutHour = 60*60 / block; // Количество выходов в час стандартного хроно, равного слоту
						const timeFree = free * slot; // свободно в часе
						const timeReserved = reserved * slot; // зарезервировано в часе

						// const totalTime = timeFree + timeReserved;
						const personTime = Math.ceil(duration * (frequency * countOutHour)); // Сколько нужно пользователю

						if(free && timeFree >= personTime) statusSide.free = true;
						else if( reserved && timeReserved >= personTime ) statusSide.reserve = true;
						else statusSide.busy = true;


					} else {
						if(monthData?.status == 'free') statusSide.free = true;
						else if(monthData?.status == 'reserved') statusSide.reserve = true;
						else if(monthData?.status == 'busy') statusSide.busy = true;
					}

					statusSidesMap.set(id, statusSide);
				}
			}

			const statusSides = [];
			for( const [id, item] of statusSidesMap.entries() ){
				const { free, reserve, busy } = item
				statusSides.push({ id, ...item });

				statusMarker.free = statusMarker.free || free;
				statusMarker.reserve = statusMarker.reserve || reserve;
				statusMarker.busy = statusMarker.busy || busy;
			}

			return {
				marker: statusMarker,
				sides: statusSides,
			};
		},
	},
});