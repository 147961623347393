import { ElSelect, ElOption, ElBadge } from 'element-plus';
import 'element-plus/es/components/select/style/css';
import 'element-plus/es/components/option/style/css';


export default {
	name: 'TargetSelectMonth',
	components: {
		ElSelect, ElOption, ElBadge,
	},
	props: {
		modelValue: String,
		options: Array,
	},
	emits: ['update:modelValue', 'change'],
	data() {
		return {
			value: this.modelValue,
		}
	},
	computed: {
		total(){
			let total = 0;
			for(let item of this.options){
				total += item.count;
			}
			return total;
		},
		curOpt(){
			return this.options.find( item=>item.value==this.value );
		}
	},
	methods: {
		change(value){
			this.$emit('change', value);
		}
	},
	watch: {
		modelValue(val){
			this.value = val;
		},
		value(val){
			this.$emit('update:modelValue', val);
		}
	},
}