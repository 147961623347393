import { ElSelectV2, ElCheckbox } from "element-plus";
import 'element-plus/es/components/select-v2/style/css';
import 'element-plus/es/components/checkbox/style/css';

export default {
	name: 'bs-select-v2',
	components: {
		ElSelectV2, ElCheckbox
	},
	props:{
		modelValue: [Number, String, Array],
		showCheckBox: Boolean,
		// options: {
		// 	type: Array,
		// 	default: [],
		// },
		teleported: {
			type: Boolean,
			default: true,
		}
	},
	emits: ['update:modelValue'],
	computed: {
		valueData: {
			get(){
				return this.modelValue || [];
			},
			set(val){
				this.$emit('update:modelValue', val);
			}
		},
		popoverClass(){
			const classList = ['bs-select-v2__popover'];

			if(this.showCheckBox) classList.push('--show-checkbox');

			return classList.join(' ');
		}
	},
}