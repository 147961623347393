import { mapState, mapActions, mapWritableState } from 'pinia';
import { usePricingDetailStore } from '@/app/stores/pricing-policies/pricing-detail-store';

import { ElTabs, ElTabPane, ElBadge, ElMessageBox, ElMessage } from 'element-plus';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import EditField from '@/app/components/controls/edit-field/EditField.vue';
import PricingSummary from '@/app/components/pricing-policies/aside/pricing-summary/PricingSummary.vue';
import PricingFilter from '@/app/components/pricing-policies/aside/pricing-filter/PricingFilter.vue';

export default {
	name: 'pricing-detail-aside',
	components: {
		ElTabs, ElTabPane, ElBadge,
		LoaderSpinner, EditField,
		PricingSummary, PricingFilter,
	},
	data() {
		return {
			countFilter: 2,
			activeTab: 'pricing-summary',
			// activeTab: 'pricing-filter',
		}
	},
	computed: {
		...mapState( usePricingDetailStore, ['isAsideLoader', 'countFilters'] ),
		...mapWritableState( usePricingDetailStore, ['summaryData'] ),
	},
	methods: {
		async changeName(title){
			this.summaryData.title = title;

			// const res = await this.updateDetailTargetAsideItem({ fieldKey: 'name', value });

			// if(res){
			// 	this.defaultData.name = value;
			// 	ElMessage({
			// 		message: 'Данные успешно измененыю',
			// 		type: 'success',
			// 	});
			// } else {
			// 	ElMessageBox.alert(null, null, {
			// 		type: 'error',
			// 		title: 'Ошибка!',
			// 		message: `Что-то пошло не так...`,
			// 		customClass: 'bs-message-box',
			// 	}).then(() => { }).catch(() => { });
			// }
		}
	},
}