import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pr-cell-coef" }
const _hoisted_2 = { class: "pr-cell-coef__input" }
const _hoisted_3 = { class: "pr-cell-coef__checkbox" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PrCellEditNum = _resolveComponent("PrCellEditNum")
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PrCellEditNum, {
        data: { row: _ctx.rowData },
        groupKey: _ctx.groupKey,
        fieldKey: _ctx.fieldKey,
        onChange: _ctx.changeNum
      }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BsCheckbox, {
        modelValue: _ctx.isMultipCoefficient,
        onChange: _ctx.changeMultip
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Перемнож.")
        ])),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "onChange"])
    ])
  ]))
}