import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'marker-side-system',
	components: {
		SvgIcon,
	},
	props: {
		sideList: {
			type: Array,
			default: [],
		},
	},
	data() {
		return {
			testAngle: 0,
			spinner: false,
			designType: {
				1: 'design-line',
				2: 'design-line',
				3: 'design-triangle',
				4: 'design-square',
			}
		}
	},
	computed: {
		angle(){
			const findSide = this.sideList[0];
			if(findSide) return 90 - findSide.angle;
			if(this.spinner) return this.testAngle;
			return 0;
		},
		angleDirect(){
			const angle = (-1)*this.angle;
			return `transform: translate(-50%, -50%) rotate(${angle}deg)`;
		},
		countSides(){
			return this.sideList.length;
		},
	},
	methods: {
		getSideClass(item, index){
			return {
				'--is-side-active': item?.selected,
				'--is-side-digital': item?.isDigital || item?.is_digital,
				'--is-side-in-target': item?.inTargetProgram,
				[`direct-${index}`]: true,
			}
		}
	},
	mounted(){
		// this.spinner = true;
		if(this.spinner) setInterval(() => {
			this.testAngle += 5;
		}, 100);
	}
}