import { ElSelect, ElOption, ElCheckbox } from 'element-plus';
import 'element-plus/es/components/select/style/css';
import 'element-plus/es/components/option/style/css';
import 'element-plus/es/components/checkbox/style/css';

export default {
	name: 'bs-multi-select',
	components: {
		ElSelect, ElOption, ElCheckbox,
	},
	props: {
		modelValue: [String, Array],
		options: Array,
		placeholder: String,
		filterable: {
			type: Boolean,
			default: false,
		},
		noMatchText: {
			type: String,
			default: '',
		},
		showAll: {
			type: Boolean,
			default: false,
		},
		showCheckBox: {
			type: Boolean,
			default: true,
		},
		teleported: {
			type: Boolean,
			default: true,
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			value: this.modelValue || [],
			checkAll: true,
		}
	},
	watch: {
		value(val){
			// console.log(val);
			this.$emit('update:modelValue', val);
		},
		modelValue(val){
			this.value = val;
		}
	},
}