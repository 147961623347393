import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "tprog-detail-table" }
const _hoisted_2 = {
  key: 1,
  class: "tprog-detail-table__head-cell"
}
const _hoisted_3 = {
  key: 0,
  class: "tprog-detail-table__cell cell-design-info"
}
const _hoisted_4 = {
  key: 1,
  class: "tprog-detail-table__cell cell-photo"
}
const _hoisted_5 = {
  key: 2,
  class: "tprog-detail-table__cell cell-created-at"
}
const _hoisted_6 = {
  key: 3,
  class: "tprog-detail-table__cell cell-employ-info"
}
const _hoisted_7 = {
  key: 4,
  class: "tprog-detail-table__cell cell-place-periods"
}
const _hoisted_8 = {
  key: 5,
  class: "tprog-detail-table__cell cell-deadline-date"
}
const _hoisted_9 = {
  key: 7,
  class: "tprog-detail-table__cell cell-period-month-days"
}
const _hoisted_10 = {
  key: 8,
  class: "tprog-detail-table__cell cell-place-type"
}
const _hoisted_11 = {
  key: 9,
  class: "tprog-detail-table__cell cell-category-customer"
}
const _hoisted_12 = {
  key: 10,
  class: "tprog-detail-table__cell cell-category-customer"
}
const _hoisted_13 = {
  key: 11,
  class: "tprog-detail-table__cell cell-product"
}
const _hoisted_14 = {
  key: 12,
  class: "tprog-detail-table__cell cell-manager"
}
const _hoisted_15 = {
  key: 13,
  class: "tprog-detail-table__cell cell-eng-manager"
}
const _hoisted_16 = {
  key: 14,
  class: "tprog-detail-table__cell cell-edit-link"
}
const _hoisted_17 = {
  key: 15,
  class: "tprog-detail-table__cell cell-frequency"
}
const _hoisted_18 = {
  key: 16,
  class: "tprog-detail-table__cell cell-coefficient"
}
const _hoisted_19 = {
  key: 17,
  class: "tprog-detail-table__cell cell-is-vat"
}
const _hoisted_20 = {
  key: 18,
  class: "tprog-detail-table__cell cell-documents"
}
const _hoisted_21 = {
  key: 19,
  class: "tprog-detail-table__cell cell-person"
}
const _hoisted_22 = {
  key: 20,
  class: "tprog-detail-table__cell cell-price"
}
const _hoisted_23 = {
  key: 21,
  class: "tprog-detail-table__cell cell-percent"
}
const _hoisted_24 = {
  key: 22,
  class: "tprog-detail-table__cell cell-count-price"
}
const _hoisted_25 = {
  key: 23,
  class: "tprog-detail-table__cell"
}
const _hoisted_26 = {
  key: 0,
  class: "tprog-detail-table__cell"
}
const _hoisted_27 = { key: 1 }
const _hoisted_28 = {
  key: 1,
  class: "tprog-detail-table__cell"
}
const _hoisted_29 = { class: "tprog-detail-table__head-cell" }
const _hoisted_30 = {
  key: 0,
  class: "tprog-detail-table__cell cell-price"
}
const _hoisted_31 = {
  key: 1,
  class: "tprog-detail-table__cell cell-percent"
}
const _hoisted_32 = {
  key: 2,
  class: "tprog-detail-table__cell cell-check-price"
}
const _hoisted_33 = {
  key: 0,
  class: "coordination-comment"
}
const _hoisted_34 = { class: "popover-content" }
const _hoisted_35 = { class: "tprog-detail-table__head-cell" }
const _hoisted_36 = {
  key: 0,
  class: "tprog-detail-table__cell cell-price"
}
const _hoisted_37 = {
  key: 1,
  class: "tprog-detail-table__cell cell-percent"
}
const _hoisted_38 = {
  key: 2,
  class: "tprog-detail-table__cell cell-check-price"
}
const _hoisted_39 = {
  key: 0,
  class: "tprog-detail-table__cell"
}
const _hoisted_40 = { key: 1 }
const _hoisted_41 = {
  key: 1,
  class: "tprog-detail-table__cell"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTableColumn = _resolveComponent("ElTableColumn")
  const _component_TprogHeadDesign = _resolveComponent("TprogHeadDesign")
  const _component_TprogHeadDefault = _resolveComponent("TprogHeadDefault")
  const _component_TprogDesignInfo = _resolveComponent("TprogDesignInfo")
  const _component_TprogCellPhoto = _resolveComponent("TprogCellPhoto")
  const _component_TprogCreatedAt = _resolveComponent("TprogCreatedAt")
  const _component_TprogEmployInfo = _resolveComponent("TprogEmployInfo")
  const _component_TprogPlacePeriods = _resolveComponent("TprogPlacePeriods")
  const _component_TprogDeadlineDate = _resolveComponent("TprogDeadlineDate")
  const _component_TprogCellStatus = _resolveComponent("TprogCellStatus")
  const _component_TprogPeriodMonthDays = _resolveComponent("TprogPeriodMonthDays")
  const _component_TprogCellPlaceType = _resolveComponent("TprogCellPlaceType")
  const _component_TprogCategoryCustomer = _resolveComponent("TprogCategoryCustomer")
  const _component_TprogCellPromotion = _resolveComponent("TprogCellPromotion")
  const _component_TprogCellBrand = _resolveComponent("TprogCellBrand")
  const _component_TprogCellManager = _resolveComponent("TprogCellManager")
  const _component_CellEditLink = _resolveComponent("CellEditLink")
  const _component_TprogCellFreq = _resolveComponent("TprogCellFreq")
  const _component_CellCoefficient = _resolveComponent("CellCoefficient")
  const _component_TprogCellIsVat = _resolveComponent("TprogCellIsVat")
  const _component_TprogCellDocuments = _resolveComponent("TprogCellDocuments")
  const _component_TprogCellPerson = _resolveComponent("TprogCellPerson")
  const _component_CellPrice = _resolveComponent("CellPrice")
  const _component_CellEditable = _resolveComponent("CellEditable")
  const _component_TprogCellPercent = _resolveComponent("TprogCellPercent")
  const _component_TprogEditCountPrice = _resolveComponent("TprogEditCountPrice")
  const _component_CellNumber = _resolveComponent("CellNumber")
  const _component_TprogCheckPrice = _resolveComponent("TprogCheckPrice")
  const _component_ElPopover = _resolveComponent("ElPopover")
  const _component_ElTable = _resolveComponent("ElTable")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "tprog-detail-table__coordination" }, null, -1 /* HOISTED */)),
    _createVNode(_component_ElTable, {
      class: "tprog-detail-table__container",
      onSelectionChange: _ctx.selectionChange,
      stripe: "",
      "summary-method": _ctx.getSummaries,
      "show-summary": "",
      data: _ctx.detailTargetGridData,
      "scrollbar-always-on": true,
      "header-cell-class-name": "tprog-detail-table__th",
      "cell-class-name": "tprog-detail-table__td",
      "row-class-name": _ctx.CheckRow
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElTableColumn, {
          type: "selection",
          width: "38",
          fixed: "left",
          "header-align": "center"
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colConfigList, (col) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: col.key
          }, [
            (!['hr'].includes(col.key) && col.show)
              ? (_openBlock(), _createBlock(_component_ElTableColumn, {
                  key: 0,
                  prop: col.key,
                  label: col.label,
                  "min-width": col.width,
                  resizable: !['designInfo'].includes(col.key),
                  fixed: (col.key=='designInfo')?'left':false,
                  "class-name": _ctx.getCellClass(col),
                  "header-align": "center",
                  align: "center"
                }, {
                  header: _withCtx((scope) => [
                    (col.key=='designInfo')
                      ? (_openBlock(), _createBlock(_component_TprogHeadDesign, {
                          key: 0,
                          colData: col,
                          onChangeCompact: _ctx.changeCompactHeadDesign,
                          onClickSettings: _cache[0] || (_cache[0] = () => {})
                        }, null, 8 /* PROPS */, ["colData", "onChangeCompact"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(_component_TprogHeadDefault, {
                            modelValue: col.order,
                            "onUpdate:modelValue": $event => ((col.order) = $event),
                            label: col.label,
                            sort: col.sort,
                            onChange: $event => (_ctx.sortTable($event, col.keyProp))
                          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "sort", "onChange"])
                        ]))
                  ]),
                  default: _withCtx((scope) => [
                    (col.key=='designInfo')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_TprogDesignInfo, {
                            data: scope,
                            fieldKey: col.keyProp,
                            cityMap: _ctx.cityMap,
                            compact: _ctx.isDesignInfoCompact,
                            onChangeComment: _ctx.changeComment
                          }, null, 8 /* PROPS */, ["data", "fieldKey", "cityMap", "compact", "onChangeComment"])
                        ]))
                      : (col.key=='photo')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_component_TprogCellPhoto, {
                              data: scope,
                              groupKey: col.keyGroup,
                              fieldKey: col.keyProp
                            }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                          ]))
                        : (col.key=='createdAt')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createVNode(_component_TprogCreatedAt, {
                                data: scope,
                                groupKey: col.keyGroup,
                                fieldKey: col.keyProp
                              }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                            ]))
                          : (col.key=='placementMonths')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_TprogEmployInfo, {
                                  data: scope,
                                  groupKey: col.keyGroup,
                                  fieldKey: col.keyProp,
                                  onClickMonth: _ctx.clickEmployMonth
                                }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onClickMonth"])
                              ]))
                            : (col.key=='placementPeriods')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                  _createVNode(_component_TprogPlacePeriods, {
                                    data: scope,
                                    groupKey: col.keyGroup,
                                    fieldKey: col.keyProp,
                                    onClickPeriod: _ctx.clickItemPeriod
                                  }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onClickPeriod"])
                                ]))
                              : (col.key=='deadlineDate')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                    _createVNode(_component_TprogDeadlineDate, {
                                      data: scope,
                                      groupKey: col.keyGroup,
                                      fieldKey: col.keyProp
                                    }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                                  ]))
                                : (col.key=='status')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 6,
                                      class: "tprog-detail-table__cell cell-status",
                                      ref_for: true,
                                      ref: "refCellStatus"
                                    }, [
                                      _createVNode(_component_TprogCellStatus, {
                                        data: scope,
                                        groupKey: col.keyGroup,
                                        fieldKey: col.keyProp,
                                        options: _ctx.statusList,
                                        onChange: _ctx.changeDefaultCell,
                                        onExtendReserve: _ctx.extendStatusReserve
                                      }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange", "onExtendReserve"])
                                    ], 512 /* NEED_PATCH */))
                                  : (col.key=='periodInMonthDays')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                        _createVNode(_component_TprogPeriodMonthDays, {
                                          data: scope,
                                          groupKey: col.keyGroup,
                                          fieldKey: col.keyProp,
                                          onChange: _ctx.changeDefaultCell
                                        }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
                                      ]))
                                    : (col.key=='placeType')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                          _createVNode(_component_TprogCellPlaceType, {
                                            data: scope,
                                            groupKey: col.keyGroup,
                                            fieldKey: col.keyProp,
                                            options: _ctx.getOptions('placement_type'),
                                            onChange: _ctx.changeDefaultCell
                                          }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"])
                                        ]))
                                      : (col.key=='customerCategory')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                            _createVNode(_component_TprogCategoryCustomer, {
                                              data: scope,
                                              groupKey: col.keyGroup,
                                              fieldKey: col.keyProp,
                                              options: _ctx.getOptions('customer_category'),
                                              onChange: _ctx.changeDefaultCell
                                            }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"])
                                          ]))
                                        : (col.key=='promotion')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                              _createVNode(_component_TprogCellPromotion, {
                                                data: scope,
                                                groupKey: col.keyGroup,
                                                fieldKey: col.keyProp,
                                                options: _ctx.getOptions('promo'),
                                                onChange: _ctx.changeDefaultCell
                                              }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"])
                                            ]))
                                          : (col.key=='product')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                                _createVNode(_component_TprogCellBrand, {
                                                  data: scope,
                                                  groupKey: col.keyGroup,
                                                  fieldKey: col.keyProp,
                                                  options: _ctx.getOptions('product'),
                                                  onChange: _ctx.changeDefaultCell
                                                }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"])
                                              ]))
                                            : (col.key=='manager')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                                  _createVNode(_component_TprogCellManager, {
                                                    data: scope,
                                                    groupKey: col.keyGroup,
                                                    fieldKey: col.keyProp,
                                                    options: _ctx.managerList,
                                                    onChange: _ctx.changeDefaultCell
                                                  }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"])
                                                ]))
                                              : (col.key=='engManager')
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                                    _createVNode(_component_TprogCellManager, {
                                                      data: scope,
                                                      groupKey: col.keyGroup,
                                                      fieldKey: col.keyProp,
                                                      options: _ctx.managerList,
                                                      onChange: _ctx.changeDefaultCell
                                                    }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"])
                                                  ]))
                                                : (_ctx.getEditLinkField(col.key))
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                                      _createVNode(_component_CellEditLink, {
                                                        data: scope,
                                                        groupKey: col.keyGroup,
                                                        fieldKey: col.keyProp,
                                                        onChange: _ctx.changeEditableCell
                                                      }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
                                                    ]))
                                                  : (col.key=='frequency')
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                                        _createVNode(_component_TprogCellFreq, {
                                                          data: scope,
                                                          groupKey: col.keyGroup,
                                                          fieldKey: col.keyProp,
                                                          onChange: _ctx.changeDefaultCell
                                                        }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
                                                      ]))
                                                    : (col.key=='coefficient')
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                                          _createVNode(_component_CellCoefficient, {
                                                            data: scope,
                                                            groupKey: col.keyGroup,
                                                            fieldKey: col.keyProp,
                                                            onChange: _ctx.changeEditableCell
                                                          }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
                                                        ]))
                                                      : (col.key=='isVAT')
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                                            _createVNode(_component_TprogCellIsVat, {
                                                              data: scope,
                                                              groupKey: col.keyGroup,
                                                              fieldKey: col.keyProp,
                                                              options: _ctx.vatOptions,
                                                              onChange: _ctx.changeDefaultCell
                                                            }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"])
                                                          ]))
                                                        : (col.key=='documents')
                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                                              _createVNode(_component_TprogCellDocuments, {
                                                                data: scope,
                                                                groupKey: col.keyGroup,
                                                                fieldKey: col.keyProp
                                                              }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                                                            ]))
                                                          : (_ctx.getPersonField(col.key))
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                                                _createVNode(_component_TprogCellPerson, {
                                                                  data: scope,
                                                                  groupKey: col.keyGroup,
                                                                  fieldKey: col.keyProp,
                                                                  options: _ctx.getOptions(col.keyOptions),
                                                                  onChange: _ctx.changeDefaultCell
                                                                }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"])
                                                              ]))
                                                            : (_ctx.getPriceField(col.key))
                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                                                  (col.editable)
                                                                    ? (_openBlock(), _createBlock(_component_CellEditable, {
                                                                        key: 0,
                                                                        data: scope,
                                                                        groupKey: col.keyGroup,
                                                                        fieldKey: col.keyProp,
                                                                        onChange: _ctx.changeEditableCell
                                                                      }, {
                                                                        default: _withCtx(() => [
                                                                          _createVNode(_component_CellPrice, {
                                                                            data: scope,
                                                                            groupKey: col.keyGroup,
                                                                            fieldKey: col.keyProp
                                                                          }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                                                                        ]),
                                                                        _: 2 /* DYNAMIC */
                                                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "groupKey", "fieldKey", "onChange"]))
                                                                    : (_openBlock(), _createBlock(_component_CellPrice, {
                                                                        key: 1,
                                                                        data: scope,
                                                                        groupKey: col.keyGroup,
                                                                        fieldKey: col.keyProp
                                                                      }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"]))
                                                                ]))
                                                              : (_ctx.getPercentField(col.key))
                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                                                    (col.editable)
                                                                      ? (_openBlock(), _createBlock(_component_CellEditable, {
                                                                          key: 0,
                                                                          data: scope,
                                                                          groupKey: col.keyGroup,
                                                                          fieldKey: col.keyProp,
                                                                          typeField: "percent",
                                                                          onChange: _ctx.changeEditableCell
                                                                        }, {
                                                                          default: _withCtx(() => [
                                                                            _createVNode(_component_TprogCellPercent, {
                                                                              data: scope,
                                                                              groupKey: col.keyGroup,
                                                                              fieldKey: col.keyProp
                                                                            }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                                                                          ]),
                                                                          _: 2 /* DYNAMIC */
                                                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "groupKey", "fieldKey", "onChange"]))
                                                                      : (_openBlock(), _createBlock(_component_TprogCellPercent, {
                                                                          key: 1,
                                                                          data: scope,
                                                                          groupKey: col.keyGroup,
                                                                          fieldKey: col.keyProp
                                                                        }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"]))
                                                                  ]))
                                                                : (_ctx.getEditCountPriceField(col.key))
                                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                                                      _createVNode(_component_TprogEditCountPrice, {
                                                                        data: scope,
                                                                        groupKey: col.keyGroup,
                                                                        fieldKey: col.keyProp,
                                                                        onChange: _ctx.changeEditableCell
                                                                      }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
                                                                    ]))
                                                                  : (['grp', 'ots'].includes(col.key))
                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                                                        _createVNode(_component_CellNumber, {
                                                                          data: scope,
                                                                          groupKey: col.keyGroup,
                                                                          fieldKey: col.keyProp
                                                                        }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                                                                      ]))
                                                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 24 }, [
                                                                        (scope.row?.[col.keyGroup]?.[col.keyProp] != undefined)
                                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                                                              (col.editable)
                                                                                ? (_openBlock(), _createBlock(_component_CellEditable, {
                                                                                    key: 0,
                                                                                    data: scope,
                                                                                    groupKey: col.keyGroup,
                                                                                    fieldKey: col.keyProp,
                                                                                    onChange: _ctx.changeEditableCell
                                                                                  }, {
                                                                                    default: _withCtx(() => [
                                                                                      _createTextVNode(_toDisplayString(scope.row?.[col.keyGroup]?.[col.keyProp]), 1 /* TEXT */)
                                                                                    ]),
                                                                                    _: 2 /* DYNAMIC */
                                                                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "groupKey", "fieldKey", "onChange"]))
                                                                                : (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(scope.row?.[col.keyGroup]?.[col.keyProp]), 1 /* TEXT */))
                                                                            ]))
                                                                          : (_openBlock(), _createElementBlock("div", _hoisted_28, "–"))
                                                                      ], 64 /* STABLE_FRAGMENT */)),
                    (col.group?.length && col.key=='pricePlacePeriod')
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 25 }, _renderList(col.group, (column, index) => {
                          return (_openBlock(), _createBlock(_component_ElTableColumn, {
                            key: column.key,
                            prop: column.key,
                            label: column.label,
                            "min-width": column.width,
                            resizable: !['designInfo', 'employ', 'queue'].includes(column.key),
                            fixed: (column.key=='designInfo')?'left':false,
                            "class-name": _ctx.getPlacePeriodClass(column, col, index),
                            "header-align": "center",
                            align: "center"
                          }, {
                            header: _withCtx((scope) => [
                              _createElementVNode("div", _hoisted_29, [
                                _createVNode(_component_TprogHeadDefault, {
                                  modelValue: column.order,
                                  "onUpdate:modelValue": $event => ((column.order) = $event),
                                  label: column.label,
                                  sort: column.sort,
                                  onChange: $event => (_ctx.sortTable($event, column.keyProp))
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "sort", "onChange"])
                              ])
                            ]),
                            default: _withCtx((scope) => [
                              (_ctx.getPriceField(column.key))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                    (column.editable)
                                      ? (_openBlock(), _createBlock(_component_CellEditable, {
                                          key: 0,
                                          data: scope,
                                          groupKey: column.keyGroup,
                                          fieldKey: column.keyProp,
                                          onChange: _ctx.changeEditableCell
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_CellPrice, {
                                              data: scope,
                                              groupKey: column.keyGroup,
                                              fieldKey: column.keyProp
                                            }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "groupKey", "fieldKey", "onChange"]))
                                      : (_openBlock(), _createBlock(_component_CellPrice, {
                                          key: 1,
                                          data: scope,
                                          groupKey: column.keyGroup,
                                          fieldKey: column.keyProp
                                        }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"]))
                                  ]))
                                : (_ctx.getPercentField(column.key))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                      (column.editable)
                                        ? (_openBlock(), _createBlock(_component_CellEditable, {
                                            key: 0,
                                            data: scope,
                                            groupKey: column.keyGroup,
                                            fieldKey: column.keyProp,
                                            typeField: "percent",
                                            onChange: _ctx.changeEditableCell
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_TprogCellPercent, {
                                                data: scope,
                                                groupKey: column.keyGroup,
                                                fieldKey: column.keyProp
                                              }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "groupKey", "fieldKey", "onChange"]))
                                        : (_openBlock(), _createBlock(_component_TprogCellPercent, {
                                            key: 1,
                                            data: scope,
                                            groupKey: column.keyGroup,
                                            fieldKey: column.keyProp
                                          }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"]))
                                    ]))
                                  : (column.key=='pricePlacePeriodCheck')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                        _createVNode(_component_TprogCheckPrice, {
                                          data: scope,
                                          groupKey: column.keyGroup,
                                          fieldKey: column.keyProp
                                        }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"]),
                                        (_ctx.hasCoordinationComment(scope))
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                              _createVNode(_component_ElPopover, {
                                                trigger: "hover",
                                                placement: "right"
                                              }, {
                                                reference: _withCtx(() => _cache[1] || (_cache[1] = [
                                                  _createElementVNode("div", { class: "coordination-comment-btn" }, null, -1 /* HOISTED */)
                                                ])),
                                                default: _withCtx(() => [
                                                  _createElementVNode("div", _hoisted_34, [
                                                    _createElementVNode("p", null, "Комментарий " + _toDisplayString(scope.row.monthData.discount) + "%", 1 /* TEXT */)
                                                  ])
                                                ]),
                                                _: 2 /* DYNAMIC */
                                              }, 1024 /* DYNAMIC_SLOTS */)
                                            ]))
                                          : _createCommentVNode("v-if", true)
                                      ]))
                                    : _createCommentVNode("v-if", true)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "min-width", "resizable", "fixed", "class-name"]))
                        }), 128 /* KEYED_FRAGMENT */))
                      : (col.group?.length)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 26 }, _renderList(col.group, (col) => {
                            return (_openBlock(), _createBlock(_component_ElTableColumn, {
                              key: col.key,
                              prop: col.key,
                              label: col.label,
                              "min-width": col.width,
                              resizable: !['designInfo', 'employ', 'queue'].includes(col.key),
                              fixed: (col.key=='designInfo')?'left':false,
                              "class-name": _ctx.getCellClass(col),
                              "header-align": "center",
                              align: "center"
                            }, {
                              header: _withCtx((scope) => [
                                _createElementVNode("div", _hoisted_35, [
                                  _createVNode(_component_TprogHeadDefault, {
                                    modelValue: col.order,
                                    "onUpdate:modelValue": $event => ((col.order) = $event),
                                    label: col.label,
                                    sort: col.sort,
                                    onChange: $event => (_ctx.sortTable($event, col.keyProp))
                                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "sort", "onChange"])
                                ])
                              ]),
                              default: _withCtx((scope) => [
                                (_ctx.getPriceField(col.key))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                      (col.editable)
                                        ? (_openBlock(), _createBlock(_component_CellEditable, {
                                            key: 0,
                                            data: scope,
                                            groupKey: col.keyGroup,
                                            fieldKey: col.keyProp,
                                            onChange: _ctx.changeEditableCell
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_CellPrice, {
                                                data: scope,
                                                groupKey: col.keyGroup,
                                                fieldKey: col.keyProp
                                              }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "groupKey", "fieldKey", "onChange"]))
                                        : (_openBlock(), _createBlock(_component_CellPrice, {
                                            key: 1,
                                            data: scope,
                                            groupKey: col.keyGroup,
                                            fieldKey: col.keyProp
                                          }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"]))
                                    ]))
                                  : (_ctx.getPercentField(col.key))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                        (col.editable)
                                          ? (_openBlock(), _createBlock(_component_CellEditable, {
                                              key: 0,
                                              data: scope,
                                              groupKey: col.keyGroup,
                                              fieldKey: col.keyProp,
                                              typeField: "percent",
                                              onChange: _ctx.changeEditableCell
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_TprogCellPercent, {
                                                  data: scope,
                                                  groupKey: col.keyGroup,
                                                  fieldKey: col.keyProp
                                                }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                                              ]),
                                              _: 2 /* DYNAMIC */
                                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "groupKey", "fieldKey", "onChange"]))
                                          : (_openBlock(), _createBlock(_component_TprogCellPercent, {
                                              key: 1,
                                              data: scope,
                                              groupKey: col.keyGroup,
                                              fieldKey: col.keyProp
                                            }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"]))
                                      ]))
                                    : (col.key=='pricePlacePeriodCheck')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                          _createVNode(_component_TprogCheckPrice, {
                                            data: scope,
                                            groupKey: col.keyGroup,
                                            fieldKey: col.keyProp
                                          }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                                        ]))
                                      : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                          (scope.row?.[col.keyGroup]?.[col.keyProp] != undefined)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                                                (col.editable)
                                                  ? (_openBlock(), _createBlock(_component_CellEditable, {
                                                      key: 0,
                                                      data: scope,
                                                      groupKey: col.keyGroup,
                                                      fieldKey: col.keyProp,
                                                      onChange: _ctx.changeEditableCell
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(scope.row?.[col.keyGroup]?.[col.keyProp]), 1 /* TEXT */)
                                                      ]),
                                                      _: 2 /* DYNAMIC */
                                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data", "groupKey", "fieldKey", "onChange"]))
                                                  : (_openBlock(), _createElementBlock("span", _hoisted_40, _toDisplayString(scope.row?.[col.keyGroup]?.[col.keyProp]), 1 /* TEXT */))
                                              ]))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_41, "–"))
                                        ], 64 /* STABLE_FRAGMENT */))
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "min-width", "resizable", "fixed", "class-name"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        : _createCommentVNode("v-if", true)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "min-width", "resizable", "fixed", "class-name"]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onSelectionChange", "summary-method", "data", "row-class-name"])
  ]))
}