import cellMixin from '@/app/mixins/table/cell-mixin';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'tprog-cell-other',
	mixins: [ cellMixin ],
	components: {
		SvgIcon,
	}
}