import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "di-edit-select__content" }
const _hoisted_2 = { class: "di-edit-select__control" }
const _hoisted_3 = { class: "di-edit-select__btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "di-edit-select",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.isVisible) = $event)),
    title: _ctx.titleDialog,
    "destroy-on-close": true,
    width: "480",
    "align-center": "",
    onClosed: _ctx.clickCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.typeSelect=='single')
            ? (_openBlock(), _createBlock(_component_BsSelectV2, {
                key: 0,
                modelValue: _ctx.fieldValue,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.fieldValue) = $event)),
                options: _ctx.fieldOptions,
                clearable: true,
                filterable: true,
                size: "large"
              }, null, 8 /* PROPS */, ["modelValue", "options"]))
            : _createCommentVNode("v-if", true),
          (_ctx.typeSelect=='multi')
            ? (_openBlock(), _createBlock(_component_BsSelectV2, {
                key: 1,
                modelValue: _ctx.fieldValue,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.fieldValue) = $event)),
                options: _ctx.fieldOptions,
                clearable: true,
                filterable: true,
                size: "large",
                multiple: "",
                showCheckBox: true,
                "collapse-tags": "",
                "collapse-tags-tooltip": "",
                "max-collapse-tags": 3
              }, null, 8 /* PROPS */, ["modelValue", "options"]))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BsBtn, {
            type: "primary",
            disabled: _ctx.isBtnEditDisabled,
            size: "small",
            onClick: _ctx.clickEdit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.labelActiveButton), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "onClick"]),
          _createVNode(_component_BsBtn, {
            size: "small",
            onClick: _ctx.clickCancel
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Отмена")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "onClosed"]))
}