import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "target-aside" }
const _hoisted_2 = { class: "target-aside__loader" }
const _hoisted_3 = { class: "target-aside__head" }
const _hoisted_4 = { class: "target-aside__head-title" }
const _hoisted_5 = { class: "target-aside__head-right" }
const _hoisted_6 = {
  key: 0,
  class: "target-aside__head-info"
}
const _hoisted_7 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_EditField = _resolveComponent("EditField")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_TargetSummary = _resolveComponent("TargetSummary")
  const _component_ElTabPane = _resolveComponent("ElTabPane")
  const _component_TargetPersons = _resolveComponent("TargetPersons")
  const _component_TargetStatistics = _resolveComponent("TargetStatistics")
  const _component_ElBadge = _resolveComponent("ElBadge")
  const _component_TargetFilter = _resolveComponent("TargetFilter")
  const _component_ElTabs = _resolveComponent("ElTabs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "el-fade-in",
      mode: "out-in",
      persisted: ""
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LoaderSpinner)
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.isAsideLoader]
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_EditField, {
          label: _ctx.defaultData.name,
          titleDialog: "Название АП",
          onChange: _ctx.editTargetName
        }, null, 8 /* PROPS */, ["label", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.defaultData?.dealId || _ctx.defaultData?.b24DealLink)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_ctx.defaultData?.dealId)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "target-aside__b24",
                    href: _ctx.defaultData?.dealId,
                    target: "_blank"
                  }, [
                    _createVNode(_component_SvgIcon, { name: "16-bitrix24" })
                  ], 8 /* PROPS */, _hoisted_7))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", {
          class: "target-aside__btn-reset",
          title: "Сбросить фильтры",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickResetFilters && _ctx.clickResetFilters(...args)))
        }, [
          _createVNode(_component_SvgIcon, { name: "24-refresh-left" })
        ])
      ])
    ]),
    _createVNode(_component_ElTabs, {
      class: "target-aside__tabs",
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.activeTab) = $event)),
      onTabClick: _cache[2] || (_cache[2] = () => {})
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElTabPane, {
          class: "target-aside__tab-pane",
          label: "Сводка",
          name: "target-summary"
        }, {
          label: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "target-aside__tab-pane-label" }, "Сводка", -1 /* HOISTED */)
          ])),
          default: _withCtx(() => [
            _createVNode(_component_TargetSummary)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElTabPane, {
          class: "target-aside__tab-pane",
          label: "Юрлица",
          name: "target-persons"
        }, {
          label: _withCtx(() => _cache[4] || (_cache[4] = [
            _createElementVNode("div", { class: "target-aside__tab-pane-label" }, "Юрлица", -1 /* HOISTED */)
          ])),
          default: _withCtx(() => [
            _createVNode(_component_TargetPersons)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElTabPane, {
          class: "target-aside__tab-pane",
          label: "Статистика",
          name: "target-statistics"
        }, {
          label: _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "target-aside__tab-pane-label" }, "Статистика", -1 /* HOISTED */)
          ])),
          default: _withCtx(() => [
            _createVNode(_component_TargetStatistics)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElTabPane, {
          class: "target-aside__tab-pane",
          label: "Фильтр",
          name: "target-filter"
        }, {
          label: _withCtx(() => [
            _createVNode(_component_ElBadge, {
              class: "target-aside__tab-badge",
              value: _ctx.countDetailTargetFilter,
              hidden: !_ctx.countDetailTargetFilter
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("div", { class: "target-aside__tab-pane-label" }, "Фильтр", -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value", "hidden"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_TargetFilter)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElTabPane, {
          class: "target-aside__tab-pane",
          label: "РС/РП",
          name: "detail-design"
        }, {
          label: _withCtx(() => _cache[7] || (_cache[7] = [
            _createElementVNode("div", { class: "target-aside__tab-pane-label" }, "РС/РП", -1 /* HOISTED */)
          ])),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}