import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tprog-comment-popover__container" }
const _hoisted_2 = { class: "tprog-comment-popover__form" }
const _hoisted_3 = { class: "tprog-comment-popover__textarea" }
const _hoisted_4 = { class: "tprog-comment-popover__btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createBlock(_component_ElPopover, {
    trigger: "click",
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.isVisible) = $event)),
    offset: 8,
    persistent: false,
    width: "auto",
    placement: "right-start",
    "popper-class": "tprog-comment-popover"
  }, {
    reference: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BsInput, {
              type: "textarea",
              rows: 3,
              modelValue: _ctx.text,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.text) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BsBtn, {
              size: "small",
              type: "primary",
              onClick: _ctx.clickOk
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Ок")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ])
        ])
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["visible"]))
}