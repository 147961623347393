import { ElTabs, ElTabPane, ElBadge } from 'element-plus';




export default {
	name: 'target-tab-filter',
	components: {
		ElTabs, ElTabPane, ElBadge,
	},
	props: {
		tabList: {
			type: Array,
			default: [],
		},
		activeTab: String,
	},
	emits: ['tabChange'],
	data() {
		return {
			tab: this.activeTab,
		}
	},
	methods: {
		changeActiveTab(data){
			console.log('changeActiveTab', data);
			this.$emit('tabChange', data);
		}
	},
	watch: {
		activeTab(val){
			this.tab = val;
		},
		tabList(list){
			console.log(list);
		}
	},
}