import dayjs from 'dayjs';
import helper from '@/app/utils/helper';

import { ElCollapseTransition } from "element-plus";
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'summary-month',
	components:{
		ElCollapseTransition,
		SvgIcon,
	},
	props: {
		data: {
			type: Object,
			default: {},
		}
	},
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		monthLabel(){
			if(!this.data?.month) return '';
			const date = this.data.month;
			const month = dayjs(date).format('MMMM');
			const year = dayjs(date).format('YY');

			return `${helper.upFirstLetter(month)} '${year}`;
		},
		code(){ return this.data?.code },
		price(){ return this.data?.price },
		discount(){ return this.data?.discount },
		discountPercent(){
			if(!this.price || !this.discount) return 0;

			return Math.ceil((this.discount/this.price)*100);
		},
		agencyDiscount(){ return this.data?.agencyDiscount },
		personDiscount(){ return this.data?.personDiscount },
		totalPriceNotVat(){ return this.data?.totalPriceNotVat },
		totalPrice(){ return this.data?.totalPrice },
	},
	methods: {
		getMoney: helper.getMoney,
		clickCollapse(){
			this.isOpen = !this.isOpen;
		}
	},
}