import { ElBadge } from 'element-plus';;


import cellMixin from '@/app/mixins/table/cell-mixin';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'tprog-cell-notific',
	mixins: [ cellMixin ],
	components: {
		ElBadge,
		SvgIcon,
	},
	computed: {
		count(){
			return this.fieldData;
		}
	},
}