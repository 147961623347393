import { mapState } from "pinia";
import { useMapTargetStore } from "@/app/stores/map-target-store";

import { ElTooltip } from "element-plus";
import 'element-plus/es/components/tooltip/style/css';

export default {
	name: 'map-target-cluster',
	components: {
		ElTooltip,
	},
	props: {
		data: Object,
		dev: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			isShow: false,
			isShowTooltipInfo: false,
		}
	},
	computed:{
		...mapState(useMapTargetStore, ['markerMap']),

		clusterSelector(){
			return `#cluster-${this.data?.clusterId}`;
		},
		markerList(){
			const list = [];
			const geoObjects = this.data.getGeoObjects();

			for(let item of geoObjects){
				let marker = this.markerMap[item.markerCode];
				if(marker) list.push(marker);
			}
			return list;
		},
		size(){
			return this.markerList?.length;
		},
		info(){
			let free = 0;
			let busy = 0;
			let reserve = 0;
			let added = 0;

			let freeBusy = 0;
			let freeReserve = 0;
			let busyReserve = 0;

			let freeBusyReserve = 0;

			for(let item of this.markerList){
				const { statusInfo } = item;
				const { sides } = statusInfo;

				for( const item of sides ){
					if(item.free && !item.busy && !item.reserve) free++;
					else if(!item.free && !item.busy && !item.reserve) free++;
					else if(item.reserve && !item.busy && !item.free) reserve++;
					else if(item.busy && !item.free && !item.reserve) busy++;
					else if(item.free && item.reserve && !item.busy) freeReserve++;
					else if(item.free && item.busy && !item.reserve) freeBusy++;
					else if(item.reserve && item.busy && !item.free) busyReserve++;
					else if(item.free && item.reserve && item.busy) freeBusyReserve++;

					if(item.added) added++;
				}
			}

			return {
				free, busy, reserve, added,
				freeBusy, freeReserve, busyReserve,
				freeBusyReserve,
			};
		},
		countEmploy(){
			let count = 0;
			for(let [key, value] of Object.entries(this.info)){
				count += value;
			}
			return count;
		},

		style(){
			const { free, busy, reserve, added,
				freeBusy, freeReserve, busyReserve,
				freeBusyReserve } = this.info;
			const total = free + busy + reserve +
				(freeBusy + freeReserve + busyReserve)*2 + freeBusyReserve*3;

			return `
				--map-target-cluster-free:${(free + freeBusy + freeReserve + freeBusyReserve)/total};
				--map-target-cluster-busy:${(busy + freeBusy + busyReserve + freeBusyReserve)/total};
				--map-target-cluster-reserved:${(reserve + freeReserve + busyReserve + freeBusyReserve)/total};
			`;
		}
	},
	methods: {
		hasMarkerContainer(){
			const clusterContainer = document.querySelector(this.clusterSelector);
			this.isShow = !!clusterContainer;
		}
	},
	mounted() {
		this.hasMarkerContainer();

		$(window).on(`update:map.${this.data?.clusterId}`, ()=>{
			this.hasMarkerContainer();
			this.isShowTooltipInfo = false;
		});
		this.data.events.add('mouseenter', ()=>{
			if(!this.countEmploy) return;
			this.isShowTooltipInfo = true;
		});
		this.data.events.add('mouseleave', ()=>{
			if(!this.countEmploy) return;
			this.isShowTooltipInfo = false;
		});

		// console.log(this.clusterMarkerList);
	},
	unmounted() {
		$(window).off(`update:map.${this.data.clusterId}`);
		this.data.events.remove('mouseenter', ()=>{
			this.isShowTooltipInfo = true;
		});
		this.data.events.remove('mouseleave', ()=>{
			this.isShowTooltipInfo = false;
		});
	},
}