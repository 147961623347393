import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElImage = _resolveComponent("ElImage")
  const _component_ElCarouselItem = _resolveComponent("ElCarouselItem")
  const _component_ElCarousel = _resolveComponent("ElCarousel")

  return (_openBlock(), _createBlock(_component_ElCarousel, {
    class: "bs-photo-slider",
    trigger: "click",
    arrow: _ctx.list.length>1?'hover':'never'
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createBlock(_component_ElCarouselItem, {
          class: "bs-photo-slider__item",
          key: item
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElImage, {
              class: "bs-photo-slider__img",
              fit: "cover",
              src: item,
              "preview-src-list": _ctx.list,
              "initial-index": index,
              "zoom-rate": 1.2,
              "max-scale": 7,
              "min-scale": 0.2,
              "preview-teleported": true
            }, null, 8 /* PROPS */, ["src", "preview-src-list", "initial-index"])
          ]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["arrow"]))
}