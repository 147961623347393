import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "tprog-edit-count-price" }
const _hoisted_2 = {
  key: 0,
  class: "tprog-edit-count-price__info"
}
const _hoisted_3 = { class: "tprog-edit-count-price__info-total" }
const _hoisted_4 = {
  key: 1,
  class: "tprog-edit-count-price__edit"
}
const _hoisted_5 = { class: "tprog-edit-count-price__edit-input" }
const _hoisted_6 = { class: "tprog-edit-count-price__edit-input" }
const _hoisted_7 = {
  key: 1,
  class: "tprog-edit-count-price__empty-dash"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isDataEmpty)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!_ctx.isEditField)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: "tprog-edit-count-price__info-calc",
                  title: "Изменить",
                  onClick: _cache[0] || (_cache[0] = $event => (_ctx.isEditField=true))
                }, _toDisplayString(_ctx.fieldData?.count ?? 0) + " * " + _toDisplayString(_ctx.getMoney(_ctx.fieldData?.price ?? 0)), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_3, "=" + _toDisplayString(_ctx.getMoney(_ctx.fieldData?.total ?? 0)) + " ₽", 1 /* TEXT */)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_BsInput, {
                    modelValue: _ctx.editData.count,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.editData.count) = $event)),
                    ref: "refCountInput",
                    type: "number",
                    size: "small",
                    min: "0",
                    step: "0.01",
                    onFocus: _cache[2] || (_cache[2] = $event => (_ctx.isBlurOneInput=false)),
                    onBlur: _cache[3] || (_cache[3] = $event => (_ctx.isBlurOneInput=true)),
                    onKeyup: _withKeys(_ctx.keyUpEnter, ["enter"])
                  }, null, 8 /* PROPS */, ["modelValue", "onKeyup"])
                ]),
                _cache[10] || (_cache[10] = _createElementVNode("span", null, "*", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_BsInput, {
                    modelValue: _ctx.editData.price,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.editData.price) = $event)),
                    type: "number",
                    size: "small",
                    min: "0",
                    step: "0.01",
                    onFocus: _cache[5] || (_cache[5] = $event => (_ctx.isBlurTwoInput=false)),
                    onBlur: _cache[6] || (_cache[6] = $event => (_ctx.isBlurTwoInput=true)),
                    onKeyup: _withKeys(_ctx.keyUpEnter, ["enter"])
                  }, null, 8 /* PROPS */, ["modelValue", "onKeyup"])
                ])
              ]))
        ], 64 /* STABLE_FRAGMENT */))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "tprog-edit-count-price__empty",
          onMouseenter: _cache[8] || (_cache[8] = (...args) => (_ctx.emptyMouseEnter && _ctx.emptyMouseEnter(...args))),
          onMouseleave: _cache[9] || (_cache[9] = (...args) => (_ctx.emptyMouseLeave && _ctx.emptyMouseLeave(...args)))
        }, [
          (_ctx.isHoverEmpty)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "tprog-edit-count-price__btn-edit",
                title: "Изменить",
                onClick: _cache[7] || (_cache[7] = $event => (_ctx.isEditField=true))
              }, [
                _createVNode(_component_SvgIcon, { name: "16-plus" })
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_7, "–"))
        ], 32 /* NEED_HYDRATION */))
  ]))
}