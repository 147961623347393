import { resolveComponent as _resolveComponent, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cell-editable__input"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsInput = _resolveComponent("BsInput")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cell-editable", { '--is-not-data': _ctx.isNotData }])
  }, [
    (_ctx.isVisibleInput)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.typeField=='string')
            ? (_openBlock(), _createBlock(_component_BsInput, {
                key: 0,
                modelValue: _ctx.inputData,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.inputData) = $event)),
                onKeydown: _withKeys(_ctx.inputBlur, ["enter"]),
                onBlur: _ctx.inputBlur,
                ref: "bsInputRef"
              }, null, 8 /* PROPS */, ["modelValue", "onKeydown", "onBlur"]))
            : (_openBlock(), _createBlock(_component_BsInput, {
                key: 1,
                modelValue: _ctx.inputData,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.inputData) = $event)),
                type: "number",
                onKeydown: _withKeys(_ctx.inputBlur, ["enter"]),
                onBlur: _ctx.inputBlur,
                ref: "bsInputRef"
              }, null, 8 /* PROPS */, ["modelValue", "onKeydown", "onBlur"]))
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "cell-editable__cell",
          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.clickCell && _ctx.clickCell(...args)))
        }, [
          _renderSlot(_ctx.$slots, "default")
        ]))
  ], 2 /* CLASS */))
}