import { mapState, mapActions, mapWritableState } from 'pinia';
import { usePricingListStore } from '@/app/stores/pricing-policies/pricing-list-store';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';
import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';

export default {
	name: 'pricing-list-aside',
	components: {
		LoaderSpinner, SvgIcon,
		BsBtn, BsInput, BsSelectV2,
		BsCheckbox,
	},
	computed: {
		...mapState( usePricingListStore, ['isAsideLoader'] ),
		...mapWritableState( usePricingListStore, ['filterData'] ),
	},
}