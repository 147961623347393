import { h } from 'vue';

import { mapActions, mapState, mapWritableState } from 'pinia';
import { useTargetImportStore } from '@/app/stores/target-import-store';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import dayjs from 'dayjs';
import helper from '@/app/utils/helper';

import { ElMessageBox, ElDialog } from "element-plus";

import "element-plus/es/components/dialog/style/css";
import "element-plus/es/components/message/style/css";

import BtnAction from "@/app/components/controls/buttons/btn-action/BtnAction.vue";
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsCheckbox from "@/app/components/controls/bs-checkbox/BsCheckbox.vue";
import TargetImportTable from '@/app/components/target-programs/target-import/target-import-table/TargetImportTable.vue';
import MessageImportResult from '@/app/components/target-programs/target-import/message-import-result/MessageImportResult.vue';

export default {
	name: "target-import",
	components: {
		ElDialog,

		BtnAction, BsBtn, SvgIcon,
		BsCheckbox, TargetImportTable,
	},
	props:{
		visible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: {},
		},
	},
	emits: ['update:visible', 'done'],
	data() {
		return {
			isSetReserved: false,
			isReplaceMatches: false,

			frequency: 60,
			timing: 10
		};
	},
	computed: {
		...mapState(useDetailTargetProgStore, ['detailTargetStatsData', 'detailMonthSelectData']),
		...mapWritableState(useTargetImportStore, ['tableImportData']),


		isTargetImportDialog: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			}
		},
		targetTitle(){
			const { name } = this.detailTargetStatsData?.defaultData ?? {};
			return name ?? '';
		},
		yearMonthFormat(){
			const yearMonth = this.detailMonthSelectData?.value;
			const str = dayjs(yearMonth).format('MMM’YY');
			return helper.upFirstLetter(str);
		},
		targetProgramId(){
			const { id } = this.detailTargetStatsData?.defaultData ?? {};
			return id;
		},
		countFindTr(){
			return this.tableImportData?.filter(item=>item?.foundedInTp)?.length ?? 0;
		},
		countFindDs(){
			return this.tableImportData?.filter(item=>{
				return item?.side && ( item?.matching && item?.matching != 'none' );
			})?.length ?? 0;
		},
	},
	methods: {
		...mapActions(useTargetImportStore, ['importStore']),

		clickCancel(){
			// console.log('clickCancel');
		},
		closeImport() {
			ElMessageBox.confirm(null, null, {
				type: 'confirm',
				title: 'Закрыть результаты анализа?',
				cancelButtonText: 'Отмена',
				confirmButtonText: 'Ок',
				customClass: 'bs-message-box import-ms-box',
				confirmButtonClass: 'bs-confirm-btn',
				cancelButtonClass: 'bs-cancel-btn'
			})
				.then(() => {
					this.isTargetImportDialog = false;
				})
				.catch(() => { });
		},

		async importData() {
			const { params } = this.$route;
			const targetProgramId = Number(params?.id);
			const yearMonth = this.detailMonthSelectData.value;

			const res = await this.importStore({
				targetProgramId, yearMonth,
				shouldReserve: this.isSetReserved,
				replaceMatching: this.isReplaceMatches,
			});

			if(res){
				console.log(res);
				ElMessageBox.alert(null, null, {
					type: 'confirm',
					title: 'Результаты импорта',
					message: h( MessageImportResult, {
						data: {
							...(res?.result ?? {}),
							// importedCount: 100,
							// addedCount: 70,
							// replacedCount: 10,
							// failedCount: 30
						}
					}),
					confirmButtonText: 'Ок',
					customClass: 'bs-message-box',
					confirmButtonClass: 'bs-confirm-btn',
				}).then(() => {
					this.$emit('done');
					this.isTargetImportDialog = false;
				}) .catch(() => { });
			}
		},
		changeSelectionTable(list){
			console.log('changeSelectionTable', list);
		}
	},

	watch: {
		isTargetImportDialog(is){
			if(!is){
				this.isSetReserved = false;
				this.isReplaceMatches = false;
				this.tableImportData = [];
			}
		}
	},
};
