import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mass-ext-reserve__content" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = { class: "control__group" }
const _hoisted_4 = { class: "control" }
const _hoisted_5 = { class: "control__group" }
const _hoisted_6 = { class: "mass-ext-reserve__action-buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsMultiSelect = _resolveComponent("BsMultiSelect")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "mass-ext-reserve",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.isVisible) = $event)),
    "append-to-body": true,
    "destroy-on-close": true,
    title: "Продление резерва",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "control__label" }, "Выберите месяцы", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BsMultiSelect, {
              modelValue: _ctx.months,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.months) = $event)),
              placeholder: "Введите или выберите месяцы из списка",
              options: this.detailMonthSelectData.options
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "control__label" }, "Кол-во дней продления", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BsInput, {
              modelValue: _ctx.countDay,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.countDay) = $event)),
              modelModifiers: { number: true },
              type: "number",
              placeholder: "Введите значение",
              min: 0,
              step: 1,
              clearable: true,
              onChange: _ctx.inputChange
            }, null, 8 /* PROPS */, ["modelValue", "onChange"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BsBtn, {
          disabled: !(_ctx.countDay >= 1 && _ctx.months.length),
          type: "primary",
          size: "small",
          onClick: _ctx.clickChange
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Изменить")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"]),
        _createVNode(_component_BsBtn, {
          size: "small",
          onClick: _cache[2] || (_cache[2] = $event => (_ctx.isVisible=false))
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Отмена")
          ])),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}