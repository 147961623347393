import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'import-cell-result',
	mixins: [cellMixin],
	computed: {
		foundedInTp(){
			return this.rowData?.foundedInTp;
		},
		label(){
			if(this.fieldData == 'single' || this.foundedInTp) return 'Есть совпадение';
			else if(this.fieldData == 'multiple') return 'Множественное совпадение';
			else return 'Не найдено';
		},
		classBgStatus(){
			if(this.fieldData == 'single' || this.foundedInTp) return 'cell-match';
			else if(this.fieldData == 'multiple') return 'cell-multiple-matches';
			else return 'cell-no-matches';
		}
	},
}