export default {
	name: 'footer-cell-default',
	props:{
		label: {
			type: String,
			default: '',
		},
		value: {
			type: [String, Number],
			default: '',
		},
		className:{
			type: String,
			default: '',
		}
	}
}