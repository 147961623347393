import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "target-import-table" }
const _hoisted_2 = {
  key: 0,
  class: "target-import-table__head-cell"
}
const _hoisted_3 = {
  key: 1,
  class: "target-import-table__head-cell"
}
const _hoisted_4 = {
  key: 0,
  class: "target-import-table__cell"
}
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 2,
  class: "target-import-table__cell"
}
const _hoisted_7 = {
  key: 3,
  class: "target-import-table__cell"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 4,
  class: "target-import-table__cell"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 5,
  class: "target-import-table__cell"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 6,
  class: "target-import-table__cell"
}
const _hoisted_15 = {
  key: 7,
  class: "target-import-table__cell"
}
const _hoisted_16 = {
  key: 8,
  class: "target-import-table__cell"
}
const _hoisted_17 = {
  key: 9,
  class: "target-import-table__cell"
}
const _hoisted_18 = {
  key: 10,
  class: "target-import-table__cell"
}
const _hoisted_19 = {
  key: 11,
  class: "target-import-table__cell"
}
const _hoisted_20 = { class: "target-import-table__cell-label" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_ImportCellStatus = _resolveComponent("ImportCellStatus")
  const _component_ImportPlacePeriods = _resolveComponent("ImportPlacePeriods")
  const _component_ImportShSelect = _resolveComponent("ImportShSelect")
  const _component_ImportCellPrice = _resolveComponent("ImportCellPrice")
  const _component_ImportCellResult = _resolveComponent("ImportCellResult")
  const _component_ImportDesignsInfo = _resolveComponent("ImportDesignsInfo")
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_ElTableV2 = _resolveComponent("ElTableV2")
  const _component_ElAutoResizer = _resolveComponent("ElAutoResizer")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElAutoResizer, null, {
      default: _withCtx(({ height, width }) => [
        _createVNode(_component_ElTableV2, {
          columns: _ctx.colConfigList,
          data: _ctx.tableData,
          stripe: "",
          border: "",
          width: width,
          height: height,
          "estimated-row-height": 160,
          "h-scrollbar-size": 8,
          "v-scrollbar-size": 8,
          cache: 1,
          fixed: ""
        }, {
          "header-cell": _withCtx(({ column }) => [
            (column.key=='selection')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_BsCheckbox, {
                    value: _ctx.allSelected,
                    indeterminate: _ctx.indeterminate,
                    onChange: _ctx.onChangeAll
                  }, null, 8 /* PROPS */, ["value", "indeterminate", "onChange"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(column.title), 1 /* TEXT */))
          ]),
          cell: _withCtx(({ column, rowData }) => [
            (column.key=='selection')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_BsCheckbox, {
                    modelValue: _ctx.isCheckedRow(rowData),
                    onChange: $event => (_ctx.onChange($event, rowData))
                  }, null, 8 /* PROPS */, ["modelValue", "onChange"])
                ]))
              : (_ctx.getDefaultCell(column))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["target-import-table__cell", { 'cell-address': column.key=='address' }])
                  }, [
                    _createElementVNode("span", {
                      class: "target-import-table__cell-label",
                      title: rowData?.[column.key]?.value
                    }, _toDisplayString(rowData?.[column.key]?.value), 9 /* TEXT, PROPS */, _hoisted_5),
                    _createVNode(_component_ImportCellStatus, {
                      data: { row: rowData },
                      groupKey: column.keyGroup,
                      fieldKey: column.keyProp
                    }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                  ], 2 /* CLASS */))
                : (column.key=='periods')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_ImportPlacePeriods, {
                        data: { row: rowData },
                        groupKey: column.keyGroup,
                        fieldKey: column.key,
                        onClick: _ctx.clickItemPeriod
                      }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onClick"])
                    ]))
                  : (column.key=='blockAndSlot')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (rowData?.[column.key]?.value && rowData?.side?.type == 'digital')
                          ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(rowData?.[column.key]?.value?.block) + "/" + _toDisplayString(rowData?.[column.key]?.value?.slot), 1 /* TEXT */))
                          : (_openBlock(), _createElementBlock("span", _hoisted_9, "–")),
                        _createVNode(_component_ImportCellStatus, {
                          data: { row: rowData },
                          groupKey: column.keyGroup,
                          fieldKey: column.keyProp
                        }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                      ]))
                    : (column.key=='duration')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          (rowData?.side?.type == 'digital')
                            ? (_openBlock(), _createBlock(_component_ImportShSelect, {
                                key: 0,
                                data: { row: rowData },
                                groupKey: column.keyGroup,
                                fieldKey: column.keyProp,
                                options: _ctx.getDurationOptions(rowData),
                                onChange: _ctx.changeCell
                              }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_11, "–"))
                        ]))
                      : (column.key=='showsInHour')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            (rowData?.side?.type == 'digital')
                              ? (_openBlock(), _createBlock(_component_ImportShSelect, {
                                  key: 0,
                                  data: { row: rowData },
                                  groupKey: column.keyGroup,
                                  fieldKey: column.keyProp,
                                  options: _ctx.getOutFrequencyOptions(rowData),
                                  onChange: _ctx.changeCell
                                }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_13, "–"))
                          ]))
                        : (column.key=='cost')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              _createVNode(_component_ImportCellPrice, {
                                data: { row: rowData },
                                groupKey: column.keyGroup,
                                fieldKey: column.keyProp
                              }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                            ]))
                          : (column.key=='customerLegalEntity')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                _createVNode(_component_ImportShSelect, {
                                  data: { row: rowData },
                                  groupKey: column.keyGroup,
                                  fieldKey: column.keyProp,
                                  filtrable: true,
                                  options: _ctx.getOptions('customer_legal_entity'),
                                  onChange: _ctx.changeCell
                                }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"])
                              ]))
                            : (column.key=='product')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                  _createVNode(_component_ImportShSelect, {
                                    data: { row: rowData },
                                    groupKey: column.keyGroup,
                                    fieldKey: column.keyProp,
                                    filtrable: true,
                                    options: _ctx.getOptions('product'),
                                    onChange: _ctx.changeCell
                                  }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "options", "onChange"])
                                ]))
                              : (column.key=='matching')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                    _createVNode(_component_ImportCellResult, {
                                      data: { row: rowData },
                                      groupKey: column.keyGroup,
                                      fieldKey: column.keyProp
                                    }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                                  ]))
                                : (column.key=='designInfo')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                      (rowData?.side)
                                        ? (_openBlock(), _createBlock(_component_ImportDesignsInfo, {
                                            key: 0,
                                            data: { row: rowData }
                                          }, null, 8 /* PROPS */, ["data"]))
                                        : (_openBlock(), _createBlock(_component_BsSelectV2, {
                                            key: 1,
                                            modelValue: '',
                                            placeholder: "Выберите РС",
                                            options: _ctx.getSidesOptions(rowData),
                                            size: "large",
                                            clearable: true,
                                            filterable: true,
                                            remote: true,
                                            "remote-method": (query)=>_ctx.searchSides(query, rowData),
                                            onChange: $event => (_ctx.changeSelectSide($event, rowData))
                                          }, null, 8 /* PROPS */, ["options", "remote-method", "onChange"]))
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                      _createElementVNode("span", _hoisted_20, _toDisplayString(rowData?.[column.keyProp] ?? '–'), 1 /* TEXT */)
                                    ]))
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["columns", "data", "width", "height"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}