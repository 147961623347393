import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "target-agr-panel" }
const _hoisted_2 = { class: "target-agr-panel__close" }
const _hoisted_3 = { class: "target-agr-panel__main" }
const _hoisted_4 = { class: "target-agr-panel__main-label" }
const _hoisted_5 = { class: "target-agr-panel__form" }
const _hoisted_6 = { class: "target-agr-panel__form-btn" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElCollapseTransition = _resolveComponent("ElCollapseTransition")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SvgIcon, {
        name: "16-close",
        title: "Скрыть",
        onClick: _ctx.closePanel
      }, null, 8 /* PROPS */, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SvgIcon, { name: "16-warning-filled" }),
        _createElementVNode("span", null, _toDisplayString(_ctx.count) + " РС/РП требуют согласования", 1 /* TEXT */)
      ]),
      (!_ctx.isOpenForm)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "target-agr-panel__main-btn",
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.openCommentForm && _ctx.openCommentForm(...args)))
          }, "Отправить"))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "target-agr-panel__main-btn",
            onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.closeCommentForm && _ctx.closeCommentForm(...args)))
          }, "Закрыть"))
    ]),
    _createVNode(_component_ElCollapseTransition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "target-agr-panel__info" }, "Введенный комментарий будет использован во всех согласованиях, где нет отдельного комментария", -1 /* HOISTED */)),
          _createVNode(_component_BsInput, {
            class: "target-agr-panel__comment",
            modelValue: _ctx.comment,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.comment) = $event)),
            rows: 4,
            type: "textarea",
            placeholder: "Введите комментарий"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_BsBtn, {
              type: "primary",
              size: "small",
              disabled: _ctx.comment?.length < 5
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Оставить комментарий")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.isOpenForm]
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}