import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useDesignFilterStore } from '@/app/stores/design-filter-store';
import { useMapDesignStore } from "@/app/stores/map-design-store";
import { useGridDesignStore } from '@/app/stores/grid-design-store';
import { useFiltersStore } from '@/app/stores/filters-store';
import { useDialogStore } from '@/app/stores/dialog-store';

import { ElBadge, ElMessageBox, ElMessage } from 'element-plus';




import helper from '@/app/utils/helper';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import ActionPanel from '@/app/components/action-panel/ActionPanel.vue';
import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';

import MapDesigns from '@/app/components/map/map-designs/MapDesigns.vue';
import MapLegend from '@/app/components/map/map-legend/MapLegend.vue';
import MapInfoAp from '@/app/components/map/map-info-ap/MapInfoAp.vue';
import MapMarker from '@/app/components/map/map-marker/MapMarker.vue';
import MapMarker2 from '@/app/components/map/map-marker-2/MapMarker2.vue';
import MapMarkerSearch from '@/app/components/map/map-marker-search/MapMarkerSearch.vue';
import MapCluster from '@/app/components/map/map-cluster/MapCluster.vue';
import MapSearchPanel from '@/app/components/map/map-search-panel/MapSearchPanel.vue';

import GridDesigns from '@/app/components/grid/grid-designs/GridDesigns.vue';
// import GridCommandPanel from '@/app/components/grid/grid-command-panel/GridCommandPanel.vue';
import TableComPanel from '@/app/components/table-com-panel/TableComPanel.vue';

import NewTargprogForm from '@/app/components/modals/new-targprog-form/NewTargprogForm.vue';
import SaveFiltertempForm from '@/app/components/modals/save-filtertemp-form/SaveFiltertempForm.vue';

export default {
	name: 'all-designs-view',
	components: {
		ElBadge,
		LoaderSpinner, ActionPanel, BtnAction,
		MapDesigns, MapLegend, MapSearchPanel,
		MapMarker, MapMarkerSearch,
		MapMarker2, MapCluster, MapInfoAp,

		GridDesigns, TableComPanel,
		NewTargprogForm, SaveFiltertempForm,
	},
	data() {
		return {
			tableCommandList: [
				{
					key:'add-target-program',
					label:'Добавить в АП',
				},
			],
		}
	},
	computed: {
		...mapState(useMapDesignStore, [ 'isLoaderMap', 'markerList', 'clusterList', 'infoApData', 'detailDesignData' ]),
		...mapState(useGridDesignStore, ['selectTableData', 'gridPaginationData']),
		...mapState(useDesignFilterStore, ['countFilters', 'targetProgram']),
		...mapWritableState(useRootStore, ['activeDesignView', 'isShowSecondAside']),
		...mapWritableState(useMapDesignStore, ['checkMapMarkerView', 'searchMarker' ]),
		...mapWritableState(useDesignFilterStore, ['isFilterDataLoad','isOpenSaveFiltertempForm', 'city']),
		...mapWritableState(useDialogStore, ['targetProgDialog']),
	},
	methods: {
		...mapActions(useRootStore, ['setShowSecondAside']),
		...mapActions(useDesignFilterStore, [ 'getFilterTemplate', 'buildPlacePeriodData', 'setFilterField']),
		...mapActions(useMapDesignStore, ['generateMapData', 'generateInfoApData', 'getMapData', 'getStructure']),
		...mapActions(useGridDesignStore, ['getDesignGridData']),
		...mapActions(useFiltersStore, ['getBatchFiltersData', 'getOptions']),
		...mapActions(useDialogStore, ['openTargetProgDialog', 'addTargetProgram']),

		changeBaseActionPanel(data){
			console.log(data);
			this.activeDesignView = data.btnAction.key;
		},
		openFilterAside(){
			this.setShowSecondAside(!this.isShowSecondAside);
		},
		async initViewData(){
			const { query } = this.$route;
			console.log(this.$route);

			if(query?.view){
				if(query.view=='grid') this.activeDesignView = 'grid';
				if(query.view=='hybrid') this.activeDesignView = 'hybrid';
			}

			// подгрузка данных опций фильтров
			this.isFilterDataLoad = true;
			this.buildPlacePeriodData();
			await this.getFilterTemplate();
			await this.getBatchFiltersData();

			// Предустановка города Казань по дефолту
			if( !this.city.value ){
				const cityOptions = this.getOptions('city');
				const findItem = cityOptions.find(item=>item.label.trim().toLowerCase() == 'казань');
				if(findItem) this.city.value = [ findItem.value ];
			}

			this.isFilterDataLoad = false;


			if(this.activeDesignView == 'map'){
				// structures?structureId=1960
				if(query?.structureId){
					const id = query.structureId;
					const filter = {};

					for( let [key, value] of Object.entries(query) ){
						if(key=='structureId') continue;
						if(key.includes('[]')){
							key = key.replace('[]','');
							if(!Array.isArray(value)) value = [value];
						}

						filter[key] = value;
					}
					if(!filter?.['city']) filter['city'] = [];
					// console.log(filter);
					const res = await this.getStructure({ id, filter });
				}
				else {
					let filters = null;

					if(query?.filters){
						try {
							filters = JSON.parse(query.filters);
						} catch (error) {
							console.error(error);
						}

						if(filters) this.setFilterField(filters);
					}
					this.getMapData( filters );
				}
			}
			else if(this.activeDesignView == 'grid'){
				let params = null;
				let filters = null;

				if(this.gridPaginationData){
					const { per_page, current_page } = this.gridPaginationData;
					params = {
						page: current_page,
						per_page,
					};
				}

				if(query?.filters){
					try {
						filters = JSON.parse(query.filters);
					} catch (error) {
						console.error(error);
					}

					if(filters){
						this.setFilterField(filters);
						// await helper.pause(100);
					}
				}
				this.getDesignGridData({ data: filters, params });
			}

		},
		clickMapMarkerView(){
			this.checkMapMarkerView = !this.checkMapMarkerView;
		},
		onSearchAddress(data){
			console.log(data);
			const { geocode, coords, strSearch } = data;
			$(window).trigger('set-center:map', { type:'search', coords, strSearch });
		},
		onChangeMapSearch(query){
			if(!query) this.searchMarker = null;
		},
		actionCommandPanel(key){
			if( key=='add-target-program' ) this.addAllTargetPrograms();
		},

		addAllTargetPrograms(){
			const targetProgId = this.targetProgram.value;
			const orders = [];

			for( const rowData of this.selectTableData ){
				// console.log(rowData);
				const sideData = rowData.curSideData;

				let data = {
					side_id: sideData.id,
					periods: [],
					status: 'reserved',
				};

				if(sideData.selectedPeriods) for(let [key, monthPeriods] of Object.entries(sideData.selectedPeriods)){
					data.periods.push(...monthPeriods);
				}

				if(sideData?.digitalData){
					const { digitalDuration, outFrequency } = sideData;
					data = {
						...data,
						duration: digitalDuration.value,
						frequency: outFrequency.value,
					}
				}

				orders.push(data);
			}

			console.log('addAllTargetPrograms', orders);

			if(targetProgId){
				ElMessageBox.confirm(
					'Добавить РС с постановкой в резерв, где возможно?',
					'',
					{
						confirmButtonText: 'Да',
						cancelButtonText: 'Нет',
						type: 'warning',
						customClass: 'bs-message-box',
					}
				).then(async () => {
					const res = await this.addTargetProgram({
						orders,
						target_program_id: targetProgId,
					});
					console.log(res);

					if(res) ElMessage({
						type: 'success',
						message: 'РС успешно добавлена(ы)',
					});
					else ElMessageBox.alert(
						'Что-то пошло не так...',
						'Ошибка!',
						{
							type: 'error',
							customClass: 'bs-message-box',
							showClose: false,
						}
					)
				}).catch(async () => {
					ElMessage({
						type: 'info',
						message: 'Действие отменено',
					})
				});
			} else {
				this.openTargetProgDialog(orders);
			}
		}
	},
	watch: {
		async activeDesignView(type){
			const { query } = this.$route;

			this.$router.push({
				name: 'structures',
				query: {
					...query,
					view: type,
				}
			});

			if(type=='map'){
				if(query?.structureId){
					const id = query.structureId;
					const filter = {};

					for( let [key, value] of Object.entries(query) ){
						if(key=='structureId') continue;
						if(key.includes('[]')){
							key = key.replace('[]','');
							if(!Array.isArray(value)) value = [value];
						}

						filter[key] = value;
					}
					console.log(filter);
					const res = await this.getStructure({ id, filter });
				} else {
					this.getMapData();
				}
			}
			else if(type=='grid' && query.view!='grid'){
				let params = null;

				if(this.gridPaginationData){
					const { per_page, current_page } = this.gridPaginationData;
					params = {
						page: current_page,
						per_page,
					};
				}
				// this.$router.push({
				// 	name: 'structures',
				// 	query: {
				// 		...query,
				// 		view: 'grid',
				// 	}
				// });
				this.getDesignGridData({ params });
			}
			else if(type=='hybrid'){
				// this.$router.push({
				// 	name: 'structures',
				// 	query: {
				// 		view: 'hybrid',
				// 	}
				// });
			}
		}
	},
	mounted(){

	},
	async created() {
		await this.initViewData();

		this.generateInfoApData();
	},
}