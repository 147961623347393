import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "map-marker__icon" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: _ctx.markerSelector
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["map-marker", _ctx.typeClassList]),
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.click && _ctx.click(...args)))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_SvgIcon, { name: "down-arrow-big" })
          ])
        ], 2 /* CLASS */)
      ], 8 /* PROPS */, ["to"]))
    : _createCommentVNode("v-if", true)
}