import { colConfigList } from '../table-config';
import helper from '@/app/utils/helper';

import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'tprog-settings-table',
	components: {
		BsBtn, BsInput, BsCheckbox,
		SvgIcon,
	},
	props: {
		settings:{
			type: Object,
			default: {},
		},
	},
	emits: ['cancel', 'save'],
	data() {
		return {
			strSearch: '',
			isInputFocus: false,
			colConfigList: helper.clone(colConfigList),
			isCollapsed: this.settings?.isCollapsed ?? false,
		}
	},
	computed: {
		searchList(){
			let list = this.colConfigList;
			const strFormat = this.strSearch.toLocaleLowerCase();
			if(this.strSearch?.length > 2){
				list = this.colConfigList.filter(
					item=>item.label?.toLocaleLowerCase().includes(strFormat)
				);
			}
			return list;
		},
		btnOkDisabled(){
			let is = true;
			for(let { key, show } of this.colConfigList){
				const findItem = colConfigList.find(item=>item.key==key);
				is = findItem.show == show;
				// if(!show) console.log(show);
				if(!is) break;
			}
			return is && (this.settings?.isCollapsed === this.isCollapsed);
		},
	},
	methods: {
		clickCollapse(){
			this.isCollapsed = !this.isCollapsed;
		},
		clickItem(item){
			console.log(item);
			if(!item.disabledCheck) item.show = !item.show;
		},
		clickSave(){
			if(!this.btnOkDisabled) this.$emit('save', {
				isCollapsed: this.isCollapsed,
				colConfigList: this.colConfigList,
			});
		},
		clickCancel(){
			this.colConfigList = helper.clone(colConfigList),
			this.$emit('cancel');
		},
		clickAllShow(){
			for(let item of this.colConfigList){
				if(item.key!='hr') item.show = true;
			}
			this.isCollapsed = false;
		},
	},
	watch:{
		['settings.isCollapsed'](is){
			this.isCollapsed = is;
		}
	},
	created(){

	},
}