import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pr-rules-edit" }
const _hoisted_2 = { class: "pr-rules-edit__list" }
const _hoisted_3 = {
  key: 0,
  class: "pr-rules-edit__item"
}
const _hoisted_4 = { class: "pr-rules-edit__add-select" }
const _hoisted_5 = { class: "pr-rules-edit__btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PrRulesEditItem = _resolveComponent("PrRulesEditItem")
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_BsBtn = _resolveComponent("BsBtn")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeList, (item) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: item.key
        }, [
          (_ctx.getRole(item.key))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_PrRulesEditItem, {
                  data: _ctx.getRole(item.key),
                  onRemove: _ctx.removeRule
                }, null, 8 /* PROPS */, ["data", "onRemove"])
              ]))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      }), 128 /* KEYED_FRAGMENT */))
    ], 512 /* NEED_PATCH */), [
      [_vShow, _ctx.ruleSelectedMap.size]
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_BsSelectV2, {
        placeholder: "Добавить условие",
        modelValue: '',
        options: _ctx.typeData.options,
        clearable: true,
        filterable: true,
        "popper-class": "pr-rules-edit__select-popper",
        "item-height": 28,
        onChange: _ctx.changeSelectAdd
      }, null, 8 /* PROPS */, ["options", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_BsBtn, { onClick: _ctx.clickCancel }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Отмена")
        ])),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_BsBtn, {
        type: "primary",
        disabled: _ctx.disabledBtnSave,
        onClick: _ctx.clickSave
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Сохранить")
        ])),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"])
    ])
  ]))
}