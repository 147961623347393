import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useGridDesignStore } from '@/app/stores/grid-design-store';
import { useFiltersStore } from '@/app/stores/filters-store';
import { useDesignFilterStore } from '@/app/stores/design-filter-store';
import { useMapDesignStore } from '@/app/stores/map-design-store';

import { columns } from "./table-config";
import helper from "@/app/utils/helper";

import TableGrid from '@/app/components/table-grid/TableGrid.vue';
import HeadBtnSort from '@/app/components/grid/grid-designs-table/head-btn-sort/HeadBtnSort.vue';
import HeadDesignInfo from '@/app/components/grid/grid-cell-types/head-design-info/HeadDesignInfo.vue';
import GridDesignsInfo from '@/app/components/grid/grid-designs-info/GridDesignsInfo.vue';
import GridEmployInfo from '@/app/components/grid/grid-employ-info/GridEmployInfo.vue';
import CellNumber from '@/app/components/table/cell-types/cell-number/CellNumber.vue';
import GridCellMoney from '@/app/components/grid/grid-cell-types/grid-cell-money/GridCellMoney.vue';
import GridCellPhoto from '@/app/components/grid/grid-cell-types/grid-cell-photo/GridCellPhoto.vue';
import GridCellQueue from '@/app/components/grid/grid-cell-types/grid-cell-queue/GridCellQueue.vue';

export default {
	name: 'grid-designs-table-2',
	components: {
		TableGrid,
		HeadBtnSort, HeadDesignInfo,
		GridDesignsInfo, GridEmployInfo, CellNumber, GridCellMoney,
		GridCellPhoto, GridCellQueue,
	},
	props:{
		tableData: {
			type: Array,
			default: [],
		}
	},
	emits: ['update:tableData', 'changeSelectedRows'],
	data() {
		return {
			columns,
			selectedRows: [],
			isDesignInfoCompact: false,
		}
	},
	computed: {
		...mapWritableState(useDesignFilterStore, ['isFilterDataLoad']),
		...mapState(useGridDesignStore, ['sideQueueList']),

		cityMap(){
			const map = {};
			const list = this.getOptions('city');

			for( const item of list){
				map[item.value] = item;
			}

			return map;
		}
	},
	methods: {
		...mapActions( useFiltersStore, ['getOptions']),
		...mapActions( useMapDesignStore, ['getStructure'] ),
		...mapActions( useRootStore, ['setShowSecondAside']),

		changeSelectedRows(list){
			this.$emit('changeSelectedRows', list);
		},

		changeCompactHeadDesign(is){
			const findCol = this.columns.find(item=>item.key=='designInfo');

			this.isDesignInfoCompact = is;
			if(!findCol){
				this.isDesignInfoCompact = false;
				return;
			}

			findCol.minWidth = is ? 290 : 530;
		},

		getCellMoney(key){
			return [ 'priceMonth','pricePeriod',
				'priceInstallationFra', 'priceInstallationLocal', 'pricePrint' ].includes(key);
		},

		sortTable(order, key){
			// if(!order) return;
			// console.log(order, key);
			const tableData = helper.clone(this.tableData);
			this.sortData = { order, key };

			for(let item of this.columns){
				if(item.key == key) continue;
				item.order = '';
			}

			if( key=='designInfo' ){
				if(order=='asc') tableData.sort((a,b)=>a['gid'].toLowerCase().localeCompare(b['gid'].toLowerCase()));
				else if(order=='desc') tableData.sort((a,b)=>b['gid'].toLowerCase().localeCompare(a['gid'].toLowerCase()));
				else tableData.sort((a,b)=>a['id'] - b['id']);
			} else {
				if(order=='asc') tableData.sort((a,b)=>a.curSideData[key] - b.curSideData[key]);
				else if(order=='desc') tableData.sort((a,b)=>b.curSideData[key] - a.curSideData[key]);
				else tableData.sort((a,b)=>a['id'] - b['id']);
			}

			this.$emit('update:tableData', tableData);
		},

		getRowClasses({ rowData }){
			const classList = [];
			const { inTargetProgram } = rowData?.curSideData ?? {};
			// console.log(row);
			// classList.push('row-in-tprog');
			if(inTargetProgram) classList.push('row-in-tprog');

			return classList.join(' ');
		},

		async dblClickRow(rowData){
			console.log('dblClickRow', rowData);

			this.isFilterDataLoad = true;
			await this.getStructure({ id: rowData.id, noDataMap: true });
			if(!this.isShowSecondAside) this.setShowSecondAside(true);
			this.isFilterDataLoad = false;
		}
	},
	mounted() {
		$(window).on('clear-table-selection', ()=>{
			const { gridTable } = this.$refs;
			if(gridTable) gridTable.clearSelection();
		});
	},
	unmounted() {
		$(window).off('clear-table-selection');
	},
}