import helper from "@/app/utils/helper";
import BtnAction from "../controls/buttons/btn-action/BtnAction.vue"

export default {
	name: 'action-panel',
	components:{
		BtnAction,
	},
	props: {
		activeKey: [String, Array],
		listType: Array,
		typeCheck:{
			type: String,
			default: 'checkbox' // 'checkbox' | 'radio',
		}
	},
	emits: ['onChange'],
	data() {
		return {
			dataList: [],
		}
	},
	methods: {
		buildDataList(){
			this.dataList = this.listType.map((key)=>{
				let activeKey = this.activeKey;
				if(Array.isArray(activeKey)){
					const findKey = this.activeKey.find(item=>item==key);
					if(findKey) activeKey = findKey;
				}

				return {
					id: helper.hash,
					key,
					isActive: activeKey==key,
				}
			});
		},
		clickButton(item){
			if(this.typeCheck=='checkbox'){
				item.isActive = !item.isActive;
				this.$emit('onChange', {
					btnAction: item,
					dataList: this.dataList,
					view: item.key,
				});
			}
			if(this.typeCheck=='radio'){
				if(item.isActive) return;

				for(let btnItem of this.dataList){
					btnItem.isActive = item.id==btnItem.id;
				}
				this.$emit('onChange', {
					btnAction: item,
					dataList: this.dataList,
					view: item.key,
				});
			}
		}
	},
	watch: {
		activeKey(){
			this.buildDataList();
		},
	},
	created() {
		this.buildDataList();
	},
}