import { ElPopover } from 'element-plus';
import 'element-plus/es/components/popover/style/css';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';

export default {
	name: 'short-select',
	components: {
		ElPopover,
		SvgIcon, BsInput,
	},
	props: {
		modelValue: [String, Number],
		options: {
			type: Array,
			default: [],
		},
		filtrable: {
			type: Boolean,
			default: false,
		},
		placement: {
			type: String,
			default: 'bottom',
		},
		requiredLabel: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: 'Выбрать',
		},
		clearable: Boolean,
	},
	emits: ['update:modelValue', 'change'],
	data() {
		return {
			isVisiblePopover: false,
			isInputFocus: false,
			strSearch: '',
			selectValue: this.modelValue ?? '',
			maxCount: 30,
		}
	},
	computed: {
		searchList(){
			let list = this.options;
			const strFormat = this.strSearch.toLocaleLowerCase();
			if(this.strSearch?.length > 1){
				list = this.options.filter(
					item=>item.label?.toLocaleLowerCase().includes(strFormat)
				);
			}
			return list.slice(0, this.maxCount);
		},
		label(){
			const findItem = this.options.find(item=>item.value==this.selectValue);
			if(this.requiredLabel) return this.requiredLabel;
			return findItem?.label ?? this.placeholder;
		},
	},
	methods: {
		clickItem(item){
			console.log(this.clearable, item);
			if(item?.disabled) return;

			if(this.clearable && item?.value == this.selectValue) this.selectValue = '';
			else this.selectValue = item.value;

			this.isVisiblePopover = false;
			this.$emit('update:modelValue', this.selectValue);
			this.$emit('change', this.selectValue);
		},
		load(){
			// console.log(111);
			this.maxCount += 10;
		}
	},
	watch: {
		modelValue(val){
			this.selectValue = val;
		},
		isVisiblePopover(is){
			if(is){
				this.maxCount = 30;
			} else {
				this.strSearch = '';
			}
		}
	},
}