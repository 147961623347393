import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "map-target-cluster__center" }
const _hoisted_2 = {
  key: 0,
  class: "map-target-cluster-popover__item"
}
const _hoisted_3 = { class: "map-target-cluster-popover__item-value" }
const _hoisted_4 = {
  key: 1,
  class: "map-target-cluster-popover__item"
}
const _hoisted_5 = { class: "map-target-cluster-popover__item-value" }
const _hoisted_6 = {
  key: 2,
  class: "map-target-cluster-popover__item"
}
const _hoisted_7 = { class: "map-target-cluster-popover__item-value" }
const _hoisted_8 = {
  key: 3,
  class: "map-target-cluster-popover__item"
}
const _hoisted_9 = { class: "map-target-cluster-popover__item-value" }
const _hoisted_10 = {
  key: 4,
  class: "map-target-cluster-popover__item"
}
const _hoisted_11 = { class: "map-target-cluster-popover__item-value" }
const _hoisted_12 = {
  key: 5,
  class: "map-target-cluster-popover__item"
}
const _hoisted_13 = { class: "map-target-cluster-popover__item-value" }
const _hoisted_14 = {
  key: 6,
  class: "map-target-cluster-popover__item"
}
const _hoisted_15 = { class: "map-target-cluster-popover__item-value" }
const _hoisted_16 = {
  key: 7,
  class: "map-target-cluster-popover__item"
}
const _hoisted_17 = { class: "map-target-cluster-popover__item-value" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTooltip = _resolveComponent("ElTooltip")

  return (!_ctx.dev)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.isShow)
          ? (_openBlock(), _createBlock(_Teleport, {
              key: 0,
              to: _ctx.clusterSelector
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["map-target-cluster", { 'map-target-cluster--reserved': _ctx.info.added }]),
                style: _normalizeStyle(_ctx.style)
              }, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "map-target-cluster__chart" }, null, -1 /* HOISTED */)),
                _createVNode(_component_ElTooltip, {
                  visible: _ctx.isShowTooltipInfo,
                  placement: "top-end",
                  effect: "light",
                  offset: 6,
                  "popper-class": "map-target-cluster-popover"
                }, {
                  content: _withCtx(() => [
                    (_ctx.info.freeBusyReserve)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "map-target-cluster-popover__item-group" }, [
                            _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-free" }, "Свободно"),
                            _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-reserved" }, "Резерв"),
                            _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-busy" }, "Занято")
                          ], -1 /* HOISTED */)),
                          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.info.freeBusyReserve), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.info.freeReserve)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "map-target-cluster-popover__item-group" }, [
                            _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-free" }, "Свободно"),
                            _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-reserved" }, "Резерв")
                          ], -1 /* HOISTED */)),
                          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.info.freeReserve), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.info.freeBusy)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "map-target-cluster-popover__item-group" }, [
                            _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-free" }, "Свободно"),
                            _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-busy" }, "Занято")
                          ], -1 /* HOISTED */)),
                          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.info.freeBusy), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.info.busyReserve)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "map-target-cluster-popover__item-group" }, [
                            _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-reserved" }, "Резерв"),
                            _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-busy" }, "Занято")
                          ], -1 /* HOISTED */)),
                          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.info.busyReserve), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.info.free)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-free" }, "Свободно", -1 /* HOISTED */)),
                          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.info.free), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.info.reserve)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-reserved" }, "Резерв", -1 /* HOISTED */)),
                          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.info.reserve), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.info.busy)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-busy" }, "Занято", -1 /* HOISTED */)),
                          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.info.busy), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.info.added)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "map-target-cluster-popover__item-label type-added" }, "Добавлено", -1 /* HOISTED */)),
                          _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.info.added), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.size), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["visible"])
              ], 6 /* CLASS, STYLE */)
            ], 8 /* PROPS */, ["to"]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["map-target-cluster", { 'map-target-cluster--reserved': true }])
      }, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "map-target-cluster__chart" }, null, -1 /* HOISTED */)),
        _createVNode(_component_ElTooltip, {
          visible: true,
          placement: "top",
          effect: "light",
          offset: "6",
          "popper-class": "map-target-cluster-popover"
        }, {
          content: _withCtx(() => _cache[9] || (_cache[9] = [
            _createElementVNode("div", { class: "map-target-cluster-popover__item type-free" }, [
              _createElementVNode("div", { class: "map-target-cluster-popover__item-label" }, "Свободно"),
              _createElementVNode("div", { class: "map-target-cluster-popover__item-value" }, "4")
            ], -1 /* HOISTED */),
            _createElementVNode("div", { class: "map-target-cluster-popover__item type-part-free" }, [
              _createElementVNode("div", { class: "map-target-cluster-popover__item-label" }, "В резерве"),
              _createElementVNode("div", { class: "map-target-cluster-popover__item-value" }, "5")
            ], -1 /* HOISTED */),
            _createElementVNode("div", { class: "map-target-cluster-popover__item type-busy" }, [
              _createElementVNode("div", { class: "map-target-cluster-popover__item-label" }, "Занято"),
              _createElementVNode("div", { class: "map-target-cluster-popover__item-value" }, "1")
            ], -1 /* HOISTED */),
            _createElementVNode("div", { class: "map-target-cluster-popover__item type-added" }, [
              _createElementVNode("div", { class: "map-target-cluster-popover__item-label" }, "Добавлено"),
              _createElementVNode("div", { class: "map-target-cluster-popover__item-value" }, "7")
            ], -1 /* HOISTED */)
          ])),
          default: _withCtx(() => [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "map-target-cluster__center" }, "10", -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        })
      ]))
}