import { ElCheckboxGroup } from "element-plus";
import 'element-plus/es/components/checkbox-group/style/css';

import BsCheckbox from "@/app/components/controls/bs-checkbox/BsCheckbox.vue";

export default {
	name: 'bs-checkbox-group',
	components: {
		ElCheckboxGroup,
		BsCheckbox,
	},
	props: {
		/**
		 * @type {{
				value: string | number,
				label: string,
		 * }[]}
		 */
		list: {
			type: Array,
			default: [],
		},
	}
}