export default {
	name: 'footer-cell-status',
	props:{
		className:{
			type: String,
			default: '',
		},

		countStatus: {
			type: Object,
			default: {},
		}
	},
	data() {
		return {
			type: {
				free: 'С',
				reserved: 'Р',
				busy: 'З',
				confirm: 'П',
			},
		}
	},
}