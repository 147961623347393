import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "footer-cell-deadline" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countStatuses, (item, key) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
        item
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["footer-cell-deadline__status-count", `status-${key}`])
            }, _toDisplayString(item), 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}