import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pricing-detail-view" }
const _hoisted_2 = { class: "pricing-detail-view__container" }
const _hoisted_3 = { class: "pricing-detail-view__action-panel" }
const _hoisted_4 = { class: "pricing-detail-view__action-panel-left" }
const _hoisted_5 = { class: "pricing-detail-view__action-item item-col-settings" }
const _hoisted_6 = { class: "pricing-detail-view__action-item item-client" }
const _hoisted_7 = { class: "pricing-detail-view__action-item item-filter" }
const _hoisted_8 = { class: "pricing-detail-view__action-item item-add-rule" }
const _hoisted_9 = { class: "pricing-detail-view__action-panel-right" }
const _hoisted_10 = { class: "pricing-detail-view__action-item item-chat" }
const _hoisted_11 = { class: "pricing-detail-view__table" }
const _hoisted_12 = { class: "target-programs-view__loader" }
const _hoisted_13 = { class: "pricing-detail-view__pagination" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PrSettingTable = _resolveComponent("PrSettingTable")
  const _component_ElBadge = _resolveComponent("ElBadge")
  const _component_BtnAction = _resolveComponent("BtnAction")
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_PricingDetailTable = _resolveComponent("PricingDetailTable")
  const _component_GridPagination = _resolveComponent("GridPagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ElBadge, {
              value: _ctx.countSetSettings,
              hidden: !_ctx.countSetSettings
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PrSettingTable, {
                  columns: _ctx.columns,
                  onSave: _ctx.saveSettingsColumn
                }, null, 8 /* PROPS */, ["columns", "onSave"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value", "hidden"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_BtnAction, {
              name: "icon-excel",
              title: "Экспорт",
              disabled: false,
              loading: false,
              onClick: _cache[0] || (_cache[0] = () => {})
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ElBadge, {
              value: _ctx.countFilters,
              hidden: !_ctx.countFilters
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BtnAction, {
                  name: "16-data-analysis",
                  title: "Сайдбар",
                  active: !_ctx.isShowSecondAside,
                  onClick: _ctx.openAsideFilter
                }, null, 8 /* PROPS */, ["active", "onClick"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value", "hidden"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_BtnAction, {
              name: "16-plus",
              title: "Добавить новое правило",
              onClick: _cache[1] || (_cache[1] = () => {})
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_ElBadge, {
              value: _ctx.countMessage,
              hidden: !_ctx.countMessage
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BtnAction, {
                  name: "16-chat-dot-square",
                  title: "Чат",
                  active: false,
                  onClick: _cache[2] || (_cache[2] = () => {})
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value", "hidden"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in",
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_12, [
              _createVNode(_component_LoaderSpinner)
            ], 512 /* NEED_PATCH */), [
              [_vShow, _ctx.isViewLoader]
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_PricingDetailTable, { tableData: _ctx.tableData }, null, 8 /* PROPS */, ["tableData"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_GridPagination)
      ])
    ])
  ]))
}