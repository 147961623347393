import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, withKeys as _withKeys, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "target-programs-aside" }
const _hoisted_2 = { class: "target-programs-aside__loader" }
const _hoisted_3 = { class: "target-programs-aside__head" }
const _hoisted_4 = { class: "target-programs-aside__container" }
const _hoisted_5 = { class: "target-programs-aside__form" }
const _hoisted_6 = { class: "control" }
const _hoisted_7 = { class: "control__group" }
const _hoisted_8 = { class: "control" }
const _hoisted_9 = { class: "control__group" }
const _hoisted_10 = { class: "control" }
const _hoisted_11 = { class: "control__group group-row" }
const _hoisted_12 = { class: "control" }
const _hoisted_13 = { class: "control__group" }
const _hoisted_14 = { class: "control" }
const _hoisted_15 = { class: "control__group" }
const _hoisted_16 = { class: "control" }
const _hoisted_17 = { class: "control__group" }
const _hoisted_18 = { class: "control" }
const _hoisted_19 = { class: "control__group" }
const _hoisted_20 = { class: "control" }
const _hoisted_21 = { class: "control__group" }
const _hoisted_22 = { class: "control" }
const _hoisted_23 = { class: "control__group" }
const _hoisted_24 = { class: "control" }
const _hoisted_25 = { class: "control__group" }
const _hoisted_26 = { class: "control" }
const _hoisted_27 = { class: "control__group" }
const _hoisted_28 = { class: "control target-programs-aside__builder-compare" }
const _hoisted_29 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_30 = { class: "control" }
const _hoisted_31 = { class: "control__group" }
const _hoisted_32 = { class: "control full-free-width" }
const _hoisted_33 = { class: "control__group" }
const _hoisted_34 = { class: "control" }
const _hoisted_35 = { class: "control__group" }
const _hoisted_36 = { class: "target-programs-aside__btn-submit" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_BsDatePicker = _resolveComponent("BsDatePicker")
  const _component_BsSingleSelect = _resolveComponent("BsSingleSelect")
  const _component_BsBtn = _resolveComponent("BsBtn")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "el-fade-in",
      mode: "out-in",
      persisted: ""
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LoaderSpinner)
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.isAsideLoader]
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_3, [
      _cache[16] || (_cache[16] = _createElementVNode("div", { class: "target-programs-aside__head-title" }, "Фильтры", -1 /* HOISTED */)),
      _createElementVNode("div", {
        class: "target-programs-aside__btn-reset",
        title: "Сбросить фильтры",
        onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickResetFilters && _ctx.clickResetFilters(...args)))
      }, [
        _createVNode(_component_SvgIcon, { name: "24-refresh-left" })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "control__label" }, "Найти по Названию / ID", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_BsInput, {
              placeholder: "Найти",
              modelValue: _ctx.allTargetFilterData.name.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.allTargetFilterData.name.value) = $event)),
              onKeyup: _withKeys(_ctx.submit, ["enter"])
            }, null, 8 /* PROPS */, ["modelValue", "onKeyup"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "control__label" }, "Город", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Введите или выберите из списка",
              modelValue: _ctx.allTargetFilterData.city.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.allTargetFilterData.city.value) = $event)),
              options: _ctx.getOptions('city'),
              clearable: true,
              filterable: true,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "control__label" }, "Период размещения", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_BsDatePicker, {
              placeholder: `Дата с`,
              modelValue: _ctx.allTargetFilterData.placePeriod.from,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.allTargetFilterData.placePeriod.from) = $event)),
              size: "large",
              type: "date",
              format: "YYYY-MM-DD",
              "value-format": "YYYY-MM-DD",
              onChange: _ctx.changeDatePickerFrom
            }, null, 8 /* PROPS */, ["modelValue", "onChange"]),
            _createVNode(_component_BsDatePicker, {
              placeholder: `Дата по`,
              modelValue: _ctx.allTargetFilterData.placePeriod.to,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.allTargetFilterData.placePeriod.to) = $event)),
              type: "date",
              size: "large",
              format: "YYYY-MM-DD",
              "value-format": "YYYY-MM-DD",
              "disabled-date": _ctx.disabledDateTo
            }, null, 8 /* PROPS */, ["modelValue", "disabled-date"])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "control__label" }, "Агенство", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Выберите агенство",
              modelValue: _ctx.allTargetFilterData.agency.value,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.allTargetFilterData.agency.value) = $event)),
              options: _ctx.getOptions('agency'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 5,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "control__label" }, "Клиент", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Выберите клиента",
              modelValue: _ctx.allTargetFilterData.customer.value,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.allTargetFilterData.customer.value) = $event)),
              options: _ctx.getOptions('customer'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 5,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "control__label" }, "Бренд", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Выберите бренд",
              modelValue: _ctx.allTargetFilterData.brand.value,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.allTargetFilterData.brand.value) = $event)),
              options: _ctx.getOptions('brand'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 5,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _cache[23] || (_cache[23] = _createElementVNode("div", { class: "control__label" }, "Продукт", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Выберите продукт",
              modelValue: _ctx.allTargetFilterData.product.value,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.allTargetFilterData.product.value) = $event)),
              options: _ctx.getOptions('product'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 5,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _cache[24] || (_cache[24] = _createElementVNode("div", { class: "control__label" }, "Категория клиента", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Выберите категорию",
              modelValue: _ctx.allTargetFilterData.customerCategories.value,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.allTargetFilterData.customerCategories.value) = $event)),
              options: _ctx.getOptions('customer_category'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 5,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _cache[25] || (_cache[25] = _createElementVNode("div", { class: "control__label" }, "Ответственный менеджер", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Выберите менеджера",
              modelValue: _ctx.allTargetFilterData.responsible.value,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.allTargetFilterData.responsible.value) = $event)),
              options: _ctx.getOptions('managers'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 5,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _cache[26] || (_cache[26] = _createElementVNode("div", { class: "control__label" }, "Отдел", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_25, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Выберите отдел",
              modelValue: _ctx.allTargetFilterData.department.value,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.allTargetFilterData.department.value) = $event)),
              options: _ctx.getOptions('department'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 5,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _cache[27] || (_cache[27] = _createElementVNode("div", { class: "control__label" }, "Другие менеджеры", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Выберите из списка",
              modelValue: _ctx.allTargetFilterData.managers.value,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.allTargetFilterData.managers.value) = $event)),
              options: _ctx.getOptions('managers'),
              clearable: true,
              filterable: true,
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 5,
              size: "large",
              multiple: "",
              showCheckBox: true
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _cache[28] || (_cache[28] = _createElementVNode("div", { class: "control__label" }, "Сумма", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_BsSingleSelect, {
                  placeholder: "=",
                  modelValue: _ctx.allTargetFilterData.totalPrice.select,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.allTargetFilterData.totalPrice.select) = $event)),
                  options: _ctx.compareOptions,
                  clearable: true
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _createVNode(_component_BsInput, {
                  placeholder: "Введите данные",
                  modelValue: _ctx.allTargetFilterData.totalPrice.input,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.allTargetFilterData.totalPrice.input) = $event)),
                  type: "number",
                  min: "0",
                  step: "0.01",
                  clearable: true
                }, null, 8 /* PROPS */, ["modelValue"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_34, [
          _cache[29] || (_cache[29] = _createElementVNode("div", { class: "control__label" }, "Истекающие статусы", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_35, [
            _createVNode(_component_BsSelectV2, {
              placeholder: "Выберите из списка",
              modelValue: _ctx.allTargetFilterData.expiringStatus.value,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.allTargetFilterData.expiringStatus.value) = $event)),
              options: _ctx.expiringStatusOptions,
              clearable: true,
              filterable: true,
              size: "large"
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_36, [
      _createVNode(_component_BsBtn, {
        type: "primary",
        tag: "div",
        onClick: _ctx.submit
      }, {
        default: _withCtx(() => _cache[30] || (_cache[30] = [
          _createTextVNode("Выбрать")
        ])),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ])
  ]))
}