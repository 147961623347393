import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid-designs" }
const _hoisted_2 = { class: "grid-designs__container" }
const _hoisted_3 = { class: "grid-designs__table" }
const _hoisted_4 = { class: "grid-designs__loader" }
const _hoisted_5 = {
  key: 0,
  class: "grid-designs__pagination"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_GridDesignsTable2 = _resolveComponent("GridDesignsTable2")
  const _component_GridPagination = _resolveComponent("GridPagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in",
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createVNode(_component_LoaderSpinner)
            ], 512 /* NEED_PATCH */), [
              [_vShow, _ctx.isLoaderGrid]
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_GridDesignsTable2, {
          tableData: _ctx.gridTableData,
          "onUpdate:tableData": _cache[0] || (_cache[0] = $event => ((_ctx.gridTableData) = $event)),
          onChangeSelectedRows: _ctx.changeSelectionTable
        }, null, 8 /* PROPS */, ["tableData", "onChangeSelectedRows"])
      ])
    ]),
    (_ctx.gridPaginationData?.last_page && _ctx.gridPaginationData?.last_page > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_GridPagination, {
            currentPage: _ctx.gridPaginationData?.current_page,
            total: _ctx.gridPaginationData?.total,
            onChange: _ctx.changePagination
          }, null, 8 /* PROPS */, ["currentPage", "total", "onChange"])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}