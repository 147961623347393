import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'pr-rules-info-item',
	components: {
		SvgIcon,
	},
	props: {
		data: {
			type: Object,
			default: {
				// key: string,
				// label: string,
				// value: string,
			}
		}
	},
	data() {
		return {
			heightValue: 0,
			isOpenValue: false,
		}
	},
	methods: {
		getHeightValue(){
			const { refItemValue } = this.$refs;
			const { offsetHeight } = refItemValue;
			this.heightValue = offsetHeight;
			this.isOpenValue = false;
		},
		toggleValue(){
			if( this.heightValue < 28 ) return;
			this.isOpenValue = !this.isOpenValue;
		}
	},
	watch: {
		async ['data.value'](){
			await this.$nextTick();
			this.getHeightValue();
		}
	},
	mounted() {
		this.getHeightValue();
	},
}