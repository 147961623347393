import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "target-statistics" }
const _hoisted_2 = { class: "target-statistics__container" }
const _hoisted_3 = { class: "target-statistics__form" }
const _hoisted_4 = { class: "target-statistics__block block-struct-type" }
const _hoisted_5 = { class: "target-statistics__block-container" }
const _hoisted_6 = { class: "control__label" }
const _hoisted_7 = { class: "control__value" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeStatList, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "control",
                key: item.id
              }, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(item.label) + ":", 1 /* TEXT */),
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "control__dots" }, null, -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_7, _toDisplayString(item.value), 1 /* TEXT */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ])
    ])
  ]))
}