import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tprog-cell-title-link"
}
const _hoisted_2 = {
  key: 1,
  class: "tprog-cell-title-link"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RouterLink = _resolveComponent("RouterLink")

  return (_ctx.fieldData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_RouterLink, {
          class: "a",
          to: _ctx.link,
          onClick: _ctx.clickLink
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.fieldData), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to", "onClick"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, "–"))
}