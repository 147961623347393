import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer-cell-default__label" }
const _hoisted_2 = { class: "footer-cell-default__value" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["footer-cell-default", _ctx.className])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.value), 1 /* TEXT */)
  ], 2 /* CLASS */))
}