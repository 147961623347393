import { ElInputNumber } from "element-plus";
import 'element-plus/es/components/input-number/style/css';

import cellMixin from "@/app/mixins/table/cell-mixin";
import helper from '@/app/utils/helper';

import BsInput from '@/app/components/controls/bs-input/BsInput.vue';

export default {
	name: 'cell-coefficient',
	mixins: [ cellMixin ],
	components: {
		ElInputNumber,
		BsInput,
	},
	props: {
		typeField: {
			type: String,
			default: 'price' // percent, string
		}
	},
	emits:['change'],
	data() {
		return {
			hash: helper.hash,
			isVisibleInput: false,
			inputData: '',
		}
	},
	computed: {
		isNotData(){
			return this.fieldData === undefined;
		}
	},
	methods: {
		getNum: helper.getFormatNum,
		async clickCell(){
			if(this.isNotData) return;

			this.isVisibleInput = true;
			await this.$nextTick();

			const { bsInputRef } = this.$refs;
			// console.log(bsInputRef);
			if(bsInputRef.$el){
				const elInput = bsInputRef.$el.querySelector('input');
				elInput.focus();

				this.initOutFocusElement();
			}
		},
		change(){
			this.isVisibleInput = false;

			let num = Number(this.inputData);
			if(num < 0.1) num = 1;
			this.inputData = Number(num.toFixed(1));

			if(helper.getNum(this.fieldData) !== helper.getNum(this.inputData)) this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: this.inputData,
			});
		},
		initOutFocusElement(){
			$(window).on(`click.input_number${this.hash}`, async (e)=>{
				const isChild = this.$el.contains(e.target);

				if(!isChild){
					$(window).off(`click.input_number${this.hash}`);
					this.change();
				}
			});
		}
	},
	created(){
		if(!this.isNotData){
			this.inputData = Number(this.fieldData);
			if(this.inputData===0 || this.inputData < 0) this.inputData = 1;
		}
	},
	unmounted() {
		$(window).off(`click.input_number${this.hash}`);
	},
}