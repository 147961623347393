import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pr-cell-count-struct"
}
const _hoisted_2 = { class: "pr-cell-count-struct__item" }
const _hoisted_3 = { class: "pr-cell-count-struct__item" }
const _hoisted_4 = { class: "pr-cell-count-struct__item" }
const _hoisted_5 = {
  key: 1,
  class: "pr-cell-count-struct"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_ctx.fieldData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getFormatNum(_ctx.fieldData?.structs ?? 0)), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getFormatNum(_ctx.fieldData?.sides ?? 0)), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getFormatNum(_ctx.fieldData?.surfaces ?? 0)), 1 /* TEXT */)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, "–"))
}