import { ElCollapseTransition } from 'element-plus';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'info-search-gid',
	components: {
		SvgIcon, ElCollapseTransition,
	},
	props: {
		data: [String, Array],
	},
	data() {
		return {
			isOpenList: true,
		}
	},
	computed: {

	},
	methods: {
		openList(){
			this.isOpenList = !this.isOpenList;
		},
		getInfoTitle(item){
			let title = '';

			if(item.accurate=='surface') title = 'Cоответствие до РП';
			else if(item.accurate=='side') title = 'Cоответствие до РС';
			else if(item.accurate=='structure') title = 'Cоответствие до РК';

			return title;
		}
	},
}