import cellMixin from "@/app/mixins/table/cell-mixin";
import helper from "@/app/utils/helper";

export default {
	name: 'tprog-cell-percent',
	mixins: [ cellMixin ],
	computed: {
		label(){
			return `${helper.getFormatNum(this.fieldData)}%`;
		},
	},
}