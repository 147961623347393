import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tprog-month-status__bg-layer" }
const _hoisted_2 = { class: "tprog-month-status__icons" }
const _hoisted_3 = { class: "tprog-month-status__icon icon-employ-not-filled" }
const _hoisted_4 = { class: "tprog-month-status__icon icon-employ-filled" }
const _hoisted_5 = { class: "tprog-month-status__icon icon-confirm" }
const _hoisted_6 = {
  key: 0,
  class: "tprog-month-status__label"
}
const _hoisted_7 = {
  key: 1,
  class: "tprog-month-status__short-select"
}
const _hoisted_8 = { class: "tprog-month-status__queue" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "tprog-month-status__queue-wrap" }
const _hoisted_12 = {
  key: 1,
  class: "tprog-month-status__queue"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ShortSelect = _resolveComponent("ShortSelect")
  const _component_SideQueueItem2 = _resolveComponent("SideQueueItem2")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tprog-month-status", _ctx.statusData.className])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "tprog-month-status__count" }, "1", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_SvgIcon, { name: "14-help-not-filled" })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SvgIcon, { name: "14-help-filled" })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_SvgIcon, { name: "12-circle-check-filled" })
        ])
      ])
    ]),
    ((!_ctx.isMy && _ctx.status=='approved'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", null, _toDisplayString(_ctx.label), 1 /* TEXT */),
          _createVNode(_component_SvgIcon, { name: "14-view" })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_ShortSelect, {
            modelValue: _ctx.fieldValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.fieldValue) = $event)),
            placement: "bottom-start",
            placeholder: "Выбрать",
            requiredLabel: _ctx.label,
            options: _ctx.options,
            onChange: _ctx.changeSelect
          }, null, 8 /* PROPS */, ["modelValue", "requiredLabel", "options", "onChange"])
        ])),
    (_ctx.status=='reserved')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.queue?.queueList?.length)
            ? (_openBlock(), _createBlock(_component_ElPopover, {
                key: 0,
                "popper-class": "tprog-month-status__queue-popover",
                width: "286",
                offset: 6,
                trigger: "click",
                placement: "top-start"
              }, {
                reference: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    (_ctx.queue?.title?.position==1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Истекает " + _toDisplayString(_ctx.formatDate), 1 /* TEXT */))
                      : (_ctx.queue?.title?.position>1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Вы " + _toDisplayString(_ctx.queue?.title?.position) + " в очереди (" + _toDisplayString(_ctx.formatDate) + ")", 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queue.queueList, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "tprog-month-status__queue-item",
                        key: item.id
                      }, [
                        _createVNode(_component_SideQueueItem2, { data: item }, null, 8 /* PROPS */, ["data"])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (_ctx.queue?.title?.position==1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, "Истекает " + _toDisplayString(_ctx.formatDate), 1 /* TEXT */))
                  : (_ctx.queue?.title?.position>1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, "Вы " + _toDisplayString(_ctx.queue?.title?.position) + " в очереди (" + _toDisplayString(_ctx.formatDate) + ")", 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
              ]))
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}