import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "temp-settings" }
const _hoisted_2 = {
  class: "temp-settings__btn",
  title: "Настройки фильтров"
}
const _hoisted_3 = { class: "temp-settings__content" }
const _hoisted_4 = { class: "temp-settings__wrap" }
const _hoisted_5 = {
  key: 0,
  class: "temp-settings__title"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElPopover, {
      "popper-class": "temp-settings__popover",
      width: "286",
      offset: 6,
      trigger: "click",
      placement: "bottom-start"
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SvgIcon, { name: "24-settings" })
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.settingList, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: item.key
              }, [
                (item.key=='setting-title')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(item.label), 1 /* TEXT */))
                  : (_openBlock(), _createBlock(_component_BsCheckbox, {
                      key: 1,
                      class: "temp-settings__item",
                      modelValue: item.isCheck,
                      "onUpdate:modelValue": $event => ((item.isCheck) = $event),
                      onChange: _ctx.changeSettings
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "onChange"]))
              ], 64 /* STABLE_FRAGMENT */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}