import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid-designs-table-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "table-grid__head-cell cell-design-info"
}
const _hoisted_4 = {
  key: 2,
  class: "table-grid__head-cell"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "table-grid__cell"
}
const _hoisted_7 = {
  key: 1,
  class: "table-grid__cell cell-employ-info"
}
const _hoisted_8 = {
  key: 2,
  class: "table-grid__cell cell-type-number"
}
const _hoisted_9 = {
  key: 3,
  class: "table-grid__cell cell-type-number"
}
const _hoisted_10 = {
  key: 4,
  class: "table-grid__cell cell-photo"
}
const _hoisted_11 = {
  key: 5,
  class: "table-grid__cell"
}
const _hoisted_12 = {
  key: 6,
  class: "table-grid__cell"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeadDesignInfo = _resolveComponent("HeadDesignInfo")
  const _component_HeadBtnSort = _resolveComponent("HeadBtnSort")
  const _component_GridDesignsInfo = _resolveComponent("GridDesignsInfo")
  const _component_GridEmployInfo = _resolveComponent("GridEmployInfo")
  const _component_CellNumber = _resolveComponent("CellNumber")
  const _component_GridCellMoney = _resolveComponent("GridCellMoney")
  const _component_GridCellPhoto = _resolveComponent("GridCellPhoto")
  const _component_GridCellQueue = _resolveComponent("GridCellQueue")
  const _component_TableGrid = _resolveComponent("TableGrid")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableGrid, {
      ref: "gridTable",
      columns: _ctx.columns,
      rows: _ctx.tableData,
      isSelected: true,
      rowClassName: _ctx.getRowClasses,
      onChangeSelectedRows: _ctx.changeSelectedRows,
      onDblClickRow: _ctx.dblClickRow
    }, {
      th: _withCtx(({ colData }) => [
        (colData.key=='key1')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "key1"))
          : (colData.key=='designInfo')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_HeadDesignInfo, {
                  label: colData.label,
                  onChangeCompact: _ctx.changeCompactHeadDesign
                }, null, 8 /* PROPS */, ["label", "onChangeCompact"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", {
                  innerHTML: colData.label
                }, null, 8 /* PROPS */, _hoisted_5),
                (colData.sort)
                  ? (_openBlock(), _createBlock(_component_HeadBtnSort, {
                      key: 0,
                      modelValue: colData.order,
                      "onUpdate:modelValue": $event => ((colData.order) = $event),
                      onChange: $event => (_ctx.sortTable(colData.order, colData.key))
                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onChange"]))
                  : _createCommentVNode("v-if", true)
              ]))
      ]),
      td: _withCtx(({ colData, rowData }) => [
        (colData.key=='designInfo')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_GridDesignsInfo, {
                data: { row: rowData },
                cityMap: _ctx.cityMap,
                compact: _ctx.isDesignInfoCompact
              }, null, 8 /* PROPS */, ["data", "cityMap", "compact"])
            ]))
          : (colData.key=='employ')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_GridEmployInfo, {
                  data: { row: rowData }
                }, null, 8 /* PROPS */, ["data"])
              ]))
            : (['countFreeDays', 'grp','ots'].includes(colData.key))
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_CellNumber, {
                    data: { row: rowData },
                    groupKey: "curSideData",
                    fieldKey: colData.key
                  }, null, 8 /* PROPS */, ["data", "fieldKey"])
                ]))
              : (_ctx.getCellMoney(colData.key))
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_GridCellMoney, {
                      data: { row: rowData },
                      groupKey: "curSideData",
                      fieldKey: colData.key
                    }, null, 8 /* PROPS */, ["data", "fieldKey"])
                  ]))
                : (colData.key=='photo')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_GridCellPhoto, {
                        data: { row: rowData }
                      }, null, 8 /* PROPS */, ["data"])
                    ]))
                  : (colData.key=='queue')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createVNode(_component_GridCellQueue, {
                          data: { row: rowData },
                          fieldKey: colData.key
                        }, null, 8 /* PROPS */, ["data", "fieldKey"])
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(rowData[colData.key]), 1 /* TEXT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["columns", "rows", "rowClassName", "onChangeSelectedRows", "onDblClickRow"])
  ]))
}