import { ElDialog } from "element-plus";
import 'element-plus/es/components/dialog/style/css';

import BsBtn from "../../controls/buttons/bs-btn/BsBtn.vue";
import OperMassPeriod from "./oper-mass-period/OperMassPeriod.vue";
import MassStaticTable from "./mass-static-table/MassStaticTable.vue";
import MassDigitalTable from "./mass-digital-table/MassDigitalTable.vue";

export default {
	name: 'oper-mass-employ-popup',
	components: {
		ElDialog,
		BsBtn, OperMassPeriod,
		MassStaticTable, MassDigitalTable,
	},
	props: {
		modelValue:{
			type: Boolean,
			default: false,
		},
		targetList: {
			type: Array,
			default: [],
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			period: {
				from: '',
				to: '',
			},
			selectionTableRow: [],
			type: 'static', // static, digital
		}
	},
	computed: {
		visible: {
			get(is){
				if(is) console.log(this.targetList);
				return this.modelValue;
			},
			set(is){
				this.$emit('update:modelValue', is);
			},
		},
		width(){
			if(this.type=='static') return 560;
			else if(this.type=='digital') return 1100;
		}
	},
	methods: {
		changeTable(data){
			this.selectionTableRow = data;
		},
		removeRow(rowData){
			console.log(rowData);
		},
		apply(){
			console.log(this.selectionTableRow);
			this.visible = false;
		}
	},

}