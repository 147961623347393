import { mapState, mapActions, mapWritableState } from 'pinia';
import { usePricingDetailStore } from '@/app/stores/pricing-policies/pricing-detail-store';

import dayjs from 'dayjs';

import EditField from '@/app/components/controls/edit-field/EditField.vue';
import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';
import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';
import TargetManager from '@/app/components/target-programs/target-summary/target-manager/TargetManager.vue';
import EditComment from '@/app/components/controls/edit-comment/EditComment.vue';

export default {
	name: 'pricing-summary',
	components: {
		EditField, BsSelectV2, BsCheckbox, TargetManager,
		EditComment,
	},
	computed: {
		...mapWritableState( usePricingDetailStore, ['summaryData'] ),

		date(){
			const { createDate, updateDate } = this.summaryData;

			return {
				create: dayjs(createDate).format('DD.MM.YYYY HH:mm'),
				update: dayjs(updateDate).format('DD.MM.YYYY HH:mm'),
			}
		}
	},
	methods: {
		changeEditField(data, key){
			console.log(data);

			if(key=='customers'){

			}
			else if(key=='comment'){
				this.summaryData.comment = data;
			}
			else {
				const { fieldData, value } = data;
				fieldData.value = value;
			}
		},
		changeShowAll(is){
			this.summaryData.isAllShow = is;
		},
		changeBanOnChange(is){
			this.summaryData.isBanOnChange = is;
		}
	},
}