import { ElPopover } from "element-plus";
import 'element-plus/es/components/popover/style/css';
import dayjs from 'dayjs';

import cellMixin from "@/app/mixins/table/cell-mixin";

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import ShortSelect from "@/app/components/controls/selects/short-select/ShortSelect.vue";
import SideQueueItem2 from "@/app/components/map/map-design-info/side-queue-v2/side-queue-item-2/SideQueueItem2.vue";

export default {
	name: 'tprog-month-status',
	mixins: [ cellMixin ],
	components: {
		ElPopover,
		SvgIcon, ShortSelect, SideQueueItem2,
	},
	props: {
		options: {
			type: Array,
			default: [],
		}
	},
	emits: ['change'],
	data() {
		return {
			fieldValue: '',
		}
	},
	computed: {
		/*
			{
				"my": false,
				"status": "free",
				"queue": {
					"title": {
						"position": 1,
						"expired": "2024-05-17"
					},
					"queueList": []
				}
			}
		*/
		isMy(){
			return this.fieldData?.my;
		},
		status(){
			return this.fieldData?.status ?? '';
		},
		monthStatus(){
			return this.fieldData?.monthStatus ?? '';
		},

		queue(){
			return this.fieldData?.queue ?? {};
		},
		label(){
			const findItem = this.options.find(item=>item.value==this.status);

			if( !this.isMy && this.status=='approved' ) return 'Занято';
			if( this.isMy && this.status=='reserved' ) return 'Мой резерв';
			return findItem?.label ?? '';
		},
		formatDate(){
			if(!this?.queue?.title?.expired) return '';
			const { expired } = this.queue.title;

			return dayjs(expired).format('DD.MM.YY');
		},

		statusData(){
			if(this.status.includes('free')) return {
				className: 'type-free',
			};
			else if(this.status.includes('temporary_approved')) return {
				className: 'type-agr-reserved',
			};
			else if(this.status.includes('reserved')) return {
				className: 'type-reserved',
			};
			else if(!this.isMy && this.status=='approved') return {
				className: 'type-busy',
			};
			else if(this.status.includes('approved')) return {
				className: 'type-confirm',
			};
			return {
				className: '',
			};
		},
	},
	methods: {
		changeSelect(value){
			this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: value,
			});
		}
	},
	watch: {
		fieldData(val){
			this.fieldValue = this.monthStatus;
			console.log(this.fieldValue);
		}
	},
	created() {
		if(this.fieldData?.monthStatus) this.fieldValue = this.monthStatus;
		console.log(this.fieldValue);
	},
}