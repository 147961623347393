import dayjs from 'dayjs';

import { ElPopover } from 'element-plus';
import 'element-plus/es/components/popover/style/css';

import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsDatePicker from '@/app/components/controls/bs-date-picker/BsDatePicker.vue';


export default {
	name: 'tprog-edit-date-popover',
	components: {
		ElPopover,
		BsDatePicker, BsBtn,
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:visible', 'change'],
	data() {
		return {
			date: '',
		}
	},
	computed: {
		isVisible: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			}
		},
	},
	methods: {
		clickOk(){
			this.isVisible = false;
			if(this.date) this.$emit('change', this.date);
		},
		disabledDateFrom(data){
			const today = dayjs(new Date()).format('YYYY-MM-DD');
			let nextDate = dayjs(today).toDate();
			// if(periodType=='day') nextDate = dayjs(nextDate).startOf('month').toDate();

			return nextDate > data;
		},
	},
	watch: {
		isVisible(is){
			if(is){
				this.date = '';
			}
		},
	},
}