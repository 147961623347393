export default {
	name: 'message-import-result',
	props: {
		/**
			{
				importedCount: number,
				addedCount: number,
				replacedCount: number,
				failedCount: number
			}
		 */
		data: {
			type: Object,
			default: {},
		}
	}
}