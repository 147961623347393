import cellMixin from "@/app/mixins/table/cell-mixin";

import ShortSelect from '@/app/components/controls/selects/short-select/ShortSelect.vue';

export default {
	name: 'tprog-cell-freq',
	mixins: [ cellMixin ],
	components: {
		ShortSelect,
	},
	props: {

	},
	emits: ['change'],
	data() {
		return {
			fieldValue: '',
		}
	},
	computed: {
		sideData(){
			return this.rowData?.sideData ?? {};
		},
		isDigital(){
			return !!this.sideData?.is_digital;
		},

		options(){
			const { blockDuration } = this.sideData;
			const options = [];

			if(!blockDuration) return options;

			for(let i=0; i<5; i++){
				// const value = 1/(i+1);
				const label = 3600/(blockDuration*(i+1));
				const value = label;

				if(String(label).includes('.')) continue;
				if(i==0){
					for(let j=4; j>1; j--){
						options.push({
							value: value*j,
							label: label*j
						});
					}
				}

				options.push({ value, label })
			}

			return options;
		},
	},
	methods: {
		changeSelect(value){
			this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: value,
			});
		}
	},
	watch: {
		fieldData(val){
			this.fieldValue = this.fieldData;
		}
	},
	created() {
		if(this.fieldData) this.fieldValue = this.fieldData;
	},
}