import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "import-cell-price" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ImportCellStatus = _resolveComponent("ImportCellStatus")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.label), 1 /* TEXT */),
    _createVNode(_component_ImportCellStatus, {
      data: { row: _ctx.rowData },
      groupKey: _ctx.groupKey,
      fieldKey: _ctx.fieldKey
    }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
  ]))
}