import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "target-manager-list" }
const _hoisted_2 = { class: "target-manager-list__empty" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TargetManager = _resolveComponent("TargetManager")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_DiEditSelect = _resolveComponent("DiEditSelect")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.managerList?.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.managerList, (item) => {
          return (_openBlock(), _createBlock(_component_TargetManager, {
            key: item.id,
            titleDialog: "Изменить список менеджеров",
            typeSelect: "multi",
            fieldData: item,
            isListAddition: true,
            fieldListData: _ctx.fieldData,
            onChange: _ctx.changeEdit,
            onOnAdd: _ctx.clickAdd,
            onOnRemove: _ctx.remove
          }, null, 8 /* PROPS */, ["fieldData", "fieldListData", "onChange", "onOnAdd", "onOnRemove"]))
        }), 128 /* KEYED_FRAGMENT */))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_2, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "target-manager-list__label" }, "пусто", -1 /* HOISTED */)),
            _createElementVNode("div", {
              class: "target-manager-list__btn-edit",
              onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickAdd && _ctx.clickAdd(...args))),
              title: "Добавить"
            }, [
              _createVNode(_component_SvgIcon, { name: "14-edit-pen" })
            ])
          ]),
          _createVNode(_component_DiEditSelect, {
            visible: _ctx.isVisible,
            "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.isVisible) = $event)),
            typeSelect: "multi",
            titleDialog: "Добавить менеджера/ов",
            labelActiveButton: "Добавить",
            fieldData: _ctx.fieldData,
            onChange: _ctx.changeEdit
          }, null, 8 /* PROPS */, ["visible", "fieldData", "onChange"])
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}