import { mapState, mapActions, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import helper from "@/app/utils/helper";

export default {
	name: 'target-statistics',
	data() {
		return {

		}
	},
	computed: {
		...mapState(useDetailTargetProgStore, ['detailTargetStatsData']),

		statsData(){
			return this.detailTargetStatsData?.statsData ?? {};
		},
		typeStatList(){
			const list = [];
			if(this.statsData?.byStructureTypes) for( const [key, item] of Object.entries(this.statsData.byStructureTypes)){
				list.push({
					id: key,
					label: item?.name ?? '',
					value: item?.value ?? 0,
				});
			}
			return list;
		},
		totalPrice(){
			return this.statsData?.sumCostWithVat ?? 0;
		},
		totalPriceNotVat(){
			return this.statsData?.sumCost ?? 0;
		},
	},
	methods: {
		getMoney: helper.getMoney,
	},
}