import { mapActions, mapState, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import dayjs from 'dayjs';

import { ElTable, ElTableColumn, } from "element-plus";
import 'element-plus/es/components/table/style/css';


import { colConfigList } from './table-config';
import helper from '@/app/utils/helper';

import TprogHeadDesign from '../tprog-detail-table/header-cells/tprog-head-design/TprogHeadDesign.vue';
import TprogHeadDefault from '../tprog-detail-table/header-cells/tprog-head-default/TprogHeadDefault.vue';
import TprogDesignInfo from '../tprog-detail-table/tprog-cell-types/tprog-design-info/TprogDesignInfo.vue';
import TprogMonthStatus from './tprog-cell-types/tprog-month-status/TprogMonthStatus.vue';

export default {
	name: 'tprog-month-matrix',
	components: {
		ElTable, ElTableColumn,
		TprogHeadDesign, TprogHeadDefault, TprogDesignInfo,
		TprogMonthStatus,
	},
	emits: ['selection-change'],
	data() {
		return {

		}
	},
	computed: {
		...mapWritableState(useDetailTargetProgStore, ['detailMonthSelectData', 'detailTargetGridData']),

		colConfigList(){
			const list = [...colConfigList];
			const { options } = this.detailMonthSelectData;
			// console.log(this.detailMonthSelectData.value);

			for(const item of options){
				const { value } = item;
				const titleMonth = dayjs(value).format('MMMM');

				list.push({
					key: 'statusMonth',
					date: value,
					isActive: value==this.detailMonthSelectData.value,
					// keyProp: 'sideData',
					label: helper.upFirstLetter(titleMonth),
					className: `js-month-${value}`,
					order: '',
					sort: false,
					show: true,
					width: 200,
				});
			}
			return list;
		},
	},
	methods: {
		selectionChange(list){
			console.log(list);
			this.$emit('selection-change', list);
		},

		getCellClass(col){
			const classList = [];

			if(col?.className) classList.push(col.className);
			if(col.key=='statusMonth' && col.isActive) classList.push('th-month-active')
			if(col.key=='designInfo') classList.push('td-design-info');

			return classList.join(' ');
		},

		scrollToMonth(){
			const { value } = this.detailMonthSelectData;
			const elCell = this.$el.querySelector(`.js-month-${value}`);
			if(!elCell) return;

			const elRow = elCell.closest('tr');
			const elScrollWrap = this.$el.querySelector('.el-scrollbar__wrap');
			const left = helper.getDistanceFromParentLeft(elRow, elCell);
			// let paddingLeft = 150;
			// if(this.isShowSecondAside) paddingLeft = 650;
			if(elScrollWrap) elScrollWrap.scrollLeft = left - 650;

			// console.log(left);
		},
	},
	watch: {
		['detailMonthSelectData.value'](val){
			if(val) this.scrollToMonth();
		}
	},
}