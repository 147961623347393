import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pr-cell-rules" }
const _hoisted_2 = {
  key: 0,
  class: "pr-cell-rules__info"
}
const _hoisted_3 = {
  key: 1,
  class: "pr-cell-rules__edit"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PrRulesInfo = _resolveComponent("PrRulesInfo")
  const _component_PrRulesEdit = _resolveComponent("PrRulesEdit")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_PrRulesInfo, {
            onEdit: _cache[0] || (_cache[0] = $event => (_ctx.isEdit = true)),
            list: _ctx.rulesList
          }, null, 8 /* PROPS */, ["list"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_PrRulesEdit, {
            data: _ctx.fieldData,
            onCancel: _ctx.cancelEdit,
            onSave: _ctx.saveRules
          }, null, 8 /* PROPS */, ["data", "onCancel", "onSave"])
        ]))
  ]))
}