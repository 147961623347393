import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tprog-cell-notific"
}
const _hoisted_2 = {
  key: 1,
  class: "tprog-cell-notific"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElBadge = _resolveComponent("ElBadge")

  return (_ctx.fieldData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ElBadge, {
          class: "tprog-cell-notific__badge",
          value: _ctx.count
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SvgIcon, {
              class: "tprog-cell-notific__icon-chat",
              name: "14-chat-dot-round"
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["value"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, "-"))
}