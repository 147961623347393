import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'map-marker-search',
	components: {
		SvgIcon,
	},
	props: {
		data: {
			type: Object,
			default: {},
		},
	},
	data() {
		return {
			isShow: false,
			isHover: false,
		}
	},
	computed: {
		markerSelector(){
			return `#search-marker-${this.data.code}`;
		},
		addressLabel(){
			return this.data?.label ?? '';
		}
	},
	methods: {
		hasMarkerContainer(){
			const markerContainer = document.querySelector(this.markerSelector);
			this.isShow = !!markerContainer;
		},

		mouseEnter(){
			this.isHover = true;
		},
		mouseLeave(){
			this.isHover = false;
		}
	},
	mounted(){
		const { placemark } = this.data;
		this.hasMarkerContainer();

		placemark.events.add('mouseenter', (e)=>{
			this.mouseEnter();
		});
		placemark.events.add('mouseleave', (e)=>{
			this.mouseLeave();
		});

		$(window).on(`update:map.${this.data.code}`, ()=>{
			this.hasMarkerContainer();
		});
	},
	unmounted() {
		const { placemark } = this.data;

		placemark.events.remove('mouseenter', (e)=>{
			this.mouseEnter();
		});
		placemark.events.remove('mouseleave', (e)=>{
			this.mouseLeave();
		});

		$(window).off(`update:map.${this.data.code}`);
	},
}