import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { useUserStore } from "@/app/stores/user-store";

const isDev = window.localStorage.getItem('dev');
// console.log(isDev);
const router = createRouter({
	history: createWebHistory(),
	routes,
});

if(!isDev) router.beforeEach(async (to) => {
	const publicPages = ['/sign-in'];
	const authRequired = !publicPages.includes(to.path);
	const auth = useUserStore();

	if (!auth.signed) {
		await auth.getUser();
	}

	if (auth.signed && to.fullPath === '/sign-in') {
		return '/structures';
	}

	if (authRequired && !auth.signed) {
		auth.setReturnUrl(to.fullPath);
		return '/sign-in';
	}
})

export default router;